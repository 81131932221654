import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

const styles = (theme) => ({
  poolPlatformFeeCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: "360px",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "30px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "26px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
    },
  },
  linksContainer: {
    width: "100%",
    display: "grid",
    gridGap: "15px",
    gridTemplateColumns: "auto",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "auto auto",
      gridGap: "20px",
    },
  },
  linkAddressPartItem: {
    display: "flex",
    flexDirection: "column",
  },
  infoHeading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "4px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8px",
    },
  },
  infoValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "15px",
    [theme.breakpoints.up("ms")]: {
      fontSize: "15px",
      lineHeight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
      lineHeight: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  connectContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  actionButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.presale?.buttonBg,
    border: "solid 2px" + theme?.colors?.presale?.buttonBg,
    color: theme?.colors?.presale?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    minWidth: "150px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.presale?.buttonBgHover,
    },
    fontFamily: "SFPro",
    letterSpacing: "0px",
    marginTop: "15px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "25px",
    },
  },
  buttonLabel: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  icon: {
    color: theme?.colors?.presale?.icon,
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
  },
  iconLink: {
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
    marginRight: "20px",
  },
  itemsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  investmentLogo: {
    width: "62px",
    height: "62px",
    marginBottom: "30px",
  },
  investmentItems: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginRight: "16px",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  heading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    textAlign: "center",
    fontSize: "15px",
    lineHeight: "18px",
    textTransform: "capitalize",
    marginBottom: "8px",
  },
  value: {
    color: theme?.colors?.presale?.addressValueText,
    textAlign: "center",
    fontSize: "25px",
    lineHeight: "30px",
    textTransform: "capitalize",
    marginBottom: "16px",
  },
});

const PlatformFeeCard = ({ classes, poolInfo }) => {
  const theme = useTheme();
  return (
    <div className={classes.poolPlatformFeeCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        Platform Fees
      </Typography>
      <div className={classes.itemsWrapper}>
        <img
          className={classes.investmentLogo}
          src={theme?.feesImg}
          alt="platform"
        />
        <div className={classes.investmentItems}>
          <div className={classes.leftContainer}>
            <Typography className={classes.heading}>Development</Typography>
            <Typography className={classes.value}>2.00 %</Typography>
            <Typography className={classes.heading}>Staking Rewards</Typography>
            <Typography className={classes.value}>0.5 %</Typography>
            <Typography className={classes.heading}>Unstaking Burn</Typography>
            <Typography className={classes.value}>0.5 %</Typography>
          </div>
          <div className={classes.rightContainer}>
            <Typography className={classes.heading}>
              START/wBNB Liquidity
            </Typography>
            <Typography className={classes.value}>1.00 %</Typography>
            <Typography className={classes.heading}>Buyback & Burn</Typography>
            <Typography className={classes.value}>0.5%</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(
  withRouter(withStyles(styles)(PlatformFeeCard))
);
