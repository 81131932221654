import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useHistory } from "react-router-dom";
import bigInt from "big-integer";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Typography,
  Button,
  Fade,
  Snackbar,
  useTheme,
  CircularProgress,
  Select,
  Input,
  MenuItem,
} from "@material-ui/core";

import { withNamespaces } from "react-i18next";

import Store from "../../stores/store";

import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  GET_POOLS_SUMMARY_RETURNED,
  GET_PUBLIC_PRESALE_INFO,
  GET_PUBLIC_PRESALE_INFO_RETURNED,
  WRITE,
  CUSTOM_WRITE,
} from "../../constants/constants";

import PresalePancakeCard from "./components/presalePancakeCard";
import PresaleInfoCard from "./components/presaleInfoCard";
import AuditCard from "./components/auditCard";
import StringCard from "./components/stringCard";
import StakingCard from "./components/stakingCard";
import FactoryCard from "./components/factoryCard";

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: "1140px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 4,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: "white",
    position: "absolute",
    top: "100px",
    left: "50%",
  },
  pageHeader: {
    width: "100%",
    textAlign: "left",
    fontSize: "30px",
    lineHeight: "36px",
    letterSpacing: "0px",
    color: theme?.colors?.apply?.cardTitleSecondary,
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "40px",
    },
  },
  presaleSelectBox: {
    display: "flex",
    width: "100%",
    maxWidth: "640px",
    flexDirection: "column",
    alignItems: "center",
  },
  dropdownHeader: {
    width: "100%",
    textAlign: "center",
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0px",
    color: theme?.colors?.apply?.cardTitleSecondary,
    marginBottom: "10px",
  },
  presaleDropdown: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "16px",
    ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "transparent !important",
    },
  },
  outlinedDropdown: {
    borderRadius: "14px",
    border: "solid 1px " + theme?.colors?.admin?.cardTitleSecondary,
    color: theme?.colors?.admin?.cardText,
    fontSize: "20px",
    outline: "0px",
    padding: "14px",
  },
  iconOutlined: {
    color: theme?.colors?.admin?.cardTitleSecondary,
  },
  dropdownRoot: {
    "& > .MuiOutlinedInput-root": {
      border: "transparent !important",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AdminPage = ({ classes, location }) => {
  const [loading, setLoading] = useState(true);
  const [presaleLoading, setPresaleLoading] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");
  const [account, setAccount] = useState(null);
  const [presales, setPresales] = useState([]);

  const [activePresales, setActivePresales] = useState([]);
  const [stakingPool, setStakingPool] = useState({});
  const [factoryPool, setFactoryPool] = useState({});

  const [curIndex, setCurIndex] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const publicPresales = store.getStore("publicPresales");
    const filteredPresales = Object.keys(publicPresales).map(
      (item) => publicPresales[item]
    );

    setPresales(filteredPresales);

    if (filteredPresales.length > 0) {
      setLoading(false);
    }

    emitter.on(GET_POOLS_SUMMARY_RETURNED, getPoolsSummaryReturned);
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    emitter.on(GET_PUBLIC_PRESALE_INFO_RETURNED, getPresaleInfoReturned);

    const account = store.getStore("account");
    if (account) {
      setAccount(account);
    }

    return () => {
      emitter.removeListener(
        GET_POOLS_SUMMARY_RETURNED,
        getPoolsSummaryReturned
      );
      emitter.removeListener(
        GET_PUBLIC_PRESALE_INFO_RETURNED,
        getPresaleInfoReturned
      );
    };
  }, []);

  const getPoolsSummaryReturned = () => {
    const publicPresales = store.getStore("publicPresales");
    const filteredPresales = Object.keys(publicPresales).map(
      (item) => publicPresales[item]
    );
    setPresales(filteredPresales);
    const _stakingPool = store.getStore("stakingPool");
    setStakingPool(_stakingPool);

    const _factoryPool = store.getStore("factoryPool");
    setFactoryPool(_factoryPool);

    setLoading(false);
  };

  useEffect(() => {
    const account = store.getStore("account");
    if (account && account.address && curIndex !== -1 && curIndex !== -2) {
      setPresaleLoading(true);
      dispatcher.dispatch({
        type: GET_PUBLIC_PRESALE_INFO,
        content: { presaleIndex: curIndex },
      });
    }
  }, [curIndex]);

  const getPresaleInfoReturned = () => {
    const publicPresales = store.getStore("publicPresales");
    setActivePresales(publicPresales);
    setPresaleLoading(false);
  };

  const connectionConnected = () => {
    setAccount(store.getStore("account"));
    dispatcher.dispatch({
      type: GET_PUBLIC_PRESALE_INFO,
      content: { presaleIndex: curIndex },
    });
  };

  const connectionDisconnected = () => {
    setAccount(null);
  };

  const onWrite = (method, args) => {
    dispatcher.dispatch({
      type: WRITE,
      content: {
        presaleIndex: curIndex,
        method: method,
        args: args,
      },
    });
  };

  const onCustomWrite = (address, abi, method, args) => {
    dispatcher.dispatch({
      type: CUSTOM_WRITE,
      content: {
        address,
        abi,
        method,
        args,
      },
    });
  };

  return (
    <div className={classes.root}>
      {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={3000}
          onClick={() => {
            setErrorMessage(false);
          }}
          onClose={() => {
            setErrorMessage(false);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      )}
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          {account && account.address && (
            <>
              {(presaleLoading || loading) && (
                <div className={classes.loadingContainer}>
                  <CircularProgress className={classes.loadingIcon} size={60} />
                </div>
              )}
              <Typography variant="h3" className={classes.pageHeader}>
                Admin Page
              </Typography>

              <div className={classes.presaleSelectBox}>
                <Typography variant="h5" className={classes.dropdownHeader}>
                  Select Presale for changes.
                </Typography>
                <div className={classes.presaleDropdown}>
                  <Select
                    variant="outlined"
                    id={`presale-label`}
                    value={curIndex}
                    onChange={(ev) => {
                      setCurIndex(ev.target.value);
                    }}
                    classes={{
                      outlined: classes.outlinedDropdown,
                      iconOutlined: classes.iconOutlined,
                    }}
                    input={<Input classes={{ root: classes.dropdownRoot }} />}
                  >
                    {presales.length > 0 &&
                      presales?.map((presale, index) => {
                        return (
                          <MenuItem value={presale.presaleIndex}>
                            <Typography className={classes.dropdownMenuItem}>
                              {presale.presaleIndex} - {presale.saleTitle} (
                              {presale.tokenAddress})
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    <MenuItem value={-1}>
                      <Typography className={classes.dropdownMenuItem}>
                        Staking - ({stakingPool && stakingPool.address})
                      </Typography>
                    </MenuItem>
                    <MenuItem value={-2}>
                      <Typography className={classes.dropdownMenuItem}>
                        Factory - ({factoryPool && factoryPool.address})
                      </Typography>
                    </MenuItem>
                  </Select>
                </div>
              </div>
              {curIndex === -1 && (
                <StakingCard pool={stakingPool} onWrite={onWrite} />
              )}
              {curIndex === -2 && (
                <FactoryCard
                  pool={factoryPool}
                  onWrite={onWrite}
                  onCustomWrite={onCustomWrite}
                />
              )}
              {activePresales[curIndex] && (
                <PresaleInfoCard
                  presale={activePresales[curIndex]}
                  onWrite={onWrite}
                />
              )}
              {activePresales[curIndex] && (
                <PresalePancakeCard
                  presale={activePresales[curIndex]}
                  onWrite={onWrite}
                />
              )}
              {activePresales[curIndex] &&
                activePresales[curIndex].auditInformation && (
                  <AuditCard
                    presale={activePresales[curIndex]}
                    onWrite={onWrite}
                  />
                )}
              {activePresales[curIndex] && (
                <StringCard
                  presale={activePresales[curIndex]}
                  onWrite={onWrite}
                />
              )}
            </>
          )}
          {(!account || !account.address) && (
            <div className={classes.warningContainer}>
              <Typography variant="h4" className={classes.warningText}>
                Please connect your wallet and set network to{" "}
                {theme?.chain || "Binance Smart Chain"}.
              </Typography>
            </div>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(AdminPage)));
