import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import "./i18n";
import {
  bscTheme,
  ethTheme,
  mtcTheme,
  ftmTheme,
  solTheme,
  avaxTheme,
  pulseTheme,
} from "./theme";

import TopSidebar from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home";
import Litepaper from "./components/litepaper";
import StartSeed from "./components/start/seed";
import StartPrivate from "./components/start/private";
import StartStrategic from "./components/start/strategic";
import WsbPrivate from "./components/wsb/wsbprivate";
import WisePublic from "./components/wise/wisepublic";
import VestPrivate from "./components/vest/private";
import VestSeed from "./components/vest/seed";
import GameWinSeed from "./components/gamewin/seed";
import StarchiSeed from "./components/starchi/seed";
import KryxiviaPrivateSale1 from "./components/kryxivia/private1";
import KryxiviaPrivateSale2 from "./components/kryxivia/private2";
import StarchiRd from "./components/starchi/rd";
import StarchiPrivate from "./components/starchi/private";
import StarchiVip from "./components/starchi/vip";
import StarchiMaven from "./components/starchi/maven";
import StarchiVbc from "./components/starchi/vbc";
import StarchiCryptozen from "./components/starchi/cryptozen";
import StarchiEmpire from "./components/starchi/empire";
import StarchiDarkpool from "./components/starchi/darkpool";
import StarchiTeam from "./components/starchi/team";

import StarzTeam from "./components/starz/team";
import StarzSuperVip from "./components/starz/superVip";
import StarzSc from "./components/starz/sc";

import BetswirlSeed from "./components/betswirl/seed";
import MetalaunchStarterCapital from "./components/metalaunch/startercapital";
import MetalaunchSuperVip from "./components/metalaunch/supervip";
import MetalaunchTeam from "./components/metalaunch/team";
import VoiceStreetSuperVip from "./components/voicestreet/supervip";
import VoiceStreetSc from "./components/voicestreet/startercapital";

import MetagamzSc from "./components/metagamz/sc";
import MetagamzSvip from "./components/metagamz/superVip";

import Apply from "./components/apply";
import Pools from "./components/pools";
import PresaleDetail from "./components/presale";
import Stats from "./components/stats";
import Voting from "./components/voting";
import Admin from "./components/admin";
import Advisory from "./components/advisor";
import Farming from "./components/farming/farming";

import {
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONFIGURE,
  CONFIGURE_RETURNED,
  GET_BALANCES,
  GET_BALANCES_RETURNED,
  GET_POOLS_SUMMARY,
  GET_POOLS_SUMMARY_RETURNED,
  THEME_CHANGE_RETURNED,
  THEME_CHANGE,
} from "./constants";

import Store from "./stores";

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

function App() {
  const [account, setAccount] = useState(null);
  const getThemeFromURL = (_url) => {
    const url = _url.toLowerCase();
    if (url.includes("bsc")) return "bsc";
    if (url.includes("mtc")) return "mtc";
    if (url.includes("eth")) return "eth";
    if (url.includes("ftm")) return "ftm";
    if (url.includes("avax")) return "avax";
    if (url.includes("pulse")) return "pulse";

    return null;
  };

  const getUrlFromTheme = (_themeType) => {
    const _type = _themeType.toLowerCase();
    if (_type.includes("bsc")) return "bsc";
    if (_type.includes("mtc")) return "mtc";
    if (_type.includes("eth")) return "eth";
    if (_type.includes("ftm")) return "ftm";
    if (_type.includes("avax")) return "avax";
    if (_type.includes("pulse")) return "pulse";

    return null;
  };

  const themes = {
    bsc: bscTheme,
    eth: ethTheme,
    mtc: mtcTheme,
    ftm: ftmTheme,
    sol: solTheme,
    avax: avaxTheme,
    pulse: pulseTheme,
  };
  const [defaultTheme, setDefaultTheme] = useState(
    themes[
      getThemeFromURL(window.location.hash)
        ? getThemeFromURL(window.location.hash)
        : localStorage.getItem("THEME_TYPE") || "mtc"
    ]
  );

  const location = useLocation();
  useEffect(() => {
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    emitter.on(CONFIGURE_RETURNED, configureReturned);
    emitter.on(GET_BALANCES_RETURNED, getBalancesReturned);
    emitter.on(GET_POOLS_SUMMARY_RETURNED, getPoolsSummaryReturned);
    emitter.on(THEME_CHANGE_RETURNED, themeChanged);

    dispatcher.dispatch({ type: GET_POOLS_SUMMARY, content: {} });
    return () => {
      emitter.removeListener(CONNECTION_CONNECTED, connectionConnected);
      emitter.removeListener(CONNECTION_DISCONNECTED, connectionDisconnected);
      emitter.removeListener(CONFIGURE_RETURNED, configureReturned);
      emitter.removeListener(GET_BALANCES_RETURNED, getBalancesReturned);
      emitter.removeListener(THEME_CHANGE_RETURNED, themeChanged);
    };
  }, []);

  useEffect(() => {
    if (location) {
      const _theme = getThemeFromURL(location.pathname);
      if (_theme && _theme !== store.getStore("themeType")) {
        setDefaultTheme(themes[_theme]);
        store.setStore({ themeType: _theme });
        store.initializeStore();
        store.getPoolsSummary();
        store.getStartStats();
      }
    }
  }, [location]);

  const getBalancesReturned = () => {
    window.setTimeout(() => {
      dispatcher.dispatch({ type: GET_BALANCES, content: {} });
    }, 15000);
  };

  const getPoolsSummaryReturned = () => {
    window.setTimeout(() => {
      dispatcher.dispatch({ type: GET_POOLS_SUMMARY, content: {} });
    }, 15000);
  };

  const configureReturned = () => {
    dispatcher.dispatch({ type: GET_BALANCES, content: {} });
  };

  const connectionConnected = () => {
    setAccount(store.getStore("account"));
    dispatcher.dispatch({ type: CONFIGURE, content: {} });
  };

  const connectionDisconnected = () => {
    setAccount(store.getStore("account"));
  };

  const getLibrary = (provider) => {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
  };

  const themeChanged = () => {
    setDefaultTheme(themes[store?.getStore("themeType") || "mtc"]);
  };

  return (
    <MuiThemeProvider theme={createMuiTheme(defaultTheme)}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <CssBaseline />
        <TopSidebar>
          <Switch>
            <Route path="/home">
              <Home />
            </Route>
            <Route exact path="/bsc">
              <Home />
            </Route>
            <Route exact path="/mtc">
              <Home />
            </Route>
            <Route exact path="/eth">
              <Home />
            </Route>
            <Route exact path="/ftm">
              <Home />
            </Route>
            <Route exact path="/avax">
              <Home />
            </Route>
            <Route exact path="/pulse">
              <Home />
            </Route>
            <Route path="/litepaper">
              <Litepaper />
            </Route>
            <Route exact path="/bsc/start-seed">
              <StartSeed />
            </Route>
            <Route exact path="/bsc/start-private">
              <StartPrivate />
            </Route>
            <Route exact path="/bsc/start-strategic">
              <StartStrategic />
            </Route>
            <Route exact path="/bsc/wsbclaims">
              <WsbPrivate />
            </Route>
            <Route exact path="/bsc/wisepublic">
              <WisePublic />
            </Route>
            <Route exact path="/bsc/vest-private">
              <VestPrivate />
            </Route>
            <Route exact path="/bsc/gamewin-sd">
              <GameWinSeed />
            </Route>
            <Route exact path="/mtc/starchi-sd">
              <StarchiSeed />
            </Route>
            <Route exact path="/mtc/starchi-rd">
              <StarchiRd />
            </Route>
            <Route exact path="/mtc/starchi-private">
              <StarchiPrivate />
            </Route>
            <Route exact path="/mtc/starchi-vip">
              <StarchiVip />
            </Route>
            <Route exact path="/mtc/starchi-maven">
              <StarchiMaven />
            </Route>
            <Route exact path="/mtc/starchi-vbc">
              <StarchiVbc />
            </Route>
            <Route exact path="/mtc/starchi-cryptozen">
              <StarchiCryptozen />
            </Route>
            <Route exact path="/mtc/starchi-empire">
              <StarchiEmpire />
            </Route>
            <Route exact path="/mtc/starchi-darkpool">
              <StarchiDarkpool />
            </Route>
            <Route exact path="/mtc/starchi-team">
              <StarchiTeam />
            </Route>
            <Route exact path="/bsc/vest-sd">
              <VestSeed />
            </Route>
            <Route exact path="/bsc/kryxivia-team">
              <KryxiviaPrivateSale1 />
            </Route>
            <Route exact path="/bsc/kryxivia-svip">
              <KryxiviaPrivateSale2 />
            </Route>
            <Route exact path="/bsc/metalaunch-sc">
              <MetalaunchStarterCapital />
            </Route>
            <Route exact path="/bsc/metalaunch-supervip">
              <MetalaunchSuperVip />
            </Route>
            <Route exact path="/bsc/metalaunch-team">
              <MetalaunchTeam />
            </Route>
            <Route exact path="/bsc/metalaunch-team">
              <MetalaunchTeam />
            </Route>
            <Route exact path="/bsc/voicestreet-sv">
              <VoiceStreetSuperVip />
            </Route>
            <Route exact path="/bsc/voicestreet-sc">
              <VoiceStreetSc />
            </Route>
            <Route exact path="/mtc/starz-team">
              <StarzTeam />
            </Route>
            <Route exact path="/mtc/starz-supervip">
              <StarzSuperVip />
            </Route>
            <Route exact path="/mtc/starz-sc">
              <StarzSc />
            </Route>
            <Route exact path="/mtc/betswirl-seed">
              <BetswirlSeed />
            </Route>
            <Route exact path="/avax/mg-sc">
              <MetagamzSc />
            </Route>
            <Route exact path="/avax/mg-svip">
              <MetagamzSvip />
            </Route>
            <Route path="/apply">
              <Apply />
            </Route>
            <Route path="/pools">
              <Pools />
            </Route>
            <Route path="/stats">
              <Stats />
            </Route>
            <Route path="/pool/:bscsId">
              <PresaleDetail />
            </Route>
            <Route path="/bsc/pool/:bscsId">
              <PresaleDetail />
            </Route>
            <Route path="/eth/pool/:bscsId">
              <PresaleDetail />
            </Route>
            <Route path="/mtc/pool/:bscsId">
              <PresaleDetail />
            </Route>
            <Route path="/ftm/pool/:bscsId">
              <PresaleDetail />
            </Route>
            <Route path="/avax/pool/:bscsId">
              <PresaleDetail />
            </Route>
            <Route path="/voting">
              <Voting />
            </Route>
            <Route path="/admin">
              <Admin />
            </Route>
            <Route path="/advisors">
              <Advisory />
            </Route>
            <Route path="/mining">
              <Farming />
            </Route>
            <Redirect to="/home" />
          </Switch>
        </TopSidebar>
      </Web3ReactProvider>
    </MuiThemeProvider>
  );
}

export default App;
