import React from "react";
import {
  DialogContent,
  Dialog,
  Slide,
  Divider,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Unlock from "./unlock.jsx";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const UnlockModal = ({ closeModal, modalOpen }) => {
  const fullScreen = window.innerWidth < 450;

  return (
    <Dialog
      open={modalOpen}
      fullWidth={false}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      onClose={closeModal}
    >
      <div
        style={{
          display: "flex",
          padding: "12px 20px",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "1rem",
          width: "100%",
        }}
      >
        <span>Connect to a wallet</span>
        <IconButton
          onClick={() => {
            closeModal();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <DialogContent>
        <Unlock closeModal={closeModal} />
      </DialogContent>
    </Dialog>
  );
};

export default UnlockModal;
