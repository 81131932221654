import config from "../configure/config";
import bigInt from "big-integer";
import Web3 from "web3";
import rp from "request-promise";

import {
  ERROR,
  GET_BALANCES,
  GET_PRESALE_INFO,
  GET_PUBLIC_PRESALE_INFO,
  GET_POOLS_SUMMARY,
  GET_FARMING_INFO,
  coingeckoPriceUrls,
  defaultFundingAddress,
  isSameAddress,
} from "../constants";

export const _getBnbPrice = async (themeType, callback) => {
  try {
    const priceString = await rp(coingeckoPriceUrls[themeType].url);
    const priceJSON = JSON.parse(priceString);
    if (priceJSON) {
      const value =
        priceJSON[coingeckoPriceUrls[themeType].id] &&
        priceJSON[coingeckoPriceUrls[themeType].id].usd;
      callback(null, value);
    }
  } catch (e) {
    console.log("Get BNB Price Error", e);
    callback(e);
  }
};

export const _getSTARTPrice = async (callback) => {
  try {
    const url =
      "https://api.coingecko.com/api/v3/simple/price?ids=bscstarter&vs_currencies=usd";
    const priceString = await rp(url);
    const priceJSON = JSON.parse(priceString);
    if (priceJSON) {
      const value = priceJSON.bscstarter && priceJSON.bscstarter.usd;
      callback(null, value);
    }
  } catch (e) {
    console.log("Get START Price Error", e);
    callback(e);
  }
};

export const _getCirculatingSupply = async () => {
  try {
    const url = "https://api.starter.xyz/supply";
    const circulatingString = await rp(url);
    return circulatingString;
  } catch (e) {
    console.log("Get Circulating Supply Error", e);
    return 0;
  }
};

export const _getPoolInfo = async (web3, pool, account, callback) => {
  let StarterContract = new web3.eth.Contract(pool.abi, pool.address);
  try {
    const withdrawLimit = await StarterContract.methods
      .withdrawalLimitPercent()
      .call({
        from: account.address,
      });
    const poolStructure = await StarterContract.methods
      .poolInfo(pool.poolIndex)
      .call({
        from: account.address,
      });
    const balanceOfPool = await StarterContract.methods
      .balanceOfPool(pool.poolIndex)
      .call({
        from: account.address,
      });
    callback(null, {
      withdrawLimit,
      balanceOfPool,
      ...poolStructure,
    });
  } catch (err) {
    console.log("_getPoolInfo error", err);
    return callback(err);
  }
};

export const _callClaimReward = async (
  store,
  dispatcher,
  emitter,
  presaleIndex,
  account,
  presaleType,
  callback
) => {
  const web3 = new Web3(store.getStore("web3context").library.provider);
  const presales =
    presaleType === "private"
      ? store.getStore("presales")
      : store.getStore("publicPresales");
  const factory = store.getStore("factory");
  const starterInfoContract = new web3.eth.Contract(
    factory.starterInfoAbi,
    factory.starterInfoAddress
  );
  const presale = presales[presaleIndex];
  let presaleAddress;
  if (presaleType === "private") {
    presaleAddress = presale.presaleAddress;
  } else {
    presaleAddress = await starterInfoContract.methods
      .getPresaleAddress(presaleIndex)
      .call();
  }
  const presaleContract =
    presaleType === "private"
      ? new web3.eth.Contract(presale.presaleAbi, presale.presaleAddress)
      : new web3.eth.Contract(
          _getPresaleAbiByBscsId(store, presaleIndex),
          presaleAddress
        );
  if (presaleContract.methods.claimTokens) {
    presaleContract.methods
      .claimTokens()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_PRESALE_INFO,
            content: {
              presaleIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  }
};

export const _callClaimHodler = async (
  store,
  dispatcher,
  emitter,
  account,
  callback
) => {
  const web3 = new Web3(store.getStore("web3context").library.provider);
  const factory = store.getStore("factory");

  const factoryContract = new web3.eth.Contract(
    factory.factoryV3Abi,
    factory.factoryV3Address
  );

  if (factoryContract.methods.claimHodlerFund) {
    factoryContract.methods
      .claimHodlerFund()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  }
};

export const _getHodlerReward = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  const themeType = store.getStore("themeType");
  if (themeType === "bsc" || themeType == "pulse") {
    const starterInfoAbi = store.getStore("StarterInfoAbi");
    const starterInfoAddress = store.getStore("StarterInfoAddress");

    const factory = store.getStore("factory");

    const starterInfoContract = new web3.eth.Contract(
      starterInfoAbi,
      starterInfoAddress
    );

    const factoryBalance = await web3.eth.getBalance(factory.factoryV3Address);

    try {
      if (account && account.address) {
        let lockedBalance = await starterInfoContract.methods
          .getLockedBalance(account.address)
          .call();
        let stakedAndLockedBalance = await starterInfoContract.methods
          .getBscsStakedAndLocked(account.address)
          .call();
        const info = {
          factoryBalance: bigInt(factoryBalance),
          lockedBalance: bigInt(lockedBalance),
          stakedAndLockedBalance: bigInt(stakedAndLockedBalance),
        };
        callback(null, info);
      } else {
        let lockedBalance = "0";
        let stakedAndLockedBalance = "0";
        const info = {
          factoryBalance: bigInt(factoryBalance),
          lockedBalance: bigInt(lockedBalance),
          stakedAndLockedBalance: bigInt(stakedAndLockedBalance),
        };
        callback(null, info);
      }
    } catch (ex) {
      return callback(ex);
    }
  } else if (
    themeType === "mtc" ||
    themeType === "eth" ||
    themeType === "ftm" ||
    themeType === "avax"
  ) {
    const starterInfoAbi = store.getStore("StarterInfoAbi");
    const starterInfoAddress = store.getStore("StarterInfoAddress");

    const factory = store.getStore("factory");

    const starterInfoContract = new web3.eth.Contract(
      starterInfoAbi,
      starterInfoAddress
    );

    const factoryBalance = await web3.eth.getBalance(factory.factoryV3Address);
    const StakingTokenAddress = store.getStore("StakingToken");

    try {
      let lockedBalance = bigInt(0);
      if (account && account.address) {
        let stakedAndLockedBalance = "0";

        try {
          stakedAndLockedBalance = await starterInfoContract.methods
            .getStartLpStaked(StakingTokenAddress, account.address)
            .call();
        } catch (error) {
          stakedAndLockedBalance = 0;
        }

        const info = {
          factoryBalance: bigInt(factoryBalance),
          lockedBalance: bigInt(lockedBalance),
          stakedAndLockedBalance: bigInt(stakedAndLockedBalance),
        };
        callback(null, info);
      } else {
        let stakedAndLockedBalance = bigInt(0);
        const info = {
          factoryBalance: bigInt(factoryBalance),
          lockedBalance: bigInt(lockedBalance),
          stakedAndLockedBalance: bigInt(stakedAndLockedBalance),
        };
        callback(null, info);
      }
    } catch (ex) {
      return callback(ex);
    }
  }
};

export const _getStakingPool = async (
  store,
  dispatcher,
  emitter,
  web3,
  callback
) => {
  const themeType = store.getStore("themeType");
  if (themeType === "bsc" || themeType == "pulse") {
    const stakingTokenAddress = store.getStore("StakingToken");
    const stakingTokenName = store.getStore("StakingTokenName");
    const contractAddress = store.getStore("StakedContractV3Address");
    try {
      const info = {
        address: contractAddress,
        name: stakingTokenName,
        tokenAddress: stakingTokenAddress,
      };
      callback(null, info);
    } catch (ex) {
      return callback(ex);
    }
  } else if (
    themeType === "mtc" ||
    themeType === "eth" ||
    themeType === "ftm" ||
    themeType === "avax"
  ) {
    const stakingTokenAddress = store.getStore("StakingToken");
    const contractAddress = store.getStore("StakedContractAddress");
    const stakingTokenName = store.getStore("StakingTokenName");
    try {
      const info = {
        address: contractAddress,
        name: stakingTokenName,
        tokenAddress: stakingTokenAddress,
      };
      callback(null, info);
    } catch (ex) {
      return callback(ex);
    }
  }
};

export const _getFactoryPool = async (
  store,
  dispatcher,
  emitter,
  web3,
  callback
) => {
  const themeType = store.getStore("themeType");
  if (themeType === "bsc" ||  themeType == "pulse") {
    const factory = store.getStore("factory");

    const contractAbi = factory.factoryV3Abi;
    const contractAddress = factory.factoryV3Address;

    let factoryContract = new web3.eth.Contract(contractAbi, contractAddress);
    try {
      const stakingPool = await factoryContract.methods
        .bscsStakingPool()
        .call();
      const starterInfo = await factoryContract.methods.BSCS().call();
      const owner = await factoryContract.methods.owner().call();
      const pendingOwner = await factoryContract.methods.pendingOwner().call();
      const info = {
        address: contractAddress,
        stakingPool,
        starterInfo,
        owner,
        pendingOwner,
      };
      callback(null, info);
    } catch (ex) {
      return callback(ex);
    }
  } else if (
    themeType === "mtc" ||
    themeType === "eth" ||
    themeType === "ftm" ||
    themeType === "avax"
  ) {
    const factory = store.getStore("factory");
    const stakedContractAddress = store.getStore("StakedContractAddress");
    const starterInfoAddress = store.getStore("StarterInfoAddress");

    const contractAbi = factory.factoryV3Abi;
    const contractAddress = factory.factoryV3Address;

    let factoryContract = new web3.eth.Contract(contractAbi, contractAddress);
    try {
      const stakingPool = stakedContractAddress;
      const starterInfo = starterInfoAddress;
      const owner = await factoryContract.methods.owner().call();
      const pendingOwner = await factoryContract.methods.pendingOwner().call();
      const info = {
        address: contractAddress,
        stakingPool,
        starterInfo,
        owner,
        pendingOwner,
      };
      callback(null, info);
    } catch (ex) {
      return callback(ex);
    }
  }
};

export const _callFarmClaim = async (
  store,
  dispatcher,
  emitter,
  web3,
  farmingContract,
  account,
  callback
) => {
  try {
    farmingContract.methods
      .claim()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_FARMING_INFO,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("FARM_CLAIM ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _getBnbBalance = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  try {
    const balance =
      account && account.address
        ? await web3.eth.getBalance(account.address)
        : new bigInt();
    callback(null, bigInt(balance));
  } catch (er) {
    return callback(er);
  }
};

export const _getSTARTBalance = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  const tokenAbi = store.getStore("tokenAbi");
  const tokenAddress = store.getStore("tokenAddress");
  const themeType = store.getStore("themeType");
  const stakingTokenAddress = store.getStore("StakingToken");

  let walletContract = new web3.eth.Contract(tokenAbi, tokenAddress);
  let stakingTokenContract = new web3.eth.Contract(
    config[themeType].ERC20Abi,
    stakingTokenAddress
  );

  try {
    if (account && account.address) {
      let balance = await walletContract.methods
        .balanceOf(account.address)
        .call();
      const stakingContracts = config[themeType].stakingContracts;

      let totalStakedLocked = bigInt(0);
      for (let i = 0; i < stakingContracts.length; i++) {
        const amount = await stakingTokenContract.methods
          .balanceOf(stakingContracts[i])
          .call();
        totalStakedLocked += bigInt(amount);
      }
      let stakingTokenBalance = await stakingTokenContract.methods
        .balanceOf(account.address)
        .call();

      callback(null, {
        balance: bigInt(balance),
        stakingTokenBalance: bigInt(stakingTokenBalance),
        totalStakedLocked,
      });
    } else {
      let balance = "0";
      const stakingContracts = config[themeType].stakingContracts;

      let totalStakedLocked = bigInt(0);
      for (let i = 0; i < stakingContracts.length; i++) {
        const amount = await stakingTokenContract.methods
          .balanceOf(stakingContracts[i])
          .call();
        totalStakedLocked += bigInt(amount);
      }
      let stakingTokenBalance = "0";

      callback(null, {
        balance: bigInt(balance),
        stakingTokenBalance: bigInt(stakingTokenBalance),
        totalStakedLocked,
      });
    }
  } catch (ex) {
    return callback(ex);
  }
};

export const _getStakedBalance = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  const themeType = store.getStore("themeType");
  if (themeType === "bsc" ||  themeType == "pulse") {
    const contractAbi = store.getStore("StakedContractV3Abi");
    const contractAddress = store.getStore("StakedContractV3Address");

    const v1ContractAbi = store.getStore("StakedContractV2Abi");
    const v1ContractAddress = store.getStore("StakedContractV2Address");

    const starterInfoAbi = store.getStore("StarterInfoAbi");
    const starterInfoAddress = store.getStore("StarterInfoAddress");

    const stakingTokenAddress = store.getStore("StakingToken");

    let v1StakedContract = new web3.eth.Contract(
      v1ContractAbi,
      v1ContractAddress
    );
    let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);
    let starterContract = new web3.eth.Contract(
      starterInfoAbi,
      starterInfoAddress
    );
    try {
      if (account && account.address) {
        let v1StakedInfo = await v1StakedContract.methods
          .accountInfos(account.address)
          .call({
            from: account.address,
          });

        let stakedInfo = await stakedContract.methods
          .accountInfos(account.address)
          .call({
            from: account.address,
          });

        stakedInfo.totalStakedBalance = stakedInfo.balance;
        if (starterContract.methods.getBscsStakedAndLocked) {
          stakedInfo.totalStakedBalance = await starterContract.methods
            .getBscsStakedAndLocked(account.address)
            .call();
        }

        let firstStakedTimestamp = await stakedContract.methods
          .firstStakedTimestamp(account.address)
          .call({
            from: account.address,
          });

        let isLongStaker = await stakedContract.methods
          .isLongStaker(account.address)
          .call({
            from: account.address,
          });

        let burnFee = await stakedContract.methods
          .getBurnFee(account.address)
          .call({
            from: account.address,
          });

        let minStakeTime = await starterContract.methods
          .getMinStakeTime()
          .call();
        let minUnstakeTime = await starterContract.methods
          .getMinUnstakeTime()
          .call();

        const info = {
          v1Balance: bigInt(v1StakedInfo.balance),
          balance: bigInt(stakedInfo.balance),
          totalStakedBalance: bigInt(stakedInfo.totalStakedBalance),
          lastStakedTime: parseInt(stakedInfo.lastStakedTimestamp) || 0,
          lastUnstakedTime: parseInt(stakedInfo.lastUnstakedTimestamp) || 0,
          minStakeTime: parseInt(minStakeTime),
          minUnstakeTime: parseInt(minUnstakeTime),
          firstStakedTimestamp: parseInt(firstStakedTimestamp),
          isLongStaker,
          burnFee: parseInt(burnFee) / 100,
        };
        callback(null, info);
      } else {
        let minStakeTime = await starterContract.methods
          .getMinStakeTime()
          .call();
        let minUnstakeTime = await starterContract.methods
          .getMinUnstakeTime()
          .call();

        const info = {
          v1Balance: bigInt("0"),
          balance: bigInt("0"),
          totalStakedBalance: bigInt(0),
          lastStakedTime: 0,
          lastUnstakedTime: 0,
          minStakeTime: parseInt(minStakeTime),
          minUnstakeTime: parseInt(minUnstakeTime),
          firstStakedTimestamp: 0,
          isLongStaker: false,
          burnFee: 0,
        };
        callback(null, info);
      }
    } catch (ex) {
      return callback(ex);
    }
  } else if (
    themeType === "mtc" ||
    themeType === "eth" ||
    themeType === "ftm" ||
    themeType === "avax"
  ) {
    const contractAbi = store.getStore("StakedContractV3Abi");
    const contractAddress = store.getStore("StakedContractV3Address");

    const v1ContractAbi = store.getStore("StakedContractV2Abi");
    const v1ContractAddress = store.getStore("StakedContractV2Address");

    let v1StakedContract = new web3.eth.Contract(
      v1ContractAbi,
      v1ContractAddress
    );

    const starterInfoAbi = store.getStore("StarterInfoAbi");
    const starterInfoAddress = store.getStore("StarterInfoAddress");

    const stakingTokenAddress = store.getStore("StakingToken");
    let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);
    let starterContract = new web3.eth.Contract(
      starterInfoAbi,
      starterInfoAddress
    );
    try {
      if (account && account.address) {
        let v1StakedInfo = await v1StakedContract.methods
          .accountLpInfos(stakingTokenAddress, account.address)
          .call({
            from: account.address,
          });

        let stakedInfo = await stakedContract.methods
          .accountLpInfos(stakingTokenAddress, account.address)
          .call({
            from: account.address,
          });

        stakedInfo.totalStakedBalance = stakedInfo.balance;
        if (starterContract.methods.getStartLpStaked) {
          try {
            stakedInfo.totalStakedBalance = await starterContract.methods
              .getStartLpStaked(stakingTokenAddress, account.address)
              .call();
          } catch (error) {
            stakedInfo.totalStakedBalance = stakedInfo.balance;
          }
        }

        let firstStakedTimestamp = await stakedContract.methods
          .firstStakedTimestamp(stakingTokenAddress, account.address)
          .call({
            from: account.address,
          });

        let isLongStaker = await stakedContract.methods
          .isLongStaker(stakingTokenAddress, account.address)
          .call({
            from: account.address,
          });

        let burnFee = await stakedContract.methods
          .getLpBurnFee(stakingTokenAddress, account.address)
          .call({
            from: account.address,
          });

        let minStakeTime = await starterContract.methods
          .getMinStakeTime()
          .call();
        let minUnstakeTime = await starterContract.methods
          .getMinUnstakeTime()
          .call();

        const info = {
          v1Balance: bigInt(v1StakedInfo.balance),
          balance: bigInt(stakedInfo.balance),
          totalStakedBalance: bigInt(stakedInfo.totalStakedBalance),
          lastStakedTime: parseInt(stakedInfo.lastStakedTimestamp) || 0,
          lastUnstakedTime: parseInt(stakedInfo.lastUnstakedTimestamp) || 0,
          minStakeTime: parseInt(minStakeTime),
          minUnstakeTime: parseInt(minUnstakeTime),
          firstStakedTimestamp: parseInt(firstStakedTimestamp),
          isLongStaker,
          burnFee: parseInt(burnFee) / 100,
        };
        callback(null, info);
      } else {
        let minStakeTime = await starterContract.methods
          .getMinStakeTime()
          .call();
        let minUnstakeTime = await starterContract.methods
          .getMinUnstakeTime()
          .call();

        const info = {
          v1Balance: bigInt(),
          balance: bigInt(),
          totalStakedBalance: bigInt(),
          lastStakedTime: 0,
          lastUnstakedTime: 0,
          minStakeTime: parseInt(minStakeTime),
          minUnstakeTime: parseInt(minUnstakeTime),
          firstStakedTimestamp: 0,
          isLongStaker: false,
          burnFee: 50,
        };
        callback(null, info);
      }
    } catch (ex) {
      return callback(ex);
    }
  }
};

export const _getSwapData = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  const themeType = store.getStore("themeType");
  const startTokenAddress = store.getStore("tokenAddress");
  const swapData = store.getStore("swapData");

  const swapTokenContract = new web3.eth.Contract(
    swapData.swapTokenAbi,
    swapData.swapTokenAddress
  );
  const swapRouterContract = new web3.eth.Contract(
    swapData.swapRouterAbi,
    swapData.swapRouterAddress
  );
  const path = [swapData.swapTokenAddress, startTokenAddress];
  try {
    if (account && account.address) {
      const swapTokenBalance =
        swapData.swapTokenAddress === defaultFundingAddress[themeType]
          ? await web3.eth.getBalance(account.address)
          : await swapTokenContract.methods.balanceOf(account.address).call();

      const amountsOut = await swapRouterContract.methods
        .getAmountsOut(web3.utils.toWei("1"), path)
        .call();
      const swapPrice = amountsOut[1];
      const info = {
        ...swapData,
        swapTokenBalance: bigInt(swapTokenBalance),
        swapPrice: bigInt(swapPrice),
      };
      callback(null, info);
    } else {
      const swapTokenBalance = bigInt();

      const amountsOut = await swapRouterContract.methods
        .getAmountsOut(web3.utils.toWei("1"), path)
        .call();
      const swapPrice = amountsOut[1];
      const info = {
        ...swapData,
        swapTokenBalance: bigInt(swapTokenBalance),
        swapPrice: bigInt(swapPrice),
      };
      callback(null, info);
    }
  } catch (ex) {
    return callback(ex);
  }
};

export const _callSwap = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  swapRouterContract,
  path,
  inputToken,
  outputToken,
  amount,
  amountOut,
  callback
) => {
  try {
    const themeType = store.getStore("themeType");
    const timestamp = parseInt(Date.now() / 1000);
    if (
      web3.utils.toChecksumAddress(inputToken) ===
      web3.utils.toChecksumAddress(defaultFundingAddress[themeType])
    ) {
      if (swapRouterContract.methods.swapExactETHForTokens) {
        swapRouterContract.methods
          .swapExactETHForTokens(
            amountOut,
            path,
            account.address,
            timestamp + 600
          )
          .send({
            from: account.address,
            value: amount,
            gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
            }
          })
          .on("receipt", function (receipt) {
            console.log(receipt);
            callback(null, receipt);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      } else if (swapRouterContract.methods.swapExactAVAXForTokens) {
        swapRouterContract.methods
          .swapExactAVAXForTokens(
            amountOut,
            path,
            account.address,
            timestamp + 600
          )
          .send({
            from: account.address,
            value: amount,
            gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
            }
          })
          .on("receipt", function (receipt) {
            console.log(receipt);
            callback(null, receipt);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      }
    } else if (
      web3.utils.toChecksumAddress(outputToken) ===
      web3.utils.toChecksumAddress(defaultFundingAddress[themeType])
    ) {
      if (swapRouterContract.methods.swapExactTokensForETH) {
        swapRouterContract.methods
          .swapExactTokensForETH(
            amount,
            amountOut,
            path,
            account.address,
            timestamp + 600
          )
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
            }
          })
          .on("receipt", function (receipt) {
            console.log(receipt);
            callback(null, receipt);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      } else if (swapRouterContract.methods.swapExactTokensForAVAX) {
        swapRouterContract.methods
          .swapExactTokensForAVAX(
            amount,
            amountOut,
            path,
            account.address,
            timestamp + 600
          )
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
            }
          })
          .on("receipt", function (receipt) {
            console.log(receipt);
            callback(null, receipt);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      }
    } else {
      swapRouterContract.methods
        .swapExactTokensForTokens(
          amount,
          amountOut,
          path,
          account.address,
          timestamp + 600
        )
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("transactionHash", function (hash) {
          console.log(hash);
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 3) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          callback(null, receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    }
  } catch (error) {
    console.error("_callSwap", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callFarmWithdraw = async (
  store,
  dispatcher,
  emitter,
  web3,
  farmingContract,
  account,
  callback
) => {
  try {
    farmingContract.methods
      .withdraw()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_FARMING_INFO,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("FARM_WITHDRAW ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callFarmStake = async (
  store,
  dispatcher,
  emitter,
  web3,
  farmingContract,
  amount,
  account,
  callback
) => {
  try {
    farmingContract.methods
      .stake()
      .send({
        from: account.address,
        value: amount,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_FARMING_INFO,
            content: {},
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("FARM_STAKE ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callApply = async (
  store,
  dispatcher,
  emitter,
  web3,
  factoryContract,
  account,
  bscPresaleInfo,
  presalePancakeSwapInfo,
  presaleStringInfo,
  callback
) => {
  const themeType = store.getStore("themeType");
  try {
    if (themeType === "bsc" ||  themeType == "pulse") {
      factoryContract.methods
        .createPresale(
          bscPresaleInfo,
          presalePancakeSwapInfo,
          presaleStringInfo
        )
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_POOLS_SUMMARY,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(
            receipt,
            receipt.events.PresaleCreated.returnValues.bscsId
          );
          callback(null, receipt.events.PresaleCreated.returnValues.bscsId);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    } else if (
      themeType === "mtc" ||
      themeType === "eth" ||
      themeType === "ftm" ||
      themeType === "avax"
    ) {
      factoryContract.methods
        .createPresale(
          bscPresaleInfo,
          presalePancakeSwapInfo,
          presaleStringInfo
        )
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_POOLS_SUMMARY,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(
            receipt,
            receipt.events.PresaleCreated.returnValues.starterId
          );
          callback(null, receipt.events.PresaleCreated.returnValues.starterId);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    }
  } catch (error) {
    console.log("Apply error", error);
    callback(error);
  }
};

export const _checkApproval = async (
  store,
  dispatcher,
  emitter,
  abi,
  address,
  account,
  amount,
  contract
) => {
  try {
    const web3 = new Web3(store.getStore("web3context").library.provider);
    const erc20Contract = new web3.eth.Contract(abi, address);

    if (!erc20Contract.methods.allowance) {
      return true; // Doesn't need approval
    }
    const allowance = await erc20Contract.methods
      .allowance(account.address, contract)
      .call({
        from: account.address,
      });
    if (bigInt(allowance).lesser(amount)) {
      await erc20Contract.methods
        .approve(contract, web3.utils.toWei("9999999999", "ether"))
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        });
    }
    return true;
  } catch (error) {
    console.log(error);
    if (error.message) {
      return false;
    }
    return false;
  }
};

export const _callVote = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  presaleIndex,
  voteType,
  callback
) => {
  try {
    presaleContract.methods
      .vote(voteType)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_PRESALE_INFO,
            content: {
              presaleIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("VOTE ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callUnstake = async (
  store,
  dispatcher,
  emitter,
  web3,
  stakingContract,
  amount,
  tokenAddress,
  account,
  callback
) => {
  const themeType = store.getStore("themeType");

  try {
    if (themeType === "bsc" ||  themeType == "pulse") {
      stakingContract.methods
        .unstake(amount)
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          callback(null, receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    } else if (
      themeType === "mtc" ||
      themeType === "eth" ||
      themeType === "ftm" ||
      themeType === "avax"
    ) {
      stakingContract.methods
        .unstake(tokenAddress, amount)
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          callback(null, receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    }
  } catch (error) {
    console.log("_callUnstake error", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callUnstakeV1 = async (
  store,
  dispatcher,
  emitter,
  web3,
  stakingContract,
  amount,
  tokenAddress,
  account,
  callback
) => {
  const themeType = store.getStore("themeType");
  try {
    if (themeType === "bsc" ||  themeType == "pulse") {
      stakingContract.methods
        .unstake(amount)
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          callback(null, receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    } else if (
      themeType === "mtc" ||
      themeType === "eth" ||
      themeType === "ftm" ||
      themeType === "avax"
    ) {
      stakingContract.methods
        .unstake(tokenAddress, amount)
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          callback(null, receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    }
  } catch (error) {
    console.log("_callUnstake error", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callMigrate = async (
  store,
  dispatcher,
  emitter,
  web3,
  stakingContract,
  tokenAddress,
  amount,
  account,
  callback
) => {
  const themeType = store.getStore("themeType");
  try {
    if (themeType === "bsc" ||  themeType == "pulse") {
      stakingContract.methods
        .migrateFromV2(amount)
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          callback(null, receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    } else if (
      themeType === "mtc" ||
      themeType === "eth" ||
      themeType === "ftm" ||
      themeType === "avax"
    ) {
      stakingContract.methods
        .stake(tokenAddress, amount)
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          callback(null, receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    }
  } catch (error) {
    console.log("_callStake error", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callStake = async (
  store,
  dispatcher,
  emitter,
  web3,
  stakingContract,
  amount,
  stakingToken,
  account,
  callback
) => {
  const themeType = store.getStore("themeType");
  try {
    if (themeType === "bsc" ||  themeType == "pulse") {
      stakingContract.methods
        .stake(amount)
        // .migrateFromV2(amount)
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          callback(null, receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    } else if (
      themeType === "mtc" ||
      themeType === "eth" ||
      themeType === "ftm" ||
      themeType === "avax"
    ) {
      stakingContract.methods
        .stake(stakingToken, amount)
        // .migrateFromV2(amount)
        .send({
          from: account.address,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 2) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
          }
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
          callback(null, receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    }
  } catch (error) {
    console.log("_callStake error", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callLockLiquidity = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  presaleIndex,
  callback
) => {
  try {
    presaleContract.methods
      .addLiquidityAndLockLPTokens()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_PRESALE_INFO,
            content: {
              presaleIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("LOCK_LIQUIDITY ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callGetRefund = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  presaleIndex,
  callback
) => {
  try {
    presaleContract.methods
      .getRefund()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_PRESALE_INFO,
            content: {
              presaleIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("GET_REFUND ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callInvest = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  presaleIndex,
  presaleType,
  fundingTokenAddress,
  amount,
  callback
) => {
  const themeType = store.getStore("themeType");

  if (themeType === "bsc" ||  themeType == "pulse") {
    try {
      presaleContract.methods
        .invest()
        .send({
          from: account.address,
          value: amount,
          gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
        })
        .on("transactionHash", function (hash) {
          console.log(hash);
          callback(null, hash);
        })
        .on("confirmation", function (confirmationNumber, receipt) {
          if (confirmationNumber === 3) {
            dispatcher.dispatch({
              type: GET_BALANCES,
              content: {},
            });
            if (presaleType === "private") {
              dispatcher.dispatch({
                type: GET_PRESALE_INFO,
                content: {
                  presaleIndex,
                },
              });
            } else {
              dispatcher.dispatch({
                type: GET_PUBLIC_PRESALE_INFO,
                content: {
                  presaleIndex,
                },
              });
            }
          }
        })
        .on("receipt", function (receipt) {
          console.log(receipt);
        })
        .on("error", function (error) {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        })
        .catch((error) => {
          if (!error.toString().includes("-32601")) {
            if (error.message) {
              return callback(error.message);
            }
            callback(error);
          }
        });
    } catch (error) {
      console.error("INVEST ERROR", error);
      return emitter.emit(ERROR, error);
    }
  } else if (
    themeType === "mtc" ||
    themeType === "eth" ||
    themeType === "ftm" ||
    themeType === "avax"
  ) {
    try {
      if (
        isSameAddress(fundingTokenAddress, defaultFundingAddress[themeType])
      ) {
        presaleContract.methods
          .invest(amount)
          .send({
            from: account.address,
            value: amount,
            gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
            callback(null, hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
              if (presaleType === "private") {
                dispatcher.dispatch({
                  type: GET_PRESALE_INFO,
                  content: {
                    presaleIndex,
                  },
                });
              } else {
                dispatcher.dispatch({
                  type: GET_PUBLIC_PRESALE_INFO,
                  content: {
                    presaleIndex,
                  },
                });
              }
            }
          })
          .on("receipt", function (receipt) {
            console.log(receipt);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      } else {
        presaleContract.methods
          .invest(amount)
          .send({
            from: account.address,
            gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
          })
          .on("transactionHash", function (hash) {
            console.log(hash);
            callback(null, hash);
          })
          .on("confirmation", function (confirmationNumber, receipt) {
            if (confirmationNumber === 3) {
              dispatcher.dispatch({
                type: GET_BALANCES,
                content: {},
              });
              if (presaleType === "private") {
                dispatcher.dispatch({
                  type: GET_PRESALE_INFO,
                  content: {
                    presaleIndex,
                  },
                });
              } else {
                dispatcher.dispatch({
                  type: GET_PUBLIC_PRESALE_INFO,
                  content: {
                    presaleIndex,
                  },
                });
              }
            }
          })
          .on("receipt", function (receipt) {
            console.log(receipt);
          })
          .on("error", function (error) {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          })
          .catch((error) => {
            if (!error.toString().includes("-32601")) {
              if (error.message) {
                return callback(error.message);
              }
              callback(error);
            }
          });
      }
    } catch (error) {
      console.error("INVEST ERROR", error);
      return emitter.emit(ERROR, error);
    }
  }
};

export const _callAllowClaim = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  presaleIndex,
  callback
) => {
  try {
    presaleContract.methods
      .allowClaim()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_PRESALE_INFO,
            content: {
              presaleIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("ALLOW_CLAIM ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callSendUnSoldTokens = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  presaleIndex,
  callback
) => {
  try {
    presaleContract.methods
      .sendUnsoldTokens()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_PRESALE_INFO,
            content: {
              presaleIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("COLLECT_FUND ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callCancelPresale = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  presaleIndex,
  callback
) => {
  try {
    presaleContract.methods
      .cancelAndTransferTokensToPresaleCreator()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_PRESALE_INFO,
            content: {
              presaleIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("COLLECT_FUND ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callCollectFunds = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  presaleIndex,
  callback
) => {
  try {
    presaleContract.methods
      .collectFundsRaised()
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
        callback(null, hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          dispatcher.dispatch({
            type: GET_PUBLIC_PRESALE_INFO,
            content: {
              presaleIndex,
            },
          });
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("COLLECT_FUND ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _callWrite = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleContract,
  account,
  presaleIndex,
  method,
  args,
  callback
) => {
  try {
    presaleContract.methods[method](...args)
      .send({
        from: account.address,
        gasPrice: web3.utils.toWei(await _getGasPrice(store), "gwei"),
      })
      .on("transactionHash", function (hash) {
        console.log(hash);
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 3) {
          dispatcher.dispatch({
            type: GET_BALANCES,
            content: {},
          });
          if (presaleIndex > 0) {
            dispatcher.dispatch({
              type: GET_PUBLIC_PRESALE_INFO,
              content: {
                presaleIndex,
              },
            });
          }
          callback(null, receipt);
        }
      })
      .on("receipt", function (receipt) {
        console.log(receipt);
      })
      .on("error", function (error) {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      })
      .catch((error) => {
        if (!error.toString().includes("-32601")) {
          if (error.message) {
            return callback(error.message);
          }
          callback(error);
        }
      });
  } catch (error) {
    console.error("WRITE ERROR", error);
    return emitter.emit(ERROR, error);
  }
};

export const _getPublicPoolSummary = async (
  store,
  dispatcher,
  emitter,
  web3,
  starterInfoContract,
  bscsId,
  callback
) => {
  try {
    const themeType = store.getStore("themeType");
    const factory = store.getStore("factory");
    const presaleAddress = await starterInfoContract.methods
      .getPresaleAddress(bscsId)
      .call();
    const presaleVersion = _getPresaleTypeByBscsId(store, bscsId);
    const presaleContract = new web3.eth.Contract(
      _getPresaleAbiByBscsId(store, bscsId),
      presaleAddress
    );
    const totalInvestorsCount = await presaleContract.methods
      .totalInvestorsCount()
      .call();
    const totalCollectedWei = await presaleContract.methods
      .totalCollectedWei()
      .call();

    const totalTokens = await presaleContract.methods.totalTokens().call();
    const tokenAddress = await presaleContract.methods.token().call();
    const tokenPriceInWei = await presaleContract.methods
      .tokenPriceInWei()
      .call();
    const hardCapInWei = await presaleContract.methods.hardCapInWei().call();
    const softCapInWei = await presaleContract.methods.softCapInWei().call();
    const maxInvestInWei = await presaleContract.methods
      .maxInvestInWei()
      .call();
    const minInvestInWei = await presaleContract.methods
      .minInvestInWei()
      .call();
    const openTime = await presaleContract.methods.openTime().call();
    const closeTime = await presaleContract.methods.closeTime().call();

    const saleTitle = web3.utils.hexToUtf8(
      await presaleContract.methods.saleTitle().call()
    );
    const linkTelegram = web3.utils.hexToUtf8(
      await presaleContract.methods.linkTelegram().call()
    );
    const linkWebsite = web3.utils.hexToUtf8(
      await presaleContract.methods.linkWebsite().call()
    );

    const _linkLogo = await presaleContract.methods.linkLogo().call();
    const linkLogo =
      presaleVersion === "V1" ? web3.utils.hexToUtf8(_linkLogo) : _linkLogo;

    const url = `/pool/${bscsId}`;
    const yesVotes = await presaleContract.methods.yesVotes().call();
    const noVotes = await presaleContract.methods.noVotes().call();

    const fundingTokenAddress = presaleContract.methods.fundingTokenAddress
      ? await presaleContract.methods.fundingTokenAddress().call()
      : defaultFundingAddress[themeType];

    const fundingTokenContract = new web3.eth.Contract(
      config[themeType].ERC20Abi,
      fundingTokenAddress
    );

    const fundingTokenDecimals = await fundingTokenContract.methods
      .decimals()
      .call();

    let minYesVotesThreshold = 0;
    if (themeType === "bsc"||  themeType == "pulse") {
      minYesVotesThreshold =
        presaleVersion === "V3"
          ? await starterInfoContract.methods.getMinYesVotesThreshold().call()
          : await presaleContract.methods.minYesVotesThreshold().call();
    } else if (
      themeType === "mtc" ||
      themeType === "eth" ||
      themeType === "ftm" ||
      themeType === "avax"
    ) {
      minYesVotesThreshold = await starterInfoContract.methods
        .getMinYesVotesThreshold(fundingTokenAddress)
        .call();
    }

    const cakeListingPriceInWei = await presaleContract.methods
      .cakeListingPriceInWei()
      .call();

    const cakeLiquidityAddingTime = await presaleContract.methods
      .cakeLiquidityAddingTime()
      .call();

    const cakeLPTokensLockDurationInDays = await presaleContract.methods
      .cakeLPTokensLockDurationInDays()
      .call();

    const cakeLiquidityPercentageAllocation = await presaleContract.methods
      .cakeLiquidityPercentageAllocation()
      .call();

    const presaleCancelled = await presaleContract.methods
      .presaleCancelled()
      .call();
    const presaleType = presaleContract.methods.presaleType
      ? await presaleContract.methods.presaleType().call()
      : 1;
    const updatedPresaleInfo = {
      totalInvestorsCount,
      totalCollectedWei,
      totalTokens,
      tokenPriceInWei,
      tokenAddress,
      hardCapInWei,
      softCapInWei,
      maxInvestInWei,
      minInvestInWei,
      openTime,
      closeTime,
      saleTitle,
      linkTelegram,
      linkWebsite,
      linkLogo,
      yesVotes,
      noVotes,
      minYesVotesThreshold,
      url,
      presaleIndex: bscsId,
      cakeListingPriceInWei,
      cakeLiquidityAddingTime,
      cakeLPTokensLockDurationInDays,
      cakeLiquidityPercentageAllocation,
      presaleCancelled,
      presaleVersion,
      presaleType,
      fundingTokenAddress,
      fundingTokenDecimals,
    };

    return callback(null, updatedPresaleInfo);
  } catch (error) {
    console.error("_GET_PUBLIC_POOL_SUMMARY", error, bscsId);
    return callback(null, {});
  }
};

export const _getPoolSummary = async (
  store,
  dispatcher,
  emitter,
  web3,
  presaleIndex,
  callback
) => {
  try {
    const themeType = store.getStore("themeType");
    const presales = store.getStore("presales");
    const presale = presales[presaleIndex];
    const presaleContract = new web3.eth.Contract(
      presale.presaleAbi,
      presale.presaleAddress
    );
    const totalInvestorsCount = await presaleContract.methods
      .totalInvestorsCount()
      .call();
    const totalCollectedWei = await presaleContract.methods
      .totalCollectedWei()
      .call();
    const totalTokens = await presaleContract.methods.totalTokens().call();

    const tokenPriceInWei = await presaleContract.methods
      .tokenPriceInWei()
      .call();
    const hardCapInWei = await presaleContract.methods.hardCapInWei().call();
    const softCapInWei = await presaleContract.methods.softCapInWei().call();
    const maxInvestInWei = await presaleContract.methods
      .maxInvestInWei()
      .call();
    const minInvestInWei = await presaleContract.methods
      .minInvestInWei()
      .call();
    const openTime = await presaleContract.methods.openTime().call();
    const closeTime = await presaleContract.methods.closeTime().call();

    const saleTitle = web3.utils.hexToUtf8(
      await presaleContract.methods.saleTitle().call()
    );
    const linkTelegram = web3.utils.hexToUtf8(
      await presaleContract.methods.linkTelegram().call()
    );
    const linkWebsite = web3.utils.hexToUtf8(
      await presaleContract.methods.linkWebsite().call()
    );
    const linkLogo =
      presaleIndex !== "wsbprivate" &&
      presaleIndex !== "wisepublic" &&
      presaleIndex !== "vestSeedSale" &&
      presaleIndex !== "vestPrivateSale" &&
      presaleIndex !== "gamewinSeedSale" &&
      presaleIndex !== "starchiSeedSale" &&
      presaleIndex !== "starchiRdSale" &&
      presaleIndex !== "starchiPrivateSale" &&
      presaleIndex !== "starchiVipPrivate" &&
      presaleIndex !== "starchiVipMaven" &&
      presaleIndex !== "starchiVipVbc" &&
      presaleIndex !== "starchiPrivateCryptozen" &&
      presaleIndex !== "starchiPrivateEmpire" &&
      presaleIndex !== "starchiPrivateDarkpool" &&
      presaleIndex !== "starchiTeamSale" &&
      presaleIndex !== "kryxiviaPrivateSale1" &&
      presaleIndex !== "kryxiviaPrivateSale2" && 
      presaleIndex !== "starzTeam" &&
      presaleIndex !== "starzSuperVip" &&
      presaleIndex !== "starzSc" && 
      presaleIndex !== "betswirlSeed" && 
      presaleIndex !== "metalaunchStarterCapital" &&
      presaleIndex !== "metalaunchSuperVip" &&  
      presaleIndex !== "metalaunchTeam" && 
      presaleIndex !== "voiceStreetSuperVip" &&
      presaleIndex !== "voiceStreetSc" && 
      presaleIndex !== "metagamzSc" && 
      presaleIndex !== "metagamzSvip"
        ? web3.utils.hexToUtf8(await presaleContract.methods.linkLogo().call())
        : await presaleContract.methods.linkLogo().call();

    const updatedPresaleInfo = {
      ...presale,
      totalInvestorsCount,
      totalCollectedWei,
      totalTokens,
      tokenPriceInWei,
      hardCapInWei,
      softCapInWei,
      maxInvestInWei,
      minInvestInWei,
      openTime,
      closeTime,
      saleTitle,
      linkTelegram,
      linkWebsite,
      linkLogo,
    };
    return callback(null, updatedPresaleInfo);
  } catch (error) {
    console.error("GET_POOL_SUMMARY", error);
    return callback(null, {});
  }
};

export const _getPresaleTypeByBscsId = (store, id) => {
  const themeType = store.getStore("themeType");
  if (themeType === "bsc" ||  themeType == "pulse") {
    if (id >= 87) {
      return "V3";
    }
    if (id >= 26) {
      return "V2";
    }
    return "V1";
  } else if (
    themeType === "mtc" ||
    themeType === "eth" ||
    themeType === "ftm" ||
    themeType === "avax"
  ) {
    return "V3";
  }
  return "V1";
};

export const _getPresaleAbiByBscsId = (store, id) => {
  const factory = store.getStore("factory");
  const themeType = store.getStore("themeType");

  if (themeType == "bsc" || themeType == "pulse") {
    if (id >= 87) {
      return factory.presaleV3Abi;
    }
    if (id >= 26) {
      return factory.presaleV2Abi;
    }
  }
  return factory.presaleAbi;
};

export const _getPresaleAbiByVersion = (store, version) => {
  const factory = store.getStore("factory");
  const themeType = store.getStore("themeType");
  if (themeType === "bsc" ||  themeType == "pulse") {
    if (version == "V3") {
      return factory.presaleV3Abi;
    }
    if (version == "V2") {
      return factory.presaleV2Abi;
    }
    return factory.presaleAbi;
  } else if (
    themeType === "mtc" ||
    themeType === "eth" ||
    themeType === "ftm" ||
    themeType === "avax"
  ) {
    return factory.presaleAbi;
  }
};

export const _getFarmingInfo = async (
  store,
  dispatcher,
  emitter,
  web3,
  account,
  callback
) => {
  const themeType = store.getStore("themeType");
  const farming = store.getStore("farming");
  let farmingContract = new web3.eth.Contract(farming.abi, farming.address);
  let farmingLpContract = new web3.eth.Contract(
    farming.lpAbi,
    farming.lpAddress
  );
  let wbnbContract = new web3.eth.Contract(
    config[themeType].ERC20Abi,
    config[themeType].wbnbAddress
  );

  try {
    let halvingTimestamp = farmingContract.methods.halvingTimestamp
      ? await farmingContract.methods.halvingTimestamp().call()
      : "0";
    let rewardAllocation = await farmingContract.methods
      .rewardAllocation()
      .call();
    let totalLocked = await farmingContract.methods.totalSupply().call();
    let accountInfo = await farmingContract.methods
      .accountInfos(account && account.address)
      .call();
    let rewardEarned = await farmingContract.methods
      .rewardEarned(account && account.address)
      .call();
    let withdrawLimit = await farmingContract.methods.withdrawLimit().call();
    let withdrawCycle = await farmingContract.methods.withdrawCycle().call();
    let claimFee = await farmingContract.methods.claimTaxDenominator().call();

    let farmingStartTimestamp = await farmingContract.methods
      .farmingStartTimestamp()
      .call();

    let lpTotalSupply = await farmingLpContract.methods.totalSupply().call();

    let lpBnbBalance = await wbnbContract.methods
      .balanceOf(farming.lpAddress)
      .call();
    callback(null, {
      halvingTimestamp,
      rewardAllocation: bigInt(rewardAllocation),
      totalLocked: bigInt(totalLocked),
      accountInfo,
      lpTotalSupply: bigInt(lpTotalSupply),
      lpBnbBalance: bigInt(lpBnbBalance),
      rewardEarned: bigInt(rewardEarned),
      withdrawLimit,
      withdrawCycle,
      claimFee: claimFee > 0 ? 100 / claimFee : 0,
      farmingStartTimestamp,
    });
  } catch (ex) {
    console.log("_getFarmingInfo error", farming, ex);
    return callback(ex);
  }
};

export const _getStartInfo = async (
  store,
  dispatcher,
  emitter,
  web3,
  callback
) => {
  const tokenAbi = store.getStore("tokenAbi");
  const tokenAddress = store.getStore("tokenAddress");

  let tokenContract = new web3.eth.Contract(tokenAbi, tokenAddress);
  try {
    let totalSupply = await tokenContract.methods.totalSupply().call();
    let totalBurned = await tokenContract.methods
      .balanceOf("0x000000000000000000000000000000000000dEaD")
      .call();
    callback(null, {
      totalSupply: bigInt(totalSupply),
      totalBurned: bigInt(totalBurned),
    });
  } catch (ex) {
    return callback(ex);
  }
};

export const _getGasPrice = async (store) => {
  try {
    return store.getStore("universalGasPrice");
  } catch (e) {
    console.log(e);
    return store.getStore("universalGasPrice");
  }
};
