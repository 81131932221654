import bigInt from "big-integer";
import Web3 from "web3";
import BNBLogo from "../assets/bsc.svg";
import ETHLogo from "../assets/ETH-icon.png";
import QUICKLogo from "../assets/quick-icon.png";
import MATICLogo from "../assets/matic-icon.svg";
import FTMLogo from "../assets/FTM-logo.png";
import USDCLogo from "../assets/USDC-icon.png";
import SPIRITLogo from "../assets/spirit-icon.png";
import PNGLogo from "../assets/PNG-logo.png";
import AVAXLogo from "../assets/avax.svg";
import BustaBanner from "../assets/banners/busta.jpeg";
import NasdexBanner from "../assets/banners/nasdex.jpeg";
import EnjinBanner from "../assets/banners/enjin.jpg";
import ApeBanner from "../assets/banners/apefarmer.png";
import MimirBanner from "../assets/banners/mimir.jpg";
import VodraBanner from "../assets/banners/vodra.jpg";
import OasisBanner from "../assets/banners/oasis.png";
import StrongNodeBanner from "../assets/banners/strongnode.png";
import StripBanner from "../assets/banners/strip.jpg";
import StarchiBanner from "../assets/banners/starchi.jpeg";
import StarchiBscBanner from "../assets/banners/starchi-bsc.jpg";
import SeorBanner from "../assets/banners/seor.jpg";
import UnilabBanner from "../assets/banners/unilab.jpg";
import VENTURES18 from "../assets/partners/18Ventures.svg";
import AnySwap from "../assets/partners/AnySwap.png";
import Arculate from "../assets/partners/Arcuate.jpg";
import AssureDefi from "../assets/partners/AssureDefi.svg";
import Bidesk from "../assets/partners/bidesk.png";
import BridgeMutual from "../assets/partners/bridge.png";
import Chainlink from "../assets/partners/chainlink.png";
import DefiSingapore from "../assets/partners/defi-signapore.png";
import EmpireGlobalPartners from "../assets/partners/empire-global-partners.png";
import Fomocraft from "../assets/partners/fomocraft.webp";
import InvestDex from "../assets/partners/investdex.png";
import LDJCapital from "../assets/partners/ldjcapital.png";
import Protocol from "../assets/partners/protocol.png";
import QuickSwap from "../assets/partners/quickswap.png";
import Quotidian from "../assets/partners/quotidian.png";
import RdAuditors from "../assets/partners/rdauditors.png";
import SpiritSwap from "../assets/partners/spiritswap.png";
import StarterCapital from "../assets/partners/startercapital.png";
import Stiring from "../assets/partners/stiring.png";
import Techemy from "../assets/partners/techemy.svg";
import TransformGroup from "../assets/partners/transformgroup.png";
import Yield from "../assets/partners/yield.svg";
import ShillLabs from "../assets/partners/shilllabs.png";
import Pangolin from "../assets/partners/pangolin.svg";

const rp = require("request-promise");

export const CONNECTION_CONNECTED = "CONNECTION_CONNECTED";
export const CONNECTION_DISCONNECTED = "CONNECTION_DISCONNECTED";

export const ERROR = "ERROR";

export const CONFIGURE = "CONFIGURE";
export const CONFIGURE_RETURNED = "CONFIGURE_RETURNED";

export const STAKE = "STAKE";
export const STAKE_V1 = "STAKE_V1";
export const STAKE_RETURNED = "STAKE_RETURNED";

export const UNSTAKE = "UNSTAKE";
export const UNSTAKE_V1 = "UNSTAKE_V1";
export const UNSTAKE_RETURNED = "UNSTAKE_RETURNED";

export const CLAIM_HODLER = "CLAIM_HODLER";
export const CLAIM_HODLER_RETURNED = "CLAIM_HODLER_RETURNED";

export const CLAIM_LP = "CLAIM_LP";
export const CLAIM_LP_RETURNED = "CLAIM_LP_RETURNED";

export const GET_REWARDS = "GET_REWARDS";
export const GET_REWARDS_RETURNED = "GET_REWARDS_RETURNED";

export const EXIT = "EXIT";
export const EXIT_RETURNED = "EXIT_RETURNED";

export const WITHDRAW = "WITHDRAW";
export const WITHDRAW_RETURNED = "WITHDRAW_RETURNED";

export const GET_BALANCES = "GET_BALANCES";
export const GET_BALANCES_RETURNED = "GET_BALANCES_RETURNED";

export const GET_CLAIMABLE_ASSET = "GET_CLAIMABLE_ASSET";
export const GET_CLAIMABLE_ASSET_RETURNED = "GET_CLAIMABLE_ASSET_RETURNED";

export const CLAIM = "CLAIM";
export const CLAIM_RETURNED = "CLAIM_RETURNED";

export const PUBLIC_CLAIM = "PUBLIC_CLAIM";
export const PUBLIC_CLAIM_RETURNED = "PUBLIC_CLAIM_RETURNED";

export const CONNECT_WALLET = "CONNECT_WALLET";
export const GET_ACCOUNT_INFO = "GET_ACCOUNT_INFO";

export const UPDATE_REWARDS = "UPDATE_REWARDS";
export const UPDATE_REWARDS_RETURNED = "UPDATE_REWARDS_RETURNED";

export const GET_PRESALE_INFO = "GET_PRESALE_INFO";
export const GET_PRESALE_INFO_RETURNED = "GET_PRESALE_INFO_RETURNED";

export const GET_PUBLIC_PRESALE_INFO = "GET_PUBLIC_PRESALE_INFO";
export const GET_PUBLIC_PRESALE_INFO_RETURNED =
  "GET_PUBLIC_PRESALE_INFO_RETURNED";

export const INVEST = "INVEST";
export const INVEST_RETURNED = "INVEST_RETURNED";

export const PUBLIC_INVEST = "PUBLIC_INVEST";
export const PUBLIC_INVEST_RETURNED = "PUBLIC_INVEST_RETURNED";

export const LOCK_LIQUIDITY = "LOCK_LIQUIDITY";
export const LOCK_LIQUIDITY_RETURNED = "LOCK_LIQUIDITY_RETURNED";

export const GET_POOLS_SUMMARY = "GET_POOLS_SUMMARY";
export const GET_POOLS_SUMMARY_RETURNED = "GET_POOLS_SUMMARY";

export const APPLY = "APPLY";
export const APPLY_RETURNED = "APPLY_RETURNED";

export const VOTE = "VOTE";
export const VOTE_RETURNED = "VOTE_RETURNED";

export const GET_START_STATS = "GET_START_STATS";
export const GET_START_STATS_RETURNED = "GET_START_STATS_RETURNED";

export const COLLECT_FUND = "COLLECT_FUND";
export const COLLECT_FUND_RETURNED = "COLLECT_FUND_RETURNED";

export const CANCEL_PRESALE = "CANCEL_PRESALE";
export const CANCEL_PRESALE_RETURNED = "CANCEL_PRESALE_RETURNED";

export const SEND_UNSOLD_TOKENS = "SEND_UNSOLD_TOKENS";
export const SEND_UNSOLD_TOKENS_RETURNED = "SEND_UNSOLD_TOKENS_RETURNED";

export const MOVE_STAKE = "MOVE_STAKE";
export const MOVE_STAKE_RETURNED = "MOVE_STAKE_RETURNED";

export const WRITE = "WRITE";
export const WRITE_RETURNED = "WRITE_RETURNED";

export const CUSTOM_WRITE = "CUSTOM_WRITE";
export const CUSTOM_WRITE_RETURNED = "CUSTOM_WRITE_RETURNED";

export const GET_FARMING_INFO = "GET_FARMING_INFO";
export const GET_FARMING_INFO_RETURNED = "GET_FARMING_INFO_RETURNED";

export const FARMING_STAKE = "FARMING_STAKE";
export const FARMING_STAKE_RETURNED = "FARMING_STAKE_RETURNED";

export const FARMING_WITHDRAW = "FARMING_WITHDRAW";
export const FARMING_WITHDRAW_RETURNED = "FARMING_WITHDRAW_RETURNED";

export const FARMING_CLAIM = "FARMING_CLAIM";
export const FARMING_CLAIM_RETURNED = "FARMING_CLAIM_RETURNED";

export const ALLOW_CLAIM = "ALLOW_CLAIM";
export const ALLOW_CLAIM_RETURNED = "ALLOW_CLAIM_RETURNED";

export const GET_REFUND = "GET_REFUND";
export const GET_REFUND_RETURNED = "GET_REFUND_RETURNED";
export const THEME_CHANGE = "THEME_CHANGE";
export const THEME_CHANGE_RETURNED = "THEME_CHANGE_RETURNED";

export const SWAP = "SWAP";
export const SWAP_RETURNED = "SWAP_RETURNED";

export const FORMATE_ADDRESS = (address) => {
  if (!address) {
    return "";
  }
  return address.slice(0, 6) + "..." + address.slice(address.length - 4);
};

export const toFixed = (bi, decimals = 18, desired = 0) => {
  const trunc = decimals - desired;
  const shift = decimals - trunc;
  return (bi.divide(10 ** trunc).toJSNumber() / 10 ** shift).toFixed(desired);
};

export const toWeiString = (ni, decimals = 18) => {
  const floatNumber = parseFloat(ni);
  let newFloatNumber = 0;

  if (decimals < 9) {
    newFloatNumber = parseInt(floatNumber * 10 ** decimals);
    return bigInt(newFloatNumber).toString();
  }

  newFloatNumber = parseInt(floatNumber * 10 ** 9);

  return bigInt(newFloatNumber)
    .multiply(10 ** (decimals - 9))
    .toString();
};

export const expandFixed = (value, decimals = 18) => {
  return bigInt(value * 10 ** decimals);
};

export const isVotePassed = (presale) => {
  const yesVotes = parseFloat(toFixed(bigInt(presale?.yesVotes || "0"), 18, 4));
  const noVotes = parseFloat(toFixed(bigInt(presale?.noVotes || "0"), 18, 4));
  const minYesVotesThreshold = parseFloat(
    toFixed(bigInt(presale?.minYesVotesThreshold || "0"), 18, 4)
  );
  if (presale.presaleType === "0" || presale.presaleType === "2") {
    return true;
  }
  if (presale && presale.presaleVersion === "V1") {
    return yesVotes > noVotes && yesVotes >= minYesVotesThreshold;
  }
  if (presale && presale.presaleVersion === "V2") {
    return yesVotes > noVotes + minYesVotesThreshold;
  }
  if (presale && presale.presaleVersion === "V3") {
    if (presale.presaleType != "1") {
      return true;
    }
    return yesVotes > noVotes + minYesVotesThreshold;
  }
  return false;
};

export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export const _getBanned = async () => {
  const bannedCountryCodes = {
    AE: true,
    AF: true,
    AL: true,
    BS: true,
    BB: true,
    BW: true,
    KH: true,
    CA: true,
    CU: true,
    GH: true,
    IR: true,
    IQ: true,
    JM: true,
    LY: true,
    MU: true,
    MM: true,
    NI: true,
    PA: true,
    PK: true,
    SS: true,
    SD: true,
    SY: true,
    TT: true,
    UG: true,
    US: true,
    VU: true,
    YE: true,
    ZW: true,
  };
  try {
    const url =
      "https://ipapi.co/json/?key=yUY1a5AheTMXT2cWartlptPMGcRpFNJaWOMROjrpg3CCspyabx/";
    const locationString = await fetch(url);
    const locationJSON = JSON.parse(locationString);
    if (locationJSON) {
      return bannedCountryCodes[locationJSON.country_code];
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const fundingTokens = {
  bsc: [
    {
      value: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      label: "BNB",
      logo: BNBLogo,
    },
  ],
  eth: [
    {
      value: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      label: "ETH",
      logo: ETHLogo,
    },
    {
      value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      label: "USDC",
      logo: USDCLogo,
    },
  ],
  mtc: [
    {
      value: "0x831753DD7087CaC61aB5644b308642cc1c33Dc13",
      label: "QUICK",
      logo: QUICKLogo,
    },
    {
      value: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      label: "USDC",
      logo: USDCLogo,
    },
    {
      value: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
      label: "MATIC (Coming soon)",
      logo: MATICLogo,
    },
  ],
  ftm: [
    {
      value: "0x5Cc61A78F164885776AA610fb0FE1257df78E59B",
      label: "SPIRIT",
      logo: SPIRITLogo,
    },
    {
      value: "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75",
      label: "USDC",
      logo: USDCLogo,
    },
    {
      value: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
      label: "FTM",
      logo: FTMLogo,
    },
  ],
  avax: [
    {
      value: "0x60781C2586D68229fde47564546784ab3fACA982",
      label: "PNG",
      logo: PNGLogo,
    },
    {
      value: "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
      label: "USDC.e",
      logo: USDCLogo,
    },
    {
      value: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
      label: "AVAX",
      logo: AVAXLogo,
    },
  ],
  pulse: [
    {
      value: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      label: "BNB",
      logo: BNBLogo,
    },
  ],
};

export const tokens = {
  "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
    label: "BNB",
    logo: BNBLogo,
    decimals: 18,
  },
  "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2": {
    label: "ETH",
    logo: ETHLogo,
    decimals: 18,
  },
  "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174": {
    label: "USDC",
    logo: USDCLogo,
    decimals: 6,
  },
  "0x831753DD7087CaC61aB5644b308642cc1c33Dc13": {
    label: "QUICK",
    logo: QUICKLogo,
    decimals: 18,
  },
  "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270": {
    label: "MATIC",
    logo: MATICLogo,
    decimals: 18,
  },
  "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2": {
    label: "ETH",
    logo: ETHLogo,
    decimals: 18,
  },
  "0x5Cc61A78F164885776AA610fb0FE1257df78E59B": {
    label: "SPIRIT",
    logo: SPIRITLogo,
    decimals: 18,
  },
  "0x04068DA6C83AFCFA0e13ba15A6696662335D5B75": {
    label: "USDC",
    logo: USDCLogo,
    decimals: 6,
  },
  "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83": {
    label: "FTM",
    logo: FTMLogo,
    decimals: 18,
  },
  "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7": {
    label: "AVAX",
    logo: AVAXLogo,
    decimals: 18,
  },
  "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664": {
    label: "USDC.e",
    logo: USDCLogo,
    decimals: 6,
  },
  "0x60781C2586D68229fde47564546784ab3fACA982": {
    label: "PNG",
    logo: PNGLogo,
    decimals: 18,
  },
  "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48": {
    label: "USDC",
    logo: USDCLogo,
    decimals: 6,
  },
};

export const coingeckoPriceUrls = {
  bsc: {
    url: "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd",
    id: "binancecoin",
  },
  mtc: {
    url: "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd",
    id: "matic-network",
  },
  eth: {
    url: "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd",
    id: "ethereum",
  },
  ftm: {
    url: "https://api.coingecko.com/api/v3/simple/price?ids=fantom&vs_currencies=usd",
    id: "fantom",
  },
  sol: {
    url: "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd",
    id: "matic-network",
  },
  avax: {
    url: "https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=usd",
    id: "avalanche-2",
  },
  pulse: {
    url: "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd",
    id: "binancecoin",
  },
};

export const universalGasPrice = {
  bsc: "50",
  eth: "200",
  mtc: "200",
  sol: "25",
  ftm: "160",
  avax: "95",
  pulse: "50",
};

export const defaultFundingAddress = {
  bsc: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  eth: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  mtc: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  ftm: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
  sol: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  avax: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  pulse: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
};

export const isSameAddress = (address1, address2) => {
  return (
    Web3.utils.isAddress(address1) &&
    Web3.utils.isAddress(address2) &&
    Web3.utils.toChecksumAddress(address1) ===
      Web3.utils.toChecksumAddress(address2)
  );
};

export const tiers = {
  bsc: [
    {
      label: "SILVER",
      value: 50,
      tip: "SILVER: Voting power in IDOs plus first-come-first-serve (fcfs) access.",
    },
    {
      label: "GOLD",
      value: 500,
      tip: "GOLD: SILVER benefits plus Guaranteed Allocation in IDOs.",
    },
    {
      label: "PLATINUM",
      value: 1250,
      tip: "PLATINUM: GOLD benefits plus access to our private Platinum club.",
    },
    {
      label: "DIAMOND",
      value: 2500,
      tip: "DIAMOND : GOLD benefits plus access to our private VIP club.",
    },
  ],
  mtc: [
    {
      label: "SILVER",
      value: 3.5,
      tip: "SILVER: Voting power in IDOs plus first-come-first-serve (fcfs) access.",
    },
    {
      label: "GOLD",
      value: 35,
      tip: "GOLD: SILVER benefits plus Guaranteed Allocation in IDOs.",
    },
    {
      label: "PLATINUM",
      value: 87.5,
      tip: "PLATINUM: GOLD benefits plus access to our private Platinum club.",
    },
    {
      label: "DIAMOND",
      value: 175,
      tip: "DIAMOND : GOLD benefits plus access to our private VIP club.",
    },
  ],
  eth: [
    {
      label: "SILVER",
      value: 1,
      tip: "SILVER: Voting power in IDOs plus first-come-first-serve (fcfs) access",
    },
    {
      label: "GOLD",
      value: 10,
      tip: "GOLD: SILVER benefits plus Guaranteed Allocation in IDOs.",
    },
    {
      label: "PLATINUM",
      value: 25,
      tip: "PLATINUM: GOLD benefits plus access to our private Platinum club.",
    },
    {
      label: "DIAMOND",
      value: 50,
      tip: "DIAMOND : GOLD benefits plus access to our private VIP club.",
    },
  ],
  ftm: [
    {
      label: "SILVER",
      value: 125,
      tip: "SILVER: Voting power in IDOs plus first-come-first-serve (fcfs) access.",
    },
    {
      label: "GOLD",
      value: 1250,
      tip: "GOLD: SILVER benefits plus Guaranteed Allocation in IDOs.",
    },
    {
      label: "PLATINUM",
      value: 3125,
      tip: "PLATINUM: GOLD benefits plus access to our private Platinum club.",
    },
    {
      label: "DIAMOND",
      value: 6250,
      tip: "DIAMOND : GOLD benefits plus access to our private VIP club.",
    },
  ],
  sol: [
    {
      label: "SILVER",
      value: 3.5,
      tip: "SILVER: Voting power in IDOs plus first-come-first-serve (fcfs) access.",
    },
    {
      label: "GOLD",
      value: 35,
      tip: "GOLD: SILVER benefits plus Guaranteed Allocation in IDOs.",
    },
    {
      label: "PLATINUM",
      value: 87.5,
      tip: "PLATINUM: GOLD benefits plus access to our private Platinum club.",
    },
    {
      label: "DIAMOND",
      value: 175,
      tip: "DIAMOND : GOLD benefits plus access to our private VIP club.",
    },
  ],
  avax: [
    {
      label: "SILVER",
      value: 6.7,
      tip: "SILVER: Voting power in IDOs plus first-come-first-serve (fcfs) access.",
    },
    {
      label: "GOLD",
      value: 67,
      tip: "GOLD: SILVER benefits plus Guaranteed Allocation in IDOs.",
    },
    {
      label: "PLATINUM",
      value: 167.5,
      tip: "PLATINUM: GOLD benefits plus access to our private Platinum club.",
    },
    {
      label: "DIAMOND",
      value: 335,
      tip: "DIAMOND : GOLD benefits plus access to our private VIP club.",
    },
  ],
  pulse: [
    {
      label: "SILVER",
      value: 50,
      tip: "SILVER: Voting power in IDOs plus first-come-first-serve (fcfs) access.",
    },
    {
      label: "GOLD",
      value: 500,
      tip: "GOLD: SILVER benefits plus Guaranteed Allocation in IDOs.",
    },
    {
      label: "PLATINUM",
      value: 1250,
      tip: "PLATINUM: GOLD benefits plus access to our private Platinum club.",
    },
    {
      label: "DIAMOND",
      value: 2500,
      tip: "DIAMOND : GOLD benefits plus access to our private VIP club.",
    },
  ],
};

export const poolsSummaryUrl = {
  bsc: "https://api.starter.xyz/bsc/pools-list",
  // bsc: "http://localhost:8000/bsc/pools-list",
  mtc: "https://api.starter.xyz/mtc/pools-list",
  eth: "https://api.starter.xyz/eth/pools-list",
  // ftm: "https://api.starter.xyz/ftm/pools-list",
  ftm: "https://api.starter.xyz/ftm/pools-list",
  sol: "https://api.starter.xyz/mtc/pools-list",
  // avax: "https://api.starter.xyz/avax/pools-list",
  avax: "https://api.starter.xyz/avax/pools-list",
  pulse: "https://api.starter.xyz/bsc/pools-list",
};

export const startStatsUrl = {
  bsc: "https://api.starter.xyz/bsc/stats",
  mtc: "https://api.starter.xyz/mtc/stats",
  // ftm: "https://api.starter.xyz/ftm/stats",
  ftm: "https://api.starter.xyz/eth/stats",
  eth: "https://api.starter.xyz/eth/stats",
  sol: "https://api.starter.xyz/mtc/stats",
  // avax: "https://api.starter.xyz/avax/stats",
  avax: "https://api.starter.xyz/eth/stats",
  pulse: "https://api.starter.xyz/bsc/stats",
};

export const defaultBuyUrls = {
  bsc: "https://pancakeswap.finance/#/swap?inputCurrency=0x31D0a7AdA4d4c131Eb612DB48861211F63e57610",
  eth: "https://app.uniswap.org/#/swap?outputCurrency=0x1d7ca62f6af49ec66f6680b8606e634e55ef22c1&use=V2",
  mtc: "https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x6ccf12b480a99c54b23647c995f4525d544a7e72",
  ftm: "https://swap.spiritswap.finance/#/swap/0x8ca2ecbCE34c322fceA6Db912d9DbfD2DdA5920D",
  sol: "https://quickswap.exchange/#/swap?inputCurrency=0x831753dd7087cac61ab5644b308642cc1c33dc13&outputCurrency=0x6ccf12b480a99c54b23647c995f4525d544a7e72",
  avax: "https://app.pangolin.exchange/#/swap?outputCurrency=0xf44fb887334fa17d2c5c0f970b5d320ab53ed557",
  pulse:
    "https://pancakeswap.finance/#/swap?inputCurrency=0x31D0a7AdA4d4c131Eb612DB48861211F63e57610",
};

export const BANNER = {
  bsc: {
    themeType: "bsc",
    start: 1643047200,
    image: UnilabBanner,
    link: "https://unilab.network/",
  },
  mtc: {
    themeType: "mtc",
    start: 1638388395,
    image: StarchiBanner,
    link: "https://starchi.gg/",
  },
  eth: {
    themeType: "eth",
    start: 1638388395,
    image: StarchiBanner,
    link: "https://starchi.gg/",
  },
  avax: {
    themeType: "avax",
    start: 1638388395,
    image: StarchiBanner,
    link: "https://starchi.gg/",
  },
  ftm: {
    themeType: "ftm",
    start: 1638388395,
    image: StarchiBanner,
    link: "https://starchi.gg/",
  },
  pulse: {
    themeType: "pulse",
    start: 1638388395,
    image: StarchiBscBanner,
    link: "https://starchi.gg/",
  },
};

export const PARTNERS = [
  {
    image: AnySwap,
    link: "https://anyswap.exchange/",
  },
  {
    image: Arculate,
    link: "https://arcuate.capital/",
  },
  {
    image: AssureDefi,
    link: "https://www.assuredefi.io/",
  },
  // {
  //   image: Bidesk,
  //   link: "https://www.bidesk.com/",
  // },
  {
    image: BridgeMutual,
    link: "https://www.bridgemutual.io/",
  },
  {
    image: Chainlink,
    link: "https://chain.link/",
  },
  {
    image: DefiSingapore,
    link: "https://www.linkedin.com/company/defi-singapore/",
  },
  {
    image: EmpireGlobalPartners,
    link: "https://empireglobal.partners/",
  },
  // {
  //   image: Fomocraft,
  //   link: "https://fomocraft.com/",
  // },
  {
    image: InvestDex,
    link: "https://investdex.io/",
  },
  // {
  //   image: LDJCapital,
  //   link: "https://ldjcapital.com/",
  // },
  {
    image: Protocol,
    link: "https://www.protocolfinance.com/",
  },
  {
    image: QuickSwap,
    link: "https://quickswap.exchange/#/",
  },
  {
    image: Quotidian,
    link: "https://www.quotidianmarketing.com/",
  },
  {
    image: RdAuditors,
    link: "https://www.rdauditors.com/",
  },
  {
    image: SpiritSwap,
    link: "https://www.spiritswap.finance/",
  },
  {
    image: StarterCapital,
    link: "https://starter.capital/",
  },
  {
    image: Stiring,
    link: "",
  },
  {
    image: Techemy,
    link: "https://techemy.co/",
  },
  {
    image: TransformGroup,
    link: "https://transformgroup.com/",
  },
  {
    image: Yield,
    link: "https://www.yield.app/",
  },
  {
    image: ShillLabs,
    link: "https://shilllabs.com/",
  },
  {
    image: Pangolin,
    link: "https://pangolin.exchange/",
  },
];

export const GuideLinks = {
  bsc: "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-binance-smart-chain-bsc",
  mtc: "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-polygon-matic",
  ftm: "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-fantom-ftm",
  avax: "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-avalanche-avax",
  eth: "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-ethereum-eth",
  pulse:
    "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-binance-smart-chain-bsc",
};

export const Certified = {
  bsc: {
    150: true,
    151: true,
    152: true,
    153: true,
    155: true,
    154: true,
    136: true,
    129: true,
    145: true,
    146: true,
    131: true,
    137: true,
    138: true,
    130: true,
    108: true,
    156: true,
    157: true,
    158: true,
    159: true,
    160: true,
    161: true,
    162: true,
    167: true,
    168: true,
    169: true,
    170: true,
    171: true,
    172: true,
    174: true,
    42: true,
    33: true,
    0: true,
    wisepublic: true,
    seed: true,
    private: true,
    strategic: true,
    wsbprivate: true,
    vestPrivateSale: true,
    vestSeedSale: true,
    gamewinSeedSale: true,
  },
  mtc: {
    starchiSeedSale: true,
    starchiTeamSale: true,
    starchiPrivateDarkpool: true,
    starchiPrivateEmpire: true,
    starchiPrivateCryptozen: true,
    starchiVipVbc: true,
    starchiVipMaven: true,
    starchiVipPrivate: true,
    starchiPrivateSale: true,
    starchiRdSale: true,
    38: true,
    27: true,
    28: true,
    29: true,
    30: true,
    31: true,
    32: true,
    23: true,
    22: true,
    21: true,
    16: true,
    18: true,
    5: true,
    1: true,
    0: true,
  },
  ftm: {},
  avax: {
    0: true,
    1: true,
    metagamzSc: true,
    metagamzSvip: true,
  },
  eth: {
    0: true,
    2: true,
    1: true,
  },
  pulse: {
    150: true,
    151: true,
    152: true,
    153: true,
    155: true,
    154: true,
    136: true,
    129: true,
    145: true,
    146: true,
    131: true,
    137: true,
    138: true,
    130: true,
    108: true,
    156: true,
    157: true,
    158: true,
    159: true,
    160: true,
    161: true,
    162: true,
    42: true,
    33: true,
    0: true,
    wisepublic: true,
    seed: true,
    private: true,
    strategic: true,
    wsbprivate: true,
    vestPrivateSale: true,
    vestSeedSale: true,
    gamewinSeedSale: true,
  },
};
