import React, { useState } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import moment from "moment";
import bigInt from "big-integer";

import Alert from "@material-ui/lab/Alert";
import InfoIcon from "@material-ui/icons/Info";

import {
  Typography,
  Button,
  Fade,
  Zoom,
  Card,
  Input,
  CircularProgress,
  Snackbar,
  Tooltip,
  IconButton,
  useTheme,
} from "@material-ui/core";

import Web3 from "web3";

import { withNamespaces } from "react-i18next";

import {
  ERROR,
  GET_BALANCES_RETURNED,
  GET_FARMING_INFO,
  GET_FARMING_INFO_RETURNED,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  CONNECT_WALLET,
  FARMING_STAKE,
  FARMING_STAKE_RETURNED,
  FARMING_WITHDRAW,
  FARMING_WITHDRAW_RETURNED,
  FARMING_CLAIM,
  FARMING_CLAIM_RETURNED,
  UPDATE_REWARDS,
  UPDATE_REWARDS_RETURNED,
  THEME_CHANGE_RETURNED,
  toFixed,
} from "../../constants/constants";
import GlobalStore from "../../stores/store";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    flex: 1,
    width: "100%",
    maxWidth: "1140px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      overflow: "auto",
      height: "100vh",
    },
  },
  rightBackgroundContainer: {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
    backgroundPositionX: "right",
    width: "80%",
    height: "100%",
    zIndex: "1",
  },
  mainInfoContainer: {
    zIndex: "2",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    color: "#B5003D",
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
  metricSummaryContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: "2rem",
    opacity: "1",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  leftMetrics: {
    borderRadius: "10px",
    backgroundColor: theme?.colors.poolCard?.background,
    width: "100%",
    height: "auto",
    padding: "16px",
    display: "grid",
    gridTemplateColumns: "auto",
    gridGap: "10px",
    [theme.breakpoints.up("ms")]: {
      gridTemplateColumns: "auto auto",
    },
  },
  rightMetrics: {
    display: "flex",
    borderRadius: "10px",
    backgroundColor: theme?.colors.poolCard?.background,
    marginTop: "10px",
    marginLeft: "0px",
    width: "100%",
    height: "auto",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "18px",
    [theme.breakpoints.up("sm")]: {
      width: "100px",
      marginTop: "0px",
      marginLeft: "10px",
    },
  },
  rightMetricsAPYLabel: {
    fontWeight: "bold",
  },
  rightMetricsAPY: {
    fontWeight: "bold",
    color: theme?.colors.poolCard?.textSecondary,
    fontSize: "17px",
    lineHeight: "35px",
    whiteSpace: "nowrap",
  },
  pageTitle: {
    fontFamily: "SFPro",
    fontSize: "30px",
    fontWeight: "lighter",
    lineHeight: "36px",
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.home?.text,
    marginBottom: "20px",
  },
  globalsMetricSection: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  globalsMetricSectionHeader: {
    textAlign: "left",
    fontSize: "17px",
    lineHeight: "23px",
    letterSpacing: "0px",
    color: theme?.colors.poolCard?.title,
  },
  globalsMetricSectionSpan: {
    textAlign: "left",
    fontSize: "15px",
    lineHeight: "21px",
    letterSpacing: "0px",
    color: theme?.colors.poolCard?.title,
    display: "flex",
    flexDirection: "row",
    width: "55%",
  },
  cardDetailContainer: {
    display: "grid",
    width: "100%",
    height: "fit-content",
    gridRowGap: "10px",
    gridColumnGap: "10px",
    gridTemplateColumns: "auto",
    [theme.breakpoints.up("ms")]: {
      gridTemplateColumns: "auto auto",
      gridRowGap: "30px",
      gridColumnGap: "30px",
    },
  },
  cardDetailPanel: {
    boxShadow:
      "0px 0px 1px rgba(0,0,0,0.01), 0px 4px 8px rgba(0,0,0,0.04), 0px 16px 24px rgba(0,0,0,0.04), 0px 24px 32px rgba(0,0,0,0.01)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "24px",
    borderRadius: "10px",
    backgroundColor: theme?.colors.poolCard?.background,
    color: theme?.colors.poolCard?.title,
    width: "100%",
    height: "fit-content",
    minHeight: "300px",
    [theme.breakpoints.up("ms")]: {
      minHeight: "100%",
    },
  },
  cardValueHeader: {
    fontSize: "17px",
    lineHeight: "23px",
    position: "relative",
  },
  cardValueTooltip: {
    fontFamily: "SFPro",
    color: "#141414",
    fontSize: "1rem",
    backgroundColor: "white",
    border: "solid 1px #141414",
    maxWidth: "300px",
  },
  cardValueTooltipText: {
    fontFamily: "SFPro",
  },
  highlightValue: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: theme?.colors.poolCard?.textSecondary,
  },
  cardValue: {
    fontSize: "25px",
    lineHeight: "34px",
    letterSpacing: "0px",
    color: theme?.colors.poolCard?.statusBarBackground,
    width: "100%",
    textAlign: "center",
  },
  cardUsdValue: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme?.colors.poolCard?.statusBarBackground,
    width: "100%",
    textAlign: "center",
    height: "1rem",
    marginBottom: "15px",
  },
  cardValueTip: {
    fontSize: "25px",
    lineHeight: "34px",
    letterSpacing: "0px",
    color: theme?.colors.poolCard?.textSecondary,
    width: "100%",
    textAlign: "center",
  },
  cardUsdValueTip: {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme?.colors.poolCard?.textSecondary,
    width: "100%",
    textAlign: "center",
    height: "1rem",
    marginBottom: "15px",
  },
  stakeInputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "297px",
    position: "relative",
    marginBottom: "30px",
  },
  actionInput: {
    border: "solid 1px " + theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    paddingRight: "70px",
    color: theme?.colors?.presale.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.presale.cardTitleSecondary,
  },
  maxButton: {
    position: "absolute",
    right: "0px",
    borderRadius: "0px 14px 14px 0px",
    height: "50px",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    width: "70px",
    padding: "16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  walletButton: {
    borderRadius: "0.375rem",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    minWidth: "150px",
    padding: "16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    "&:disabled": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonTextDisabled,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    whiteSpace: "nowrap",
  },
  stakedTimeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "1rem",
  },
  stakedTimeSpan: {
    fontWeight: "bolder",
    fontSize: "0.8rem",
    letterSpacing: "0.3px",
    color: theme?.colors?.farming?.card?.withdrawTime,
    marginLeft: "0.2rem",
  },
  accountConnectPanel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  accountConnectCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "450px",
    width: "100%",
    padding: "20px",
    backgroundColor: theme?.colors.poolCard?.background,
  },
  accountConnectCardText: {
    fontSize: "16px",
    lineHeight: "22px",
    marginBottom: "20px",
    textAlign: "center",
    color: theme?.colors.poolCard?.title,
  },
  loadingContainer: {
    position: "absolute",
    left: "0px",
    right: "0px",
    top: "0px",
    bottom: "0px",
    backdropFilter: "blur(1px)",
    zIndex: "3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dollarHighlight: {
    color: theme?.colors.poolCard?.textSecondary,
    fontWeight: "bold",
  },
});

const emitter = GlobalStore.emitter;
const Store = GlobalStore.store;
const dispatcher = GlobalStore.dispatcher;

let timerId = null;

const Farming = ({ classes }) => {
  const history = useHistory();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);

  const [totalValueLocked, setTotalValueLocked] = useState(0);
  const [totalLpStaked, setTotalLpStaked] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [bnbUsdPrice, setBnbUsdPrice] = useState(0);
  const [lpUsdValue, setLpUsdValue] = useState(0);

  const [bnbBalance, setBnbBalance] = useState(0);
  const [bnbBalanceLocked, setBnbBalanceLocked] = useState(0);
  const [startBalance, setStartBalance] = useState(0);
  const [lpStaked, setLpStaked] = useState(0);

  const [withdrawPerDay, setWithdrawPerDay] = useState(25);
  const [nextWithdrawlAmount, setNextWithdrawalAmount] = useState(0);
  const [claimingFee, setClaimingFee] = useState(-1);
  const [exitFee, setExitFee] = useState(0);

  const [depositInputValue, setDepositInputValue] = useState(0);
  const [rewardsHalving, setRewardsHalving] = useState("0d 0h 0m");
  const [nextWithdrawTimestamp, setNextWithdrawTimestamp] = useState(0);
  const [farmingStartTimestamp, setFarmingStartTimestamp] = useState(0);
  const [remainingTime, setRemainingTime] = useState("");
  const [withdrawAvTime, setWithdrawAvTime] = useState("0:00:00");

  const [isWithdrawAvailable, setWithdrawAvailable] = useState(false);

  const [totalSupply, setTotalSupply] = useState(0);
  const [totalBurned, setTotalBurned] = useState(0);
  const [miningRewards, setMiningRewards] = useState(0);
  const [lpBnbPrice, setLpBnbPrice] = useState(0);
  const [pendingRewards, setPendingRewards] = useState(0);
  const [annualYield, setAnnualYield] = useState(0);
  const [lpTotalSupply, setLpTotalSupply] = useState(0);

  const formatDate = (timeValue) => {
    const date = moment
      .unix(timeValue)
      .utc()
      .format("MMM DD HH:mm:ss")
      .toString();
    const before = moment.unix(timeValue).utc().fromNow();
    return date + " UTC (" + before + ")";
  };

  const onStake = () => {
    if (remainingTime) {
      setResponseMessage({
        type: "error",
        message: "Farming Not Started",
      });
      return;
    }

    const amount = parseFloat(depositInputValue);
    if (!amount || amount < 0.001) {
      setResponseMessage({
        type: "error",
        message: "STAKE Invalid Amount!",
      });
      return;
    }
    const limitTop = parseFloat(bnbBalance);

    if (!limitTop || limitTop < 0.001 || limitTop < amount) {
      setResponseMessage({
        type: "error",
        message: "STAKE Not enough balance",
      });
      return;
    }

    setLoading(true);

    dispatcher.dispatch({
      type: FARMING_STAKE,
      content: {
        amount: `${amount}`,
      },
    });
  };

  const onUnstake = () => {
    const limitTop = parseFloat(lpStaked);
    if (limitTop === 0) {
      setResponseMessage({
        type: "warning",
        message: "FARMING_WITHDRAW Not enough balance",
      });
      return;
    }

    setLoading(true);
    dispatcher.dispatch({
      type: FARMING_WITHDRAW,
      content: {},
    });
  };

  const onClaim = () => {
    setLoading(true);
    dispatcher.dispatch({
      type: FARMING_CLAIM,
      content: {},
    });
  };

  const errorReturned = (_error) => {
    setLoading(false);
  };

  const updateInfo = () => {
    const farmingPool = Store.getStore("farming");
    setTotalLpStaked(toFixed(farmingPool.totalLpLocked, 18, 3));
    setTotalSupply(toFixed(farmingPool.totalSupply, 18, 0));
    setTotalBurned(toFixed(farmingPool.totalBurned, 18, 2));

    const lpTotalSupply = toFixed(farmingPool.lpTotalSupply, 18, 3);
    const lpBnbBalance = toFixed(farmingPool.lpBnbBalance, 18, 3);
    const lpBnbPrice = (lpBnbBalance / lpTotalSupply) * 2;
    const rewardAllocation = toFixed(farmingPool.rewardAllocation, 18, 3);

    setLpTotalSupply(lpTotalSupply);
    setMiningRewards(rewardAllocation);
    setLpBnbPrice(lpBnbPrice);

    const _lpStaked = parseFloat(
      toFixed(bigInt(farmingPool.accountInfo.balance), 18, 3)
    );
    const _lpPeak = parseFloat(
      toFixed(bigInt(farmingPool.accountInfo.peakBalance), 18, 3)
    );
    setLpStaked(_lpStaked);

    const _nextWithdrawTimestamp = moment
      .unix(farmingPool.accountInfo.withdrawTimestamp)
      .add(farmingPool.withdrawCycle, "s");
    const withdrawLimit = farmingPool.withdrawLimit;

    const _farmingStartTimestamp = moment.unix(
      farmingPool.farmingStartTimestamp
    );

    setFarmingStartTimestamp(_farmingStartTimestamp.unix());
    setWithdrawAvailable(
      _lpStaked > 0 && _nextWithdrawTimestamp.isBefore(moment.now())
    );
    if (!_nextWithdrawTimestamp.isBefore(moment.now())) {
      const diffTimeBySeconds = _nextWithdrawTimestamp.diff(
        moment(),
        "seconds"
      );
      setWithdrawAvTime(
        `${Math.floor(diffTimeBySeconds / 3600).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}:${Math.floor((diffTimeBySeconds % 3600) / 60).toLocaleString(
          "en-US",
          {
            minimumIntegerDigits: 2,
            useGrouping: false,
          }
        )}:${Math.floor(diffTimeBySeconds % 60).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })}`
      );
    } else {
      setWithdrawAvTime("0:00:00");
    }
    setNextWithdrawalAmount((_lpPeak * withdrawLimit) / 100);
    setPendingRewards(toFixed(farmingPool.rewardEarned, 18, 3));
    setWithdrawPerDay(withdrawLimit);
    setClaimingFee(farmingPool.claimFee);
    setBalanceLoading(false);
    if (account) {
      window.setTimeout(() => {
        dispatcher.dispatch({
          type: GET_FARMING_INFO,
          content: {},
        });
      }, 5000);
    }
  };

  const updateBalanceInfo = () => {
    const _bnbBalance = Store.getStore("bnbBalance");
    const _bnbPrice = Store.getStore("bnbPrice");
    const _startBalance = Store.getStore("startBalance");
    const _startPrice = Store.getStore("startPrice");
    setBnbBalance(toFixed(_bnbBalance, 18, 3));
    setBnbUsdPrice(_bnbPrice);
    setStartBalance(toFixed(_startBalance, 18, 3));
    setStartPrice(_startPrice);
    dispatcher.dispatch({ type: GET_FARMING_INFO, content: {} });
  };

  const connectionConnected = () => {
    setAccount(Store.getStore("account"));
  };

  const connectionDisconnected = () => {
    setAccount(Store.getStore("account"));
  };

  const onStakeReturned = (txData) => {
    setLoading(false);
    setResponseMessage({
      type: "info",
      message: `Stake request successful. ${txData}`,
    });
  };

  const onWithdrawReturned = (txData) => {
    setLoading(false);
    setResponseMessage({
      type: "info",
      message: `Withdraw request successful. ${txData}`,
    });
  };

  const onClaimReturned = (txData) => {
    setLoading(false);
    setResponseMessage({
      type: "info",
      message: `Claim request successful. ${txData}`,
    });
  };

  const themeChanged = () => {
    history.push("/");
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);

    emitter.on(ERROR, errorReturned);
    emitter.on(GET_BALANCES_RETURNED, updateBalanceInfo);
    emitter.on(GET_FARMING_INFO_RETURNED, updateInfo);
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    emitter.on(FARMING_STAKE_RETURNED, onStakeReturned);
    emitter.on(FARMING_WITHDRAW_RETURNED, onWithdrawReturned);
    emitter.on(FARMING_CLAIM_RETURNED, onClaimReturned);
    emitter.on(THEME_CHANGE_RETURNED, themeChanged);
    const currentBlock = Store.getStore("currentBlock");
    if (currentBlock) {
      setBalanceLoading(false);
      updateBalanceInfo();
    } else {
      setBalanceLoading(true);
    }

    const userAccount = Store.getStore("account");
    setAccount(userAccount);
    if (userAccount && userAccount.address) {
      dispatcher.dispatch({ type: GET_FARMING_INFO, content: {} });
    }

    return () => {
      emitter.removeListener(ERROR, errorReturned);
      emitter.removeListener(GET_BALANCES_RETURNED, updateBalanceInfo);
      emitter.removeListener(GET_FARMING_INFO_RETURNED, updateInfo);
      emitter.removeListener(CONNECTION_CONNECTED, connectionConnected);
      emitter.removeListener(CONNECTION_DISCONNECTED, connectionDisconnected);
      emitter.removeListener(FARMING_STAKE_RETURNED, onStakeReturned);
      emitter.removeListener(FARMING_WITHDRAW_RETURNED, onWithdrawReturned);
      emitter.removeListener(FARMING_CLAIM_RETURNED, onClaimReturned);
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, []);

  React.useEffect(() => {
    if (timerId) {
      clearInterval(timerId);
    }
    timerId = setInterval(() => {
      const _nextWithdrawTimestamp = moment.unix(nextWithdrawTimestamp);
      if (!_nextWithdrawTimestamp.isBefore(moment.now())) {
        const diffTimeBySeconds = _nextWithdrawTimestamp.diff(
          moment(),
          "seconds"
        );
        setWithdrawAvTime(
          `${Math.floor(diffTimeBySeconds / 3600).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}:${Math.floor((diffTimeBySeconds % 3600) / 60).toLocaleString(
            "en-US",
            {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }
          )}:${Math.floor(diffTimeBySeconds % 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`
        );
      } else {
        setWithdrawAvTime("0:00:00");
      }
      const _farmingStartTimestamp = moment.unix(farmingStartTimestamp);
      if (!_farmingStartTimestamp.isBefore(moment.now())) {
        const diffTimeBySeconds = _farmingStartTimestamp.diff(
          moment(),
          "seconds"
        );
        setRemainingTime(
          `${Math.floor(diffTimeBySeconds / 3600).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}:${Math.floor((diffTimeBySeconds % 3600) / 60).toLocaleString(
            "en-US",
            {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }
          )}:${Math.floor(diffTimeBySeconds % 60).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })}`
        );
      } else {
        setRemainingTime(false);
      }
    }, 1000);
  }, [nextWithdrawTimestamp, farmingStartTimestamp]);

  React.useEffect(() => {
    const _apy =
      totalLpStaked == 0
        ? 10000
        : (((miningRewards / 21) * 365 * startPrice) /
            (totalLpStaked * lpBnbPrice * bnbUsdPrice)) *
          100;
    setAnnualYield(_apy);
  }, [miningRewards, lpBnbPrice, bnbUsdPrice, totalLpStaked, startPrice]);

  return (
    <div className={classes.root}>
      {remainingTime && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
        >
          <Alert elevation={6} variant="filled" severity="info">
            Liquidity Mining starts in {remainingTime}
          </Alert>
        </Snackbar>
      )}
      {responseMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={6000}
          onClose={() => {
            setResponseMessage(null);
          }}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={responseMessage && responseMessage.type}
          >
            {responseMessage && responseMessage.message}
          </Alert>
        </Snackbar>
      )}
      {(balanceLoading || loading) && account && account.address && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={60} />
        </div>
      )}
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          <Typography className={classes.pageTitle}>
            Liquidity Mining
          </Typography>
          <div className={classes.mainInfoContainer}>
            <div className={classes.metricSummaryContainer}>
              <div className={classes.leftMetrics}>
                <div className={classes.globalsMetricSection}>
                  <span className={classes.globalsMetricSectionHeader}>
                    Value Locked:
                  </span>
                  <span className={classes.globalsMetricSectionSpan}>
                    {totalLpStaked || 0} LP{" "}
                    <span
                      className={classes.dollarHighlight}
                      style={{ marginLeft: "5px" }}
                    >
                      ($
                      {parseFloat(
                        totalLpStaked * lpBnbPrice * bnbUsdPrice
                      ).toLocaleString() || 0}
                      )
                    </span>
                  </span>
                </div>
                <div className={classes.globalsMetricSection}>
                  <span className={classes.globalsMetricSectionHeader}>
                    Mining Rewards:
                  </span>
                  <span className={classes.globalsMetricSectionSpan}>
                    <span style={{ lineHeight: "1rem" }}>
                      {parseFloat(miningRewards).toLocaleString() || 0} START ($
                      {(miningRewards * startPrice).toLocaleString()} USD)
                    </span>
                  </span>
                </div>
                <div className={classes.globalsMetricSection}>
                  <span className={classes.globalsMetricSectionHeader}>
                    Total Supply:
                  </span>
                  <span className={classes.globalsMetricSectionSpan}>
                    <span style={{ lineHeight: "1rem" }}>
                      {parseFloat(totalSupply).toLocaleString() || 0} START ($
                      {(totalSupply * startPrice).toLocaleString()} USD)
                    </span>
                  </span>
                </div>
                <div className={classes.globalsMetricSection}>
                  <span className={classes.globalsMetricSectionHeader}>
                    Total Burned:
                  </span>
                  <span className={classes.globalsMetricSectionSpan}>
                    <span style={{ lineHeight: "1rem" }}>
                      {parseFloat(totalBurned).toLocaleString() || 0} START ($
                      {(totalBurned * startPrice).toLocaleString()} USD)
                    </span>
                  </span>
                </div>
              </div>
              <div className={classes.rightMetrics}>
                <span className={classes.rightMetricsAPYLabel}>APY</span>
                <span className={classes.rightMetricsAPY}>
                  {annualYield.toFixed(0) || 0} %
                </span>
              </div>
            </div>
            {account && account.address && (
              <div className={classes.cardDetailContainer}>
                <Zoom in={true}>
                  <Card className={classes.cardDetailPanel}>
                    <span className={classes.cardValueHeader}>
                      Your {theme?.symbol || "BNB"} Balance
                    </span>
                    <span className={classes.cardValue}>
                      {bnbBalance} {theme?.symbol || "BNB"}
                    </span>
                    <span className={classes.cardUsdValue}>
                      $
                      {parseFloat(
                        (bnbBalance * bnbUsdPrice).toFixed(2)
                      ).toLocaleString()}{" "}
                      USD
                    </span>
                    <div className={classes.stakeInputContainer}>
                      <Input
                        classes={{
                          root: classes.actionInput,
                          underline: classes.actionUnderline,
                        }}
                        disableUnderline={true}
                        value={depositInputValue}
                        onChange={(ev) => {
                          setDepositInputValue(ev.target.value);
                        }}
                      />
                      <Button
                        classes={{
                          root: classes.maxButton,
                          label: classes.buttonLabel,
                        }}
                        onClick={() => {
                          if (bnbBalance > 0) {
                            setDepositInputValue(bnbBalance - 0.0001);
                          } else {
                            setDepositInputValue(0);
                          }
                        }}
                      >
                        MAX
                      </Button>
                    </div>
                    <Button
                      variant="contained"
                      classes={{
                        root: classes.walletButton,
                        label: classes.buttonLabel,
                      }}
                      onClick={() => {
                        window.open(theme.lp, "_blank");
                      }}
                    >
                      Add Liquidity
                    </Button>
                    {/* {theme.type === "mtc" ||
                    theme.type === "eth" ||
                    theme.type === "ftm" ? (
                      <Button
                        variant="contained"
                        classes={{
                          root: classes.walletButton,
                          label: classes.buttonLabel,
                        }}
                        onClick={() => {
                          window.open(theme.lp, "_blank");
                        }}
                      >
                        Add Liquidity
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        classes={{
                          root: classes.walletButton,
                          label: classes.buttonLabel,
                        }}
                        onClick={() => {
                          onStake();
                        }}
                        disabled={theme.type === "mtc" || theme.type === "eth"}
                      >
                        Stake (50% Buy)
                      </Button>
                    )} */}
                    {/* <div className={classes.stakedTimeContainer}>
                      {lastStakedTimestamp > 0 && (
                        <span className={classes.stakedTimeSpan}>
                          You last staked on {formatDate(lastStakedTimestamp)}
                        </span>
                      )}
                    </div> */}
                  </Card>
                </Zoom>

                <Zoom in={true}>
                  <Card className={classes.cardDetailPanel}>
                    <span className={classes.cardValueHeader}>
                      Your Staked LP
                      {bnbBalanceLocked > 0 && (
                        <Tooltip
                          TransitionComponent={Fade}
                          classes={{ tooltip: classes.cardValueTooltip }}
                          title={
                            <Typography
                              className={classes.cardValueTooltipText}
                            >
                              <span className={classes.highlightValue}>
                                {bnbBalanceLocked} {theme?.symbol || "BNB"}
                              </span>{" "}
                              staked from claiming rewards are permanently
                              locked in liquidity.
                            </Typography>
                          }
                          style={{
                            display: "none",
                          }}
                          arrow
                        >
                          <IconButton>
                            <InfoIcon
                              style={{
                                position: "absolute",
                                color: "#FF8800",
                                top: "-4px",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </span>
                    <span className={classes.cardValue}>
                      {parseFloat(lpStaked).toFixed(3)} LP (
                      {parseFloat(lpStaked * lpBnbPrice).toFixed(3)}{" "}
                      {theme?.symbol || "BNB"})
                    </span>
                    <span className={classes.cardUsdValue}>
                      $
                      {parseFloat(
                        (
                          lpStaked *
                          lpBnbPrice *
                          parseFloat(bnbUsdPrice)
                        ).toFixed(2)
                      ).toLocaleString()}{" "}
                      USD
                    </span>
                    <Button
                      variant="contained"
                      classes={{
                        root: classes.walletButton,
                        label: classes.buttonLabel,
                      }}
                      disabled={!isWithdrawAvailable}
                      onClick={() => {
                        onUnstake();
                      }}
                    >
                      Unstake{" "}
                      {exitFee > 0 && (
                        <span
                          className={classes.stakingFeeSpan}
                          style={{ marginLeft: "5px" }}
                        >
                          ({exitFee.toFixed(0)}% exit fee)
                        </span>
                      )}
                    </Button>
                    {nextWithdrawTimestamp > 0 && (
                      <div className={classes.stakedTimeContainer}>
                        <span className={classes.stakedTimeSpan}>
                          Next withdrawal{" "}
                          {parseFloat(nextWithdrawlAmount).toFixed(3)} LP
                          available{" "}
                          {withdrawAvTime == "0:00:00" ? "now" : withdrawAvTime}
                        </span>
                      </div>
                    )}
                    {nextWithdrawTimestamp == 0 && (
                      <div className={classes.stakedTimeContainer}>
                        <span className={classes.stakedTimeSpan}>
                          You do not have {theme?.symbol || "BNB"} available for
                          unstaking
                        </span>
                      </div>
                    )}
                  </Card>
                </Zoom>

                <Zoom in={true}>
                  <Card className={classes.cardDetailPanel}>
                    <span className={classes.cardValueHeader}>
                      Pending START Rewards
                    </span>
                    <span className={classes.cardValue}>
                      {pendingRewards || "0"} START
                    </span>
                    <span className={classes.cardUsdValue}>
                      ${(pendingRewards * startPrice).toLocaleString()} USD
                    </span>
                    <Button
                      variant="contained"
                      classes={{
                        root: classes.walletButton,
                        label: classes.buttonLabel,
                      }}
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        onClaim();
                      }}
                      disabled={pendingRewards == 0}
                    >
                      Claim{" "}
                      {claimingFee !== -1 && (
                        <span style={{ marginLeft: "5px" }}>
                          ({parseFloat(claimingFee).toFixed(0)}% Burn fee)
                        </span>
                      )}
                    </Button>
                  </Card>
                </Zoom>
                <Zoom in={true}>
                  <Card className={classes.cardDetailPanel}>
                    <span className={classes.cardValueHeader}>Unstake/Day</span>
                    <span className={classes.cardValue}>{withdrawPerDay}%</span>
                    <span className={classes.cardUsdValue}>
                      ${" "}
                      {parseFloat(
                        (
                          (lpStaked *
                            lpBnbPrice *
                            parseFloat(bnbUsdPrice) *
                            withdrawPerDay) /
                          100
                        ).toFixed(2)
                      ).toLocaleString()}{" "}
                      USD
                    </span>
                    <hr
                      style={{
                        color: "#d4d4d4",
                        width: "90%",
                      }}
                    />
                    <span className={classes.cardValueHeader}>Your START</span>
                    <span className={classes.cardValue}>
                      <span className={classes.dollarHighlight}>
                        {(
                          parseFloat(startBalance) +
                          parseFloat(pendingRewards || 0)
                        )
                          .toFixed(3)
                          .toLocaleString()}
                      </span>
                    </span>
                    <span className={classes.dollarHighlight}>
                      $
                      {(
                        (parseFloat(startBalance) +
                          parseFloat(pendingRewards || 0)) *
                        startPrice
                      ).toLocaleString()}{" "}
                      USD
                    </span>
                  </Card>
                </Zoom>
              </div>
            )}

            {!(account && account.address) && (
              <div className={classes.accountConnectPanel}>
                <Card className={classes.accountConnectCard}>
                  <span className={classes.accountConnectCardText}>
                    Securely connect your wallet below to begin.
                  </span>
                </Card>
              </div>
            )}
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(Farming)));
