import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  useTheme,
  Button,
  Input,
  TextField,
} from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import Web3 from "web3";

const styles = (theme) => ({
  poolWhitelistCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.apply?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.apply?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  poolPresaleDetailInputContainer: {
    display: "flex",
    flexDirection: "column",
  },

  poolInfoInput: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  poolInfoInputHeader: {
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: theme?.colors?.apply?.cardTitleSecondary,
    textTransform: "capitalize",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "16px",
    },
  },

  text: {
    color: theme?.colors?.apply?.cardText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  textSecondary: {
    color: theme?.colors?.apply?.cardTitleSecondary,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
  },

  actionTextInput: {
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "250px",
    width: "100%",
    color: theme?.colors?.apply?.cardText,
    outline: "0px",
    overflow: "auto",
    "& > div > fieldset ": {
      borderWidth: "0px !important",
      borderColor: "transparent !important",
    },
    "& > div > textarea ": {
      color: theme?.colors?.apply?.cardText,
    },
  },
  actionUnderline: {
    color: theme?.colors?.apply?.cardTitleSecondary,
  },
  poolPrevNextWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  walletButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "8px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  applyButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginRight: "8px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  errorText: {
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    color: theme?.colors?.apply?.warningBg,
    marginTop: "4px",
    marginRight: "4px",
    textAlign: "right",
  },
});

const WhitelistCard = ({ classes, value, onNext, onBack }) => {
  const [whitelist, setWhitelist] = useState(value.join("\n"));
  const [errors, setErrors] = useState("");

  const handleBack = () => {
    onBack();
  };

  const handleNext = () => {
    let isCompleted = true;
    let updatedErrors = "";

    if (!whitelist || !whitelist.length) {
      onNext([]);
      return;
    }

    const addresses = whitelist.split("\n");
    const filtered = addresses.filter(
      (address) => address.length > 0 && address !== " "
    );
    filtered.map((address) => {
      if (!Web3.utils.isAddress(address)) {
        isCompleted = false;
        updatedErrors = "Invalid address";
      }
    });
    if (isCompleted) {
      onNext(filtered);
    } else {
      setErrors(updatedErrors);
    }
  };

  return (
    <>
      <div className={classes.poolWhitelistCard}>
        <Typography variant="h1" className={classes.poolTitle}>
          Whitelist
        </Typography>
        <div className={classes.poolPresaleDetailInputContainer}>
          <Typography variant="h4" className={classes.poolInfoInputHeader}>
            Addresses (One Address Per line)
          </Typography>
          <TextField
            id="outlined-multiline-static"
            classes={{
              root: classes.actionTextInput,
            }}
            multiline={true}
            disableUnderline={true}
            defaultValue={value}
            variant="outlined"
            onChange={(ev) => {
              setWhitelist(ev.target.value);
            }}
            autoFocus={true}
          />
          <Typography variant="h6" className={classes.errorText}>
            {errors}
          </Typography>
        </div>
      </div>
      <div className={classes.poolPrevNextWrapper}>
        <Button
          classes={{
            root: classes.applyButton,
            label: classes.buttonLabel,
          }}
          onClick={() => {
            handleBack();
          }}
        >
          Back
        </Button>
        <Button
          classes={{
            root: classes.walletButton,
            label: classes.buttonLabel,
          }}
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(WhitelistCard)));
