import React, { useEffect } from 'react'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'

export default function FadeInWhenVisible({ duration, ease, delay, children }) {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration, ease, delay }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
    >
      {children}
    </motion.div>
  )
}

FadeInWhenVisible.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  duration: PropTypes.number,
  ease: PropTypes.string,
  delay: PropTypes.number,
}

FadeInWhenVisible.defaultProps = {
  children: () => {},
  duration: 0.53,
  ease: 'easeInOut',
  delay: 0,
}
