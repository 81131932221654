import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { FadeInWhenVisible } from "../../animation";

import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Button,
  Typography,
  Input,
  useTheme,
  Fade,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

import CloseIcon from "@material-ui/icons/Close";

import { ReactComponent as BronzeIcon } from "../../../assets/bronze.svg";
import { ReactComponent as SilverIcon } from "../../../assets/silver.svg";
import { ReactComponent as GoldIcon } from "../../../assets/gold.svg";
import { ReactComponent as PlatinumIcon } from "../../../assets/platinum.svg";
import { ReactComponent as DiamondIcon } from "../../../assets/diamond.svg";
import { ReactComponent as CheckIcon } from "../../../assets/check.svg";
import { tiers } from "../../../constants";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: theme?.colors?.stake?.background,
    minWidth: "100%",
    minHeight: "100%",
    overflow: "auto",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px",
    borderRadius: "20px",
    "&:first-child": {
      paddingTop: "0px",
    },
  },
  modalRoot: {
    "& > .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  modalPaperRoot: {
    maxWidth: "744px",
    borderRadius: "20px",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    "& > .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "-10px",
    zIndex: "5",
  },
  screenMask: {
    backgroundColor: "rgba(0, 0, 0, 0.05)",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  walletButton: {
    borderRadius: "0.375rem",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    "&:disabled": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
      opacity: "0.3",
    },
  },
  secondaryWalletButton: {
    borderRadius: "0.375rem",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackgroundSecondary,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "200px",
    padding: "8px 16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    "&:disabled": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
      opacity: "0.3",
    },
  },
  cancelButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    "&:disabled": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
      opacity: "0.3",
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  modalStakeClaimContainer: {
    width: "100%",
    minHeight: "120px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0px 0px 20px 20px",
    border: "solid 3px " + theme?.colors?.header?.borderColor,
    filter: "drop-shadow(0px 7px 2px rgba(0,0,0,0.13))",
    backgroundImage: theme?.colors?.header?.headerBackground,
    padding: "10px",
    [theme.breakpoints.up("ms")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  stakingTokenImage: {
    height: "60px",
    marginTop: "20px",
    marginBottom: "20px",
    [theme.breakpoints.up("ms")]: {
      marginLeft: "20px",
      marginRight: "20px",
    },
  },
  bnbBalanceHeader: {
    color: theme?.colors?.header?.balanceText,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "5px",
  },
  bnbBalanceText: {
    color: theme?.colors?.header?.textSecondary,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    marginBottom: "30px",
  },
  startBalanceText: {
    color: theme?.colors?.header?.balanceText,
    fontSize: "25px",
    letterSpacing: "2px",
    fontWeight: "bold",
    textAlign: "bottom",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  startUsdBalanceText: {
    color: theme?.colors?.header?.balanceUsdText,
    fontSize: "10px",
    letterSpacing: "2px",
    fontWeight: "bold",
    textAlign: "center",
  },
  dateText: {
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    color: theme?.colors?.header?.balanceValue,
  },
  bnbBalanceTextSecondary: {
    color: theme?.colors?.header?.balanceValue,
  },
  earnedText: {
    color: theme?.colors?.header?.textSecondary,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    textAlign: "center",
    marginBottom: "16px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    marginBottom: "20px",
    [theme.breakpoints.up("ms")]: {
      marginBottom: "0px",
    },
  },
  actionInput: {
    border: "solid 1px " + theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    paddingRight: "70px",
    color: theme?.colors?.presale.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.presale.cardTitleSecondary,
  },
  maxButton: {
    position: "absolute",
    right: "0px",
    borderRadius: "0px 14px 14px 0px",
    height: "50px",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    width: "70px",
    padding: "16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
  },
  divider: {
    width: "100%",
    border: "solid 1px " + theme?.colors?.divider,
    height: "0px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  stakeUnstakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    padding: "20px",
    minHeight: "77px",
    borderRadius: "19px",
    filter: "drop-shadow(0px 3px 1.5px rgba(87,87,87,0.5))",
    backgroundColor: theme?.colors?.stakeBackground,
    border: "solid 3px " + theme?.colors?.header?.borderColor,
  },
  stakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    marginRight: "0px",
    [theme.breakpoints.up("ms")]: {
      marginBottom: "0px",
      marginRight: "20px",
    },
  },
  unstakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  stakeUnstakeAvailableTip: {
    marginTop: "8px",
    marginBottom: "8px",
  },
  tipText: {
    color: theme?.colors?.header?.textSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "20px",
    textAlign: "center",
  },
  badge: {
    marginLeft: "10px",
    width: "25px",
    height: "25px",
  },
  closeIcon: {
    color: theme?.colors?.header?.closeButton,
    width: "32px",
    height: "32px",
  },
  modalHeader: {
    width: "100%",
    height: "120px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px 20px 0px 0px",
    border: "solid 3px " + theme?.colors?.header?.borderColor,
    filter: "drop-shadow(0px 7px 2px rgba(0,0,0,0.13))",
    backgroundImage: theme?.colors?.header?.headerBackground,
    padding: "10px",
    cursor: "pointer",
    [theme.breakpoints.up("ms")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  modalLeftHeader: {
    marginLeft: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  modalRightHeader: {
    minWidth: "210px",
    height: "54px",
    borderRadius: "13px",
    backgroundColor: theme?.colors?.header?.balanceBackground,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeading: {
    color: theme?.colors?.header?.heading,
    fontSize: "27px",
    letterSpacing: "2px",
    fontWeight: "bold",
    lineHeight: "36px",
  },
  modalDetails: {
    color: theme?.colors?.header?.details,
    fontSize: "12px",
    letterSpacing: "1px",
    lineHeight: "25px",
    fontWeight: "normal",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detailsIcon: {
    width: "16px",
    height: "16px",
    marginLeft: "5px",
    backgroundColor: theme?.colors?.header?.heading,
    color: theme?.colors?.header?.balanceBackground,
    borderRadius: "50%",
  },
  balanceImage: {
    width: "40px",
    height: "40px",
    marginRight: "10px",
  },
  stakedRewardClaimContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  stakedContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  rewardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  stakeUnstakeButton: {
    borderRadius: "8px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "25px",
    width: "25px",
    minWidth: "25px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    "&:disabled": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
      opacity: "0.3",
    },
  },
  stakeUnstakeBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("ms")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  leftStakeBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "160px",
    [theme.breakpoints.up("ms")]: {
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
  middleStakeBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("ms")]: {
      alignItems: "flex-start",
      justifyContent: "center",
    },
  },
  tierPanel: {
    width: "100%",
    backgroundColor: theme?.colors?.header?.tierBackground,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    [theme.breakpoints.up("ms")]: {
      padding: "20px 60px 60px 60px",
    },
  },
  tierTickers: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  },
  tierTickerLabels: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 40px",
    position: "relative",
  },
  tierTickerBar: {
    width: "100%",
    height: "5px",
    backgroundColor: theme?.colors?.header?.heading,
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  tierTickerValues: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 40px",
    position: "relative",
  },
  tierLabel: {
    position: "absolute",
    color: theme?.colors?.header?.heading,
    fontSize: "10px",
    fontWeight: "600",
    letterSpacing: "1px",
    lineHeight: "20px",
    width: "100px",
    textAlign: "center",
    [theme.breakpoints.up("ms")]: {
      fontSize: "15px",
      letterSpacing: "2px",
    },
  },
  tierValue: {
    position: "absolute",
    color: theme?.colors?.header?.heading,
    fontSize: "8px",
    letterSpacing: "0.5px",
    fontWeight: "500",
    lineHeight: "15px",
    whiteSpace: "pre-wrap",
    width: "100px",
    textAlign: "center",
    [theme.breakpoints.up("ms")]: {
      fontSize: "12px",
      letterSpacing: "1px",
    },
  },
  tierCircle: {
    position: "absolute",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    border: "solid 3px " + theme?.colors?.header?.heading,
    backgroundColor: theme?.colors.header?.tierCircle,
    top: "-5px",
  },
  tierCircleFilled: {
    position: "absolute",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    border: "solid 3px " + theme?.colors?.header?.heading,
    backgroundColor: theme?.colors.header?.tierCircleFilled,
    top: "-5px",
  },
  tooltip: {
    background: theme?.colors.header?.tooltip,
    color: theme?.colors.header?.borderColor,
    padding: "20px",
    fontSize: "15px",
    fontWeight: "normal",
    textAlign: "center",
    borderRadius: "14px",
  },
});

const StakeModal = ({
  classes,
  account,
  closeModal,
  modalOpen,
  bnbBalance = 0,
  earnedBnb = 0,
  startBalance = 0,
  v1StakedBalance = 0,
  stakedBalance = 0,
  totalStakedBalance = 0,
  stakingToken = " - ",
  bnbPrice = 0,
  stakingTokenBalance = 0,
  startPrice = 0,
  stakeAvailableTime = 0,
  unstakeAvailableTime = 0,
  firstStakedTimestamp = 0,
  isLongStaker = false,
  burnFee = 50,
  onStake,
  onUnstake,
  onMoveStake,
  onClaim,
  onStakeV1,
  onUnstakeV1,
}) => {
  const [stakeValue, setStakeValue] = useState(0);
  const [unstakeValue, setUnstakeValue] = useState(0);
  const [v1StakeValue, setV1StakeValue] = useState(0);
  const [v1UnstakeValue, setV1UnstakeValue] = useState(0);
  const fullScreen = window.innerWidth < 900;
  const v1Whitelisted = {
    "0xb987a86f8e021117554043476d20aa7a04ef8185": true,
    "0xf7e925818a20E5573Ee0f3ba7aBC963e17f2c476": true,
    "0xB1253554107FDE5ec8d047f81f0003fdE345Ad61": true,
    "0x30BcdE92242dc137BDA2569729a694B1D674F76a": true,
    "0xcc887c71ABeB5763E896859B11530cc7942c7Bd5": true,
  };

  const [isStakeBoxOpened, showStakeBox] = useState(false);
  const [isUnstakeBoxOpened, showUnstakeBox] = useState(false);
  const [tierPanelOpened, showTierPanel] = useState(true);
  const theme = useTheme();
  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      classes={{
        root: classes.modalRoot,
        paperWidthSm: classes.modalPaperRoot,
        scrollPaper: classes.screenMask,
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.modalContainer}>
          <div
            className={classes.modalHeader}
            style={!tierPanelOpened ? { borderBottom: "none" } : {}}
            onClick={(ev) => {
              showTierPanel(!tierPanelOpened);
            }}
          >
            <div className={classes.modalLeftHeader}>
              <Typography variant="h3" className={classes.modalHeading}>
                Wallet Balance
              </Typography>
              <Typography variant="h3" className={classes.modalDetails}>
                Details
                {tierPanelOpened ? (
                  <ArrowDropUpIcon className={classes.detailsIcon} />
                ) : (
                  <ArrowDropDownIcon className={classes.detailsIcon} />
                )}
              </Typography>
            </div>
            <div className={classes.modalRightHeader}>
              <img
                src={theme?.logoImage}
                alt="logo"
                className={classes.balanceImage}
              />
              <Typography variant="h3" className={classes.startBalanceText}>
                {startBalance}
                <Typography
                  variant="h3"
                  className={classes.startUsdBalanceText}
                >
                  ~$
                  {parseFloat(
                    parseFloat(startBalance * startPrice).toFixed(2)
                  ).toLocaleString()}
                </Typography>
              </Typography>
            </div>
          </div>
          {tierPanelOpened && (
            <div className={classes.tierPanel}>
              <img
                className={classes.stakingTokenImage}
                src={theme?.stakingTokenImage}
                alt="staking"
              />
              <div className={classes.tierTickers}>
                <div className={classes.tierTickerLabels}>
                  {tiers[theme?.type].map((tier) => (
                    <Typography
                      variant="h3"
                      className={classes.tierLabel}
                      style={{
                        left:
                          "calc(" +
                          ((tier.value - tiers[theme?.type][0].value) /
                            (tiers[theme?.type][3].value -
                              tiers[theme?.type][0].value)) *
                            100 +
                          "% - 50px)",
                      }}
                    >
                      {tier?.label}
                    </Typography>
                  ))}
                </div>
                <div className={classes.tierTickerBar}>
                  {tiers[theme?.type].map((tier) => (
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={tier?.tip}
                    >
                      <div
                        className={
                          parseFloat(totalStakedBalance) >= tier.value
                            ? classes.tierCircleFilled
                            : classes.tierCircle
                        }
                        style={{
                          left:
                            "calc(" +
                            ((tier.value - tiers[theme?.type][0].value) /
                              (tiers[theme?.type][3].value -
                                tiers[theme?.type][0].value)) *
                              100 +
                            "% - 7px)",
                        }}
                      />
                    </Tooltip>
                  ))}
                </div>
                <div className={classes.tierTickerValues}>
                  {tiers[theme?.type].map((tier) => (
                    <Typography
                      variant="h3"
                      className={classes.tierValue}
                      style={{
                        left:
                          "calc(" +
                          ((tier.value - tiers[theme?.type][0].value) /
                            (tiers[theme?.type][3].value -
                              tiers[theme?.type][0].value)) *
                            100 +
                          "% - 50px)",
                      }}
                    >
                      {tier?.value} <br />
                      {stakingToken}
                    </Typography>
                  ))}
                </div>
              </div>
            </div>
          )}

          <div className={classes.modalStakeClaimContainer}>
            <img
              className={classes.stakingTokenImage}
              src={theme?.stakingTokenImage}
              alt="staking"
            />
            <div className={classes.stakeUnstakeContainer}>
              <div className={classes.stakedRewardClaimContainer}>
                <div className={classes.stakedContainer}>
                  <Typography variant="h4" className={classes.bnbBalanceHeader}>
                    Total Staked{" "}
                    {theme?.type === "mtc" && "(QUICKSWAP staking included)"}
                  </Typography>
                  <Typography variant="h3" className={classes.startBalanceText}>
                    {totalStakedBalance.toFixed(3)} {}
                    <Button
                      classes={{
                        root: classes.stakeUnstakeButton,
                        label: classes.stakeUnstakeLabel,
                      }}
                      onClick={(ev) => {
                        showStakeBox(true);
                        showUnstakeBox(false);
                      }}
                    >
                      +
                    </Button>
                    <Button
                      classes={{
                        root: classes.stakeUnstakeButton,
                        label: classes.stakeUnstakeLabel,
                      }}
                      onClick={(ev) => {
                        showStakeBox(false);
                        showUnstakeBox(true);
                      }}
                    >
                      -
                    </Button>
                  </Typography>
                </div>
                <div className={classes.rewardContainer}>
                  {theme?.type === "bsc" && (
                    <>
                      <Typography
                        variant="h4"
                        className={classes.bnbBalanceHeader}
                      >
                        BNB Reward
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.startBalanceText}
                      >
                        {earnedBnb.toFixed(3)}{" "}
                        <Typography
                          variant="h3"
                          className={classes.startUsdBalanceText}
                        >
                          ~$
                          {parseFloat(
                            parseFloat(earnedBnb * bnbPrice).toFixed(2)
                          ).toLocaleString()}
                        </Typography>
                      </Typography>
                    </>
                  )}
                </div>
                <Button
                  classes={{
                    root: classes.walletButton,
                    label: classes.buttonLabel,
                  }}
                  onClick={(ev) => {
                    if (onClaim) {
                      onClaim();
                    }
                  }}
                  disabled={earnedBnb === 0}
                >
                  Harvest
                </Button>
              </div>
              {(isStakeBoxOpened || isUnstakeBoxOpened) && (
                <div className={classes.divider} />
              )}

              {isStakeBoxOpened && (
                <FadeInWhenVisible duration={0.83} delay={0.1}>
                  <div className={classes.stakeUnstakeBox}>
                    <div className={classes.leftStakeBox}>
                      <Typography
                        variant="h4"
                        className={classes.bnbBalanceHeader}
                      >
                        {stakingToken} Balance
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.startBalanceText}
                      >
                        {stakingTokenBalance.toFixed(3)}
                      </Typography>
                    </div>
                    <div className={classes.middleStakeBox}>
                      <div className={classes.inputContainer}>
                        <Input
                          classes={{
                            root: classes.actionInput,
                            underline: classes.actionUnderline,
                          }}
                          disableUnderline={true}
                          value={stakeValue}
                          onChange={(ev) => {
                            setStakeValue(ev.target.value);
                          }}
                        />
                        <Button
                          classes={{
                            root: classes.maxButton,
                            label: classes.buttonLabel,
                          }}
                          onClick={() => {
                            setStakeValue(stakingTokenBalance.toString());
                          }}
                        >
                          MAX
                        </Button>
                      </div>
                    </div>
                    <Button
                      classes={{
                        root: classes.walletButton,
                        label: classes.buttonLabel,
                      }}
                      onClick={(ev) => {
                        if (
                          0 < stakeValue &&
                          parseFloat(stakingTokenBalance) >=
                            parseFloat(stakeValue)
                        ) {
                          if (onStake) {
                            onStake(stakeValue);
                          }
                        } else {
                          setStakeValue(stakingTokenBalance.toString());
                        }
                      }}
                      disabled={parseFloat(stakingTokenBalance) == 0}
                    >
                      Stake
                    </Button>
                  </div>
                </FadeInWhenVisible>
              )}
              {isUnstakeBoxOpened && (
                <FadeInWhenVisible duration={0.83} delay={0.1}>
                  <div className={classes.stakeUnstakeBox}>
                    <div className={classes.leftStakeBox}>
                      <Typography
                        variant="h4"
                        className={classes.bnbBalanceHeader}
                      >
                        {stakingToken} Staked
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.startBalanceText}
                      >
                        {stakedBalance.toFixed(3)}
                      </Typography>

                      <Typography variant="h4" className={classes.tipText}>
                        <span className={classes.dateText}>{burnFee}</span>%
                        will be burned.
                      </Typography>
                    </div>
                    <div className={classes.middleStakeBox}>
                      <div className={classes.inputContainer}>
                        <Input
                          classes={{
                            root: classes.actionInput,
                            underline: classes.actionUnderline,
                          }}
                          disableUnderline={true}
                          value={unstakeValue}
                          onChange={(ev) => {
                            setUnstakeValue(ev.target.value);
                          }}
                        />
                        <Button
                          classes={{
                            root: classes.maxButton,
                            label: classes.buttonLabel,
                          }}
                          onClick={() => {
                            setUnstakeValue(stakedBalance.toString());
                          }}
                        >
                          MAX
                        </Button>
                      </div>
                    </div>
                    <Button
                      classes={{
                        root: classes.walletButton,
                        label: classes.buttonLabel,
                      }}
                      onClick={(ev) => {
                        if (0 < unstakeValue && stakedBalance >= unstakeValue) {
                          if (onUnstake) {
                            onUnstake(unstakeValue);
                          }
                        } else {
                          setUnstakeValue("0");
                        }
                      }}
                      disabled={parseFloat(stakedBalance) == 0}
                    >
                      Unstake
                    </Button>
                  </div>
                </FadeInWhenVisible>
              )}
              {parseFloat(v1StakedBalance) > 0 && (
                <>
                  <div className={classes.divider} />
                  <div className={classes.stakeUnstakeBox}>
                    <div className={classes.leftStakeBox}>
                      <Typography
                        variant="h4"
                        className={classes.bnbBalanceHeader}
                      >
                        {theme?.type === "bsc" && "v2"}
                        {theme?.type === "mtc" && "v1"} Staked Balance
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.startBalanceText}
                      >
                        {v1StakedBalance.toFixed(3)}
                      </Typography>
                    </div>
                    <div className={classes.middleStakeBox}>
                      <div className={classes.inputContainer} />
                    </div>
                    <Button
                      classes={{
                        root: classes.secondaryWalletButton,
                        label: classes.buttonLabel,
                      }}
                      onClick={(ev) => {
                        onMoveStake();
                      }}
                    >
                      Move Stake
                    </Button>
                  </div>
                </>
              )}
              {account && account.address && v1Whitelisted[account.address] && (
                <>
                  <div className={classes.divider} />
                  <div className={classes.stakeUnstakeBox}>
                    <div className={classes.leftStakeBox}>
                      <Typography
                        variant="h4"
                        className={classes.bnbBalanceHeader}
                      >
                        {stakingToken} Balance
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.startBalanceText}
                      >
                        {stakingTokenBalance.toFixed(3)}
                      </Typography>
                    </div>
                    <div className={classes.middleStakeBox}>
                      <div className={classes.inputContainer}>
                        <Input
                          classes={{
                            root: classes.actionInput,
                            underline: classes.actionUnderline,
                          }}
                          disableUnderline={true}
                          value={v1StakeValue}
                          onChange={(ev) => {
                            setV1StakeValue(ev.target.value);
                          }}
                        />
                        <Button
                          classes={{
                            root: classes.maxButton,
                            label: classes.buttonLabel,
                          }}
                          onClick={() => {
                            setStakeValue(stakingTokenBalance.toString());
                          }}
                        >
                          MAX
                        </Button>
                      </div>
                    </div>
                    <Button
                      classes={{
                        root: classes.walletButton,
                        label: classes.buttonLabel,
                      }}
                      onClick={(ev) => {
                        if (
                          0 < v1StakeValue &&
                          parseFloat(stakingTokenBalance) >=
                            parseFloat(v1StakeValue)
                        ) {
                          if (onStake) {
                            onStakeV1(v1StakeValue);
                          }
                        } else {
                          setV1StakeValue(stakingTokenBalance.toString());
                        }
                      }}
                      disabled={parseFloat(stakingTokenBalance) == 0}
                    >
                      Stake(v1)
                    </Button>
                  </div>
                  <div className={classes.divider} />

                  <div className={classes.stakeUnstakeBox}>
                    <div className={classes.leftStakeBox}>
                      <Typography
                        variant="h4"
                        className={classes.bnbBalanceHeader}
                      >
                        Staked Balance(v1)
                      </Typography>
                      <Typography
                        variant="h3"
                        className={classes.startBalanceText}
                      >
                        {v1StakedBalance.toFixed(3)}
                      </Typography>
                    </div>
                    <div className={classes.middleStakeBox}>
                      <div className={classes.inputContainer}>
                        <Input
                          classes={{
                            root: classes.actionInput,
                            underline: classes.actionUnderline,
                          }}
                          disableUnderline={true}
                          value={v1UnstakeValue}
                          onChange={(ev) => {
                            setV1UnstakeValue(ev.target.value);
                          }}
                        />
                        <Button
                          classes={{
                            root: classes.maxButton,
                            label: classes.buttonLabel,
                          }}
                          onClick={() => {
                            setV1UnstakeValue(v1StakedBalance.toString());
                          }}
                        >
                          MAX
                        </Button>
                      </div>
                    </div>
                    <Button
                      classes={{
                        root: classes.walletButton,
                        label: classes.buttonLabel,
                      }}
                      onClick={(ev) => {
                        if (
                          0 < v1UnstakeValue &&
                          v1StakedBalance >= v1UnstakeValue
                        ) {
                          if (onUnstakeV1) {
                            onUnstakeV1(v1UnstakeValue);
                          }
                          closeModal();
                        } else {
                          setV1UnstakeValue(v1StakedBalance.toString());
                        }
                      }}
                      disabled={v1StakedBalance === 0}
                    >
                      Unstake(v1)
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(StakeModal));
