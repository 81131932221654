const strings = [
  {
    inputs: [
      { internalType: "bytes32", name: "_saleTitle", type: "bytes32" },
      { internalType: "bytes32", name: "_linkTelegram", type: "bytes32" },
      { internalType: "bytes32", name: "_linkGithub", type: "bytes32" },
      { internalType: "bytes32", name: "_linkTwitter", type: "bytes32" },
      { internalType: "bytes32", name: "_linkWebsite", type: "bytes32" },
      { internalType: "bytes32", name: "_linkLogo", type: "bytes32" },
    ],
    name: "setStringInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const generals = [
  {
    inputs: [
      { internalType: "address", name: "_presaleCreator", type: "address" },
      { internalType: "address", name: "_tokenAddress", type: "address" },
      { internalType: "address", name: "_bscsTokenAddress", type: "address" },
      {
        internalType: "address",
        name: "_unsoldTokensDumpAddress",
        type: "address",
      },
    ],
    name: "setAddressInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_bscsLiqLockAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_bscsDevFeePercentage",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_bscsMinDevFeeInWei",
        type: "uint256",
      },
      { internalType: "uint256", name: "_bscsId", type: "uint256" },
      { internalType: "uint256", name: "_presaleGrantId", type: "uint256" },
      { internalType: "address", name: "_bscsStakingPool", type: "address" },
    ],
    name: "setBscsInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "_totalTokens", type: "uint256" },
      { internalType: "uint256", name: "_tokenPriceInWei", type: "uint256" },
      { internalType: "uint256", name: "_hardCapInWei", type: "uint256" },
      { internalType: "uint256", name: "_softCapInWei", type: "uint256" },
      { internalType: "uint256", name: "_maxInvestInWei", type: "uint256" },
      { internalType: "uint256", name: "_minInvestInWei", type: "uint256" },
      { internalType: "uint256", name: "_openTime", type: "uint256" },
      { internalType: "uint256", name: "_closeTime", type: "uint256" },
    ],
    name: "setGeneralInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_minInvestorBSCSBalance",
        type: "uint256",
      },
    ],
    name: "setMinInvestorBSCSBalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_minVoterBSCSBalance",
        type: "uint256",
      },
    ],
    name: "setMinVoterBSCSBalance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_minYesVotesThreshold",
        type: "uint256",
      },
    ],
    name: "setMinYesVotesThreshold",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "_onlyWhitelistedAddressesAllowed",
        type: "bool",
      },
    ],
    name: "setOnlyWhitelistedAddressesAllowed",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "cancelAndTransferTokensToPresaleCreator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_whitelistedAddresses",
        type: "address[]",
      },
    ],
    name: "addWhitelistedAddresses",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const pancake = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_cakeListingPriceInWei",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_cakeLiquidityAddingTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_cakeLPTokensLockDurationInDays",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_cakeLiquidityPercentageAllocation",
        type: "uint256",
      },
    ],
    name: "setPancakeSwapInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bool", name: "_bscsDevFeesExempted", type: "bool" },
    ],
    name: "setBscsDevFeesExempted",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const audit = [
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_whitelistedAddresses",
        type: "address[]",
      },
    ],
    name: "addAuditorWhitelistedAddresses",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "_auditor", type: "bytes32" },
      { internalType: "bool", name: "_isVerified", type: "bool" },
      { internalType: "bool", name: "_isWarning", type: "bool" },
      { internalType: "string", name: "_verifiedHash", type: "string" },
      { internalType: "string", name: "_warningHash", type: "string" },
    ],
    name: "setAuditorInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export { generals, audit, pancake, strings };
