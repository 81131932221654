import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import CheckMarkIcon from "../../../assets/check.svg";
import WarningIcon from "../../../assets/warning.svg";

const styles = (theme) => ({
  poolAuditCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  auditInformation: {
    display: "flex",
    flexDirection: "column",
  },
  auditNameContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    color: theme?.colors?.presale?.cardText,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
  },
  auditVerifiedMark: {
    display: "flex",
    backgroundColor: theme?.colors?.presale?.verifiedBg,
    borderRadius: "24px",
    padding: "8px 25px",
    height: "32px",
    color: theme?.colors?.presale?.cardTitleSecondary,
    textAlign: "left",
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0px",
  },
  auditFailedMark: {
    display: "flex",
    backgroundColor: theme?.colors?.presale?.warningBg,
    borderRadius: "24px",
    padding: "8px 25px",
    height: "32px",
    color: theme?.colors?.presale?.cardTitleSecondary,
    textAlign: "left",
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0px",
  },
  auditName: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
  },
  text: {
    color: theme?.colors?.presale?.cardText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  hashText: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  textSecondary: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
  },
});

const AuditCard = ({ classes, poolInfo }) => {
  const auditData = { ...poolInfo.auditInformation };
  return (
    <div className={classes.poolAuditCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        Audit
      </Typography>

      {auditData.auditor && (
        <div className={classes.auditInformation}>
          <div className={classes.auditNameContainer}>
            {auditData.isVerified && (
              <div className={classes.auditVerifiedMark}>
                <img
                  src={CheckMarkIcon}
                  alt="CheckMark"
                  style={{ width: "20px", height: "15px" }}
                />
                &nbsp;&nbsp;Verified
              </div>
            )}
            {auditData.isWarning && (
              <div className={classes.auditFailedMark}>
                <img
                  src={WarningIcon}
                  alt="WarningMark"
                  style={{ width: "20px", height: "15px" }}
                />
                &nbsp;&nbsp;failed
              </div>
            )}
            &nbsp;&nbsp;&nbsp;by&nbsp;&nbsp;&nbsp;
            <span className={classes.auditName}>{auditData.auditor}</span>
          </div>
          <Typography variant="h5" className={classes.text}>
            {auditData.isVerified && (
              <>
                <br />
                <a
                  className={classes.hashText}
                  href={auditData.verifiedHash || auditData.linkAudit}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {auditData.verifiedHash || auditData.linkAudit}
                </a>
                <br />
              </>
            )}
            {auditData.isWarning && (
              <>
                <br />
                <a
                  className={classes.hashText}
                  href={auditData.warningHash || auditData.linkAudit}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {auditData.warningHash || auditData.linkAudit}
                </a>
                <br />
              </>
            )}
          </Typography>
        </div>
      )}
      {!auditData.auditor && (
        <Typography variant="h5" className={classes.textSecondary}>
          Not audited yet.
        </Typography>
      )}
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(AuditCard)));
