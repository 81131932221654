import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button, Tooltip } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import { ReactComponent as UnlockSvg } from "../../../assets/unlocked.svg";
import { toFixed } from "../../../constants/constants";
import bigInt from "big-integer";

const styles = (theme) => ({
  poolManageCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  yourInformationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },
  yourTokenInfo: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },
  lockLiqContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  yourTokenInfoItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "0px",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
      marginBottom: "0px",
      marginRight: "40px",
    },
  },
  infoHeading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "4px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8px",
    },
  },
  infoValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "25px",
    },
  },
  actionButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.presale?.buttonBg,
    border: "solid 2px" + theme?.colors?.presale?.buttonBg,
    color: theme?.colors?.presale?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "170px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.presale?.buttonBgHover,
    },
    "&:disabled": {
      border: "solid 2px" + theme?.colors?.presale?.buttonBgDisabled,
      backgroundColor: theme?.colors?.presale?.buttonBgDisabled,
      cursor: "not-allowed",
    },
    fontFamily: "SFPro",
    letterSpacing: "0px",
    marginTop: "15px",
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },
  buttonLabel: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  tokenTooltip: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
});

const ManageCard = ({
  classes,
  poolInfo,
  onCollect,
  onCancelPresale,
  onSendUnsoldTokens,
  onAllowClaim,
  onLockLiq,
}) => {
  const tokenActionPart = (
    heading,
    value,
    buttonText,
    clickHandler,
    disabled = false,
    tooltip = false,
    tooltipText = null
  ) => {
    return (
      <div className={classes.yourTokenInfoItem}>
        <Typography variant="h5" className={classes.infoHeading}>
          {heading}
        </Typography>
        <Typography variant="h2" className={classes.infoValue}>
          {value}
        </Typography>
        {tooltip && disabled && (
          <Tooltip title={tooltipText}>
            <span>
              <Button
                classes={{
                  root: classes.actionButton,
                  label: classes.buttonLabel,
                }}
                onClick={() => {
                  if (clickHandler) {
                    clickHandler();
                  }
                }}
                disabled={disabled}
              >
                {buttonText}
              </Button>
            </span>
          </Tooltip>
        )}
        {tooltip && !disabled && (
          <Tooltip title={tooltipText}>
            <Button
              classes={{
                root: classes.actionButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                if (clickHandler) {
                  clickHandler();
                }
              }}
              disabled={disabled}
            >
              {buttonText}
            </Button>
          </Tooltip>
        )}
        {!tooltip && (
          <Button
            classes={{
              root: classes.actionButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              if (clickHandler) {
                clickHandler();
              }
            }}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={classes.poolManageCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        Presale Creator Actions
      </Typography>
      <div className={classes.yourInformationContainer}>
        <div className={classes.yourTokenInfo}>
          {tokenActionPart(
            "Collect Fund Raised",
            "",
            "Collect",
            onCollect,
            false,
            true,
            "Collect all funds raised in your presale."
          )}
          {tokenActionPart(
            "Cancel Presale",
            "",
            "Cancel",
            onCancelPresale,
            false,
            true,
            "Cancel the current presale and transfer tokens back to you"
          )}
          {/* {tokenActionPart(
            "Send Unsold Tokens",
            "",
            "Claim Unsold",
            onSendUnsoldTokens,
            false,
            true,
            "Send Unsold tokens back to presale creator"
          )} */}
          {poolInfo.presaleType === 0 &&
            tokenActionPart(
              !poolInfo.claimAllowed ? "Enable Claiming" : "Enabled",
              "",
              "Enable Claim",
              onAllowClaim,
              poolInfo.claimAllowed,
              true,
              "Allow users to claim their tokens."
            )}
          <div className={classes.lockLiqContainer}>
            <UnlockSvg />
            <Button
              classes={{
                root: classes.actionButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                if (onLockLiq) {
                  onLockLiq();
                }
              }}
            >
              Lock Liq and List
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(ManageCard)));
