import React, { useEffect, useState } from "react";
import bigInt from "big-integer";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Dialog,
  Slide,
  Button,
  Typography,
  Input,
  useTheme,
} from "@material-ui/core";

import { toFixed } from "../../../constants/constants";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: theme?.colors?.stake?.background,
    minWidth: "100%",
    minHeight: "100%",
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "45px",
    "&:first-child": {
      paddingTop: "45px",
    },
    borderRadius: "30px",
  },
  modalRoot: {
    borderRadius: "30px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  screenMask: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  walletButton: {
    borderRadius: "0.375rem",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  cancelButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  modalHeader: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
      lineHeight: "36px",
      marginBottom: "30px",
    },
  },
  priceHeader: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  priceText: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "30px",
      lineHeight: "36px",
      marginBottom: "30px",
    },
  },
  minMaxText: {
    color: theme?.colors?.presale?.commentText,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    marginBottom: "16px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    marginBottom: "10px",
    [theme.breakpoints.up("md")]: {
      width: "395px",
      marginBottom: "30px",
    },
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    marginBottom: "10px",
    [theme.breakpoints.up("ms")]: {
      flexDirection: "row",
    },
  },
  actionInput: {
    border: "solid 1px " + theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    paddingRight: "70px",
    color: theme?.colors?.presale.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.presale.cardTitleSecondary,
  },
  maxButton: {
    position: "absolute",
    right: "0px",
    borderRadius: "0px 14px 14px 0px",
    height: "50px",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    width: "70px",
    padding: "16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
  },
});

const BuyModal = ({
  classes,
  closeModal,
  modalOpen,
  tokenPrice,
  minInvestAmount,
  maxInvestAmount,
  presale,
  stakedLockedAmount,
  totalLockedValues,
  fundingToken,
  fundingTokenBalance,
  onBuy,
}) => {
  const [inputValue, setInputValue] = useState(0);
  const [maximumAmount, setMaximumAmount] = useState(0);
  const fullScreen = window.innerWidth < 900;

  const getGuaranteedAmount = () => {
    const currentTimestamp = Date.now() / 1000;
    const openTime = parseInt(presale.openTime);
    const guaEndTime = openTime + parseInt(presale.guaranteedHours);
    const hardcap = parseFloat(
      toFixed(
        bigInt(presale?.hardCapInWei || "0"),
        presale?.fundingTokenDecimals || 18,
        3
      )
    );
    const stakedAmount = parseFloat(
      toFixed(
        bigInt(stakedLockedAmount || "0"),
        presale?.fundingTokenDecimals || 18,
        3
      )
    );
    const lockedAmount = parseFloat(
      toFixed(
        bigInt(totalLockedValues || "0"),
        presale?.fundingTokenDecimals || 18,
        3
      )
    );
    if (guaEndTime > currentTimestamp) {
      return (stakedAmount * hardcap) / lockedAmount;
    }
    return maxInvestAmount;
  };

  useEffect(() => {
    if (presale?.presaleVersion === "V1") {
      setMaximumAmount(maxInvestAmount);
    } else if (presale?.presaleVersion === "V2") {
      if (presale?.presaleType === 0) {
        setMaximumAmount(maxInvestAmount);
      } else {
        const guaranteed = getGuaranteedAmount();
        if (guaranteed > maxInvestAmount) {
          setMaximumAmount(maxInvestAmount);
        } else {
          setMaximumAmount(guaranteed);
        }
      }
    } else if (presale?.presaleVersion === "V3") {
      if (presale?.presaleType === 0) {
        setMaximumAmount(maxInvestAmount);
      } else {
        const _maxInvestAmount = parseFloat(
          toFixed(
            bigInt(presale?.maxInvestAmount || "0"),
            presale?.fundingTokenDecimals || 18,
            6
          )
        );
        setMaximumAmount(_maxInvestAmount);
      }
    }
  }, [presale]);

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      classes={{
        paperWidthSm: classes.modalRoot,
        scrollPaper: classes.screenMask,
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <div className={classes.modalContainer}>
          <Typography variant="h3" className={classes.modalHeader}>
            Buy Token
          </Typography>
          <Typography variant="h5" className={classes.priceHeader}>
            Price
          </Typography>
          <Typography variant="h3" className={classes.priceText}>
            {parseFloat(tokenPrice).toFixed(5)} {fundingToken?.label}
          </Typography>
          {presale.presaleType === 0 ? (
            <Typography variant="h4" className={classes.minMaxText}>
              Min {minInvestAmount} {fundingToken?.label} and Maximum{" "}
              {maxInvestAmount}&nbsp;
              {fundingToken?.label}
            </Typography>
          ) : (
            <Typography variant="h4" className={classes.minMaxText}>
              Min {minInvestAmount} {fundingToken?.label} and Max{" "}
              {maximumAmount.toFixed(5)} {fundingToken?.label}
            </Typography>
          )}
          <div className={classes.inputContainer}>
            <Input
              classes={{
                root: classes.actionInput,
                underline: classes.actionUnderline,
              }}
              disableUnderline={true}
              value={inputValue}
              onChange={(ev) => {
                setInputValue(ev.target.value);
              }}
              autoFocus={true}
            />
            <Button
              classes={{ root: classes.maxButton, label: classes.buttonLabel }}
              onClick={() => {
                setInputValue(maximumAmount.toString());
              }}
            >
              MAX
            </Button>
          </div>
          <div className={classes.actionContainer}>
            <Button
              classes={{
                root: classes.cancelButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              classes={{
                root: classes.walletButton,
                label: classes.buttonLabel,
              }}
              onClick={(ev) => {
                if (
                  minInvestAmount <= inputValue &&
                  maximumAmount >= inputValue
                ) {
                  if (onBuy) {
                    onBuy(inputValue);
                  }
                  closeModal();
                } else {
                  setInputValue(minInvestAmount);
                }
              }}
            >
              Buy
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(BuyModal));
