import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  useTheme,
  IconButton,
  Input,
  TextField,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { withNamespaces } from "react-i18next";

const styles = (theme) => ({
  poolInfoDropdownBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "transparent !important",
    },
  },

  poolInfoDropdownBoxHeader: {
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: "#ededed",
    textTransform: "capitalize",
    maxWidth: "200px",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "16px",
    },
  },

  text: {
    color: theme?.colors?.apply?.cardText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  textSecondary: {
    color: theme?.colors?.apply?.cardTitleSecondary,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
  },

  actionInput: {
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: theme?.colors?.apply?.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.apply?.cardTitleSecondary,
  },
  poolPrevNextWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  walletButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "8px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  applyButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginRight: "8px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  errorText: {
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    color: theme?.colors?.apply?.warningBg,
    marginTop: "4px",
    marginRight: "4px",
    textAlign: "right",
  },
  textField: {
    border: "solid 0px transparent ",
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: theme?.colors?.apply?.cardText,
    fontSize: "20px",
    outline: "0px",
    "& > div > input": {
      color: theme?.colors?.apply?.cardText,
    },
  },
  themeButton: {
    borderRadius: "14px",
    color: theme?.colors?.apply?.cardText,
    fontSize: "20px",
    outline: "0px",
    padding: "14px",
  },
  iconOutlined: {
    color: theme?.colors?.apply?.cardTitleSecondary,
  },
  dropdownRoot: {
    "&:before": {
      borderBottom: "transparent !important",
    },
  },
  menuItemContainer: {
    display: "flex",
    alignItems: "center",
  },
  logoIcon: {
    height: "24px",
    marginRight: "8px",
  },
  themeName: {
    fontSize: "17px",
    lineHeight: "20px",
  },
  logoText: {
    color: theme?.colors?.header?.text,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  logoBoldText: {
    color: theme?.colors?.header?.boldBrownText,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
});

const ThemeSelectBox = ({ classes, handleClick }) => {
  const theme = useTheme();

  return (
    <div className={classes.poolInfoDropdownBox}>
      <IconButton
        onClick={() => {
          handleClick();
        }}
        classes={{
          root: classes.themeButton,
        }}
      >
        <div className={classes.menuItemContainer}>
          <img
            src={theme.chainLogo}
            alt="ethereum"
            className={classes.logoIcon}
          />
          <Typography className={classes.themeName}>{theme.chain}</Typography>
          <ArrowDropDownIcon />
        </div>
      </IconButton>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(ThemeSelectBox)));
