import STARTokenAbi from "./abis/startToken";
import ERC20Abi from "./abis/erc20";
import factoryAbi from "./abis/factory";
import starterInfoAbi from "./abis/starterInfo";
import starterStakingAbi from "./abis/starterStaking";
import starterPresaleAbi from "./abis/starterPresale";

import farmingAbi from "./abis/farming";
import farmingPairAbi from "./abis/farmingPair";

import swapRouterAbi from "./abis/swapRouter";

const config = {
  STARTokenAddress: "0x8ca2ecbCE34c322fceA6Db912d9DbfD2DdA5920D",
  STARTokenAbi,
  ERC20Abi,
  factoryAbi,
  factoryAddress: "0xeaD8aDe0150187c3399a25B7800DA3fd40Cd0714",
  factoryV3Abi: factoryAbi,
  factoryV3Address: "0xeaD8aDe0150187c3399a25B7800DA3fd40Cd0714",

  starterInfoAddress: "0xb156393238C268Ab25F83453A81Aa5C863ABb0F3",
  starterInfoAbi,
  starterInfoV3Address: "0xb156393238C268Ab25F83453A81Aa5C863ABb0F3",
  starterInfoV3Abi: starterInfoAbi,

  starterStaking: "0x3A350F48A991b3Eb7f9CBCcc7Efeb35FAD3F4E96",
  starterStakingAbi,
  starterStakingV2: "0x3A350F48A991b3Eb7f9CBCcc7Efeb35FAD3F4E96",
  starterStakingV2Abi: starterStakingAbi,
  starterStakingV3: "0x3A350F48A991b3Eb7f9CBCcc7Efeb35FAD3F4E96",
  starterStakingV3Abi: starterStakingAbi,
  starterPresaleAbi,

  starterVestingAddress: "0xE964e86E3242Ef313B8869166769217daE15eBc2",

  farmingAddress: "0xE25F8d48a1F652e567e50e554B73be79e8A00369",
  farmingAbi,
  farmingPairAddress: "0x5a38f2bee5b4413c52dd1fa44bc31c63cb5c7641",
  farmingPairAbi,
  wbnbAddress: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",

  stakingToken: "0x466EB66161B2dfC000a37017896c7a5d846b0900",
  stakingTokenName: "SPIRIT+START LP",
  stakingContracts: ["0x3A350F48A991b3Eb7f9CBCcc7Efeb35FAD3F4E96"],

  swapSymbol: "SPIRIT",
  swapToken: "0x5Cc61A78F164885776AA610fb0FE1257df78E59B",
  swapTokenAbi: ERC20Abi,
  swapRouter: "0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52",
  swapRouterAbi,
};

export default config;
