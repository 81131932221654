import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import bigInt from "big-integer";

import { withStyles } from "@material-ui/core/styles";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import { Typography, useTheme } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import { toFixed } from "../../../constants/constants";

const styles = (theme) => ({
  poolInfoCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "5px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolMainInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: "25px",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "45px",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  cardTitle: {
    color: theme?.colors?.presale?.cardTitle,
    textAlign: "left",
    fontSize: "25px",
    fontWeight: "bold",
    letterSpacing: "0px",
    lineHeight: "30px",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  poolTitleAddressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  poolImage: {
    width: "90px",
    height: "90px",
    marginRight: "15px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "35px",
    },
  },
  poolTitleAddress: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "24px",
    marginBottom: "5px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "30px",
      lineHeight: "36px",
      marginBottom: "10px",
      textAlign: "left",
    },
  },
  poolDescription: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },

  poolTokenLabel: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  poolTokenAddress: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "15px",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
      fontWeight: "normal",
      lineHeight: "18px",
    },
  },
  poolStatusContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  poolStatusBox: {
    minWidth: "140px",
    width: "fit-content",
    paddingLeft: "8px",
    paddingRight: "8px",
    height: "32px",
    borderRadius: "24px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.colors.poolCard?.poolTypeText,
    whiteSpace: "nowrap",
  },
  poolListingInfo: {
    marginTop: "25px",
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  poolStatusInfo: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
    },
  },
  poolSaleInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "20px",
    },
  },
  poolSaleText: {
    display: "flex",
    flexDirection: "column",
  },
  poolSaleHeader: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  poolSaleValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "25px",
    fontWeight: "normal",
    lineHeight: "30px",
  },
  poolParticipantsInfo: {
    display: "flex",
    alignItems: "flex-end",
  },
  poolParticipantText: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "25px",
    fontWeight: "bold",
    lineHeight: "30px",
  },
  poolPercentageBox: {
    display: "flex",
    height: "12px",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "12px",
    marginBottom: "4px",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      height: "16px",
      marginBottom: "8px",
    },
  },
  poolFilledBox: {
    height: "12px",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.addressValueText,
    borderRadius: "12px",
    position: "absolute",
    left: "0px",
    top: "0px",
    [theme.breakpoints.up("sm")]: {
      height: "16px",
    },
  },
  poolPercentageInfo: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  poolPercentageText: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  poolSubInfoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  poolSubInfoRow: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "16px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  poolSubInfoCard: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px",
    width: "100%",
  },
  poolSubInfoHeading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    textAlign: "center",
    marginBottom: "4px",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
      marginBottom: "8px",
    },
  },
  poolSubInfoAddress: {
    display: "flex",
    alignItems: "center",
    color: theme?.colors?.presale?.addressValueText,
    minWidth: "200px",
    maxWidth: "200px",
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textAlign: "center",
    [theme.breakpoints.up("ms")]: {
      textAlign: "left",
      maxWidth: "600px",
    },
  },
  poolSubInfoValue: {
    color: theme?.colors?.presale?.addressValueText,
    minWidth: "200px",
    maxWidth: "400px",
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    textAlign: "center",
    justifyContent: "center",
    [theme.breakpoints.up("ms")]: {
      textAlign: "left",
      justifyContent: "flex-start",
      maxWidth: "600px",
    },
    display: "flex",
    alignItems: "center",
  },
  poolLinkIcon: {
    marginLeft: "5px",
    width: "16px",
    height: "16px",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
  },
});

const MainInfoCard = ({ classes, poolInfo }) => {
  const theme = useTheme();
  const poolSubInfo = (
    title,
    info,
    type = "string",
    decimals = 0,
    unit = 0,
    link
  ) => {
    let poolInfoItem = poolInfo[info] || 0;
    if (type === "number") {
      const tokenAmount = parseFloat(parseFloat(poolInfoItem).toFixed(0));
      const usdAmount = tokenAmount * unit;
      poolInfoItem =
        parseFloat(tokenAmount.toFixed(decimals)).toLocaleString() +
        " ($" +
        parseFloat(usdAmount.toFixed(2)).toLocaleString() +
        ")";
    }
    if (type === "start") {
      poolInfoItem =
        parseFloat(parseFloat(poolInfoItem).toFixed(0)).toLocaleString() +
        " START";
    }
    if (type === "wei") {
      poolInfoItem =
        parseFloat(
          toFixed(bigInt(poolInfoItem), 18, decimals)
        ).toLocaleString() + " BNB";
    }
    if (type === "days") {
      if (parseInt(poolInfoItem) > 1) {
        poolInfoItem = poolInfoItem + " days";
      } else {
        poolInfoItem = poolInfoItem + " day";
      }
    }
    if (type === "percent") {
      poolInfoItem = poolInfoItem + "%";
    }
    if (type === "time") {
      poolInfoItem = moment
        .unix(poolInfoItem)
        .format("MMMM DD, YYYY HH: mm A z");
    }
    if (type === "presaleType") {
      const pTypes = ["FCFS", "Guaranteed Allocation"];
      poolInfoItem = pTypes[poolInfoItem];
    }
    if (type === "mix") {
      const tokenAmount = parseFloat(
        toFixed(bigInt(poolInfoItem), 18, decimals)
      );
      const usdAmount = tokenAmount * unit;
      poolInfoItem =
        parseFloat(tokenAmount.toFixed(decimals)).toLocaleString() +
        " ($" +
        parseFloat(usdAmount.toFixed(2)).toLocaleString() +
        ")";
    }

    if (type === "usd") {
      poolInfoItem = "$" + parseFloat(poolInfoItem).toFixed(decimals);
    }
    return (
      <div className={classes.poolSubInfoCard}>
        <Typography variant="h5" className={classes.poolSubInfoHeading}>
          {title}
        </Typography>
        {link && (
          <a
            className={classes.link}
            href={link}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Typography variant="h5" className={classes.poolSubInfoValue}>
              {poolInfoItem}
              <OpenInNewIcon className={classes.poolLinkIcon} />
            </Typography>
          </a>
        )}
        {!link && (
          <Typography variant="h5" className={classes.poolSubInfoValue}>
            {poolInfoItem}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <div className={classes.poolInfoCard}>
      <div className={classes.poolMainInfo}>
        <Typography variant="h6" className={classes.cardTitle}>
          START Tokenomics&nbsp;
          <a
            className={classes.link}
            href={theme?.blockExplorer + "address/" + poolInfo.tokenAddress}
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className={classes.poolSubInfoAddress}>
              ({poolInfo.tokenAddress})
              <OpenInNewIcon className={classes.poolLinkIcon} />
            </span>
          </a>
        </Typography>
      </div>
      <div className={classes.poolSubInfoContainer}>
        <div className={classes.poolSubInfoRow}>
          {poolSubInfo("Total Supply", "totalSupply")}
          {poolSubInfo(
            "Total Staked",
            "totalStaked",
            "mix",
            0,
            poolInfo?.startPrice || 0
          )}
          {theme?.type === "bsc" &&
            poolSubInfo(
              "Total Burned",
              "totalBurned",
              "mix",
              2,
              poolInfo?.startPrice || 0,
              theme?.blockExplorer +
                "token/0x31d0a7ada4d4c131eb612db48861211f63e57610?a=0x000000000000000000000000000000000000dead"
            )}
          {theme?.type === "mtc" &&
            poolSubInfo(
              "Total Burned",
              "totalBurned",
              "mix",
              2,
              poolInfo?.startPrice || 0,
              theme?.blockExplorer +
                "token/0x6ccf12b480a99c54b23647c995f4525d544a7e72?a=0x000000000000000000000000000000000000dead"
            )}
          {poolSubInfo(
            "Circulating Supply",
            "circulatingSupply",
            "number",
            0,
            poolInfo?.startPrice || 0
          )}
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(MainInfoCard)));
