import STARTokenAbi from "./abis/startToken";
import ERC20Abi from "./abis/erc20";
import factoryAbi from "./abis/factory";
import starterInfoAbi from "./abis/starterInfo";
import starterStakingAbi from "./abis/starterStaking";
import starterPresaleAbi from "./abis/starterPresale";

import farmingAbi from "./abis/farming";
import farmingPairAbi from "./abis/farmingPair";

import swapRouterAbi from "./abis/swapRouter";

const config = {
  STARTokenAddress: "0x1d7Ca62F6Af49ec66f6680b8606E634E55Ef22C1",
  STARTokenAbi,
  ERC20Abi,
  factoryAbi,
  factoryAddress: "0xFeCD8315BdcbA3A788093751fCb6E7AcC62F05df",
  factoryV3Abi: factoryAbi,
  factoryV3Address: "0xFeCD8315BdcbA3A788093751fCb6E7AcC62F05df",

  starterInfoAddress: "0x300f135ceaced1f2c91ddc5c9df742f4b6a9611f",
  starterInfoAbi,
  starterInfoV3Address: "0x300f135ceaced1f2c91ddc5c9df742f4b6a9611f",
  starterInfoV3Abi: starterInfoAbi,

  starterStaking: "0x8386954c44e9d72aa7a5433a7af64b6f8b740f59",
  starterStakingAbi,
  starterStakingV2: "0x8386954c44e9d72aa7a5433a7af64b6f8b740f59",
  starterStakingV2Abi: starterStakingAbi,
  starterStakingV3: "0x8386954c44e9d72aa7a5433a7af64b6f8b740f59",
  starterStakingV3Abi: starterStakingAbi,
  starterPresaleAbi,

  starterVestingAddress: "0x0e7b582003de0E541548cF02a1F00725Df6E6E6f",

  farmingAddress: "0x9dFda6ca37734D69B0EBC5B65f98501Ea7296893",
  farmingAbi,
  farmingPairAddress: "0xAeAf0c8f9B6b281A1f552F3bDA1958091C762dE3",
  farmingPairAbi,
  wbnbAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",

  stakingToken: "0xAeAf0c8f9B6b281A1f552F3bDA1958091C762dE3",
  stakingTokenName: "ETH+START LP",
  stakingContracts: ["0x8386954c44e9d72aa7a5433a7af64b6f8b740f59"],

  swapSymbol: "ETH",
  swapToken: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  swapTokenAbi: ERC20Abi,
  swapRouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
  swapRouterAbi,
};

export default config;
