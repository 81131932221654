import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button, Tooltip } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import { ReactComponent as UnlockSvg } from "../../../assets/unlocked.svg";
import { toFixed } from "../../../constants/constants";
import bigInt from "big-integer";
import moment from "moment";

const styles = (theme) => ({
  poolInvestCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("md")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("md")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  yourInformationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },
  yourTokenInfo: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },
  lockLiqContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  yourTokenInfoItem: {
    display: "flex",
    flexDirection: "column",
    marginRight: "0px",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "0px",
      marginRight: "40px",
    },
  },
  infoHeading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "4px",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
      marginBottom: "8px",
    },
  },
  infoValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "25px",
    },
  },
  actionButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.presale?.buttonBg,
    border: "solid 2px" + theme?.colors?.presale?.buttonBg,
    color: theme?.colors?.presale?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "170px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.presale?.buttonBgHover,
    },
    "&:disabled": {
      border: "solid 2px" + theme?.colors?.presale?.buttonBgDisabled,
      backgroundColor: theme?.colors?.presale?.buttonBgDisabled,
      cursor: "not-allowed",
    },
    fontFamily: "SFPro",
    letterSpacing: "0px",
    marginTop: "15px",
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },
  buttonLabel: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  tokenTooltip: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
});

const InvestCard = ({
  classes,
  yourInfo,
  onClaim,
  onRefund,
  onBuy,
  onLockLiq,
  account,
}) => {
  const listingTime = yourInfo.cakeLiquidityAddingTime;
  const currentTimestamp = Date.now() / 1000;
  const isClaimAllowed =
    listingTime <= currentTimestamp && yourInfo.claimAllowed;
  const theme = useTheme();

  const tokenInvestPart = (
    heading,
    value,
    buttonText,
    clickHandler,
    disabled = false,
    tooltip = false,
    tooltipText = null
  ) => {
    return (
      <div className={classes.yourTokenInfoItem}>
        <Typography variant="h5" className={classes.infoHeading}>
          {heading}
        </Typography>
        <Typography variant="h2" className={classes.infoValue}>
          {value}
        </Typography>
        {tooltip && disabled && (
          <Tooltip title={tooltipText}>
            <span>
              <Button
                classes={{
                  root: classes.actionButton,
                  label: classes.buttonLabel,
                }}
                onClick={() => {
                  if (clickHandler) {
                    clickHandler();
                  }
                }}
                disabled={disabled}
              >
                {buttonText}
              </Button>
            </span>
          </Tooltip>
        )}
        {tooltip && !disabled && (
          <Tooltip title={tooltipText}>
            <Button
              classes={{
                root: classes.actionButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                if (clickHandler) {
                  clickHandler();
                }
              }}
              disabled={disabled}
            >
              {buttonText}
            </Button>
          </Tooltip>
        )}
        {!tooltip && (
          <Button
            classes={{
              root: classes.actionButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              if (clickHandler) {
                clickHandler();
              }
            }}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={classes.poolInvestCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        Your Investment
      </Typography>
      <div className={classes.yourInformationContainer}>
        {account && account.address && (
          <>
            <div className={classes.yourTokenInfo}>
              {tokenInvestPart(
                "Your Tokens",
                parseFloat(yourInfo.tokenAmount || 0).toFixed(4) + " ASVA",
                "Claim Tokens",
                onClaim,
                !isClaimAllowed
              )}
              {tokenInvestPart(
                "Your BNB Investment",
                (toFixed(bigInt(yourInfo.accountInvestment), 18, 4) || 0) +
                  " BNB",
                "Get Refund",
                onRefund,
                true,
                true,
                <span className={classes.tokenTooltip}>
                  Refund is not allowed in this presale.
                </span>
              )}
              {tokenInvestPart(
                "Buy Tokens",
                "1 Token = " +
                  (toFixed(bigInt(yourInfo.tokenPriceInWei), 18, 6) || 0) +
                  " BNB",
                "Buy",
                onBuy
              )}
            </div>
            <div className={classes.lockLiqContainer}>
              <UnlockSvg />
              <Tooltip
                title={
                  <span className={classes.tokenTooltip}>
                    Liquidity Workflow is not needed in this presale.
                  </span>
                }
              >
                <span>
                  <Button
                    classes={{
                      root: classes.actionButton,
                      label: classes.buttonLabel,
                    }}
                    onClick={() => {
                      if (onLockLiq) {
                        onLockLiq();
                      }
                    }}
                    disabled={true}
                  >
                    Lock Liq and List
                  </Button>
                </span>
              </Tooltip>
            </div>
          </>
        )}
        {(!account || !account.address) && (
          <div className={classes.warningContainer}>
            <Typography variant="h4" className={classes.warningText}>
              Please connect your wallet and set network to{" "}
              {theme?.chain || "Binance Smart Chain"}.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(InvestCard)));
