import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import bigInt from "big-integer";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Typography,
  Button,
  Fade,
  Snackbar,
  useTheme,
  CircularProgress,
} from "@material-ui/core";

import { withNamespaces } from "react-i18next";

import Web3 from "web3";
import { formatBytes32String } from "ethers/utils";

import Store from "../../stores/store";

import {
  APPLY,
  APPLY_RETURNED,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  GET_PRESALE_INFO,
  GET_PRESALE_INFO_RETURNED,
  INVEST,
  INVEST_RETURNED,
  ERROR,
  toFixed,
  toWeiString,
  tokens,
} from "../../constants/constants";

import PresaleDetailsCard from "./components/presaleDetailsCard";
import WhitelistCard from "./components/whitelistCard";
import PancakeListingInfoCard from "./components/pancakeListingInfoCard";
import ProductionInfoCard from "./components/productionInfoCard";

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: "1140px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme?.colors?.presale?.loadingBackground,
    zIndex: 4,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: theme?.colors?.presale?.loadingIcon,
    position: "absolute",
    top: "100px",
    left: "50%",
  },
  pageHeader: {
    width: "100%",
    textAlign: "left",
    fontSize: "30px",
    lineHeight: "36px",
    letterSpacing: "0px",
    color: theme?.colors?.voting?.heading,
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "40px",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ApplyPage = ({ classes, location }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [account, setAccount] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [presaleDetails, setPresaleDetails] = useState({});
  const [whitelist, setWhitelist] = useState([]);
  const [pancakeListingInfo, setPancakeListingInfo] = useState([]);
  const [productInfo, setProductInfo] = useState({});
  const [warningMessage, setWarningMessage] = useState("");
  const theme = useTheme();

  useEffect(() => {
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    emitter.on(APPLY_RETURNED, applyReturned);
    emitter.on(ERROR, errorReturned);

    const account = store.getStore("account");
    if (account) {
      setAccount(account);
    }
  }, []);

  const connectionConnected = () => {
    setAccount(store.getStore("account"));
  };

  const connectionDisconnected = () => {
    setAccount(null);
  };

  const errorReturned = (error) => {
    setErrorMessage("Applying error!");
    setLoading(false);
  };

  const applyReturned = (bscId) => {
    setWarningMessage("Presale created successfully.");
    history.push(`/pool/${bscId}`);
  };

  const onApply = (_productInfo) => {
    try {
      const presaleStringInfoInput = {
        saleTitle: formatBytes32String(`${_productInfo.saleTitle}`),
        linkTelegram: formatBytes32String(`${_productInfo.linkTelegram}`),
        linkGithub: formatBytes32String(`${_productInfo.linkGithub}`),
        linkTwitter: formatBytes32String(`${_productInfo.linkTwitter}`),
        linkWebsite: formatBytes32String(`${_productInfo.linkWebsite}`),
        linkLogo: _productInfo.linkLogo,
        kycInformation: _productInfo.kycInformation,
        description: _productInfo.description,
        whitepaper: _productInfo.whitepaper,
        categoryId: _productInfo.categoryId,
      };

      const fundingToken = tokens[presaleDetails.fundingTokenAddress];

      const presaleDetailsInput = {
        tokenAddress: presaleDetails.tokenAddress || "",
        tokenDecimals: presaleDetails.tokenDecimals,
        fundingTokenAddress: presaleDetails.fundingTokenAddress,
        unsoldTokensDumpAddress: presaleDetails.unsoldTokensDumpAddress || "",
        tokenPriceInWei:
          toWeiString(
            presaleDetails.tokenPriceInWei,
            fundingToken?.decimals || 18
          ) || 0,
        hardCapInWei:
          toWeiString(
            presaleDetails.hardCapInWei,
            fundingToken?.decimals || 18
          ) || 0,
        softCapInWei:
          toWeiString(
            presaleDetails.softCapInWei,
            fundingToken?.decimals || 18
          ) || 0,
        maxInvestInWei:
          toWeiString(
            presaleDetails.maxInvestInWei,
            fundingToken?.decimals || 18
          ) || 0,
        minInvestInWei:
          toWeiString(
            presaleDetails.minInvestInWei,
            fundingToken?.decimals || 18
          ) || 0,
        openTime: presaleDetails.openTime || Math.floor(Date.now() / 1000),
        closeTime: presaleDetails.closeTime || Math.floor(Date.now() / 1000),
        presaleType: presaleDetails.presaleType,
        guaranteedHours: parseInt(presaleDetails.guaranteedHours),
        whitelistedAddresses: whitelist,
        releasePerCycle: parseInt(presaleDetails.releasePerCycle),
        releaseCycle: parseInt(presaleDetails.releaseCycle),
      };

      const pancakeSwapInfoInput =
        presaleDetailsInput.presaleType == 0
          ? {
              listingPriceInWei:
                toWeiString("0.1", fundingToken?.decimals || 18) || 0,
              liquidityAddingTime: presaleDetailsInput.closeTime + 1,
              lpTokensLockDurationInDays: 1,
              liquidityPercentageAllocation: 50,
              swapIndex: 0,
            }
          : {
              listingPriceInWei:
                toWeiString(
                  pancakeListingInfo.listingPriceInWei,
                  fundingToken?.decimals || 18
                ) || 0,
              liquidityAddingTime: pancakeListingInfo.liquidityAddingTime,
              lpTokensLockDurationInDays:
                pancakeListingInfo.lpTokensLockDurationInDays,
              liquidityPercentageAllocation:
                pancakeListingInfo.liquidityPercentageAllocation,
              // swapIndex: 0,
            };

      console.log(
        presaleDetailsInput,
        pancakeSwapInfoInput,
        presaleStringInfoInput
      );
      setLoading(true);

      dispatcher.dispatch({
        type: APPLY,
        content: {
          bscPresaleInfo: presaleDetailsInput,
          presalePancakeSwapInfo: pancakeSwapInfoInput,
          presaleStringInfo: presaleStringInfoInput,
        },
      });
    } catch (error) {
      console.log("error", error);
      setErrorMessage("Invalid Parameters!");
    }
  };

  return (
    <div className={classes.root}>
      {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={3000}
          onClick={() => {
            setErrorMessage(false);
          }}
          onClose={() => {
            setErrorMessage(false);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      )}
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          {account && account.address && (
            <>
              {loading && (
                <div className={classes.loadingContainer}>
                  <CircularProgress className={classes.loadingIcon} size={60} />
                </div>
              )}
              <Typography variant="h3" className={classes.pageHeader}>
                Apply for Presale
              </Typography>
              {currentStep === 0 && (
                <PresaleDetailsCard
                  value={presaleDetails}
                  onNext={(newValue) => {
                    setPresaleDetails(newValue);
                    setCurrentStep(1);
                  }}
                />
              )}
              {currentStep === 1 && (
                <WhitelistCard
                  value={whitelist}
                  onNext={(newValue) => {
                    setWhitelist(newValue);
                    if (presaleDetails?.presaleType == 0) {
                      setCurrentStep(3);
                    } else {
                      setCurrentStep(2);
                    }
                  }}
                  onBack={() => {
                    setCurrentStep(0);
                  }}
                />
              )}
              {currentStep === 2 && (
                <PancakeListingInfoCard
                  value={pancakeListingInfo}
                  onNext={(newValue) => {
                    setPancakeListingInfo(newValue);
                    setCurrentStep(3);
                  }}
                  onBack={() => {
                    setCurrentStep(1);
                  }}
                />
              )}
              {currentStep === 3 && (
                <ProductionInfoCard
                  value={productInfo}
                  onNext={(newValue) => {
                    setProductInfo(newValue);
                    onApply(newValue);
                  }}
                  onBack={() => {
                    setCurrentStep(2);
                  }}
                />
              )}
            </>
          )}
          {(!account || !account.address) && (
            <div className={classes.warningContainer}>
              <Typography variant="h4" className={classes.warningText}>
                Please connect your wallet and set network to{" "}
                {theme?.chain || "Binance Smart Chain"}.
              </Typography>
            </div>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(ApplyPage)));
