import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Button, Snackbar, useTheme } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as TelegramIcon } from "../../assets/telegram.svg";
import { ReactComponent as DiscordIcon } from "../../assets/discord.svg";
import { ReactComponent as MediumIcon } from "../../assets/medium.svg";
import { ReactComponent as EtherscanIcon } from "../../assets/etherscan-logo.svg";
import { ReactComponent as GitbookIcon } from "../../assets/gitbook.svg";
import HowToGuideImage from "../../assets/how-to-guide.png";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";

import HeaderLink from "./link";
import RedirectModal from "./modal";
import StakeModal from "./modal/stakeModal";
import ThemeModal from "./modal/themeModal";

import { injected } from "../../stores/connectors";
import GlobalStore from "../../stores";
import UnlockModal from "../unlock/unlockModal";

import bigInt from "big-integer";
import ThemeSelect from "./components/themeSelect";

import {
  ERROR,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  FORMATE_ADDRESS,
  CONNECT_WALLET,
  GET_BALANCES_RETURNED,
  STAKE,
  STAKE_V1,
  STAKE_RETURNED,
  UNSTAKE,
  UNSTAKE_V1,
  UNSTAKE_RETURNED,
  MOVE_STAKE,
  MOVE_STAKE_RETURNED,
  CLAIM_HODLER,
  CLAIM_HODLER_RETURNED,
  toFixed,
  COLLECT_FUND_RETURNED,
  CANCEL_PRESALE_RETURNED,
  SEND_UNSOLD_TOKENS_RETURNED,
  WRITE_RETURNED,
  SWAP_RETURNED,
} from "../../constants";

const emitter = GlobalStore.emitter;
const Store = GlobalStore.store;
const dispatcher = GlobalStore.dispatcher;

const styles = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    background: theme?.colors?.header?.background,
    borderBottom: "solid 2px " + theme?.colors?.header?.border,
    [theme.breakpoints.up("md")]: {
      maxWidth: "100vw",
      maxHeight: "100vh",
      flexDirection: "row",
      overflow: "hidden",
    },
  },
  headerContainer: {
    display: "flex",
    width: "100%",
    height: "80px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      width: "360px",
      height: "100vh",
      padding: "50px 5px",
    },
  },
  bodyContainer: {
    width: "100%",
    height: "100%",
    minHeight: "calc(100vh - 80px)",
    background: theme?.colors?.backgroundColor,
    backgroundImage: theme?.backgroundImage,
    backgroundSize: "cover",
    [theme.breakpoints.up("md")]: {
      height: "100vh",
      overflow: "auto",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  walletContainer: {
    height: "50px",
    width: "100%",
    marginTop: "30px",
    padding: "0px 30px",
    maxWidth: "1140px",
    display: "none",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "0px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      marginBottom: "40px",
    },
  },
  linkContainer: {
    display: "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& > *": {
      marginBottom: "10px",
      marginTop: "10px",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  logoButton: {},
  logoText: {
    color: theme?.colors?.header?.text,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  logoBoldText: {
    color: theme?.colors?.header?.boldBrownText,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  walletButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  applyButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "240px",
    padding: "8px 16px",
    marginBottom: "16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  certifiedButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonBackground,
    textTransform: "capitalize",
    height: "40px",
    width: "240px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  openMenuButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    position: "absolute",
    left: "1px",
    top: "16px",
  },
  menuButton: {
    padding: "6px",
  },
  menuIcon: {
    width: "24px",
    height: "24px",
    color: theme?.colors?.header?.menuButton,
  },
  balanceInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors?.header?.balanceText,
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
    cursor: "pointer",
  },
  footerLinks: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "16px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
  },
  footerLinksText: {
    fontSize: "15px",
    lineHeight: "20px",
    color: theme?.colors?.footer?.text,
    fontWeight: "bold",
    letterSpacing: "0px",
    marginBottom: "15px",
  },
  footerLinksContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footerRight: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: {
    color: theme?.colors?.footer?.icon,
  },
  link: {
    padding: "0 5px 0 5px",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 10px 0 10px",
    },
  },
  certifiedBadge: {
    width: "30px",
    height: "30px",
    position: "absolute",
    left: "3px",
    top: "3px",
  },
  starterLtdText: {
    color: theme?.colors?.footer?.textSecondary,
    letterSpacing: "0.1rem",
    fontSize: "16px",
    lineHeight: "16px",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Header({ classes, children }) {
  const history = useHistory();
  const navUrl = history?.location?.pathname?.toLowerCase() || "";
  const nav = (screen) => {
    history.push("/" + screen);
  };
  const [modalOpen, setModalOpen] = React.useState(false);
  const [accountInfo, setAccountInfo] = React.useState("");
  const [accountModalOpen, setAccountModalOpen] = React.useState(false);
  const [stakeModalOpen, setStakeModalOpen] = React.useState(false);
  const [themeModalOpen, setThemeModalOpen] = React.useState(false);

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [infoMessage, setInfoMessage] = React.useState(null);
  const [walletBnbBalance, setWalletBnbBalance] = React.useState(0);
  const [walletStartBalance, setWalletStartBalance] = React.useState(0);
  const [walletStakeTokenBalance, setWalletStakeTokenBalance] =
    React.useState(0);
  const [startPrice, setStartPrice] = React.useState(0);
  const [bnbPrice, setBnbPrice] = React.useState(0);
  const [stakedBalance, setStakedBalance] = React.useState(0);
  const [totalStakedBalance, setTotalStakedBalance] = React.useState(0);
  const [stakingToken, setStakingToken] = React.useState("-");
  const [v1StakedBalance, setV1StakedBalance] = React.useState(0);
  const [rewardBalance, setRewardBalance] = React.useState(0);

  const [stakeAvailableTime, setStakeAvailableTime] = React.useState(0);
  const [unstakeAvailableTime, setUnstakeAvailableTime] = React.useState(0);
  const [firstStakedTimestamp, setFirstStakedTimestamp] = React.useState(0);
  const [isLongStaker, setLongStaker] = React.useState(false);

  const [burnFee, setBurnFee] = React.useState(0);

  const theme = useTheme();
  const web3Context = useWeb3React();
  const { library, account, activate, active, chainId, deactivate } =
    web3Context;

  React.useEffect(() => {
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    emitter.on(CONNECT_WALLET, onConnectWallet);
    emitter.on(ERROR, errorReturned);
    emitter.on(GET_BALANCES_RETURNED, getBalancesReturned);

    emitter.on(STAKE_RETURNED, stakeReturned);
    emitter.on(UNSTAKE_RETURNED, unstakeReturned);

    emitter.on(COLLECT_FUND_RETURNED, collectFundReturned);
    emitter.on(CANCEL_PRESALE_RETURNED, cancelPresaleReturned);
    emitter.on(SEND_UNSOLD_TOKENS_RETURNED, sendUnsoldTokensReturned);
    emitter.on(WRITE_RETURNED, writeReturned);

    emitter.on(SWAP_RETURNED, swapReturned);

    injected.isAuthorized().then((isAuthorized) => {
      if (isAuthorized) {
        activate(injected);
        injected.on("Web3ReactDeactivate", () => {
          Store.setStore({
            account: {},
            web3context: null,
          });
          emitter.emit(CONNECTION_DISCONNECTED);
        });
      }
    });

    return () => {
      emitter.removeListener(ERROR, errorReturned);
      emitter.removeListener(CONNECTION_CONNECTED, connectionConnected);
      emitter.removeListener(CONNECTION_DISCONNECTED, connectionDisconnected);
      emitter.removeListener(CONNECT_WALLET, onConnectWallet);

      emitter.removeListener(COLLECT_FUND_RETURNED, collectFundReturned);
      emitter.removeListener(CANCEL_PRESALE_RETURNED, cancelPresaleReturned);
      emitter.removeListener(
        SEND_UNSOLD_TOKENS_RETURNED,
        sendUnsoldTokensReturned
      );
      emitter.removeListener(WRITE_RETURNED, writeReturned);
    };
  }, []);

  React.useEffect(() => {
    if (active) {
      if (account && library && chainId === theme.chainId) {
        Store.setStore({
          account: { address: account },
          web3context: web3Context,
        });
        emitter.emit(CONNECTION_CONNECTED);
      }
    } else {
      Store.setStore({ account: {}, web3context: null });
      emitter.emit(CONNECTION_DISCONNECTED);
    }
  }, [active]);

  const requestChangeNetwork = async () => {
    const params = {
      chainId: "0x" + theme.chainId.toString(16),
      chainName: theme.chain,
      nativeCurrency: {
        name: theme.chainSymbol,
        symbol: theme.symbol,
        decimals: 18,
      },
      rpcUrls: [theme.rpcUrl],
      blockExplorerUrls: [theme.blockExplorer],
    };
    try {
      if (window?.ethereum) {
        if (theme.chainId === 1) {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: "0x1",
              },
            ],
          });
        } else {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [params],
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  React.useEffect(() => {
    if (chainId !== theme.chainId && chainId !== undefined) {
      setErrorMessage(`Connect your wallet and set network to ${theme.chain}!`);
      deactivate();
      Store.setStore({ account: {}, web3context: null });
      emitter.emit(CONNECTION_DISCONNECTED);
    }
  }, [theme]);

  React.useEffect(() => {
    if (chainId !== theme.chainId && chainId !== undefined) {
      setErrorMessage(`Connect your wallet and set network to ${theme.chain}!`);
      deactivate();
      Store.setStore({ account: {}, web3context: null });
      emitter.emit(CONNECTION_DISCONNECTED);
    }
  }, [chainId]);

  const collectFundReturned = (receipt) => {
    setInfoMessage(
      "Collect Fund Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const cancelPresaleReturned = (receipt) => {
    setInfoMessage(
      "CANCEL PRESALE Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const sendUnsoldTokensReturned = (receipt) => {
    setInfoMessage(
      "SEND UNSOLD TOKENS Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const closeNavModal = () => {
    setModalOpen(false);
  };

  const connectionConnected = () => {
    setAccountInfo(Store.getStore("account"));
  };

  const connectionDisconnected = () => {
    setAccountInfo(Store.getStore("account"));
  };

  const errorReturned = (_error) => {
    const message = `${_error}`;
    if (!message.includes("Invalid JSON")) {
      setErrorMessage(
        (message && message.length > 0 && message.slice(0, 60) + "...") ||
          "Error"
      );
    }
  };

  const onConnectWallet = () => {
    if (chainId === theme.chainId) {
      setAccountModalOpen(true);
    } else {
      setErrorMessage(`Connect your wallet and set network to ${theme.chain}!`);
    }
  };

  const getBalancesReturned = () => {
    const bnbBalance = Store.getStore("bnbBalance");
    const _bnbPrice = Store.getStore("bnbPrice");
    const startBalance = Store.getStore("startBalance");
    const stakingTokenBalance = Store.getStore("stakingTokenBalance");
    const stakedBalance = Store.getStore("stakedBalance");
    const totalStakedBalance = Store.getStore("totalStakedBalance");
    const _stakingToken = Store.getStore("StakingTokenName");
    const v1StakedBalance = Store.getStore("v1StakedBalance");
    const lastStakedTime = Store.getStore("lastStakedTime");
    const lastUnstakedTime = Store.getStore("lastUnstakedTime");
    const _firstStakedTimestamp = Store.getStore("firstStakedTimestamp");
    const _isLongStaker = Store.getStore("isLongStaker");

    const _burnFee = Store.getStore("burnFee");
    const _startPrice = Store.getStore("startPrice");

    const minStakeTime = Store.getStore("minStakeTime");
    const minUnstakeTime = Store.getStore("minUnstakeTime");

    const currentTimestamp = Date.now() / 1000;
    const lockedBalance = Store.getStore("lockedBalance");

    const _stakeAvailableTime = lastStakedTime + minStakeTime;
    const _unstakeAvailableTime = lastUnstakedTime + minUnstakeTime;

    const totalRewardBalanceInWei =
      _stakeAvailableTime > currentTimestamp
        ? lockedBalance
        : bigInt(lockedBalance).add(stakedBalance);
    const factoryBalance = Store.getStore("factoryBalance");
    let rewardBalance = parseFloat(toFixed(totalRewardBalanceInWei, 18, 3));
    if (rewardBalance < 1250 || rewardBalance > 20000) {
      rewardBalance = 0;
    }
    let userRewardBalance =
      (rewardBalance / 1000000) * parseFloat(toFixed(factoryBalance, 18, 3));

    setWalletBnbBalance(toFixed(bnbBalance, 18, 3));
    setWalletStartBalance(toFixed(startBalance, 18, 3));
    setWalletStakeTokenBalance(toFixed(stakingTokenBalance, 18, 3));
    setStakedBalance(toFixed(stakedBalance, 18, 3));
    setTotalStakedBalance(toFixed(totalStakedBalance, 18, 3));
    setStakingToken(_stakingToken);
    setStartPrice(_startPrice);
    setBnbPrice(_bnbPrice);
    setV1StakedBalance(toFixed(v1StakedBalance, 18, 3));
    setRewardBalance(userRewardBalance);
    setStakeAvailableTime(_stakeAvailableTime);
    setUnstakeAvailableTime(_unstakeAvailableTime);
    setFirstStakedTimestamp(_firstStakedTimestamp);
    setLongStaker(_isLongStaker);
    setBurnFee(_burnFee);
  };

  const onStake = (amount) => {
    if (
      parseFloat(amount) > 0 &&
      parseFloat(amount) <= parseFloat(walletStakeTokenBalance)
    ) {
      dispatcher.dispatch({
        type: STAKE,
        content: {
          amount: Web3.utils.toWei(amount),
        },
      });
    } else {
      setErrorMessage("Invalid parameter");
    }
  };

  const onStakeV1 = (amount) => {
    if (
      parseFloat(amount) > 0 &&
      parseFloat(amount) <= parseFloat(walletStakeTokenBalance)
    ) {
      dispatcher.dispatch({
        type: STAKE_V1,
        content: {
          amount: Web3.utils.toWei(amount),
        },
      });
    } else {
      setErrorMessage("Invalid parameter");
    }
  };

  const onUnstake = (amount) => {
    if (
      parseFloat(amount) > 0 &&
      parseFloat(amount) <= parseFloat(stakedBalance)
    ) {
      dispatcher.dispatch({
        type: UNSTAKE,
        content: {
          amount: Web3.utils.toWei(amount),
        },
      });
    } else {
      setErrorMessage("Invalid parameter");
    }
  };

  const onUnstakeV1 = (amount) => {
    if (
      parseFloat(amount) > 0 &&
      parseFloat(amount) <= parseFloat(v1StakedBalance)
    ) {
      dispatcher.dispatch({
        type: UNSTAKE_V1,
        content: {
          amount: Web3.utils.toWei(amount),
        },
      });
    } else {
      setErrorMessage("Invalid parameter");
    }
  };

  const onMoveStake = () => {
    dispatcher.dispatch({
      type: MOVE_STAKE,
      content: {},
    });
  };

  const onClaimReward = () => {
    if (rewardBalance > 0) {
      dispatcher.dispatch({
        type: CLAIM_HODLER,
        content: {},
      });
    } else {
      setErrorMessage("No Reward");
    }
  };

  const stakeReturned = (receipt) => {
    setInfoMessage(
      "Stake Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const unstakeReturned = (receipt) => {
    setInfoMessage(
      "Unstake Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const writeReturned = (receipt) => {
    setInfoMessage(
      "Write Success! TransactionHash: " + receipt.transactionHash
    );
  };

  const swapReturned = (receipt) => {
    setInfoMessage(
      "Successfully swapped! TransactionHash: " + receipt.transactionHash
    );
  };

  return (
    <div className={classes.root}>
      {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={4000}
          onClick={() => {
            setErrorMessage(false);
          }}
          onClose={() => {
            setErrorMessage(false);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      )}
      {infoMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={6000}
          onClose={() => {
            setInfoMessage(false);
          }}
        >
          <Alert severity="info">{infoMessage}</Alert>
        </Snackbar>
      )}
      <div className={classes.headerContainer}>
        <div className={classes.openMenuButtonContainer}>
          <IconButton
            aria-label="menu"
            classes={{ root: classes.menuButton }}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <MenuIcon className={classes.menuIcon} />
          </IconButton>
        </div>
        <div className={classes.logoContainer}>
          <IconButton
            aria-label="logo"
            classes={{ root: classes.logoButton }}
            onClick={() => {
              nav("home");
            }}
          >
            <img
              src={theme.logoImage}
              alt="logo"
              style={{ width: "46px", height: "46px" }}
            />
          </IconButton>
          <ThemeSelect
            handleClick={() => {
              setThemeModalOpen(true);
            }}
          />
        </div>
        <div className={classes.linkContainer}>
          <HeaderLink
            screenType="DESKTOP"
            text="Pools"
            to="/pools"
            selected={navUrl.includes("pools")}
          />

          <HeaderLink
            screenType="DESKTOP"
            text="Voting"
            to="/voting"
            selected={navUrl.includes("voting")}
          />
          <HeaderLink
            screenType="DESKTOP"
            text="Liquidity Mining"
            to="/mining"
            selected={navUrl.includes("mining")}
          />
          {theme?.type === "bsc" && (
            <HeaderLink
              screenType="DESKTOP"
              text="Stats"
              to="/stats"
              selected={navUrl.includes("stats")}
            />
          )}
          {/* <HeaderLink
            screenType="DESKTOP"
            text="Advisors"
            to="/advisors"
            selected={navUrl.includes("advisors")}
          />
          <HeaderLink
            screenType="DESKTOP"
            text="Litepaper"
            to="/litepaper"
            selected={navUrl.includes("faq")}
          /> */}

          <HeaderLink
            screenType="DESKTOP"
            text="Staking"
            onClick={() => {
              setStakeModalOpen(true);
            }}
          />
          <HeaderLink
            screenType="DESKTOP"
            text="Store"
            to={"https://shop.starter.xyz/"}
            externalLink={true}
          />
          <HeaderLink
            screenType="DESKTOP"
            text="How-to Guide"
            to={theme.guide}
            externalLink={true}
          />

          <div className={classes.actionContainer}>
            <Button
              classes={{
                root: classes.applyButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                history.push("/apply");
              }}
            >
              Create Pool
            </Button>
            <Button
              classes={{
                root: classes.certifiedButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                window.open("http://bit.ly/StarterCertifiedApp", "_blank");
              }}
            >
              <img
                src={theme?.certifiedBadge}
                alt="certified"
                className={classes.certifiedBadge}
              />
              Apply For Certified
            </Button>
          </div>
          <div className={classes.footerLinks}>
            <span className={classes.footerLinksText}>Connect with us</span>
            <div className={classes.footerLinksContainer}>
              <a
                href="https://t.me/StarterXyz"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegramIcon
                  className={classes.icon}
                  style={{ width: "24px", height: "24px" }}
                />
              </a>
              <a
                href="https://twitter.com/StarterXyz"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon
                  className={classes.icon}
                  style={{ width: "24px", height: "24px" }}
                />
              </a>
              <a
                href="https://starterxyz.medium.com"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MediumIcon
                  className={classes.icon}
                  style={{ width: "24px", height: "24px" }}
                />
              </a>
              {theme.type === "bsc" && (
                <a
                  href="https://bscscan.com/token/0x31d0a7ada4d4c131eb612db48861211f63e57610"
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <EtherscanIcon
                    className={classes.icon}
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
              )}
              {theme.type === "mtc" && (
                <a
                  href="https://polygonscan.com/token/0x6ccf12b480a99c54b23647c995f4525d544a7e72"
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <EtherscanIcon
                    className={classes.icon}
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
              )}
              <a
                href="https://docs.bscstarter.finance/"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <GitbookIcon
                  className={classes.icon}
                  style={{ width: "24px", height: "24px" }}
                />
              </a>
            </div>
          </div>
          <div className={classes.starterLtdText}>
            © 2022 Starter Ltd. <br />
            All rights reserved.
          </div>
        </div>
      </div>
      <div className={classes.bodyContainer}>
        <div className={classes.walletContainer}>
          <Button
            classes={{
              root: classes.walletButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              if (chainId !== theme.chainId) {
                requestChangeNetwork();
              }
              if (chainId === theme.chainId || chainId === undefined) {
                setAccountModalOpen(true);
              }
            }}
          >
            {accountInfo && accountInfo.address
              ? FORMATE_ADDRESS(accountInfo.address)
              : "Connect Wallet"}
          </Button>
        </div>
        {children}
      </div>
      <RedirectModal
        closeModal={closeNavModal}
        modalOpen={modalOpen}
        account={accountInfo}
        balance={walletBnbBalance}
        onConnect={() => {
          setAccountModalOpen(true);
        }}
        setStakeModalOpen={setStakeModalOpen}
      />
      <UnlockModal
        closeModal={() => {
          setAccountModalOpen(false);
        }}
        modalOpen={accountModalOpen}
      />
      {stakeModalOpen && (
        <StakeModal
          closeModal={() => {
            setStakeModalOpen(false);
          }}
          account={accountInfo}
          modalOpen={stakeModalOpen}
          bnbBalance={parseFloat(walletBnbBalance)}
          earnedBnb={parseFloat(rewardBalance)}
          stakingTokenBalance={parseFloat(walletStakeTokenBalance)}
          startBalance={parseFloat(walletStartBalance)}
          v1StakedBalance={parseFloat(v1StakedBalance)}
          stakedBalance={parseFloat(stakedBalance)}
          totalStakedBalance={parseFloat(totalStakedBalance)}
          stakingToken={stakingToken}
          startPrice={startPrice}
          bnbPrice={bnbPrice}
          stakeAvailableTime={parseInt(stakeAvailableTime)}
          unstakeAvailableTime={parseInt(unstakeAvailableTime)}
          firstStakedTimestamp={parseInt(firstStakedTimestamp)}
          isLongStaker={isLongStaker}
          burnFee={parseInt(burnFee)}
          onStake={onStake}
          onUnstake={onUnstake}
          onMoveStake={onMoveStake}
          onClaim={onClaimReward}
          onStakeV1={onStakeV1}
          onUnstakeV1={onUnstakeV1}
        />
      )}
      <ThemeModal
        modalOpen={themeModalOpen}
        closeModal={() => {
          setThemeModalOpen(false);
        }}
      />
    </div>
  );
}

export default withRouter(withStyles(styles)(Header));
