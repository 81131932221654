import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import bigInt from "big-integer";
import Web3 from "web3";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Fade,
  FormControlLabel,
  IconButton,
  Switch,
  useTheme,
} from "@material-ui/core";

import MuiAlert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { FadeInWhenVisible } from "../animation";

import PoolCard from "../poolCard";
import VotingPoolCard from "../poolCard/votingCard";
import CancelledPool from "../poolCard/cancelledCard";
import CertifiedCard from "../poolCard/certifiedCard";

import BakerToolsSvg from "../../assets/bakery-tools-logo.svg";

import BuyModalBox from "./components/modal";
import { withNamespaces } from "react-i18next";
import {
  GET_POOLS_SUMMARY_RETURNED,
  toFixed,
  isVotePassed,
  defaultBuyUrls,
  GET_BALANCES_RETURNED,
  SWAP,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  BANNER,
  PARTNERS,
} from "../../constants";
import { Certified } from "../../constants";

import Store from "../../stores";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "1140px",
  },
  logo: {
    width: "250px",
    height: "250px",
    objectFit: "contain",
    marginBottom: "1rem",
  },
  mainHeader: {
    fontSize: "30px",
    lineHeight: "35px",
    fontFamily: "SFPro",
    fontStyle: "italic",
    fontWeight: "bold",
    textAlign: "center",
    textShadow: "2px 2px " + theme?.colors?.home?.headingShadow,
    color: theme?.colors?.home?.heading,
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
      lineHeight: "60px",
      maxWidth: "768px",
      marginBottom: "20px",
    },
  },
  header: {
    fontSize: "30px",
    lineHeight: "35px",
    fontFamily: "SFPro",
    fontWeight: "bold",
    textAlign: "center",
    color: theme?.colors?.home?.heading,
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
      lineHeight: "60px",
      maxWidth: "768px",
      marginBottom: "20px",
    },
  },
  headerSecondary: {
    fontSize: "30px",
    lineHeight: "35px",
    fontWeight: "bold",
    textAlign: "left",
    color: theme?.colors?.home?.headingSecondary,
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
      lineHeight: "60px",
    },
  },
  headerSubText: {
    fontSize: "10px",
    fontFamily: "SFPro",
    lineHeight: "15px",
    fontWeight: "lighter",
    textAlign: "center",
    letterSpacing: "0px",
    color: theme?.colors?.home?.text,
    width: "100%",
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "20px",
      maxWidth: "660px",
      marginBottom: "60px",
    },
  },
  headerActionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  mainBody: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginTop: "100px",
    },
  },
  poolsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "75px",
    },
  },
  poolLabelContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  poolsLabel: {
    fontFamily: "SFPro",
    fontSize: "30px",
    fontWeight: "lighter",
    lineHeight: "36px",
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.home?.text,
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "50px",
    },
  },
  switchLabel: {
    fontFamily: "SFPro",
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.home?.text,
  },
  switchIcon: {
    width: "20px",
    height: "20px",
  },
  switchTrack: {
    opacity: "0.8 !important",
    background: `${theme?.colors?.home?.text} !important`,
  },
  mediaLabel: {
    fontFamily: "SFPro",
    fontSize: "25px",
    fontWeight: "lighter",
    lineHeight: "30px",
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.home?.text,
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "20px",
    },
  },
  poolsWrapper: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridColumnGap: "30px",
    gridRowGap: "40px",

    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },

    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "33% 33% 33%",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },
  },
  poolsAluminiWrapper: {
    padding: "20px",
    borderRadius: "40px",
    display: "grid",
    gridTemplateColumns: "auto",
    gridColumnGap: "30px",
    gridRowGap: "40px",

    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },

    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "33% 33% 33%",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },
  },
  poolsPartnersWrapper: {
    padding: "20px",
    borderRadius: "40px",
    display: "grid",
    gridTemplateColumns: "auto",
    gridColumnGap: "30px",
    gridRowGap: "40px",

    [theme.breakpoints.up("md")]: {
      padding: "10px",
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },

    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "33% 33% 33%",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },
  },
  poolItemWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  text: {
    fontSize: "16px",
    fontWeight: "lighter",
    textAlign: "left",
    color: theme?.colors?.home?.text,
    lineHeight: "24px",
    marginBottom: "15px",
    marginTop: "0px",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
  },
  poolButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "200px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    fontFamily: "SFPro",

    marginRight: "5px",
    marginLeft: "5px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "15px",
      marginLeft: "15px",
      marginBottom: "15px",
    },
  },
  joinButton: {
    borderRadius: "20px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "200px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    fontFamily: "SFPro",
    letterSpace: "0px",
    whiteSpace: "nowrap",

    marginRight: "5px",
    marginLeft: "5px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "15px",
      marginLeft: "15px",
      marginBottom: "15px",
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    whiteSpace: "nowrap",
  },
  emptyContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  mediaContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    [theme.breakpoints.up("ms")]: {
      marginBottom: "50px",
    },
  },
  mediasWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  mediaItem: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
    [theme.breakpoints.up("lg")]: {
      flex: 1,
      marginRight: "15px",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  partnerLogo: {
    filter: "brightness(1)",
    height: "80px",
    cursor: "pointer",
    maxWidth: "240px",
    width: "100%",
    objectFit: "contain",
    "&:hover": {
      filter: "brightness(1.2)",
    },
    borderRadius: "40px",
    padding: "10px",
  },
  logoImage: {
    left: "-3px",
    top: "-3px",
    position: "absolute",
    height: "40px",
  },
  closeIcon: {
    color: theme?.colors?.header?.closeButton,
    width: "32px",
    height: "32px",
  },
  closeButton: {
    position: "absolute",
    right: "0px",
    top: "-10px",
    zIndex: "5",
  },
  bannerContainer: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  bannerImage: {
    width: "100%",
    height: "100%",
  },
  totalBannerDiv: {
    position: "relative",
    width: "100vw",
    height: "100%",
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      width: "calc(100vw - 330px)",
    },
  },
});

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Home = ({ classes, location }) => {
  const history = useHistory();
  const theme = useTheme();
  const [snackOpen, setSnackOpen] = useState(true);
  const [accountInfo, setAccountInfo] = React.useState("");
  const [onGoingPools, setOnGoingPools] = useState([]);

  const [votingPools, setVotingPools] = useState([]);
  const [cancelledPools, setCancelledPools] = useState([]);
  const [finishedPools, setFinishedPools] = useState([]);
  const [privatePools, setPrivatePools] = useState([]);

  const [isGoingPoolsShown, showGoingPools] = useState(true);
  const [isVotingPoolsShown, showVotingPools] = useState(true);
  const [isPrivatePoolsShown, showPrivatePools] = useState(true);

  const [isFinishedPoolsShown, showFinishedPools] = useState(false);
  const [isCancelledPoolsShown, showCancelledPools] = useState(false);

  const [certifiedPools, setCertifiedPools] = useState([]);
  const [nonCertifiedPools, setNonCertifiedPools] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [totalRaisedUSD, setTotalRaisedUSD] = useState(0);
  const [isBuyModalShown, showBuyModal] = useState(false);
  const [swapTokenAmount, setSwapTokenAmount] = useState(0);
  const [startTokenAmount, setStartTokenAmount] = useState(0);
  const [swapPrice, setSwapPrice] = useState(0);
  const currentDate = Date.now() / 1000;
  const [isBannerShown, showBanner] = useState(
    currentDate <= (BANNER[theme.type]?.start || 0)
  );

  const estimatedWidth =
    window.innerWidth > 1200
      ? (window.innerWidth - 40) / 2
      : window.innerWidth - 40;
  const videoWidth = estimatedWidth > 550 ? 550 : estimatedWidth;
  const upcomings = {
    bsc: {},
    mtc: {},
    avax: {},
    ftm: {},
    eth: {},
    pulse: {},
  };
  const finishedCertified = { 87: true, 107: true, 97: true, 120: true };

  useEffect(() => {
    fetchPools();
    fetchSwapData();
    emitter.on(GET_POOLS_SUMMARY_RETURNED, getPoolsSummaryReturned);
    emitter.on(GET_BALANCES_RETURNED, getBalancesReturned);
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    return () => {
      emitter.removeListener(
        GET_POOLS_SUMMARY_RETURNED,
        getPoolsSummaryReturned
      );
      emitter.removeListener(GET_BALANCES_RETURNED, getBalancesReturned);
      emitter.removeListener(CONNECTION_CONNECTED, connectionConnected);
      emitter.removeListener(CONNECTION_DISCONNECTED, connectionDisconnected);
    };
  }, []);

  const fetchSwapData = () => {
    const swapData = store.getStore("swapData");
    const startBalance = store.getStore("startBalance");

    setSwapTokenAmount(toFixed(swapData.swapTokenBalance, 18, 3));
    setStartTokenAmount(toFixed(startBalance, 18, 3));
    setSwapPrice(toFixed(swapData.swapPrice, 18, 3));
  };

  const fetchPools = () => {
    const publicPresales = store.getStore("publicPresales");
    const filteredPublicPresales = Object.values(publicPresales).filter(
      (presale) => !presale?.presaleCancelled
    );
    const cancelledPresales = Object.values(publicPresales)
      .filter((presale) => presale?.presaleCancelled)
      .sort(
        (presale1, presale2) =>
          parseInt(presale1?.openTime) - parseInt(presale2?.openTime)
      );

    const presales = store.getStore("presales");
    const filteredPresales = Object.values(presales).filter(
      (item) => item.public
    );
    let featuredPresales = [
      ...filteredPresales,
      ...filteredPublicPresales,
    ].sort(
      (presale1, presale2) =>
        parseInt(presale1?.openTime) - parseInt(presale2?.openTime)
    );
    const currenTimestamp = Date.now() / 1000;

    console.log("featured", featuredPresales, currenTimestamp);
    const _certifiedPools = featuredPresales
      .filter(
        (presale) =>
          (presale.presaleVersion === "V3" && presale.presaleType == "2") ||
          Certified[theme?.type][presale.presaleIndex]
      )
      .sort(
        (presale1, presale2) =>
          parseInt(presale2.openTime) - parseInt(presale1.openTime)
      );

    const _nonCertifiedPools = featuredPresales.filter(
      (presale) => presale.presaleVersion !== "V3" || presale.presaleType != "2"
    );

    featuredPresales = featuredPresales.filter(
      (presale) => presale.presaleVersion !== "V3" || presale.presaleType != "2"
    );

    const votingPresales = featuredPresales.filter((presale) => {
      if (presale.presaleVersion !== "V3") {
        return parseInt(presale.openTime) > currenTimestamp;
      }
      if (presale.presaleType === "1") {
        return parseInt(presale.openTime) > currenTimestamp;
      }
      return false;
    });

    const ongoingPresales = featuredPresales.filter(
      (presale) =>
        parseInt(presale.openTime) <= currenTimestamp &&
        parseInt(presale.closeTime) >= currenTimestamp &&
        parseInt(toFixed(bigInt(presale?.totalCollectedWei), 18, 3)) <
          parseInt(toFixed(bigInt(presale?.hardCapInWei), 18, 3)) &&
        isVotePassed(presale)
    );

    const voteFailedPresales = featuredPresales.filter(
      (presale) =>
        parseInt(presale.openTime) <= currenTimestamp &&
        parseInt(presale.closeTime) >= currenTimestamp &&
        !isVotePassed(presale)
    );

    const privatePresales = featuredPresales.filter(
      (presale) => presale.presaleType === "0"
    );

    const finishedPresales = featuredPresales.filter(
      (presale) => parseInt(presale.closeTime) < currenTimestamp
    );

    console.log("_certified", _certifiedPools);
    setCertifiedPools(_certifiedPools);
    setNonCertifiedPools(_nonCertifiedPools);
    setOnGoingPools(ongoingPresales);
    setFinishedPools(finishedPresales);
    setVotingPools(votingPresales);
    setPrivatePools(privatePresales);
    setCancelledPools([...cancelledPresales, ...voteFailedPresales]);
    const _bnbPrice = store.getStore("bnbPrice");
    setBnbPrice(_bnbPrice);
    const publicPresalesUsdRaised = parseFloat(
      toFixed(
        Object.values(publicPresales).reduce((totalRaised, presale) => {
          return totalRaised.add(bigInt(presale?.totalCollectedWei));
        }, bigInt(0)),
        18,
        4
      )
    );
    const customPresalesUsdRaised = parseFloat(
      toFixed(
        Object.values(presales).reduce((totalRaised, presale) => {
          return totalRaised.add(bigInt(presale?.totalCollectedWei));
        }, bigInt(0)),
        18,
        4
      )
    );
    console.log(publicPresalesUsdRaised, customPresalesUsdRaised, _bnbPrice);
    setTotalRaisedUSD(publicPresalesUsdRaised + customPresalesUsdRaised);
    setLoaded(true);
  };
  const getPoolsSummaryReturned = () => {
    fetchPools();
  };
  const getBalancesReturned = () => {
    fetchSwapData();
  };

  const connectionConnected = () => {
    setAccountInfo(store.getStore("account"));
  };

  const connectionDisconnected = () => {
    setAccountInfo(store.getStore("account"));
  };

  const onSwap = (amount, amountOut, direction) => {
    const minAmountOut = (parseFloat(amountOut) * 995) / 1000;

    dispatcher.dispatch({
      type: SWAP,
      content: {
        amount: Web3.utils.toWei(`${amount}`),
        amountOut: Web3.utils.toWei(`${minAmountOut}`),
        direction,
      },
    });
  };

  return (
    <div className={classes.root}>
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          <Typography className={classes.mainHeader} variant="h1">
            The leading IDO launchpad, incubator, and investor network
          </Typography>
          {theme?.type === "bsc" && totalRaisedUSD > 0 && bnbPrice < 500 && (
            <Typography className={classes.header} variant="h1">
              <span className={classes.headerSecondary}>
                {totalRaisedUSD.toLocaleString()} {theme?.symbol || "BNB"}
              </span>
              &nbsp;Raised on BSC
            </Typography>
          )}
          {theme?.type === "bsc" && totalRaisedUSD > 0 && bnbPrice >= 500 && (
            <Typography className={classes.header} variant="h1">
              <span className={classes.headerSecondary}>
                $
                {parseFloat(
                  (totalRaisedUSD * bnbPrice).toFixed(0)
                ).toLocaleString()}{" "}
                USD
              </span>
              &nbsp;Raised on BSC
            </Typography>
          )}
          <Typography className={classes.headerSubText} variant="h2">
            With over $45M+ raised in seed, private and public rounds for 60+
            projects across multiple blockchains, we help connect inventors with
            investors to recreate a new future
          </Typography>
          <div className={classes.headerActionContainer}>
            <Button
              classes={{
                root: classes.joinButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                showBuyModal(true);
              }}
            >
              <img
                src={theme?.logoImage}
                alt="logo"
                className={classes.logoImage}
              />
              Buy START
            </Button>
            <Button
              classes={{
                root: classes.poolButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                history.push("/pools");
              }}
            >
              View all Pools
            </Button>
          </div>
          {isBannerShown && BANNER[theme.type] && (
            <div className={classes.totalBannerDiv}>
              <FadeInWhenVisible duration={0.83} delay={0.1}>
                <div
                  className={classes.bannerContainer}
                  onClick={() => {
                    window.open(BANNER[theme.type].link, "_blank");
                  }}
                >
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={(ev) => {
                      ev.stopPropagation();
                      showBanner(false);
                    }}
                  >
                    <CloseIcon className={classes.closeIcon} />
                  </IconButton>
                  <img
                    src={BANNER[theme.type].image}
                    alt="banner"
                    className={classes.bannerImage}
                  />
                </div>
              </FadeInWhenVisible>
            </div>
          )}
          <div className={classes.mainBody}>
            {(Object.keys(upcomings[theme.type]).length > 0 ||
              certifiedPools.filter(
                (pool) => !finishedCertified[pool.presaleIndex]
              ).length > 0) && (
              <div className={classes.poolsContainer}>
                <Typography className={classes.poolsLabel}>
                  Featured Projects
                </Typography>
                <FadeInWhenVisible duration={0.83} delay={0.1}>
                  <div className={classes.poolsWrapper}>
                    {onGoingPools
                      .filter(
                        (pool) =>
                          upcomings[theme.type][pool.presaleIndex] ||
                          Certified[theme.type][pool.presaleIndex]
                      )
                      .map((pool) => {
                        const totalCollected = parseFloat(
                          toFixed(
                            bigInt(pool.totalCollectedWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const hardCap = parseFloat(
                          toFixed(
                            bigInt(pool.hardCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const softCap = parseFloat(
                          toFixed(
                            bigInt(pool.softCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const tokenPrice = parseFloat(
                          toFixed(
                            bigInt(pool.tokenPriceInWei),
                            pool?.fundingTokenDecimals || 18,
                            5
                          )
                        );
                        const maximumInvest = parseFloat(
                          toFixed(
                            bigInt(pool.maxInvestInWei),
                            pool?.fundingTokenDecimals || 18,
                            4
                          )
                        );
                        const presaleCancelled = pool?.presaleCancelled;
                        const liquidityAllocation =
                          pool?.cakeLiquidityPercentageAllocation;

                        return (
                          <div
                            className={classes.poolItemWrapper}
                            key={pool.saleTitle}
                          >
                            <PoolCard
                              key={pool.tokenAddress}
                              poolId={pool.presaleIndex}
                              openTime={parseInt(pool.openTime)}
                              closeTime={parseInt(pool.closeTime)}
                              image={pool.linkLogo}
                              title={pool.saleTitle}
                              token={pool.token}
                              tokenPrice={tokenPrice}
                              salesValue={totalCollected}
                              percentage={(totalCollected / hardCap) * 100}
                              maximum={hardCap}
                              minimum={softCap}
                              maximumInvest={maximumInvest}
                              liquidityAllocation={liquidityAllocation}
                              presaleType={pool.presaleType}
                              telegram={"https://t.me/" + pool.linkTelegram}
                              externalLink={pool.linkWebsite}
                              url={pool.url}
                              version={pool.presaleVersion}
                              cancelled={presaleCancelled}
                              bnbPrice={bnbPrice}
                              isVotePassed={isVotePassed(pool)}
                              fundingTokenAddress={pool?.fundingTokenAddress}
                            />
                          </div>
                        );
                      })}
                    {votingPools
                      .filter(
                        (pool) => upcomings[theme.type][pool.presaleIndex]
                      )
                      .map((pool) => {
                        const totalCollected = parseFloat(
                          toFixed(
                            bigInt(pool.totalCollectedWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const hardCap = parseFloat(
                          toFixed(
                            bigInt(pool.hardCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const softCap = parseFloat(
                          toFixed(
                            bigInt(pool.softCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const tokenPrice = parseFloat(
                          toFixed(
                            bigInt(pool.tokenPriceInWei),
                            pool?.fundingTokenDecimals || 18,
                            5
                          )
                        );
                        const maximumInvest = parseFloat(
                          toFixed(
                            bigInt(pool.maxInvestInWei),
                            pool?.fundingTokenDecimals || 18,
                            4
                          )
                        );
                        const yesVotes = parseFloat(
                          toFixed(bigInt(pool.yesVotes), 18, 4)
                        );
                        const noVotes = parseFloat(
                          toFixed(bigInt(pool.noVotes), 18, 4)
                        );
                        const minYesVotesThreshold = parseFloat(
                          toFixed(bigInt(pool.minYesVotesThreshold), 18, 4)
                        );
                        const presaleCancelled = pool?.presaleCancelled;
                        const liquidityAllocation =
                          pool?.cakeLiquidityPercentageAllocation;
                        return (
                          <div
                            className={classes.poolItemWrapper}
                            key={pool.saleTitle}
                          >
                            <VotingPoolCard
                              key={pool.tokenAddress}
                              poolId={pool.presaleIndex}
                              openTime={parseInt(pool.openTime)}
                              closeTime={parseInt(pool.closeTime)}
                              image={pool.linkLogo}
                              title={pool.saleTitle}
                              token={pool.token}
                              tokenPrice={tokenPrice}
                              salesValue={totalCollected}
                              yesPercentage={(yesVotes / 1000000) * 100}
                              noPercentage={(noVotes / 1000000) * 100}
                              percentage={(totalCollected / hardCap) * 100}
                              yesVotes={yesVotes}
                              noVotes={noVotes}
                              minYesVotesThreshold={minYesVotesThreshold}
                              maximum={hardCap}
                              minimum={softCap}
                              maximumInvest={maximumInvest}
                              liquidityAllocation={liquidityAllocation}
                              presaleType={pool.presaleType}
                              telegram={"https://t.me/" + pool.linkTelegram}
                              externalLink={pool.linkWebsite}
                              url={pool.url}
                              version={pool.presaleVersion}
                              cancelled={presaleCancelled}
                              bnbPrice={bnbPrice}
                              fundingTokenAddress={pool?.fundingTokenAddress}
                            />
                          </div>
                        );
                      })}
                    {finishedPools
                      .filter(
                        (pool) => upcomings[theme.type][pool.presaleIndex]
                      )
                      .map((pool) => {
                        const totalCollected = parseFloat(
                          toFixed(
                            bigInt(pool.totalCollectedWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const hardCap = parseFloat(
                          toFixed(
                            bigInt(pool.hardCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const softCap = parseFloat(
                          toFixed(
                            bigInt(pool.softCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const tokenPrice = parseFloat(
                          toFixed(
                            bigInt(pool.tokenPriceInWei),
                            pool?.fundingTokenDecimals || 18,
                            5
                          )
                        );
                        const maximumInvest = parseFloat(
                          toFixed(
                            bigInt(pool.maxInvestInWei),
                            pool?.fundingTokenDecimals || 18,
                            4
                          )
                        );
                        const presaleCancelled = pool?.presaleCancelled;

                        return (
                          <div
                            className={classes.poolItemWrapper}
                            key={pool.saleTitle}
                          >
                            <PoolCard
                              key={pool.tokenAddress}
                              poolId={pool.presaleIndex}
                              openTime={parseInt(pool.openTime)}
                              closeTime={parseInt(pool.closeTime)}
                              image={pool.linkLogo}
                              title={pool.saleTitle}
                              token={pool.token}
                              tokenPrice={tokenPrice}
                              salesValue={totalCollected}
                              percentage={(totalCollected / hardCap) * 100}
                              maximum={hardCap}
                              minimum={softCap}
                              maximumInvest={maximumInvest}
                              telegram={"https://t.me/" + pool.linkTelegram}
                              externalLink={pool.linkWebsite}
                              url={pool.url}
                              version={pool.presaleVersion}
                              cancelled={presaleCancelled}
                              bnbPrice={bnbPrice}
                              isVotePassed={isVotePassed(pool)}
                              fundingTokenAddress={pool?.fundingTokenAddress}
                            />
                          </div>
                        );
                      })}
                    {certifiedPools
                      .filter(
                        (pool) =>
                          !finishedCertified[pool.presaleIndex] &&
                          !upcomings[theme.type][pool.presaleIndex]
                      )
                      .slice(0, 9)
                      .map((pool) => {
                        const totalCollected = parseFloat(
                          toFixed(
                            bigInt(pool.totalCollectedWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const hardCap = parseFloat(
                          toFixed(
                            bigInt(pool.hardCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const softCap = parseFloat(
                          toFixed(
                            bigInt(pool.softCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const tokenPrice = parseFloat(
                          toFixed(
                            bigInt(pool.tokenPriceInWei),
                            pool?.fundingTokenDecimals || 18,
                            5
                          )
                        );
                        const maximumInvest = parseFloat(
                          toFixed(
                            bigInt(pool.maxInvestInWei),
                            pool?.fundingTokenDecimals || 18,
                            4
                          )
                        );
                        const presaleCancelled = pool?.presaleCancelled;
                        const liquidityAllocation =
                          pool?.cakeLiquidityPercentageAllocation;

                        return (
                          <div
                            className={classes.poolItemWrapper}
                            key={pool.saleTitle}
                          >
                            <CertifiedCard
                              key={pool.tokenAddress}
                              openTime={parseInt(pool.openTime)}
                              closeTime={parseInt(pool.closeTime)}
                              image={pool.linkLogo}
                              title={pool.saleTitle}
                              token={pool.token}
                              tokenPrice={tokenPrice}
                              salesValue={totalCollected}
                              percentage={(totalCollected / hardCap) * 100}
                              maximum={hardCap}
                              minimum={softCap}
                              maximumInvest={maximumInvest}
                              liquidityAllocation={liquidityAllocation}
                              presaleType={pool.presaleType}
                              telegram={"https://t.me/" + pool.linkTelegram}
                              externalLink={pool.linkWebsite}
                              url={pool.url}
                              version={pool.presaleVersion}
                              cancelled={presaleCancelled}
                              bnbPrice={bnbPrice}
                              fundingTokenAddress={pool?.fundingTokenAddress}
                            />
                          </div>
                        );
                      })}
                  </div>
                </FadeInWhenVisible>
              </div>
            )}

            {onGoingPools.filter(
              (pool) => !upcomings[theme.type][pool.presaleIndex]
            ).length > 0 && (
              <div className={classes.poolsContainer}>
                <div className={classes.poolLabelContainer}>
                  <Typography className={classes.poolsLabel}>
                    Non-Certified Pools
                  </Typography>
                  <FormControlLabel
                    classes={{ label: classes.switchLabel }}
                    value="start"
                    control={
                      <Switch
                        classes={{ track: classes.switchTrack }}
                        color="primary"
                        size="medium"
                        checked={isGoingPoolsShown}
                        onChange={(ev) => {
                          showGoingPools(ev.target.checked);
                        }}
                        icon={
                          <img
                            className={classes.switchIcon}
                            src={theme.logoImage}
                            alt="logo"
                          />
                        }
                        checkedIcon={
                          <img
                            className={classes.switchIcon}
                            src={theme.logoImage}
                            alt="logo"
                          />
                        }
                      />
                    }
                    label="View Pools"
                    labelPlacement="start"
                  />
                </div>
                {isGoingPoolsShown && loaded && onGoingPools.length > 0 && (
                  <FadeInWhenVisible duration={0.83} delay={0.1}>
                    <div className={classes.poolsWrapper}>
                      {onGoingPools
                        .filter(
                          (pool) =>
                            !upcomings[theme.type][pool.presaleIndex] &&
                            !Certified[theme.type][pool.presaleIndex]
                        )
                        .map((pool) => {
                          const totalCollected = parseFloat(
                            toFixed(
                              bigInt(pool.totalCollectedWei),
                              pool?.fundingTokenDecimals || 18,
                              2
                            )
                          );
                          const hardCap = parseFloat(
                            toFixed(
                              bigInt(pool.hardCapInWei),
                              pool?.fundingTokenDecimals || 18,
                              2
                            )
                          );
                          const softCap = parseFloat(
                            toFixed(
                              bigInt(pool.softCapInWei),
                              pool?.fundingTokenDecimals || 18,
                              2
                            )
                          );
                          const tokenPrice = parseFloat(
                            toFixed(
                              bigInt(pool.tokenPriceInWei),
                              pool?.fundingTokenDecimals || 18,
                              5
                            )
                          );
                          const maximumInvest = parseFloat(
                            toFixed(
                              bigInt(pool.maxInvestInWei),
                              pool?.fundingTokenDecimals || 18,
                              4
                            )
                          );
                          const presaleCancelled = pool?.presaleCancelled;
                          const liquidityAllocation =
                            pool?.cakeLiquidityPercentageAllocation;

                          return (
                            <div
                              className={classes.poolItemWrapper}
                              key={pool.saleTitle}
                            >
                              <PoolCard
                                key={pool.tokenAddress}
                                poolId={pool.presaleIndex}
                                openTime={parseInt(pool.openTime)}
                                closeTime={parseInt(pool.closeTime)}
                                image={pool.linkLogo}
                                title={pool.saleTitle}
                                token={pool.token}
                                tokenPrice={tokenPrice}
                                salesValue={totalCollected}
                                percentage={(totalCollected / hardCap) * 100}
                                maximum={hardCap}
                                minimum={softCap}
                                maximumInvest={maximumInvest}
                                liquidityAllocation={liquidityAllocation}
                                presaleType={pool.presaleType}
                                telegram={"https://t.me/" + pool.linkTelegram}
                                externalLink={pool.linkWebsite}
                                url={pool.url}
                                version={pool.presaleVersion}
                                cancelled={presaleCancelled}
                                bnbPrice={bnbPrice}
                                isVotePassed={isVotePassed(pool)}
                                fundingTokenAddress={pool?.fundingTokenAddress}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </FadeInWhenVisible>
                )}
                {!loaded && (
                  <div className={classes.emptyContainer}>
                    <div className={classes.warningContainer}>
                      <Typography variant="h4" className={classes.warningText}>
                        Loading...
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            )}
            {votingPools.length > 0 && (
              <div className={classes.poolsContainer}>
                <div className={classes.poolLabelContainer}>
                  <Typography className={classes.poolsLabel}>
                    Pools in Voting
                  </Typography>
                  <FormControlLabel
                    classes={{ label: classes.switchLabel }}
                    value="start"
                    control={
                      <Switch
                        classes={{ track: classes.switchTrack }}
                        color="primary"
                        size="medium"
                        checked={isVotingPoolsShown}
                        onChange={(ev) => {
                          showVotingPools(ev.target.checked);
                        }}
                        icon={
                          <img
                            className={classes.switchIcon}
                            src={theme.logoImage}
                            alt="logo"
                          />
                        }
                        checkedIcon={
                          <img
                            className={classes.switchIcon}
                            src={theme.logoImage}
                            alt="logo"
                          />
                        }
                      />
                    }
                    label="View Pools"
                    labelPlacement="start"
                  />
                </div>
                {isVotingPoolsShown && votingPools.length > 0 && (
                  <FadeInWhenVisible duration={0.83} delay={0.1}>
                    <div className={classes.poolsWrapper}>
                      {votingPools
                        .filter(
                          (pool) => !upcomings[theme.type][pool.presaleIndex]
                        )
                        .map((pool) => {
                          const totalCollected = parseFloat(
                            toFixed(
                              bigInt(pool.totalCollectedWei),
                              pool?.fundingTokenDecimals || 18,
                              2
                            )
                          );
                          const hardCap = parseFloat(
                            toFixed(
                              bigInt(pool.hardCapInWei),
                              pool?.fundingTokenDecimals || 18,
                              2
                            )
                          );
                          const softCap = parseFloat(
                            toFixed(
                              bigInt(pool.softCapInWei),
                              pool?.fundingTokenDecimals || 18,
                              2
                            )
                          );
                          const tokenPrice = parseFloat(
                            toFixed(
                              bigInt(pool.tokenPriceInWei),
                              pool?.fundingTokenDecimals || 18,
                              5
                            )
                          );
                          const maximumInvest = parseFloat(
                            toFixed(
                              bigInt(pool.maxInvestInWei),
                              pool?.fundingTokenDecimals || 18,
                              4
                            )
                          );
                          const yesVotes = parseFloat(
                            toFixed(bigInt(pool.yesVotes), 18, 4)
                          );
                          const noVotes = parseFloat(
                            toFixed(bigInt(pool.noVotes), 18, 4)
                          );
                          const minYesVotesThreshold = parseFloat(
                            toFixed(bigInt(pool.minYesVotesThreshold), 18, 4)
                          );
                          const presaleCancelled = pool?.presaleCancelled;
                          const liquidityAllocation =
                            pool?.cakeLiquidityPercentageAllocation;

                          return (
                            <div
                              className={classes.poolItemWrapper}
                              key={pool.saleTitle}
                            >
                              <VotingPoolCard
                                key={pool.tokenAddress}
                                poolId={pool.presaleIndex}
                                openTime={parseInt(pool.openTime)}
                                closeTime={parseInt(pool.closeTime)}
                                image={pool.linkLogo}
                                title={pool.saleTitle}
                                token={pool.token}
                                tokenPrice={tokenPrice}
                                salesValue={totalCollected}
                                yesPercentage={(yesVotes / 1000000) * 100}
                                noPercentage={(noVotes / 1000000) * 100}
                                percentage={(totalCollected / hardCap) * 100}
                                yesVotes={yesVotes}
                                noVotes={noVotes}
                                minYesVotesThreshold={minYesVotesThreshold}
                                maximum={hardCap}
                                minimum={softCap}
                                maximumInvest={maximumInvest}
                                liquidityAllocation={liquidityAllocation}
                                presaleType={pool.presaleType}
                                telegram={"https://t.me/" + pool.linkTelegram}
                                externalLink={pool.linkWebsite}
                                url={pool.url}
                                version={pool.presaleVersion}
                                cancelled={presaleCancelled}
                                bnbPrice={bnbPrice}
                                fundingTokenAddress={pool?.fundingTokenAddress}
                              />
                            </div>
                          );
                        })}
                    </div>
                  </FadeInWhenVisible>
                )}
                {!loaded && (
                  <div className={classes.emptyContainer}>
                    <div className={classes.warningContainer}>
                      <Typography variant="h4" className={classes.warningText}>
                        Loading...
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            )}

            {privatePools.length > 0 &&
              theme.type !== "bsc" &&
              theme.type !== "pulse" && (
                <div className={classes.poolsContainer}>
                  <div className={classes.poolLabelContainer}>
                    <Typography className={classes.poolsLabel}>
                      Token Sales
                    </Typography>
                    <FormControlLabel
                      classes={{ label: classes.switchLabel }}
                      value="start"
                      control={
                        <Switch
                          classes={{ track: classes.switchTrack }}
                          color="primary"
                          size="medium"
                          checked={isPrivatePoolsShown}
                          onChange={(ev) => {
                            showPrivatePools(ev.target.checked);
                          }}
                          icon={
                            <img
                              className={classes.switchIcon}
                              src={theme.logoImage}
                              alt="logo"
                            />
                          }
                          checkedIcon={
                            <img
                              className={classes.switchIcon}
                              src={theme.logoImage}
                              alt="logo"
                            />
                          }
                        />
                      }
                      label="View Pools"
                      labelPlacement="start"
                    />
                  </div>
                  {isPrivatePoolsShown && privatePools.length > 0 && (
                    <FadeInWhenVisible duration={0.83} delay={0.1}>
                      <div className={classes.poolsWrapper}>
                        {privatePools
                          .filter(
                            (pool) => !upcomings[theme.type][pool.presaleIndex] && !Certified[theme.type][pool.presaleIndex]
                          )
                          .map((pool) => {
                            const totalCollected = parseFloat(
                              toFixed(
                                bigInt(pool.totalCollectedWei),
                                pool?.fundingTokenDecimals || 18,
                                2
                              )
                            );
                            const hardCap = parseFloat(
                              toFixed(
                                bigInt(pool.hardCapInWei),
                                pool?.fundingTokenDecimals || 18,
                                2
                              )
                            );
                            const softCap = parseFloat(
                              toFixed(
                                bigInt(pool.softCapInWei),
                                pool?.fundingTokenDecimals || 18,
                                2
                              )
                            );
                            const tokenPrice = parseFloat(
                              toFixed(
                                bigInt(pool.tokenPriceInWei),
                                pool?.fundingTokenDecimals || 18,
                                5
                              )
                            );
                            const maximumInvest = parseFloat(
                              toFixed(
                                bigInt(pool.maxInvestInWei),
                                pool?.fundingTokenDecimals || 18,
                                4
                              )
                            );
                            const yesVotes = parseFloat(
                              toFixed(
                                bigInt(pool.yesVotes),
                                pool?.fundingTokenDecimals || 18,
                                4
                              )
                            );
                            const noVotes = parseFloat(
                              toFixed(
                                bigInt(pool.noVotes),
                                pool?.fundingTokenDecimals || 18,
                                4
                              )
                            );
                            const minYesVotesThreshold = parseFloat(
                              toFixed(
                                bigInt(pool.minYesVotesThreshold),
                                pool?.fundingTokenDecimals || 18,
                                4
                              )
                            );
                            const presaleCancelled = pool?.presaleCancelled;
                            const liquidityAllocation =
                              pool?.cakeLiquidityPercentageAllocation;

                            return (
                              <div
                                className={classes.poolItemWrapper}
                                key={pool.saleTitle}
                              >
                                <VotingPoolCard
                                  key={pool.tokenAddress}
                                  poolId={pool.presaleIndex}
                                  openTime={parseInt(pool.openTime)}
                                  closeTime={parseInt(pool.closeTime)}
                                  image={pool.linkLogo}
                                  title={pool.saleTitle}
                                  token={pool.token}
                                  tokenPrice={tokenPrice}
                                  salesValue={totalCollected}
                                  yesPercentage={(yesVotes / 1000000) * 100}
                                  noPercentage={(noVotes / 1000000) * 100}
                                  percentage={(totalCollected / hardCap) * 100}
                                  yesVotes={yesVotes}
                                  noVotes={noVotes}
                                  minYesVotesThreshold={minYesVotesThreshold}
                                  maximum={hardCap}
                                  minimum={softCap}
                                  maximumInvest={maximumInvest}
                                  liquidityAllocation={liquidityAllocation}
                                  presaleType={pool.presaleType}
                                  telegram={"https://t.me/" + pool.linkTelegram}
                                  externalLink={pool.linkWebsite}
                                  url={pool.url}
                                  version={pool.presaleVersion}
                                  cancelled={presaleCancelled}
                                  bnbPrice={bnbPrice}
                                  fundingTokenAddress={
                                    pool?.fundingTokenAddress
                                  }
                                />
                              </div>
                            );
                          })}
                      </div>
                    </FadeInWhenVisible>
                  )}
                  {!loaded && (
                    <div className={classes.emptyContainer}>
                      <div className={classes.warningContainer}>
                        <Typography
                          variant="h4"
                          className={classes.warningText}
                        >
                          Loading...
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              )}

            {/* <div className={classes.poolsContainer}>
              <Typography className={classes.poolsLabel}>
                Featured Alumni
              </Typography>
              <div className={classes.poolsAluminiWrapper}>
                <img
                  className={classes.partnerLogo}
                  src="https://bscstarter.finance/wsb_token.png"
                  alt="logo"
                  onClick={() => {
                    window.open("https://www.wsbdapp.com/");
                  }}
                />
                <img
                  className={classes.partnerLogo}
                  src="https://goswapp.io/media/logo_home.svg"
                  alt="logo"
                  onClick={() => {
                    window.open("https://goswapp.io/");
                  }}
                />

                <img
                  className={classes.partnerLogo}
                  src="https://bscstarter.finance/fomo.png"
                  alt="logo"
                  onClick={() => {
                    window.open("https://www.fomolab.io/");
                  }}
                />
                <img
                  className={classes.partnerLogo}
                  src="https://cuep.io/rebrand/wp-content/uploads/2021/04/cue-logo-p2.png"
                  alt="logo"
                  onClick={() => {
                    window.open("https://cuep.io/");
                  }}
                />
                <img
                  className={classes.partnerLogo}
                  src="https://wisetoken.net/teal/wise-logo-200px.svg"
                  alt="logo"
                  onClick={() => {
                    window.open("https://wisetoken.net/bsc");
                  }}
                />
                <img
                  className={classes.partnerLogo}
                  src={BakerToolsSvg}
                  alt="logo"
                  onClick={() => {
                    window.open("https://bakerytools.io/");
                  }}
                />
                <img
                  className={classes.partnerLogo}
                  src="https://cake.monster/static/monster_head-d3427f26c5ea224cf45ee8c8fa6683b2.svg"
                  alt="logo"
                  onClick={() => {
                    window.open("https://cake.monster/");
                  }}
                />
                <img
                  className={classes.partnerLogo}
                  src="https://yamp.finance/img/yamp_logo.svg"
                  alt="logo"
                  onClick={() => {
                    window.open("https://yamp.finance");
                  }}
                />
                <img
                  className={classes.partnerLogo}
                  src="https://starter.xyz/Relay.png"
                  alt="logo"
                  onClick={() => {
                    window.open("https://relaychain.com");
                  }}
                />
              </div>
            </div> */}
            <div className={classes.poolsContainer}>
              <Typography className={classes.poolsLabel}>Partners</Typography>
              <div className={classes.poolsPartnersWrapper}>
                {PARTNERS.map((partner) => (
                  <img
                    key={partner.link}
                    className={classes.partnerLogo}
                    src={partner.image}
                    alt="logo"
                    onClick={() => {
                      window.open(partner.link);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <BuyModalBox
        modalOpen={isBuyModalShown}
        closeModal={() => {
          showBuyModal(false);
        }}
        bnbBalance={swapTokenAmount}
        startBalance={startTokenAmount}
        price={swapPrice}
        onSwap={onSwap}
        account={accountInfo}
        url={defaultBuyUrls[theme?.type]}
      />
      <div className="klaviyo-form-YdiSzv" />
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(Home)));
