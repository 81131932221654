import STARTokenAbi from "./abis/startToken";
import ERC20Abi from "./abis/erc20";
import factoryAbi from "./abis/factory";
import starterInfoAbi from "./abis/starterInfo";
import starterStakingAbi from "./abis/starterStaking";
import starterPresaleAbi from "./abis/starterPresale";

import farmingAbi from "./abis/farming";
import farmingPairAbi from "./abis/farmingPair";

import swapRouterAbi from "./abis/swapRouter";

const config = {
  STARTokenAddress: "0xf44fb887334fa17d2c5c0f970b5d320ab53ed557",
  STARTokenAbi,
  ERC20Abi,
  factoryAbi,
  factoryAddress: "0x438d7bC7cF9d13BE657943781A4568c1D6106F18",
  factoryV3Abi: factoryAbi,
  factoryV3Address: "0x438d7bC7cF9d13BE657943781A4568c1D6106F18",

  starterInfoAddress: "0xe634810546e72BC409530D945656Ffc66262A920",
  starterInfoAbi,
  starterInfoV3Address: "0xe634810546e72BC409530D945656Ffc66262A920",
  starterInfoV3Abi: starterInfoAbi,

  starterStaking: "0x34F46932e48577A8Ec1d65A4E4fFF3dC43B1D662",
  starterStakingAbi,
  starterStakingV2: "0x34F46932e48577A8Ec1d65A4E4fFF3dC43B1D662",
  starterStakingV2Abi: starterStakingAbi,
  starterStakingV3: "0x34F46932e48577A8Ec1d65A4E4fFF3dC43B1D662",
  starterStakingV3Abi: starterStakingAbi,
  starterPresaleAbi,

  starterVestingAddress: "0xE964e86E3242Ef313B8869166769217daE15eBc2",

  metagamzTokenAddress: "0xf44fb887334fa17d2c5c0f970b5d320ab53ed557",
  metagamzTokenAbi: ERC20Abi,
  metagamzScAddress: "0x8F1185DB9d5550EE3e76055ab4e71906bcea083F",
  metagamzScAbi: starterPresaleAbi,
  metagamzSvipAddress: "0x1318DF8557854DCA8D7E3261208a129AA898D42e",
  metagamzSvipAbi: starterPresaleAbi,

  farmingAddress: "0xA1B28DFF568c509275666bf77300F77611F785bE",
  farmingAbi,
  farmingPairAddress: "0x8d0BfC06AF725CFaA38672b97c9fFaAD16081aF9",
  farmingPairAbi,
  wbnbAddress: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",

  stakingToken: "0x8d0BfC06AF725CFaA38672b97c9fFaAD16081aF9",
  stakingTokenName: "AVAX+START LP",
  stakingContracts: ["0x34F46932e48577A8Ec1d65A4E4fFF3dC43B1D662"],

  swapSymbol: "AVAX",
  swapToken: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
  swapTokenAbi: ERC20Abi,
  swapRouter: "0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106",
  swapRouterAbi,
};

export default config;
