const abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_bscsInfoAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_bscsToken",
        type: "address",
      },
      {
        internalType: "address",
        name: "_bscsStakingPool",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "_buyBackBurnAddress",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "title",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bscsId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "creator",
        type: "address",
      },
    ],
    name: "PresaleCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Received",
    type: "event",
  },
  {
    inputs: [],
    name: "BSCS",
    outputs: [
      {
        internalType: "contract StarterInfo",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bscsStakingPool",
    outputs: [
      {
        internalType: "contract StarterStakingV2",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bscsToken",
    outputs: [
      {
        internalType: "contract ERC20",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "buyBackBurnAddress",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "lastClaimedTimestamp",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minGuaranteedBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minStake",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_minStake",
        type: "uint256",
      },
    ],
    name: "setMinStake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "factory",
        type: "address",
      },
      {
        internalType: "address",
        name: "tokenA",
        type: "address",
      },
      {
        internalType: "address",
        name: "tokenB",
        type: "address",
      },
    ],
    name: "cakeV2LibPairFor",
    outputs: [
      {
        internalType: "address",
        name: "pair",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "sender",
        type: "address",
      },
    ],
    name: "getBscsStakedAndLocked",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "address",
            name: "tokenAddress",
            type: "address",
          },
          {
            internalType: "uint8",
            name: "tokenDecimals",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "unsoldTokensDumpAddress",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "whitelistedAddresses",
            type: "address[]",
          },
          {
            internalType: "uint256",
            name: "tokenPriceInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "hardCapInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "softCapInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "maxInvestInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "minInvestInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "openTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "closeTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "presaleType",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "guaranteedHours",
            type: "uint256",
          },
        ],
        internalType: "struct StarterFactoryV2.PresaleInfo",
        name: "_info",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "listingPriceInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidityAddingTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lpTokensLockDurationInDays",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidityPercentageAllocation",
            type: "uint256",
          },
        ],
        internalType: "struct StarterFactoryV2.PresalePancakeSwapInfo",
        name: "_cakeInfo",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "bytes32",
            name: "saleTitle",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "linkTelegram",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "linkGithub",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "linkTwitter",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "linkWebsite",
            type: "bytes32",
          },
          {
            internalType: "string",
            name: "linkLogo",
            type: "string",
          },
          {
            internalType: "string",
            name: "kycInformation",
            type: "string",
          },
          {
            internalType: "string",
            name: "description",
            type: "string",
          },
          {
            internalType: "string",
            name: "whitepaper",
            type: "string",
          },
          {
            internalType: "uint256",
            name: "categoryId",
            type: "uint256",
          },
        ],
        internalType: "struct StarterFactoryV2.PresaleStringInfo",
        name: "_stringInfo",
        type: "tuple",
      },
    ],
    name: "createPresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "claimHodlerFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export default abi;
