import STARTokenAbi from "./abis/startToken";
import ERC20Abi from "./abis/erc20";
import factoryAbi from "./abis/factory";
import starterInfoAbi from "./abis/starterInfo";
import starterStakingAbi from "./abis/starterStaking";
import starterPresaleAbi from "./abis/starterPresale";
import farmingAbi from "./abis/farming";
import farmingPairAbi from "./abis/farmingPair";
import starchiTokenAbi from "./abis/starchiToken";
import starchiSeedAbi from "./abis/starchiSeedSale";
import starzSaleAbi from "./abis/starzSale";
import swapRouterAbi from "./abis/swapRouter";

const config = {
  STARTokenAddress: "0x6ccf12b480a99c54b23647c995f4525d544a7e72",
  STARTokenAbi,
  ERC20Abi,
  factoryAbi,
  factoryAddress: "0x6d048Ce7a8989aBB3058573621aD8A2532C15768",
  factoryV3Abi: factoryAbi,
  factoryV3Address: "0x6d048Ce7a8989aBB3058573621aD8A2532C15768",

  starterInfoAddress: "0x089Ae0bF74173FC262DE3DAf31434BBca6d0f612",
  starterInfoAbi,
  starterInfoV3Address: "0x089Ae0bF74173FC262DE3DAf31434BBca6d0f612",
  starterInfoV3Abi: starterInfoAbi,

  starterStaking: "0x0bc7C564D58Af5d22bDC07920540BDc126947001",
  starterStakingAbi,
  starterStakingV2: "0x74b4769278b1c0fac3a31cc8ebaae927836c9d8d",
  starterStakingV2Abi: starterStakingAbi,
  starterStakingV3: "0x0bc7C564D58Af5d22bDC07920540BDc126947001",
  starterStakingV3Abi: starterStakingAbi,
  starterPresaleAbi,

  starterVestingAddress: "0x0e7b582003de0E541548cF02a1F00725Df6E6E6f",

  farmingAddress: "0x17ba8C9397c8Ff1C9CE91A8ac73ABbf8357F992e",
  farmingAbi,
  farmingPairAddress: "0x8c8f796d49d6720e62ff4acab35879538e57ec9f",
  farmingPairAbi,
  wbnbAddress: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",

  stakingToken: "0x9e2b254c7d6ad24afb334a75ce21e216a9aa25fc",
  stakingTokenName: "QUICK+START LP",
  stakingContracts: ["0x0bc7C564D58Af5d22bDC07920540BDc126947001"],

  swapSymbol: "QUICK",
  swapToken: "0x831753dd7087cac61ab5644b308642cc1c33dc13",
  swapTokenAbi: ERC20Abi,
  swapRouter: "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff",
  swapRouterAbi,

  starchiToken: "0xed0bb69f9f96e8c5fffdf16c468ecec385134ea5",
  starchiTokenAbi,
  starchiSeedAddress: "0xDB28b7DDF67E7345c24e3c7904279C4949EBE2a8",
  starchiSeedAbi,
  starchiRdAddress: "0x2d000e49286dff8c20108b68d0df62d84bac5061",
  starchiRdAbi: starchiSeedAbi,
  starchiPrivateAddress: "0x5b17F608A0ec24aa5423a226798a3da1744391b5",
  starchiPrivateAbi: starchiSeedAbi,
  starchiVipPrivateAddress: "0x7835b3805944df9342b6efaba4c28e31d7439d59",
  starchiVipPrivateAbi: starchiSeedAbi,
  starchiVipMavenAddress: "0x8fcefbea4019e24277f8e0ce90103736e831e8cd",
  starchiVipMavenAbi: starchiSeedAbi,
  starchiVipVbcAddress: "0x0c400d4357a19d6e85694d85550d05ccb0d2e678",
  starchiVipVbcAbi: starchiSeedAbi,
  starchiPrivateCryptozenAddress: "0xa18371a8bfC952847e2FEba987b6bCF4Fbc4D4D9",
  starchiPrivateCryptozenAbi: starchiSeedAbi,
  starchiPrivateEmpireAddress: "0x22DE6B49AFB625cB7856062A8a1137a3e5CdaA80",
  starchiPrivateEmpireAbi: starchiSeedAbi,
  starchiPrivateDarkpoolAddress: "0x3A4c6432dc8a1E8E74fF851db1454fc4D7248F3A",
  starchiPrivateDarkpoolAbi: starchiSeedAbi,
  starchiTeamSaleAddress: "0xC171060e8D8470e89646F6D9a56d92f983EDDfD9",
  starchiTeamSaleAbi: starchiSeedAbi,

  starzToken: "0x2c92a8a41f4b806a6f6f1f7c9d9dec78dcd8c18e",
  starzTokenAbi: ERC20Abi,
  starzTeamAddress: "0xAE4A39c6e6f1C5A688D49cb3E95B84be39c27B9E",
  starzSuperVipAddress: "0x5681B94B1ba9d9FEBa125dfd6Bc8ca9B6f9a9d58",
  starzScAddress: "0xbf63a673911692e291fc74fCc9a64464d2f01865",
  starzSaleAbi: starzSaleAbi,

  betToken: "0x9246a5F10A79a5a939b0C2a75A3AD196aAfDB43b",
  betTokenAbi: ERC20Abi,
  betswirlSeedAddress: "0xff3e712ef6d6E9005a972809bF25169AB9176313",
  betswirlAbi: starzSaleAbi,
};

export default config;
