import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button } from "@material-ui/core";
import { withNamespaces } from "react-i18next";
import { ReactComponent as MediumIcon } from "../../../assets/medium.svg";

const styles = (theme) => ({
  poolAdvisoryCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "30px",
      marginBottom: "50px",
    },
  },

  itemsWrapper: {
    display: "grid",
    width: "100%",
    gridGap: "40px",
    gridTemplateColumns: "auto",
    [theme.breakpoints.up("ms")]: {
      gridTemplateColumns: "auto auto",
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "33% 33% 33%",
    },
  },
  advisorWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  advisorLogo: {
    width: "200ox",
    height: "200px",
    marginBottom: "24px",
  },

  advisorName: {
    color: theme?.colors?.presale?.addressValueText,
    textAlign: "center",
    fontSize: "25px",
    lineHeight: "25px",
    textTransform: "capitalize",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
  },
  advisorPosition: {
    color: theme?.colors?.presale?.addressValueText,
    textAlign: "center",
    fontSize: "18px",
    lineHeight: "21px",
    textTransform: "capitalize",
    marginBottom: "8px",
  },
  advisorProfile: {
    color: "#A1A1A4",
    textAlign: "center",
    fontSize: "14px",
    lineHeight: "16px",
    maxWidth: "240px",
  },
  link: {
    marginLeft: "8px",
    width: "24px",
    height: "24px",
    "&:hover": {
      opacity: "0.8",
    },
  },

  icon: {
    color: theme?.colors?.footer?.icon,
  },
});

const AdvisoryCard = ({ classes, advisors }) => {
  const theme = useTheme();
  return (
    <div className={classes.poolAdvisoryCard}>
      <div className={classes.itemsWrapper}>
        {advisors.map((advisor) => (
          <div className={classes.advisorWrapper}>
            <img
              className={classes.advisorLogo}
              src={advisor.logo}
              alt="logo"
            />
            <Typography className={classes.advisorName}>
              {advisor?.name || ""}
              <a
                href={advisor?.link || "https://t.me/bscstarter"}
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MediumIcon
                  className={classes.icon}
                  style={{ width: "24px", height: "24px" }}
                />
              </a>
            </Typography>
            <Typography className={classes.advisorPosition}>
              {advisor?.position || ""}
            </Typography>
            <Typography className={classes.advisorProfile}>
              {advisor?.profile || ""}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(AdvisoryCard)));
