import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Fade,
  Snackbar,
  IconButton,
  Input,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import MuiAlert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";

import bigInt from "big-integer";
import PoolCard from "../poolCard";
import VotingPoolCard from "../poolCard/votingCard";
import CertifiedCard from "../poolCard/certifiedCard";

import { FadeInWhenVisible } from "../animation";

import FilterMenu from "./components/filterMenu";

import { withNamespaces } from "react-i18next";
import {
  GET_POOLS_SUMMARY_RETURNED,
  isVotePassed,
  toFixed,
} from "../../constants";

import Store from "../../stores";
import { PaginationItem } from "@material-ui/lab";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "1140px",
  },
  logo: {
    width: "250px",
    height: "250px",
    objectFit: "contain",
    marginBottom: "1rem",
  },
  header: {
    fontSize: "30px",
    lineHeight: "35px",
    fontFamily: "SFPro",
    fontWeight: "bold",
    textAlign: "center",
    color: theme?.colors?.home?.heading,
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
      lineHeight: "60px",
      maxWidth: "768px",
      marginBottom: "20px",
    },
  },
  headerSecondary: {
    fontSize: "30px",
    lineHeight: "35px",
    fontWeight: "bold",
    textAlign: "left",
    color: theme?.colors?.home?.headingSecondary,
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
      lineHeight: "60px",
    },
  },
  headerSubText: {
    fontSize: "10px",
    fontFamily: "SFPro",
    lineHeight: "15px",
    fontWeight: "lighter",
    textAlign: "justify",
    letterSpacing: "0px",
    color: theme?.colors?.home?.textSecondary,
    width: "100%",
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "20px",
      maxWidth: "660px",
      marginBottom: "60px",
    },
  },
  headerActionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "100px",
    },
  },
  mainBody: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  poolsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "75px",
    },
  },
  poolsLabel: {
    fontFamily: "SFPro",
    fontSize: "30px",
    fontWeight: "lighter",
    lineHeight: "36px",
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.home?.text,
  },
  poolsWrapper: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridColumnGap: "30px",
    gridRowGap: "40px",

    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },

    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "33% 33% 33%",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },
  },
  poolItemWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: "16px",
    fontWeight: "lighter",
    textAlign: "left",
    color: theme?.colors?.home?.text,
    lineHeight: "24px",
    marginBottom: "15px",
    marginTop: "0px",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
  },
  poolButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "170px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    fontFamily: "SFPro",

    marginRight: "5px",
    marginLeft: "5px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "15px",
      marginLeft: "15px",
    },
  },
  joinButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "170px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
    fontFamily: "SFPro",
    letterSpace: "0px",
    whiteSpace: "nowrap",

    marginRight: "5px",
    marginLeft: "5px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "15px",
      marginLeft: "15px",
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    whiteSpace: "nowrap",
  },
  emptyContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  poolsTitleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
  },

  searchBox: {
    width: "100%",
    height: "40px",
    borderRadius: "14px",
    border: "solid 1px " + theme?.colors?.pools?.border,
    marginBottom: "50px",
  },
  searchInput: {
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0px",
    color: theme?.colors?.pools?.searchText,
    borderRadius: "14px",
  },

  searchMagnifier: {
    width: "17px",
    height: "17px",
    margin: "16px",
    color: theme?.colors?.pools?.searchMagnifier,
  },

  paginationContainer: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paginationUl: {
    "& > li:first-child > button": {
      border: "solid 1px " + theme?.colors?.pools?.paginationItemBorder,
      borderRadius: "8px",
    },
    "& > li:last-child > button": {
      border: "solid 1px " + theme?.colors?.pools?.paginationItemBorder,
      borderRadius: "8px",
    },
  },

  paginationItemRoot: {
    width: "35px",
    height: "25px",
  },

  paginationItemRounded: {
    color: theme?.colors?.pools?.paginationText,
  },
  paginationItemSelected: {
    backgroundColor:
      theme?.colors?.pools?.paginationItemSelectedBkg + " !important",
    color: theme?.colors?.pools?.paginationItemSelectedText,
    borderRadius: "8px",
  },
});

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Pools = ({ classes, location }) => {
  const history = useHistory();
  const [pools, setPools] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [filterType, setFilterType] = useState("All");
  const [filteredPools, setFilteredPools] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);

  const getPoolsByType = (pools, type) => {
    console.log(pools);
    const currentTimestamp = Date.now() / 1000;
    if (type === "All") {
      const filtered = pools
        .filter(
          (presale) =>
            !presale.presaleCancelled &&
            (parseFloat(presale.openTime) > currentTimestamp ||
              isVotePassed(presale))
        )
        .sort((presale1, presale2) => {
          const is2Opened =
            parseFloat(presale2.openTime) <= currentTimestamp &&
            parseFloat(presale2.closeTime) >= currentTimestamp;
          const is1Opened =
            parseFloat(presale1.openTime) <= currentTimestamp &&
            parseFloat(presale1.closeTime) >= currentTimestamp;
          if (is2Opened && is1Opened) {
            return presale2.openTime - presale1.openTime;
          }

          if (is1Opened && !is2Opened) {
            return -1;
          }
          if (!is1Opened && is2Opened) {
            return 1;
          }
          return presale2.openTime - presale1.openTime;
        });
      return filtered;
    }
    if (type === "Started") {
      return pools
        .filter(
          (presale) =>
            !presale.presaleCancelled &&
            presale.openTime <= currentTimestamp &&
            presale.closeTime > currentTimestamp &&
            isVotePassed(presale)
        )
        .sort((presale1, presale2) => presale1.openTime - presale2.openTime);
    }
    if (type === "Ended") {
      return pools
        .filter(
          (presale) =>
            !presale.presaleCancelled && presale.closeTime <= currentTimestamp
        )
        .sort((presale1, presale2) => presale2.openTime - presale1.openTime);
    }
    if (type === "Certified") {
      return pools
        .filter((presale) => presale.presaleType == 2)
        .sort((presale1, presale2) => presale1.openTime - presale2.openTime);
    }

    if (type === "Voting") {
      return pools
        .filter(
          (presale) =>
            !presale.presaleCancelled && presale.openTime > currentTimestamp
        )
        .sort((presale1, presale2) => presale2.openTime - presale1.openTime);
    }
    if (type === "Filled") {
      return pools
        .filter((presale) => {
          const totalCollected = parseFloat(
            toFixed(bigInt(presale.totalCollectedWei), 18, 2)
          );
          const hardCap = parseFloat(
            toFixed(bigInt(presale.hardCapInWei), 18, 2)
          );
          return !presale.presaleCancelled && totalCollected >= hardCap;
        })
        .sort((presale1, presale2) => presale2.openTime - presale1.openTime);
    }
    if (type === "Not filled") {
      return pools
        .filter((presale) => {
          const totalCollected = parseFloat(
            toFixed(bigInt(presale.totalCollectedWei), 18, 2)
          );
          const hardCap = parseFloat(
            toFixed(bigInt(presale.hardCapInWei), 18, 2)
          );
          return (
            !presale.presaleCancelled &&
            isVotePassed(presale) &&
            presale.closeTime <= currentTimestamp &&
            totalCollected < hardCap
          );
        })
        .sort((presale1, presale2) => presale2.openTime - presale1.openTime);
    }
    if (type === "Cancelled") {
      return pools
        .filter((presale) => presale.presaleCancelled)
        .sort((presale1, presale2) => presale2.openTime - presale1.openTime);
    }
    return [];
  };

  const sortPools = (pools) => {
    const currentTimestamp = Date.now() / 1000;
    return pools.sort((presale1, presale2) => {
      if (
        presale1.openTime > currentTimestamp &&
        presale2.openTime > currentTimestamp
      ) {
        return presale1?.openTime - presale2?.openTime;
      }
      if (
        presale1.openTime < currentTimestamp &&
        presale2.openTime < currentTimestamp
      ) {
        return presale1?.openTime - presale2?.openTime;
      }
      if (
        presale1.openTime > currentTimestamp &&
        presale2.openTime < currentTimestamp
      ) {
        return -1;
      }
      if (
        presale1.openTime < currentTimestamp &&
        presale2.openTime > currentTimestamp
      ) {
        return 1;
      }
      return 1;
    });
  };

  useEffect(() => {
    const presales = store.getStore("presales");
    const filteredPresales = Object.values(presales).filter(
      (item) => item.public
    );
    const publicPresales = store.getStore("publicPresales");
    console.log(publicPresales);
    setPools(
      sortPools([...Object.values(publicPresales), ...filteredPresales])
    );

    emitter.on(GET_POOLS_SUMMARY_RETURNED, getPoolsSummaryReturned);
    return () => {
      emitter.removeListener(
        GET_POOLS_SUMMARY_RETURNED,
        getPoolsSummaryReturned
      );
    };
  }, []);

  useEffect(() => {
    if (!searchString || searchString === "") {
      setFilteredPools(getPoolsByType(pools, filterType));
    }
    const _searchString = searchString?.toLowerCase();
    const _filtered = getPoolsByType(pools, filterType).filter((pool) => {
      return (
        pool?.saleTitle
          ?.toLowerCase()
          .replace(/ /g, "")
          .includes(_searchString) ||
        pool?.tokenAddress
          ?.toLowerCase()
          .replace(/ /g, "")
          .includes(_searchString)
      );
    });
    setFilteredPools(_filtered);
  }, [pools, searchString, filterType]);

  useEffect(() => {
    setPageNumber(0);
  }, [searchString, filterType]);

  const getPoolsSummaryReturned = () => {
    const presales = store.getStore("presales");
    const filteredPresales = Object.values(presales).filter(
      (item) => item.public
    );
    const publicPresales = store.getStore("publicPresales");
    setPools(
      sortPools([...Object.values(publicPresales), ...filteredPresales])
    );
  };

  return (
    <div className={classes.root}>
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          <div className={classes.mainBody}>
            <div className={classes.poolsContainer}>
              <div className={classes.poolsTitleContainer}>
                <Typography className={classes.poolsLabel}>
                  All Pools - {filterType}
                </Typography>
                <FilterMenu
                  menuItems={[
                    "All",
                    "Started",
                    "Ended",
                    "Certified",
                    "Voting",
                    "Filled",
                    "Not filled",
                    "Cancelled",
                  ]}
                  onChange={(type) => {
                    setFilterType(type);
                  }}
                />
              </div>
              <Input
                classes={{
                  root: classes.searchBox,
                  input: classes.searchInput,
                }}
                disableUnderline={true}
                placeholder="Search by Name, Token contract address, Token description"
                value={searchString}
                startAdornment={
                  <SearchIcon classes={{ root: classes.searchMagnifier }} />
                }
                onChange={(ev) => {
                  setSearchString(ev.target.value);
                }}
              />
              {filteredPools.slice(pageNumber * 9, pageNumber * 9 + 9).length >
                0 && (
                <FadeInWhenVisible duration={0.83} delay={0.1}>
                  <div className={classes.poolsWrapper}>
                    {filteredPools
                      .slice(pageNumber * 9, pageNumber * 9 + 9)
                      .map((pool) => {
                        const totalCollected = parseFloat(
                          toFixed(
                            bigInt(pool.totalCollectedWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const hardCap = parseFloat(
                          toFixed(
                            bigInt(pool.hardCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const softCap = parseFloat(
                          toFixed(
                            bigInt(pool.softCapInWei),
                            pool?.fundingTokenDecimals || 18,
                            2
                          )
                        );
                        const tokenPrice = parseFloat(
                          toFixed(
                            bigInt(pool.tokenPriceInWei),
                            pool?.fundingTokenDecimals || 18,
                            5
                          )
                        );
                        const maximumInvest = parseFloat(
                          toFixed(
                            bigInt(pool.maxInvestInWei),
                            pool?.fundingTokenDecimals || 18,
                            4
                          )
                        );
                        const yesVotes = parseFloat(
                          toFixed(bigInt(pool.yesVotes), 18, 4)
                        );
                        const noVotes = parseFloat(
                          toFixed(bigInt(pool.noVotes), 18, 4)
                        );
                        const minYesVotesThreshold = parseFloat(
                          toFixed(bigInt(pool.minYesVotesThreshold), 18, 4)
                        );
                        const currentTimestamp = Date.now() / 1000;
                        const presaleType = pool.presaleType;
                        const isVoting =
                          presaleType == 1 && pool.openTime >= currentTimestamp;
                        const cancelled = pool?.presaleCancelled;
                        const liquidityAllocation =
                          pool?.cakeLiquidityPercentageAllocation;

                        if (presaleType == 2) {
                          return (
                            <div
                              className={classes.poolItemWrapper}
                              key={pool.name}
                            >
                              <CertifiedCard
                                key={pool.tokenAddress}
                                openTime={parseInt(pool.openTime)}
                                closeTime={parseInt(pool.closeTime)}
                                image={pool.linkLogo}
                                title={pool.saleTitle}
                                token={pool.token}
                                tokenPrice={tokenPrice}
                                salesValue={totalCollected}
                                percentage={(totalCollected / hardCap) * 100}
                                maximum={hardCap}
                                minimum={softCap}
                                maximumInvest={maximumInvest}
                                liquidityAllocation={liquidityAllocation}
                                presaleType={presaleType}
                                telegram={"https://t.me/" + pool.linkTelegram}
                                externalLink={pool.linkWebsite}
                                url={pool.url}
                                version={pool.presaleVersion}
                                cancelled={cancelled}
                                fundingTokenAddress={pool?.fundingTokenAddress}
                              />
                            </div>
                          );
                        }
                        return (
                          <div
                            className={classes.poolItemWrapper}
                            key={pool.name}
                          >
                            {isVoting ? (
                              <VotingPoolCard
                                key={pool.tokenAddress}
                                openTime={parseInt(pool.openTime)}
                                closeTime={parseInt(pool.closeTime)}
                                image={pool.linkLogo}
                                title={pool.saleTitle}
                                token={pool.token}
                                tokenPrice={tokenPrice}
                                salesValue={totalCollected}
                                yesPercentage={(yesVotes / 1000000) * 100}
                                noPercentage={(noVotes / 1000000) * 100}
                                yesVotes={yesVotes}
                                noVotes={noVotes}
                                minYesVotesThreshold={minYesVotesThreshold}
                                maximum={hardCap}
                                minimum={softCap}
                                maximumInvest={maximumInvest}
                                liquidityAllocation={liquidityAllocation}
                                presaleType={presaleType}
                                telegram={"https://t.me/" + pool.linkTelegram}
                                externalLink={pool.linkWebsite}
                                url={pool.url}
                                version={pool.presaleVersion}
                                cancelled={cancelled}
                                fundingTokenAddress={pool.fundingTokenAddress}
                              />
                            ) : (
                              <PoolCard
                                key={pool.tokenAddress}
                                poolId={pool.presaleIndex}
                                openTime={pool.openTime}
                                closeTime={pool.closeTime}
                                image={pool.linkLogo}
                                title={pool.saleTitle}
                                token={pool.token}
                                tokenPrice={tokenPrice}
                                salesValue={totalCollected}
                                percentage={(totalCollected / hardCap) * 100}
                                maximum={hardCap}
                                minimum={softCap}
                                maximumInvest={maximumInvest}
                                liquidityAllocation={liquidityAllocation}
                                presaleType={presaleType}
                                telegram={"https://t.me/" + pool.linkTelegram}
                                externalLink={pool.linkWebsite}
                                url={pool.url}
                                cancelled={cancelled}
                                isVotePassed={isVotePassed(pool)}
                                fundingTokenAddress={pool?.fundingTokenAddress}
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                </FadeInWhenVisible>
              )}
              {filteredPools.length === 0 && (
                <div className={classes.emptyContainer}>
                  <div className={classes.warningContainer}>
                    <Typography variant="h4" className={classes.warningText}>
                      No {filterType !== "All" ? filterType.toLowerCase() : ""}{" "}
                      pools yet...
                    </Typography>
                  </div>
                </div>
              )}
              {filteredPools.length > 9 && (
                <div className={classes.paginationContainer}>
                  <Pagination
                    count={Math.ceil(filteredPools.length / 9)}
                    shape="rounded"
                    page={pageNumber + 1}
                    onChange={(ev, value) => {
                      setPageNumber(value - 1);
                    }}
                    renderItem={(item) => (
                      <PaginationItem
                        {...item}
                        classes={{
                          root: classes.paginationItemRoot,
                          rounded: classes.paginationItemRounded,
                          selected: classes.paginationItemSelected,
                        }}
                      />
                    )}
                    classes={{ ul: classes.paginationUl }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(Pools)));
