import STARTokenAbi from "./abis/startToken";
import SeedPresaleAbi from "./abis/seedPresale";
import PrivatePresaleAbi from "./abis/privatePresale";
import StrategicPresaleAbi from "./abis/strategicPresale";
import ERC20Abi from "./abis/erc20";
import factoryAbi from "./abis/factory";
import factoryV2Abi from "./abis/factoryV2";
import factoryV3Abi from "./abis/factoryV3";
import starterInfoAbi from "./abis/starterInfo";
import starterInfoV3Abi from "./abis/starterInfoV3";
import starterStakingAbi from "./abis/starterStaking";
import starterStakingV2Abi from "./abis/starterStakingV2";
import starterStakingV3Abi from "./abis/starterStakingV3";
import starterPresaleAbi from "./abis/starterPresale";
import starterPresaleV2Abi from "./abis/starterPresaleV2";
import starterPresaleV3Abi from "./abis/starterPresaleV3";
import vestingAbi from "./abis/vesting";
import wsbTokenAbi from "./abis/wsb";
import wsbPrivatePresaleAbi from "./abis/wsbPrivatePresale";
import wiseTokenAbi from "./abis/wise";
import wisePublicPresaleAbi from "./abis/wisePublicPresale";
import vestTokenAbi from "./abis/vest";
import vestPrivateSaleAbi from "./abis/vestPrivateSale";
import vestSeedSaleAbi from "./abis/vestSeed";
import gamewinTokenAbi from "./abis/gamewin";
import gamewinSeedSaleAbi from "./abis/gamewinSeedSale";
import kxaTokenAbi from "./abis/kxa";
import kryxiviaPrivateSaleAbi from "./abis/kryxiviaPrivateSale";
import farmingAbi from "./abis/farming";
import farmingPairAbi from "./abis/farmingPair";
import swapRouterAbi from "./abis/swapRouter";

const config = {
  STARTokenAddress: "0x31d0a7ada4d4c131eb612db48861211f63e57610",
  STARTokenAbi,
  SeedPresaleAddress: "0x2019459B04E37436E7EdC3D5d6346453d08606Fe",
  SeedPresaleAbi,
  PrivatePresaleAddress: "0x3b447f280D03761De72AE1d1640C4E3D4e6D0909",
  PrivatePresaleAbi,
  StrategicPresaleAddress: "0x58DAB443fc4E7977dD09EA4cD83944a60f17C283",
  StrategicPresaleAbi,
  ERC20Abi,
  factoryAbi,
  factoryAddress: "0x34Cc2A333d4A6086726B3d84220eA15d78Ca1962",
  factoryV2Abi,
  factoryV2Address: "0x43a953884f7C2bB63f74F921b266f5174195afe3",
  factoryV3Abi,
  // factoryV3Address: "0xC4Bc9098C6a5ec1Ed12C3C8beB201ff7Cd271290",
  factoryV3Address: "0xaEba4Eb71Da16C1bA4b03BaDAF7FfC7B77d1dc6B",

  starterInfoAddress: "0x135C2C8956Af96c41Ac6BF7ae76e2BCcD526FDBe",
  starterInfoAbi,

  // starterInfoV3Address: "0x9AB4268495934F3C64896E817d137F969023296f",
  starterInfoV3Address: "0xfCe0F36c7d2a8719c30C5f1CB71a011d8b1F3439",
  starterInfoV3Abi,

  starterStaking: "0x808AcEb7aDC3D64f912E88B061e89EeA2540b979",
  starterStakingAbi,
  starterStakingV2Abi,
  starterStakingV2: "0x1B1dBF35039999f3748f2c983A6081f58ACB21e7",

  starterStakingV3: "0x110A802a550d9465f9De234845942DeABBb22524",
  starterStakingV3Abi,
  starterPresaleAbi,
  starterPresaleV2Abi,
  starterPresaleV3Abi,
  vestingAddress: "0x3C24256B2cE30c88c29cc57e273958038B62222a",
  vestingAbi,

  wsbToken: "0x22168882276e5D5e1da694343b41DD7726eeb288",
  wsbTokenAbi,
  wsbPrivatePresaleAddress: "0x84E736a4cCC7FB032d671B2877d6210E3B64F105",
  wsbPrivatePresaleAbi,

  wiseToken: "0x0000000000000000000000000000000000000000",
  wiseTokenAbi,
  wisePublicPresaleAddress: "0xbB064642C34C3FDE27fba69940B0de5FE5445609",
  wisePublicPresaleAbi,

  gamewinToken: "0x7a02088900a9b3aa186bf41cb431216d1e2ace9f",
  gamewinTokenAbi,
  gamewinSeedSaleAddress: "0x8F1185DB9d5550EE3e76055ab4e71906bcea083F",
  gamewinSeedSaleAbi,

  kxaToken: "0x2223bF1D7c19EF7C06DAB88938EC7B85952cCd89",
  kxaTokenAbi,
  kryxiviaPrivateSale1Address: "0x4cfd44246d0fb278Ba8aB7d017a013B8bD00bD36",
  kryxiviaPrivateSale2Address: "0xbEc99831baC0C87d8b2C2671c7B3c831E31925Ab",
  kryxiviaPrivateSaleAbi,

  asvaTokenAddress: '0x31d0a7ada4d4c131eb612db48861211f63e57610',
  asvaTokenAbi: ERC20Abi,
  metalaunchStarterCapitalAddress: "0x9236969E3630dA2145d86C358825d71C99E6aEE2",
  metalaunchStarterCapitalAbi: kryxiviaPrivateSaleAbi,
  metalaunchSuperVipAddress: "0x40b9060F79F5B1F0bd9D0B376D0dfE2418F0f246",
  metalaunchSuperVipAbi: kryxiviaPrivateSaleAbi,
  metalaunchTeamAddress: "0x3849eE016aa1978b039439B613dDcC97E2B40A05",
  metalaunchTeamAbi: kryxiviaPrivateSaleAbi,

  vestToken: "0x22f6c963e3da212e050da4852c472988dab52f31",
  vestTokenAbi,
  vestPrivateSale: "0x79E9042C67E1672869D0D745080A239c8FED8c38",
  vestPrivateSaleAbi,
  vestSeedSale: "0xF9A761254481796F6B8b0DE711A5029215775f93",
  vestSeedSaleAbi,

  starterVestingAddress: "0x0e7b582003de0E541548cF02a1F00725Df6E6E6f",
  farmingAddress: "0x38E85adcaBdFe9f07AD155147EB5F55582011FD0",
  farmingAbi,
  farmingPairAddress: "0x67b78318db88eeb6c9271816114d3ca6423fd66d",
  farmingPairAbi,
  wbnbAddress: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  stakingToken: "0x31d0a7ada4d4c131eb612db48861211f63e57610",
  stakingTokenName: "START",
  stakingContracts: [
    "0x110A802a550d9465f9De234845942DeABBb22524",
    "0x3b447f280D03761De72AE1d1640C4E3D4e6D0909",
    "0x2019459B04E37436E7EdC3D5d6346453d08606Fe",
    "0x58DAB443fc4E7977dD09EA4cD83944a60f17C283",
  ],
  swapSymbol: "BNB",
  swapToken: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
  swapTokenAbi: ERC20Abi,
  swapRouter: "0x05ff2b0db69458a0750badebc4f9e13add608c7f",
  swapRouterAbi,
};

export default config;
