import bscConfig from "./bsc/production.config";
import polygonConfig from "./polygon/production.config";
import ethereumConfig from "./ethereum/production.config";
import fantomConfig from "./fantom/production.config";
import avalancheConfig from "./avalanche/production.config";
import pulseConfig from "./pulse/production.config";

const config = {
  bsc: bscConfig,
  mtc: polygonConfig,
  eth: ethereumConfig,
  ftm: fantomConfig,
  sol: polygonConfig,
  avax: avalancheConfig,
  pulse: pulseConfig
};

export default config;
