const strings = [
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_saleTitle",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "_linkTelegram",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "_linkGithub",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "_linkTwitter",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "_linkWebsite",
        type: "bytes32",
      },
      {
        internalType: "string",
        name: "_linkLogo",
        type: "string",
      },
      {
        internalType: "string",
        name: "_kycInformation",
        type: "string",
      },
      {
        internalType: "string",
        name: "_description",
        type: "string",
      },
      {
        internalType: "string",
        name: "_whitepaper",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_categoryId",
        type: "uint256",
      },
    ],
    name: "setStringInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const generals = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_presaleCreator",
        type: "address",
      },
      {
        internalType: "address",
        name: "_tokenAddress",
        type: "address",
      },
      {
        internalType: "uint8",
        name: "_tokenDecimals",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "_unsoldTokensDumpAddress",
        type: "address",
      },
      {
        internalType: "address payable",
        name: "_buyBackBurnAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_fundingTokenAddress",
        type: "address",
      },
    ],
    name: "setAddressInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_totalTokens",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_tokenPriceInWei",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_hardCapInWei",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_softCapInWei",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_maxInvestInWei",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_minInvestInWei",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_openTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_closeTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_presaleType",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_guaranteedHours",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_releasePerCycle",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_releaseCycle",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "_onlyWhitelistedAddressesAllowed",
        type: "bool",
      },
    ],
    name: "setGeneralInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "allowClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "disableClaim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_lpToken",
        type: "address",
      },
      {
        internalType: "address",
        name: "_starterLpToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_starterDevFeePercentage",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_starterMinDevFeeInWei",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_starterId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "_starterStakingPool",
        type: "address",
      },
    ],
    name: "setStarterInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "cancelAndTransferTokensToPresaleCreator",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "_whitelistedAddresses",
        type: "address[]",
      },
    ],
    name: "addWhitelistedAddresses",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "sendUnsoldTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "collectFundsRaised",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const audit = [
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "_auditor",
        type: "bytes32",
      },
      {
        internalType: "bool",
        name: "_isVerified",
        type: "bool",
      },
      {
        internalType: "bool",
        name: "_isWarning",
        type: "bool",
      },
      {
        internalType: "string",
        name: "_linkAudit",
        type: "string",
      },
    ],
    name: "setAuditorInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const pancake = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_cakeListingPriceInWei",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_cakeLiquidityAddingTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_cakeLPTokensLockDurationInDays",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_cakeLiquidityPercentageAllocation",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_swapIndex",
        type: "uint256",
      },
    ],
    name: "setPancakeSwapInfo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "addLiquidityAndLockLPTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export { generals, audit, pancake, strings };
