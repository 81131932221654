import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Typography,
  Button,
  Fade,
  Snackbar,
  useTheme,
  CircularProgress,
} from "@material-ui/core";

import { withNamespaces } from "react-i18next";

import AdvisoryBoard from "./components/advisoryBoard";

import Store from "../../stores/store";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "1140px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme?.colors?.presale?.loadingBackground,
    zIndex: 4,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: theme?.colors?.presale?.loadingIcon,
    position: "absolute",
    top: "100px",
    left: "50%",
  },
  miniCardContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.up("ms")]: {
      flexDirection: "row",
    },
  },
  blankCard: {
    width: "360px",
  },
  pageTitle: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
    color: theme?.colors?.voting?.heading,
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "bold",
    lineHeight: "36px",
    marginLeft: "10px",
    marginBottom: "40px",
  },
});

const advisors = [
  {
    name: "Warren Whitlock",
    logo: require("../../assets/warren@3x.png"),
    link: "https://tinyurl.com/startad5",
    position: "Founder & CEO of Stirling Corp",
    profile: "Digital marketing & blockchain strategic advisor",
  },
  {
    name: "Lionel Iruk",
    logo: require("../../assets/lionel@3x.png"),
    link: "https://tinyurl.com/startad3",
    position: "Managing Partner of Empire Legal PLLC",
    profile: "Special counsel to Roger Ver and Bitcoin.com",
  },
  {
    name: "Joel Comm",
    logo: require("../../assets/joel@3x.png"),
    link: "https://tinyurl.com/startad6",
    position: "Bad Crypto Podcast",
    profile: "Co-host of World’s Top Cryptocurrency Podcast",
  },
  {
    name: "Travis Wright",
    logo: require("../../assets/travis@3x.png"),
    link: "https://tinyurl.com/startad1",
    position: "Bad Crypto Podcast",
    profile: "Co-host of World’s Top Cryptocurrency Podcast",
  },
  {
    name: "Michael Terpin",
    logo: require("../../assets/michael@3x.png"),
    link: "https://tinyurl.com/startad2",
    position: "Founder & CEO of Transform Group",
    profile: "#1 blockchain public relations firm worldwide",
  },
];

const AdvisorPage = ({ classes, location }) => {
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          <Typography className={classes.pageTitle}>Advisory Board</Typography>
          <AdvisoryBoard advisors={advisors} />
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(AdvisorPage)));
