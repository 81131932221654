import React, { useEffect, useState } from "react";

const PresaleLogo = ({ className, src, alt, defaultImage, ...props }) => {
  const [logoImage, setLogoImage] = useState(src);
  useEffect(() => {
    setLogoImage(src);
  }, [src]);

  return (
    <img
      className={className}
      src={logoImage}
      alt={alt}
      onError={(er) => {
        setLogoImage(defaultImage);
      }}
      {...props}
    />
  );
};

export default PresaleLogo;
