import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button, Tooltip } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import { toFixed } from "../../../constants/constants";
import bigInt from "big-integer";
import { tokens } from "../../../constants";

const styles = (theme) => ({
  poolInvestCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  poolInformationContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },
  poolTokenInfo: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },
  lockLiqContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  poolTokenInfoItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "0px",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      alignItems: "flex-start",
      marginBottom: "0px",
      marginRight: "40px",
    },
  },
  infoHeading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "4px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8px",
    },
  },
  infoValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "20px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "25px",
    },
  },
  actionButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.presale?.buttonBg,
    border: "solid 2px" + theme?.colors?.presale?.buttonBg,
    color: theme?.colors?.presale?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "170px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.presale?.buttonBgHover,
    },
    "&:disabled": {
      border: "solid 2px" + theme?.colors?.presale?.buttonBgDisabled,
      backgroundColor: theme?.colors?.presale?.buttonBgDisabled,
      cursor: "not-allowed",
    },
    fontFamily: "SFPro",
    letterSpacing: "0px",
    marginTop: "15px",
    [theme.breakpoints.up("md")]: {
      marginTop: "25px",
    },
  },
  buttonLabel: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  tokenTooltip: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
});

const InvestCard = ({
  classes,
  poolInfo,
  onClaim,
  onVote,
  onRefund,
  onBuy,
  onLockLiq,
  account,
}) => {
  const theme = useTheme();
  const currentTimestamp = Date.now() / 1000;
  const [fundingToken, setFundingToken] = useState(
    tokens[poolInfo?.fundingTokenAddress]
  );
  const [voteAvailable, setVoteAvailable] = useState(
    parseFloat(toFixed(bigInt(poolInfo?.voteAvailable), 18, 3))
  );
  const [votedBalance, setVotedBalance] = useState(
    parseFloat(toFixed(bigInt(poolInfo?.votedBalance), 18, 3))
  );

  const [voteDisabled, setVoteDisabled] = useState(
    voteAvailable === 0 ||
      votedBalance > 0 ||
      poolInfo?.openTime <= currentTimestamp
  );

  useEffect(() => {
    setFundingToken(tokens[poolInfo?.fundingTokenAddress]);
    setVoteAvailable(
      parseFloat(toFixed(bigInt(poolInfo?.voteAvailable), 18, 3))
    );
    setVotedBalance(parseFloat(toFixed(bigInt(poolInfo?.votedBalance), 18, 3)));
    setVoteDisabled(
      voteAvailable === 0 ||
        votedBalance > 0 ||
        poolInfo?.openTime <= currentTimestamp
    );
  }, [poolInfo, account]);

  const tokenInvestPart = (
    heading,
    value,
    buttonText,
    clickHandler,
    disabled = false,
    tooltip = false,
    tooltipText = null
  ) => {
    return (
      <div className={classes.poolTokenInfoItem}>
        <Typography variant="h5" className={classes.infoHeading}>
          {heading}
        </Typography>
        <Typography variant="h2" className={classes.infoValue}>
          {value}
        </Typography>
        {tooltip && disabled && (
          <Tooltip title={tooltipText}>
            <span>
              <Button
                classes={{
                  root: classes.actionButton,
                  label: classes.buttonLabel,
                }}
                onClick={() => {
                  if (clickHandler) {
                    clickHandler();
                  }
                }}
                disabled={disabled}
              >
                {buttonText}
              </Button>
            </span>
          </Tooltip>
        )}
        {tooltip && !disabled && (
          <Tooltip title={tooltipText}>
            <Button
              classes={{
                root: classes.actionButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                if (clickHandler) {
                  clickHandler();
                }
              }}
              disabled={disabled}
            >
              {buttonText}
            </Button>
          </Tooltip>
        )}
        {!tooltip && (
          <Button
            classes={{
              root: classes.actionButton,
              label: classes.buttonLabel,
            }}
            onClick={() => {
              if (clickHandler) {
                clickHandler();
              }
            }}
            disabled={disabled}
          >
            {buttonText}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={classes.poolInvestCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        Your Investment
      </Typography>
      {account && account.address && (
        <div className={classes.poolInformationContainer}>
          <div className={classes.poolTokenInfo}>
            {poolInfo?.presaleType === 1 &&
              tokenInvestPart(
                "VOTE",
                voteAvailable.toFixed(3) +
                  " " +
                  (poolInfo?.stakingTokenName || "START"),
                "VOTE",
                onVote,
                voteDisabled
              )}
            {tokenInvestPart(
              "Your Tokens",
              parseFloat(poolInfo?.tokenAmount || 0).toFixed(4),
              "Claim Tokens",
              onClaim,
              parseFloat(poolInfo?.tokenAmount || 0) === 0
            )}
            {tokenInvestPart(
              `Your ${fundingToken?.label} Investment`,
              (toFixed(
                bigInt(poolInfo?.accountInvestment),
                poolInfo?.fundingTokenDecimals || 18,
                4
              ) || 0) +
                " " +
                fundingToken?.label,
              "Get Refund",
              onRefund,
              !poolInfo?.presaleCancelled
            )}
            {tokenInvestPart(
              "Buy Tokens",
              "1 Token = " +
                (toFixed(
                  bigInt(poolInfo?.tokenPriceInWei),
                  poolInfo?.fundingTokenDecimals || 18,
                  poolInfo?.fundingTokenDecimals == 18 ? 12 : 5
                ) || 0) +
                " " +
                fundingToken?.label,
              "Buy",
              onBuy,
              poolInfo?.openTime - 3600 > currentTimestamp,
              poolInfo?.openTime - 3600 > currentTimestamp,
              poolInfo?.openTime - 3600 > currentTimestamp
                ? "Presale is not opened yet."
                : null
            )}
          </div>
        </div>
      )}
      {(!account || !account.address) && (
        <div className={classes.warningContainer}>
          <Typography variant="h4" className={classes.warningText}>
            Please connect your wallet and set network to{" "}
            {theme?.chain || "Binance Smart Chain"}.
          </Typography>
        </div>
      )}
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(InvestCard)));
