import config from "../configure/config";
import async from "async";
import bigInt from "big-integer";
import {
  CLAIM,
  CLAIM_RETURNED,
  CLAIM_HODLER,
  CLAIM_HODLER_RETURNED,
  PUBLIC_CLAIM,
  CONFIGURE,
  CONFIGURE_RETURNED,
  ERROR,
  GET_BALANCES,
  GET_BALANCES_RETURNED,
  GET_PRESALE_INFO,
  GET_PRESALE_INFO_RETURNED,
  GET_PUBLIC_PRESALE_INFO,
  GET_PUBLIC_PRESALE_INFO_RETURNED,
  STAKE,
  STAKE_V1,
  STAKE_RETURNED,
  UNSTAKE,
  UNSTAKE_RETURNED,
  UPDATE_REWARDS,
  INVEST,
  INVEST_RETURNED,
  PUBLIC_INVEST,
  GET_POOLS_SUMMARY,
  GET_POOLS_SUMMARY_RETURNED,
  APPLY,
  APPLY_RETURNED,
  LOCK_LIQUIDITY,
  LOCK_LIQUIDITY_RETURNED,
  VOTE,
  VOTE_RETURNED,
  GET_START_STATS,
  GET_START_STATS_RETURNED,
  COLLECT_FUND,
  COLLECT_FUND_RETURNED,
  CANCEL_PRESALE,
  CANCEL_PRESALE_RETURNED,
  SEND_UNSOLD_TOKENS,
  SEND_UNSOLD_TOKENS_RETURNED,
  MOVE_STAKE,
  MOVE_STAKE_RETURNED,
  WRITE,
  CUSTOM_WRITE,
  WRITE_RETURNED,
  THEME_CHANGE,
  THEME_CHANGE_RETURNED,
  toFixed,
  sleep,
  UNSTAKE_V1,
  GET_FARMING_INFO,
  GET_FARMING_INFO_RETURNED,
  FARMING_STAKE,
  FARMING_STAKE_RETURNED,
  FARMING_WITHDRAW,
  FARMING_WITHDRAW_RETURNED,
  FARMING_CLAIM,
  FARMING_CLAIM_RETURNED,
  ALLOW_CLAIM,
  ALLOW_CLAIM_RETURNED,
  GET_REFUND,
  GET_REFUND_RETURNED,
  SWAP,
  SWAP_RETURNED,
  universalGasPrice,
  defaultFundingAddress,
  isSameAddress,
  poolsSummaryUrl,
  startStatsUrl,
} from "../constants";

import {
  _getBnbPrice,
  _getSTARTPrice,
  _callClaimReward,
  _callClaimHodler,
  _getHodlerReward,
  _getStakingPool,
  _getFactoryPool,
  _callFarmClaim,
  _getBnbBalance,
  _getSTARTBalance,
  _getStakedBalance,
  _getSwapData,
  _callFarmWithdraw,
  _callFarmStake,
  _callApply,
  _checkApproval,
  _callVote,
  _callUnstake,
  _callUnstakeV1,
  _callMigrate,
  _callStake,
  _callLockLiquidity,
  _callGetRefund,
  _callInvest,
  _callAllowClaim,
  _callSendUnSoldTokens,
  _callCancelPresale,
  _callCollectFunds,
  _callWrite,
  _callSwap,
  _getPublicPoolSummary,
  _getPoolSummary,
  _getPresaleTypeByBscsId,
  _getPresaleAbiByBscsId,
  _getPresaleAbiByVersion,
  _getFarmingInfo,
  _getStartInfo,
} from "./private";

import Web3 from "web3";

import { injected, injectedtw, walletconnect, WalletLink } from "./connectors";

const rp = require("request-promise");

const Dispatcher = require("flux").Dispatcher;
const Emitter = require("events").EventEmitter;

const dispatcher = new Dispatcher();
const emitter = new Emitter();

const rpcUrls = {
  bsc: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
  ],
  eth: ["https://mainnet.infura.io/v3/1eb884084d77488cb2e87bd298bc7cb8"],
  mtc: [
    "https://polygon-rpc.com/",
    "https://rpc-mainnet.matic.network",
    "https://polygon-mainnet.infura.io/v3/fcff88c9730449d5b3404188deaa2e12",
  ],
  ftm: [
    "https://rpcapi.fantom.network",
    "https://apis.ankr.com/bbd46eb85b7e4564b4c2424a26a2555e/6fcf448e08d85dc2a0726dea7d38bc8d/fantom/full/main",
  ],
  sol: ["https://rpc-mainnet.matic.network"],
  avax: ["https://api.avax.network/ext/bc/C/rpc"],
  pulse: [
    "https://bsc-dataseed.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
  ],
};

const bscPresales = {
  seed: {
    public: true,
    token: "START",
    url: "/bsc/start-seed",
    tokenAddress: config["bsc"].STARTokenAddress,
    tokenAbi: config["bsc"].STARTokenAbi,
    presaleIndex: "seed",
    presaleAddress: config["bsc"].SeedPresaleAddress,
    presaleAbi: config["bsc"].SeedPresaleAbi,
    openTime: "1619410000",
    closeTime: "1620669600",
    linkLogo: "https://bscstarter.finance/logo_x200.png",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    saleTitle: "START Seed Sale",
    presaleVersion: "V1",
  },
  private: {
    public: true,
    token: "START",
    url: "/bsc/start-private",
    tokenAddress: config["bsc"].STARTokenAddress,
    tokenAbi: config["bsc"].STARTokenAbi,
    presaleIndex: "private",
    presaleAddress: config["bsc"].PrivatePresaleAddress,
    presaleAbi: config["bsc"].PrivatePresaleAbi,
    openTime: "1619410000",
    closeTime: "1620669600",
    linkLogo: "https://bscstarter.finance/logo_x200.png",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    saleTitle: "START Private Sale",
    presaleVersion: "V1",
  },
  strategic: {
    public: false,
    token: "START",
    url: "/bsc/start-strategic",
    tokenAddress: config["bsc"].STARTokenAddress,
    tokenAbi: config["bsc"].STARTokenAbi,
    presaleIndex: "strategic",
    presaleAddress: config["bsc"].StrategicPresaleAddress,
    presaleAbi: config["bsc"].StrategicPresaleAbi,
    openTime: "1619410000",
    closeTime: "1620669600",
    linkLogo: "https://bscstarter.finance/logo_x200.png",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    presaleVersion: "V1",
  },
  wsbprivate: {
    public: false,
    token: "WSB",
    url: "/bsc/wsbclaims",
    tokenAddress: config["bsc"].wsbToken,
    tokenAbi: config["bsc"].wsbTokenAbi,
    presaleIndex: "wsbprivate",
    presaleAddress: config["bsc"].wsbPrivatePresaleAddress,
    presaleAbi: config["bsc"].wsbPrivatePresaleAbi,
    openTime: 0,
    closeTime: 0,
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
  },
  wisepublic: {
    public: true,
    token: "WISE",
    url: "/bsc/wisepublic",
    presaleIndex: "wisepublic",
    tokenAddress: config["bsc"].wiseToken,
    tokenAbi: config["bsc"].wiseTokenAbi,
    presaleAddress: config["bsc"].wisePublicPresaleAddress,
    presaleAbi: config["bsc"].wisePublicPresaleAbi,
    openTime: "1619460000",
    closeTime: "1620669600",
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "WISE Liquidity Formation Event",
    linkLogo: "https://bscstarter.finance/wise.png",
    tokenPriceInWei: "700000000000000",
    linkTelegram: "WiseToken",
    linkWebsite: "https://wisetoken.net/bsc",
  },
  vestPrivateSale: {
    public: false,
    token: "VEST",
    url: "/bsc/vest-private",
    presaleIndex: "vestPrivateSale",
    tokenAddress: config["bsc"].vestToken,
    tokenAbi: config["bsc"].vestTokenAbi,
    presaleAddress: config["bsc"].vestPrivateSale,
    presaleAbi: config["bsc"].vestPrivateSaleAbi,
    openTime: "1621036800",
    closeTime: "1621900800",
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "VEST Private B",
    linkLogo: "https://bscstarter.finance/vest_blk_bg.png",
    linkTelegram: "bscstarter",
    linkWebsite: "https://bscstarter.finance",
  },
  vestSeedSale: {
    public: false,
    token: "VEST",
    url: "/bsc/vest-sd",
    presaleIndex: "vestSeedSale",
    tokenAddress: config["bsc"].vestToken,
    tokenAbi: config["bsc"].vestTokenAbi,
    presaleAddress: config["bsc"].vestSeedSale,
    presaleAbi: config["bsc"].vestSeedSaleAbi,
    openTime: "1621036800",
    closeTime: "1621900800",
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "VEST Private A",
    linkLogo: "https://bscstarter.finance/vest_blk_bg.png",
    linkTelegram: "bscstarter",
    linkWebsite: "https://bscstarter.finance",
  },
  gamewinSeedSale: {
    public: false,
    token: "GAMEWIN",
    url: "/bsc/gamewin-sd",
    presaleIndex: "gamewinSeedSale",
    tokenAddress: config["bsc"].gamewinToken,
    tokenAbi: config["bsc"].gamewinTokenAbi,
    presaleAddress: config["bsc"].gamewinSeedSaleAddress,
    presaleAbi: config["bsc"].gamewinSeedSaleAbi,
    openTime: "1629460800",
    closeTime: "1630411200",
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "GAMEWIN Seed Sale",
    linkLogo: "https://gamewin.finance/logo_x200.png",
    linkTelegram: "gamewin_finance_official",
    linkWebsite: "https://gamewin.finance/",
  },
  kryxiviaPrivateSale1: {
    public: false,
    token: "KXA",
    url: "/bsc/kryxivia-team",
    presaleIndex: "kryxiviaPrivateSale1",
    tokenAddress: config["bsc"].kxaToken,
    tokenAbi: config["bsc"].kxaTokenAbi,
    presaleAddress: config["bsc"].kryxiviaPrivateSale1Address,
    presaleAbi: config["bsc"].kryxiviaPrivateSaleAbi,
    openTime: "1638218368",
    closeTime: "1638736768",
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "Kryxivia Private Sale - 1",
    linkLogo: "https://starter.xyz/kryxivia-logo.jpeg",
    linkTelegram: "kryxivia",
    linkWebsite: "https://kryxivia.io/",
  },
  kryxiviaPrivateSale2: {
    public: false,
    token: "KXA",
    url: "/bsc/kryxivia-svip",
    presaleIndex: "kryxiviaPrivateSale2",
    tokenAddress: config["bsc"].kxaToken,
    tokenAbi: config["bsc"].kxaTokenAbi,
    presaleAddress: config["bsc"].kryxiviaPrivateSale2Address,
    presaleAbi: config["bsc"].kryxiviaPrivateSaleAbi,
    openTime: "1638218368",
    closeTime: "1638736768",
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "Kryxivia Private Sale - 2",
    linkLogo: "https://starter.xyz/kryxivia-logo.jpeg",
    linkTelegram: "kryxivia",
    linkWebsite: "https://kryxivia.io/",
  },
  metalaunchStarterCapital: {
    public: false,
    token: "ASVA",
    url: "/bsc/metalaunch-startercapital",
    presaleIndex: "metalaunchStarterCapital",
    tokenAddress: config["bsc"].asvaTokenAddress,
    tokenAbi: config["bsc"].asvaTokenAbi,
    presaleAddress: config["bsc"].metalaunchStarterCapitalAddress,
    presaleAbi: config["bsc"].metalaunchStarterCapitalAbi,
    openTime: 1639670400,
    closeTime: 1640102400,
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "Metalaunch Starter Capital",
    linkLogo:
      "https://pbs.twimg.com/profile_images/1456907300199800837/q40WsDzw_400x400.png",
    linkTelegram: "AsvaLabsOfficial",
    linkWebsite: "https://metalaunch.io",
  },
  metalaunchSuperVip: {
    public: false,
    token: "ASVA",
    url: "/bsc/metalaunch-supervip",
    presaleIndex: "metalaunchSuperVip",
    tokenAddress: config["bsc"].asvaTokenAddress,
    tokenAbi: config["bsc"].asvaTokenAbi,
    presaleAddress: config["bsc"].metalaunchSuperVipAddress,
    presaleAbi: config["bsc"].metalaunchSuperVipAbi,
    openTime: 1639670400,
    closeTime: 1640102400,
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "Metalaunch Super VIP",
    linkLogo:
      "https://pbs.twimg.com/profile_images/1456907300199800837/q40WsDzw_400x400.png",
    linkTelegram: "AsvaLabsOfficial",
    linkWebsite: "https://metalaunch.io",
  },
  metalaunchTeam: {
    public: false,
    token: "ASVA",
    url: "/bsc/metalaunch-team",
    presaleIndex: "metalaunchTeam",
    tokenAddress: config["bsc"].asvaTokenAddress,
    tokenAbi: config["bsc"].asvaTokenAbi,
    presaleAddress: config["bsc"].metalaunchTeamAddress,
    presaleAbi: config["bsc"].metalaunchTeamAbi,
    openTime: 1639670400,
    closeTime: 1640102400,
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "Metalaunch Team",
    linkLogo:
      "https://pbs.twimg.com/profile_images/1456907300199800837/q40WsDzw_400x400.png",
    linkTelegram: "AsvaLabsOfficial",
    linkWebsite: "https://metalaunch.io",
  },
  voiceStreetSuperVip: {
    public: false,
    token: "VST",
    url: "/bsc/voicestreet-sv",
    presaleIndex: "voiceStreetSuperVip",
    tokenAddress: config["bsc"].vstTokenAddress,
    tokenAbi: config["bsc"].vstTokenAbi,
    presaleAddress: config["bsc"].voiceStreetSuperVipAddress,
    presaleAbi: config["bsc"].voiceStreetSuperVipAbi,
    openTime: 1640880000,
    closeTime: 1641398400,
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "Voice Street Super VIP",
    linkLogo:
      "https://pbs.twimg.com/profile_images/1470993152240611330/F2AZqafQ_400x400.jpg",
    linkTelegram: "VoiceStreet",
    linkWebsite: "https://vsnft.org/#/",
  },
  voiceStreetSc: {
    public: false,
    token: "VST",
    url: "/bsc/voicestreet-sc",
    presaleIndex: "voiceStreetSc",
    tokenAddress: config["bsc"].vstTokenAddress,
    tokenAbi: config["bsc"].vstTokenAbi,
    presaleAddress: config["bsc"].voiceStreetScAddress,
    presaleAbi: config["bsc"].voiceStreetScAbi,
    openTime: 1640880000,
    closeTime: 1641398400,
    presaleVersion: "V2",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "Voice Street Starter Capital",
    linkLogo:
      "https://pbs.twimg.com/profile_images/1470993152240611330/F2AZqafQ_400x400.jpg",
    linkTelegram: "VoiceStreet",
    linkWebsite: "https://vsnft.org/#/",
  },
};

const mtcPresales = {
  starchiSeedSale: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-sd",
    presaleIndex: "starchiSeedSale",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiSeedAddress,
    presaleAbi: config["mtc"].starchiSeedAbi,
    openTime: "1630929600",
    closeTime: "1632225600",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "Starchi Seed Sale",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starchiRdSale: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-rd",
    presaleIndex: "starchiRdSale",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiRdAddress,
    presaleAbi: config["mtc"].starchiRdAbi,
    openTime: "1630929600",
    closeTime: "1632225600",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "500000000000000000",
    saleTitle: "Starchi Rd Sale",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starchiPrivateSale: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-private",
    presaleIndex: "starchiPrivateSale",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiPrivateAddress,
    presaleAbi: config["mtc"].starchiPrivateAbi,
    openTime: "1631836800",
    closeTime: "1632960000",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "5000000000",
    saleTitle: "Starchi Private Sale",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starchiVipPrivate: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-vip",
    presaleIndex: "starchiVipPrivate",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiVipPrivateAddress,
    presaleAbi: config["mtc"].starchiVipPrivateAbi,
    openTime: "1633910400",
    closeTime: "1635638400",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "5000000000",
    saleTitle: "Starchi VIP Private Sale",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starchiVipMaven: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-maven",
    presaleIndex: "starchiVipMaven",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiVipMavenAddress,
    presaleAbi: config["mtc"].starchiVipMavenAbi,
    openTime: "1633910400",
    closeTime: "1635638400",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "5000000000",
    saleTitle: "Starchi Private - Maven",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starchiVipVbc: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-vbc",
    presaleIndex: "starchiVipVbc",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiVipVbcAddress,
    presaleAbi: config["mtc"].starchiVipVbcAbi,
    openTime: "1633910400",
    closeTime: "1635638400",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "5000000000",
    saleTitle: "Starchi Private - Vbc",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starchiPrivateCryptozen: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-cryptozen",
    presaleIndex: "starchiPrivateCryptozen",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiPrivateCryptozenAddress,
    presaleAbi: config["mtc"].starchiPrivateCryptozenAbi,
    openTime: "1637280722",
    closeTime: "1638231122",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "5000000000",
    saleTitle: "Starchi Private - CryptoZen",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starchiPrivateEmpire: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-empire",
    presaleIndex: "starchiPrivateEmpire",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiPrivateEmpireAddress,
    presaleAbi: config["mtc"].starchiPrivateEmpireAbi,
    openTime: "1637280722",
    closeTime: "1638231122",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "5000000000",
    saleTitle: "Starchi Private - Empire",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starchiPrivateDarkpool: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-darkpool",
    presaleIndex: "starchiPrivateDarkpool",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiPrivateDarkpoolAddress,
    presaleAbi: config["mtc"].starchiPrivateDarkpoolAbi,
    openTime: "1637280722",
    closeTime: "1638231122",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "5000000000",
    saleTitle: "Starchi Private - Darkpool",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starchiTeamSale: {
    public: false,
    token: "ELIXIR",
    url: "/mtc/starchi-team",
    presaleIndex: "starchiTeamSale",
    tokenAddress: config["mtc"].starchiToken,
    tokenAbi: config["mtc"].starchiTokenAbi,
    presaleAddress: config["mtc"].starchiTeamSaleAddress,
    presaleAbi: config["mtc"].starchiTeamSaleAbi,
    openTime: "1637280722",
    closeTime: "1638231122",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "5000000000",
    saleTitle: "Starchi Team Pool",
    linkLogo: "https://starchi.gg/static/media/starchi_smaller_I.0c062a15.png",
    linkTelegram: "OfficialStarchi",
    linkWebsite: "https://starchi.gg/",
  },
  starzTeam: {
    public: false,
    token: "STZ",
    url: "/mtc/starz-team",
    presaleIndex: "starzTeam",
    tokenAddress: config["mtc"].starzToken,
    tokenAbi: config["mtc"].starzTokenAbi,
    presaleAddress: config["mtc"].starzTeamAddress,
    presaleAbi: config["mtc"].starzSaleAbi,
    openTime: "1638979200",
    closeTime: "1639152000",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "250000000",
    saleTitle: "Starz Team Sale",
    linkLogo: "http://99starz.io/wp-content/uploads/2021/06/logo.svg",
    linkTelegram: "99starz",
    linkWebsite: "http://99starz.io/",
  },
  starzSuperVip: {
    public: false,
    token: "STZ",
    url: "/mtc/starz-supervip",
    presaleIndex: "starzSuperVip",
    tokenAddress: config["mtc"].starzToken,
    tokenAbi: config["mtc"].starzTokenAbi,
    presaleAddress: config["mtc"].starzSuperVipAddress,
    presaleAbi: config["mtc"].starzSaleAbi,
    openTime: "1638979200",
    closeTime: "1639152000",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "250000000",
    saleTitle: "Starz Super VIP",
    linkLogo: "http://99starz.io/wp-content/uploads/2021/06/logo.svg",
    linkTelegram: "99starz",
    linkWebsite: "http://99starz.io/",
  },
  starzSc: {
    public: false,
    token: "STZ",
    url: "/mtc/starz-sc",
    presaleIndex: "starzSc",
    tokenAddress: config["mtc"].starzToken,
    tokenAbi: config["mtc"].starzTokenAbi,
    presaleAddress: config["mtc"].starzScAddress,
    presaleAbi: config["mtc"].starzSaleAbi,
    openTime: "1638979200",
    closeTime: "1639152000",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "250000000",
    saleTitle: "Starz SC",
    linkLogo: "http://99starz.io/wp-content/uploads/2021/06/logo.svg",
    linkTelegram: "99starz",
    linkWebsite: "http://99starz.io/",
  },
  betswirlSeed: {
    public: false,
    token: "BET",
    url: "/mtc/betswirl-seed",
    presaleIndex: "betswirlSeed",
    tokenAddress: config["mtc"].betToken,
    tokenAbi: config["mtc"].betTokenAbi,
    presaleAddress: config["mtc"].betswirlSeedAddress,
    presaleAbi: config["mtc"].betswirlAbi,
    openTime: "1638837770",
    closeTime: "1639269770",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "250000000",
    saleTitle: "Betswirl Seed Sale",
    linkLogo:
      "https://pbs.twimg.com/profile_images/1438744219464646656/XIhTXi1t_400x400.jpg",
    linkTelegram: "Betswirl",
    linkWebsite: "https://betswirl.com/",
  },
};

const avaxPresales = {
  metagamzSc: {
    public: false,
    token: "METAG",
    url: "/avax/mg-sc",
    presaleIndex: "metagamzSc",
    tokenAddress: config["avax"].metagamzTokenAddress,
    tokenAbi: config["avax"].metagamzTokenAbi,
    presaleAddress: config["avax"].metagamzScAddress,
    presaleAbi: config["avax"].metagamzScAbi,
    openTime: "1642723200",
    closeTime: "1643673600",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "250000000",
    saleTitle: "Metagamz - Starter Capital",
    linkLogo:
      "https://pbs.twimg.com/profile_images/1471762110120136707/FlrPQkBM_400x400.jpg",
    linkTelegram: "metagamz1",
    linkWebsite: "https://metagamz.io/",
  },
  metagamzSvip: {
    public: false,
    token: "METAG",
    url: "/avax/mg-svip",
    presaleIndex: "metagamzSvip",
    tokenAddress: config["avax"].metagamzTokenAddress,
    tokenAbi: config["avax"].metagamzTokenAbi,
    presaleAddress: config["avax"].metagamzSvipAddress,
    presaleAbi: config["avax"].metagamzSvipAbi,
    openTime: "1642723200",
    closeTime: "1643673600",
    presaleVersion: "V3",
    yesVotes: "1000000000000000000000000",
    noVotes: "0",
    minYesVotesThreshold: "100000000000000000000000",
    maxInvestInWei: "250000000",
    saleTitle: "Metagamz - Super VIP",
    linkLogo:
      "https://pbs.twimg.com/profile_images/1471762110120136707/FlrPQkBM_400x400.jpg",
    linkTelegram: "metagamz1",
    linkWebsite: "https://metagamz.io/",
  },
};

const staticPresales = {
  bsc: bscPresales,
  eth: {},
  mtc: mtcPresales,
  sol: {},
  ftm: {},
  avax: avaxPresales,
  pulse: bscPresales,
};

const getThemeFromURL = (_url) => {
  const url = _url.toLowerCase();
  if (url.includes("bsc")) return "bsc";
  if (url.includes("mtc")) return "mtc";
  if (url.includes("eth")) return "eth";
  if (url.includes("ftm")) return "ftm";
  if (url.includes("avax")) return "avax";
  if (url.includes("pulse")) return "pulse";

  return null;
};
class Store {
  constructor() {
    const chainIds = { bsc: 56, mtc: 137, ftm: 250, eth: 1, avax: 43114 };
    const themeType = getThemeFromURL(window.location.hash)
      ? getThemeFromURL(window.location.hash)
      : localStorage.getItem("THEME_TYPE") || "mtc";

    this.store = {
      themeType,
      currentBlock: 0,
      universalGasPrice: universalGasPrice[themeType],
      account: {},
      web3: null,
      connectorsByName: {
        MetaMask: injected,
        TrustWallet: injectedtw,
        WalletConnect: walletconnect(chainIds[themeType]),
        Coinbase: WalletLink(chainIds[themeType]),
        // Ledger: ledger,
        // Trezor: trezor,
        // Portis: portis,
        // Authereum: authereum,
      },
      web3context: null,
      languages: [
        {
          language: "English",
          code: "en",
        },
      ],

      tokenAddress: config[themeType].STARTokenAddress,
      tokenAbi: config[themeType].STARTokenAbi,

      StakingToken: config[themeType].stakingToken,
      StakingTokenAbi: config[themeType].ERC20Abi,
      StakingTokenName: config[themeType].stakingTokenName,
      StakedContractAbi: config[themeType].starterStakingAbi,
      StakedContractAddress: config[themeType].starterStaking,
      StakedContractV2Abi: config[themeType].starterStakingV2Abi,
      StakedContractV2Address: config[themeType].starterStakingV2,
      StakedContractV3Abi: config[themeType].starterStakingV3Abi,
      StakedContractV3Address: config[themeType].starterStakingV3,

      StarterInfoAbi: config[themeType].starterInfoV3Abi,
      StarterInfoAddress: config[themeType].starterInfoV3Address,

      starterVestingContract: config[themeType].starterVestingAddress,
      factory: {
        factoryAddress: config[themeType].factoryAddress,
        factoryAbi: config[themeType].factoryAbi,
        factoryV2Address: config[themeType].factoryV2Address,
        factoryV2Abi: config[themeType].factoryV2Abi,
        factoryV3Address: config[themeType].factoryV3Address,
        factoryV3Abi: config[themeType].factoryV3Abi,

        starterInfoAddress: config[themeType].starterInfoV3Address,
        starterInfoAbi: config[themeType].starterInfoV3Abi,

        presaleAbi: config[themeType].starterPresaleAbi,
        presaleV2Abi: config[themeType].starterPresaleV2Abi,
        presaleV3Abi: config[themeType].starterPresaleV3Abi,
      },
      publicPresales: {},
      presales: staticPresales[themeType],
      farming: {
        rewardAllocation: bigInt(),
        totalSupply: bigInt(),
        totalBurned: bigInt(),
        totalLpLocked: bigInt(),
        halvingTimestamp: 0,
        accountInfo: {},
        lpTotalSupply: bigInt(),
        lpBnbBalance: bigInt(),
        rewardEarned: bigInt(),
        withdrawalLimit: 25,
        abi: config[themeType].farmingAbi,
        address: config[themeType].farmingAddress,
        lpAbi: config[themeType].farmingPairAbi,
        lpAddress: config[themeType].farmingPairAddress,
        withdrawLimit: 25,
        withdrawCycle: 86400,
        claimFee: 4,
      },
      bnbPrice: 0,
      bnbBalance: bigInt(),
      startBalance: bigInt(),
      startPrice: 0,
      totalStakedLocked: bigInt(),
      stakingTokenBalance: bigInt(),
      stakedBalance: bigInt(),
      totalStakedBalance: bigInt(),
      v1StakedBalance: bigInt(),
      lastStakedTime: 0,
      lastUnstakedTime: 0,
      minStakeTime: 0,
      minUnstakeTime: 0,
      firstStakedTimestamp: 0,
      isLongStaker: false,
      burnFee: 50,

      bnbRewardBalance: bigInt(),
      factoryBalance: bigInt(),
      lockedBalance: bigInt(),
      stakedAndLockedBalance: bigInt(),
      startStats: {},
      stakingPool: {},
      factoryPool: {
        address: "0x00000000000000000",
        starterInfo: "0x00000000000000000",
        stakingPool: "0x00000000000000000",
        owner: "0x00000000000000000",
        pendingOwner: "0x00000000000000000",
      },
      swapData: {
        swapSymbol: config[themeType].swapSymbol,
        swapTokenAddress: config[themeType].swapToken,
        swapTokenAbi: config[themeType].swapTokenAbi,
        swapRouterAddress: config[themeType].swapRouter,
        swapRouterAbi: config[themeType].swapRouterAbi,
        swapTokenBalance: bigInt(),
        swapPrice: bigInt(),
      },
      rpcIndex: 0,
    };

    dispatcher.register(
      function (payload) {
        switch (payload.type) {
          case THEME_CHANGE:
            this.themeChange(payload);
            break;
          case CONFIGURE:
            this.configure(payload);
            break;
          case GET_BALANCES:
            this.getBalances(payload);
            break;
          case GET_FARMING_INFO:
            this.getFarmingBalances(payload);
            break;
          case STAKE:
            this.stake(payload);
            break;
          case UNSTAKE:
            this.unstake(payload);
            break;
          case STAKE_V1:
            this.stakeV1(payload);
            break;
          case UNSTAKE_V1:
            this.unstakeV1(payload);
            break;
          case CLAIM:
            this.claim(payload);
            break;
          case CLAIM_HODLER:
            this.claimHodler(payload);
            break;
          case PUBLIC_CLAIM:
            this.claim(payload, "public");
            break;
          case UPDATE_REWARDS:
            this.updateRewards(payload);
            break;
          case GET_PRESALE_INFO:
            this.getPresaleInfo(payload);
            break;
          case INVEST:
            this.invest(payload);
            break;
          case PUBLIC_INVEST:
            this.invest(payload, "public");
            break;
          case GET_POOLS_SUMMARY:
            this.getPoolsSummary();
            break;
          case APPLY:
            this.apply(payload);
            break;
          case GET_PUBLIC_PRESALE_INFO:
            this.getPublicPresaleInfo(payload);
            break;
          case LOCK_LIQUIDITY:
            this.lockLiquidity(payload);
            break;
          case VOTE:
            this.vote(payload);
            break;
          case GET_START_STATS:
            this.getStartStats(payload);
            break;
          case COLLECT_FUND:
            this.collectFunds(payload);
            break;
          case CANCEL_PRESALE:
            this.cancelPresale(payload);
            break;
          case SEND_UNSOLD_TOKENS:
            this.sendUnsoldTokens(payload);
            break;
          case ALLOW_CLAIM:
            this.allowClaim(payload);
            break;
          case MOVE_STAKE:
            this.moveStake(payload);
            break;
          case WRITE:
            this.write(payload);
            break;
          case CUSTOM_WRITE:
            this.customWrite(payload);
            break;
          case FARMING_STAKE:
            this.farmStake(payload);
            break;
          case FARMING_WITHDRAW:
            this.farmWithdraw(payload);
            break;
          case FARMING_CLAIM:
            this.farmClaim(payload);
            break;
          case GET_REFUND:
            this.getRefund(payload);
            break;
          case SWAP:
            this.swap(payload);
            break;
          default: {
          }
        }
      }.bind(this)
    );
  }

  getStore(index) {
    return this.store[index];
  }

  setStore(obj) {
    this.store = {
      ...this.store,
      ...obj,
    };
    return emitter.emit("StoreUpdated");
  }

  initializeStore = () => {
    const themeType = store.getStore("themeType");
    const chainIds = {
      bsc: 56,
      mtc: 137,
      ftm: 250,
      eth: 1,
      avax: 43114,
      pulse: 56,
    };
    this.store = {
      ...this.store,
      connectorsByName: {
        MetaMask: injected,
        TrustWallet: injectedtw,
        WalletConnect: walletconnect(chainIds[themeType]),
        Coinbase: WalletLink(chainIds[themeType]),
      },
      currentBlock: 0,
      universalGasPrice: universalGasPrice[themeType],
      tokenAddress: config[themeType].STARTokenAddress,
      tokenAbi: config[themeType].STARTokenAbi,

      StakingToken: config[themeType].stakingToken,
      StakingTokenAbi: config[themeType].ERC20Abi,
      StakingTokenName: config[themeType].stakingTokenName,
      StakedContractAbi: config[themeType].starterStakingAbi,
      StakedContractAddress: config[themeType].starterStaking,
      StakedContractV2Abi: config[themeType].starterStakingV2Abi,
      StakedContractV2Address: config[themeType].starterStakingV2,
      StakedContractV3Abi: config[themeType].starterStakingV3Abi,
      StakedContractV3Address: config[themeType].starterStakingV3,

      StarterInfoAbi: config[themeType].starterInfoV3Abi,
      StarterInfoAddress: config[themeType].starterInfoV3Address,

      starterVestingContract: config[themeType].starterVestingAddress,
      factory: {
        factoryAddress: config[themeType].factoryAddress,
        factoryAbi: config[themeType].factoryAbi,
        factoryV2Address: config[themeType].factoryV2Address,
        factoryV2Abi: config[themeType].factoryV2Abi,
        factoryV3Address: config[themeType].factoryV3Address,
        factoryV3Abi: config[themeType].factoryV3Abi,

        starterInfoAddress: config[themeType].starterInfoV3Address,
        starterInfoAbi: config[themeType].starterInfoV3Abi,

        presaleAbi: config[themeType].starterPresaleAbi,
        presaleV2Abi: config[themeType].starterPresaleV2Abi,
        presaleV3Abi: config[themeType].starterPresaleV3Abi,
      },
      publicPresales: {},
      presales: staticPresales[themeType],
      farming: {
        rewardAllocation: bigInt(),
        totalSupply: bigInt(),
        totalBurned: bigInt(),
        totalLpLocked: bigInt(),
        halvingTimestamp: 0,
        accountInfo: {},
        lpTotalSupply: bigInt(),
        lpBnbBalance: bigInt(),
        rewardEarned: bigInt(),
        withdrawalLimit: 25,
        abi: config[themeType].farmingAbi,
        address: config[themeType].farmingAddress,
        lpAbi: config[themeType].farmingPairAbi,
        lpAddress: config[themeType].farmingPairAddress,
        withdrawLimit: 25,
        withdrawCycle: 86400,
        claimFee: 4,
      },
      bnbPrice: 0,
      bnbBalance: bigInt(),
      startBalance: bigInt(),
      startPrice: 0,
      totalStakedLocked: bigInt(),
      stakingTokenBalance: bigInt(),
      stakedBalance: bigInt(),
      totalStakedBalance: bigInt(),
      v1StakedBalance: bigInt(),
      lastStakedTime: 0,
      lastUnstakedTime: 0,
      minStakeTime: 0,
      minUnstakeTime: 0,
      firstStakedTimestamp: 0,
      isLongStaker: false,
      burnFee: 50,

      bnbRewardBalance: bigInt(),
      factoryBalance: bigInt(),
      lockedBalance: bigInt(),
      stakedAndLockedBalance: bigInt(),
      startStats: {},
      stakingPool: {},
      factoryPool: {
        address: "0x00000000000000000",
        starterInfo: "0x00000000000000000",
        stakingPool: "0x00000000000000000",
        owner: "0x00000000000000000",
        pendingOwner: "0x00000000000000000",
      },
      swapData: {
        swapSymbol: config[themeType].swapSymbol,
        swapTokenAddress: config[themeType].swapToken,
        swapTokenAbi: config[themeType].swapTokenAbi,
        swapRouterAddress: config[themeType].swapRouter,
        swapRouterAbi: config[themeType].swapRouterAbi,
        swapTokenBalance: bigInt(),
        swapPrice: bigInt(),
      },
      rpcIndex: 0,
    };
  };

  themeChange = ({ content: { themeType } }) => {
    localStorage.setItem("THEME_TYPE", themeType);
    const orgThemeType = store.getStore("themeType");
    store.setStore({ themeType });
    if (orgThemeType !== themeType) {
      store.initializeStore();
      store.getPoolsSummary();
      store.getStartStats();
      return emitter.emit(THEME_CHANGE_RETURNED);
    }
  };

  configure = async () => {
    const web3 = new Web3(store.getStore("web3context").library.provider);
    const currentBlock = await web3.eth.getBlockNumber();

    store.setStore({
      currentBlock: currentBlock,
    });

    window.setTimeout(() => {
      emitter.emit(CONFIGURE_RETURNED);
    }, 100);
  };

  getRpcUrl = async () => {
    const themeType = store.getStore("themeType");
    let rpcIndex = 0;
    while (1) {
      for (rpcIndex = 0; rpcIndex < rpcUrls[themeType].length; rpcIndex++) {
        try {
          const web3 = new Web3(rpcUrls[themeType][rpcIndex]);
          const currentBlock = await web3.eth.getBlockNumber();
          store.setStore({
            rpcIndex,
          });
          return rpcUrls[themeType][rpcIndex];
        } catch (error) {
          await sleep(1000);
        }
      }
      await sleep(10000);
    }
  };

  getBalances = async () => {
    try {
      const themeType = store.getStore("themeType");
      const account = store.getStore("account");
      const rpcUrl = await this.getRpcUrl();
      const web3 = new Web3(rpcUrl);

      const currentBlock = await web3.eth.getBlockNumber();
      store.setStore({
        currentBlock: currentBlock,
      });

      async.parallel(
        [
          (callbackInnerInner) => {
            _getBnbPrice(themeType, callbackInnerInner);
          },
          (callbackInnerInner) => {
            _getBnbBalance(
              store,
              dispatcher,
              emitter,
              web3,
              account,
              callbackInnerInner
            );
          },
          (callbackInnerInner) => {
            _getSTARTBalance(
              store,
              dispatcher,
              emitter,
              web3,
              account,
              callbackInnerInner
            );
          },
          (callbackInnerInner) => {
            _getStakedBalance(
              store,
              dispatcher,
              emitter,
              web3,
              account,
              callbackInnerInner
            );
          },
          (callbackInnerInner) => {
            _getHodlerReward(
              store,
              dispatcher,
              emitter,
              web3,
              account,
              callbackInnerInner
            );
          },
          (callbackInnerInner) => {
            _getSTARTPrice(callbackInnerInner);
          },
          (callbackInnerInner) => {
            _getSwapData(
              store,
              dispatcher,
              emitter,
              web3,
              account,
              callbackInnerInner
            );
          },
        ],
        (err, data) => {
          if (err) {
            console.log("GET_BALANCES error", err);
            return emitter.emit(ERROR, err);
          }
          store.setStore({
            bnbPrice: data[0],
            bnbBalance: data[1],
            startBalance: data[2].balance,
            totalStakedLocked: data[2].totalStakedLocked,
            stakingTokenBalance: data[2].stakingTokenBalance,
            stakedBalance: data[3].balance,
            totalStakedBalance: data[3].totalStakedBalance,
            v1StakedBalance: data[3].v1Balance,
            lastStakedTime: data[3].lastStakedTime,
            lastUnstakedTime: data[3].lastUnstakedTime,
            minStakeTime: data[3].minStakeTime,
            minUnstakeTime: data[3].minUnstakeTime,
            firstStakedTimestamp: data[3].firstStakedTimestamp,
            isLongStaker: data[3].isLongStaker,
            burnFee: data[3].burnFee,
            factoryBalance: data[4].factoryBalance,
            lockedBalance: data[4].lockedBalance,
            stakedAndLockedBalance: data[4].stakedAndLockedBalance,
            startPrice: data[5],
            swapData: data[6],
          });
          return emitter.emit(GET_BALANCES_RETURNED);
        }
      );
    } catch (error) {
      console.error("GET_BALANCES", error);
      return emitter.emit(ERROR, error);
    }
  };

  getStartStats = async () => {
    const themeType = store.getStore("themeType");
    try {
      const url = startStatsUrl[themeType];
      const jsonObject = await rp(url);
      const statsJson = JSON.parse(jsonObject);
      store.setStore({
        startStats: statsJson,
      });
      return emitter.emit(GET_START_STATS_RETURNED);
    } catch (error) {
      console.error("GET_START_STATS_RETURNED", error);
      return emitter.emit(ERROR, error);
    }
  };

  getPresaleInfo = async ({ content: { presaleIndex } }) => {
    try {
      const themeType = store.getStore("themeType");
      const account = store.getStore("account");
      // const account = {address: '0x30BcdE92242dc137BDA2569729a694B1D674F76a'};
      const rpcUrl = await this.getRpcUrl();
      const web3 = new Web3(rpcUrl);
      const presales = store.getStore("presales");
      const presale = presales[presaleIndex];
      const presaleContract = new web3.eth.Contract(
        presale.presaleAbi,
        presale.presaleAddress
      );
      const totalInvestorsCount = await presaleContract.methods
        .totalInvestorsCount()
        .call();
      const totalCollectedWei = await presaleContract.methods
        .totalCollectedWei()
        .call();
      const totalTokens = await presaleContract.methods.totalTokens().call();
      const tokensLeft = await presaleContract.methods.tokensLeft().call();
      const tokenPriceInWei = await presaleContract.methods
        .tokenPriceInWei()
        .call();
      const hardCapInWei = await presaleContract.methods.hardCapInWei().call();
      const softCapInWei = await presaleContract.methods.softCapInWei().call();
      const maxInvestInWei = await presaleContract.methods
        .maxInvestInWei()
        .call();
      const minInvestInWei = await presaleContract.methods
        .minInvestInWei()
        .call();
      const openTime = await presaleContract.methods.openTime().call();
      const closeTime = await presaleContract.methods.closeTime().call();
      const cakeLiquidityAddingTime = presaleContract.methods
        .cakeLiquidityAddingTime
        ? await presaleContract.methods.cakeLiquidityAddingTime().call()
        : 0;

      const saleTitle = web3.utils.hexToUtf8(
        await presaleContract.methods.saleTitle().call()
      );
      const linkTelegram = web3.utils.hexToUtf8(
        await presaleContract.methods.linkTelegram().call()
      );
      const linkTwitter = web3.utils.hexToUtf8(
        await presaleContract.methods.linkTwitter().call()
      );
      const linkGithub = web3.utils.hexToUtf8(
        await presaleContract.methods.linkGithub().call()
      );
      const linkWebsite = web3.utils.hexToUtf8(
        await presaleContract.methods.linkWebsite().call()
      );
      const linkLogo =
        presaleIndex !== "wsbprivate" &&
        presaleIndex !== "wisepublic" &&
        presaleIndex !== "vestSeedSale" &&
        presaleIndex !== "vestPrivateSale" &&
        presaleIndex !== "gamewinSeedSale" &&
        presaleIndex !== "starchiSeedSale" &&
        presaleIndex !== "starchiRdSale" &&
        presaleIndex !== "starchiPrivateSale" &&
        presaleIndex !== "starchiVipPrivate" &&
        presaleIndex !== "starchiVipMaven" &&
        presaleIndex !== "starchiVipVbc" &&
        presaleIndex !== "starchiPrivateCryptozen" &&
        presaleIndex !== "starchiPrivateEmpire" &&
        presaleIndex !== "starchiPrivateDarkpool" &&
        presaleIndex !== "starchiTeamSale" &&
        presaleIndex !== "kryxiviaPrivateSale1" &&
        presaleIndex !== "kryxiviaPrivateSale2" &&
        presaleIndex !== "starzTeam" &&
        presaleIndex !== "starzSuperVip" &&
        presaleIndex !== "starzSc" &&
        presaleIndex !== "betswirlSeed" &&
        presaleIndex !== "metalaunchStarterCapital" &&
        presaleIndex !== "metalaunchSuperVip" &&
        presaleIndex !== "metalaunchTeam" &&
        presaleIndex !== "voiceStreetSuperVip" &&
        presaleIndex !== "voiceStreetSc" &&
        presaleIndex !== "metagamzSc" &&
        presaleIndex !== "metagamzSvip"
          ? web3.utils.hexToUtf8(
              await presaleContract.methods.linkLogo().call()
            )
          : await presaleContract.methods.linkLogo().call();
      const auditedInfo = presaleContract.methods.auditInformation
        ? await presaleContract.methods.auditInformation().call()
        : {
            auditor: "0x0",
            isVerified: false,
            isWarning: false,
            linkAudit: "",
            verifiedHash: "",
            warningHash: "",
          };
      const kycInformation = presaleContract.methods.kycInformation
        ? await presaleContract.methods.kycInformation().call()
        : "";

      const claimAllowed = await presaleContract.methods.claimAllowed().call();
      const claimCycle = presaleContract.methods.claimCycle
        ? await presaleContract.methods.claimCycle().call()
        : 10000000000;

      const auditInformation = {
        auditor: web3.utils.hexToUtf8(auditedInfo.auditor),
        isVerified: auditedInfo.isVerified,
        isWarning: auditedInfo.isWarning,
        linkAudit: auditedInfo?.linkAudit,
        verifiedHash: auditedInfo.verifiedHash,
        warningHash: auditedInfo.warningHash,
      };
      const accountInvestment =
        account && account.address
          ? await presaleContract.methods.investments(account.address).call({
              from: account.address,
            })
          : "0";
      const isWhitelistMode = presaleContract.methods
        .onlyWhitelistedAddressesAllowed
        ? await presaleContract.methods.onlyWhitelistedAddressesAllowed().call()
        : true;

      const isWhitelistedAddress = isWhitelistMode
        ? account && account.address
          ? await presaleContract.methods
              .whitelistedAddresses(account.address)
              .call()
          : false
        : true;
      let tokenAmount = 0;
      let claimedPeriod = 0;
      let claimed = 0;

      if (
        presaleIndex === "gamewinSeedSale" ||
        presaleIndex === "starchiSeedSale" ||
        presaleIndex === "starchiRdSale" ||
        presaleIndex === "starchiPrivateSale" ||
        presaleIndex === "starchiVipPrivate" ||
        presaleIndex === "starchiVipMaven" ||
        presaleIndex === "starchiVipVbc" ||
        presaleIndex === "starchiPrivateCryptozen" ||
        presaleIndex === "starchiPrivateEmpire" ||
        presaleIndex === "starchiPrivateDarkpool" ||
        presaleIndex === "starchiTeamSale" ||
        presaleIndex === "kryxiviaPrivateSale1" ||
        presaleIndex === "kryxiviaPrivateSale2" ||
        presaleIndex === "starzTeam" ||
        presaleIndex === "starzSuperVip" ||
        presaleIndex === "starzSc" ||
        presaleIndex === "betswirlSeed" ||
        presaleIndex === "metalaunchStarterCapital" ||
        presaleIndex === "metalaunchSuperVip" ||
        presaleIndex === "metalaunchTeam" ||
        presaleIndex === "voiceStreetSuperVip" ||
        presaleIndex === "voiceStreetSc" ||
        presaleIndex === "metagamzSc" ||
        presaleIndex === "metagamzSvip"
      ) {
        claimed =
          account && account.address
            ? await presaleContract.methods.claimed(account.address).call({
                from: account.address,
              })
            : "0";
        let fundingTokenDecimals = 18;
        const fundingTokenAddress = presaleContract.methods.fundingTokenAddress
          ? await presaleContract.methods.fundingTokenAddress().call()
          : defaultFundingAddress[themeType];

        const fundingTokenContract = new web3.eth.Contract(
          config[themeType].ERC20Abi,
          fundingTokenAddress
        );
        fundingTokenDecimals = await fundingTokenContract.methods
          .decimals()
          .call();

        const _accountInvestment = parseFloat(
          toFixed(
            bigInt(accountInvestment),
            parseInt(fundingTokenDecimals) || 18,
            parseInt(fundingTokenDecimals) == 18 ? 8 : 6
          )
        );

        const _tokenPriceInWei = parseFloat(
          toFixed(
            bigInt(tokenPriceInWei),
            parseInt(fundingTokenDecimals) || 18,
            6
          )
        );

        const _claimed = parseFloat(toFixed(bigInt(claimed), 18, 8));
        tokenAmount =
          _tokenPriceInWei > 0
            ? _accountInvestment / _tokenPriceInWei - _claimed
            : 0;
        console.log(_accountInvestment, _tokenPriceInWei, claimed, _claimed);
      } else {
        claimedPeriod =
          account && account.address
            ? await presaleContract.methods.claimed(account.address).call({
                from: account.address,
              })
            : "0";

        const totalDistributionPeriod =
          presaleIndex == "seed" ||
          presaleIndex == "private" ||
          presaleIndex == "strategic"
            ? 4
            : presaleIndex == "vestSeedSale" ||
              presaleIndex == "vestPrivateSale"
            ? 9
            : 1;

        tokenAmount =
          tokenPriceInWei > 0
            ? presaleIndex == "seed" ||
              presaleIndex == "private" ||
              presaleIndex == "strategic" ||
              presaleIndex == "vestSeedSale" ||
              presaleIndex == "vestPrivateSale"
              ? bigInt(accountInvestment)
                  .divide(bigInt(tokenPriceInWei))
                  .multiply(totalDistributionPeriod - claimedPeriod)
                  .divide(totalDistributionPeriod)
              : bigInt(accountInvestment)
                  .divide(bigInt(tokenPriceInWei))
                  .multiply(1 - claimedPeriod)
            : 0;
      }

      const description = presaleContract.methods.description
        ? await presaleContract.methods.description().call()
        : "";

      const guaranteedHours = presaleContract.methods.guaranteedHours
        ? await presaleContract.methods.guaranteedHours().call()
        : "";
      let releasePerCycle = presaleContract.methods.releasePerCycle
        ? await presaleContract.methods.releasePerCycle().call()
        : 100;

      if (
        presaleIndex === "betswirlSeed" ||
        presaleIndex === "starzTeam" ||
        presaleIndex === "starzSuperVip" ||
        presaleIndex === "metalaunchStarterCapital" ||
        presaleIndex === "metalaunchSuperVip" ||
        presaleIndex === "metalaunchTeam" ||
        presaleIndex === "voiceStreetSuperVip" ||
        presaleIndex === "voiceStreetSc"
      ) {
        releasePerCycle = releasePerCycle / 100;
      }
      const releaseCycle = presaleContract.methods.releaseCycle
        ? await presaleContract.methods.releaseCycle().call()
        : 30 * 86400;
      const updatedPresaleInfo = {
        ...presale,
        totalInvestorsCount,
        totalCollectedWei,
        totalTokens,
        tokensLeft,
        tokenPriceInWei,
        hardCapInWei,
        softCapInWei,
        maxInvestInWei,
        minInvestInWei,
        openTime,
        closeTime,
        cakeLiquidityAddingTime,
        saleTitle,
        linkTelegram,
        linkTwitter,
        linkGithub,
        linkWebsite,
        linkLogo,
        accountInvestment,
        isWhitelistedAddress,
        claimedPeriod,
        tokenAmount,
        auditInformation,
        kycInformation,
        claimAllowed,
        claimCycle,
        description,
        guaranteedHours,
        releasePerCycle,
        releaseCycle,
      };

      store.setStore({
        presales: {
          ...presales,
          [presaleIndex]: {
            ...updatedPresaleInfo,
          },
        },
      });
      return emitter.emit(GET_PRESALE_INFO_RETURNED);
    } catch (error) {
      console.error("GET_PRESALE_INFO", error);
      return emitter.emit(ERROR, error);
    }
  };

  getPublicPresaleInfo = async ({ content: { presaleIndex } }) => {
    try {
      const themeType = store.getStore("themeType");
      const account = store.getStore("account");
      const rpcUrl = await this.getRpcUrl();
      const web3 = new Web3(rpcUrl);
      const presales = store.getStore("publicPresales");
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );

      const bscStarterVestingAddress = store.getStore("starterVestingContract");

      const presaleAddress = await starterInfoContract.methods
        .getPresaleAddress(presaleIndex)
        .call();

      const presale = presales[presaleIndex];
      const presaleContract = new web3.eth.Contract(
        _getPresaleAbiByBscsId(store, presaleIndex),
        presaleAddress
      );

      const fundingTokenAddress = presaleContract.methods.fundingTokenAddress
        ? await presaleContract.methods.fundingTokenAddress().call()
        : defaultFundingAddress[themeType];

      let fundingTokenBalance;
      let fundingTokenDecimals = 18;
      if (
        isSameAddress(fundingTokenAddress, defaultFundingAddress[themeType])
      ) {
        fundingTokenBalance =
          account && account.address
            ? await web3.eth.getBalance(account.address)
            : "0";
      } else {
        const fundingTokenContract = new web3.eth.Contract(
          config[themeType].ERC20Abi,
          fundingTokenAddress
        );
        fundingTokenBalance =
          account && account.address
            ? await fundingTokenContract.methods
                .balanceOf(account.address)
                .call()
            : "0";
        fundingTokenDecimals = await fundingTokenContract.methods
          .decimals()
          .call();
      }

      const presaleVersion = _getPresaleTypeByBscsId(store, presaleIndex);
      const presaleCreatorAddress = await presaleContract.methods
        .presaleCreatorAddress()
        .call();
      const tokenAddress = await presaleContract.methods.token().call();
      const lockedLiquidityAddress = presaleContract.methods.bscsLiqLockAddress
        ? await presaleContract.methods.bscsLiqLockAddress().call()
        : bscStarterVestingAddress;
      const totalInvestorsCount = await presaleContract.methods
        .totalInvestorsCount()
        .call();
      const totalCollectedWei = await presaleContract.methods
        .totalCollectedWei()
        .call();
      const totalTokens = await presaleContract.methods.totalTokens().call();
      const tokensLeft = await presaleContract.methods.tokensLeft().call();
      const tokenPriceInWei = await presaleContract.methods
        .tokenPriceInWei()
        .call();
      const hardCapInWei = await presaleContract.methods.hardCapInWei().call();
      const softCapInWei = await presaleContract.methods.softCapInWei().call();
      const maxInvestInWei = await presaleContract.methods
        .maxInvestInWei()
        .call();
      const minInvestInWei = await presaleContract.methods
        .minInvestInWei()
        .call();
      const openTime = await presaleContract.methods.openTime().call();
      const closeTime = await presaleContract.methods.closeTime().call();

      const saleTitle = web3.utils.hexToUtf8(
        await presaleContract.methods.saleTitle().call()
      );
      const linkTelegram = web3.utils.hexToUtf8(
        await presaleContract.methods.linkTelegram().call()
      );
      const linkTwitter = web3.utils.hexToUtf8(
        await presaleContract.methods.linkTwitter().call()
      );
      const linkGithub = web3.utils.hexToUtf8(
        await presaleContract.methods.linkGithub().call()
      );
      const linkWebsite = web3.utils.hexToUtf8(
        await presaleContract.methods.linkWebsite().call()
      );
      const _linkLogo = await presaleContract.methods.linkLogo().call();
      const linkLogo =
        presaleVersion === "V1" ? web3.utils.hexToUtf8(_linkLogo) : _linkLogo;

      const url = `/pool/${presaleIndex}`;
      const yesVotes = await presaleContract.methods.yesVotes().call();
      const noVotes = await presaleContract.methods.noVotes().call();

      let minYesVotesThreshold = 0;
      let totalStakedLocked = 0;
      if (themeType === "bsc" || themeType == "pulse") {
        minYesVotesThreshold =
          presaleVersion === "V3"
            ? await starterInfoContract.methods.getMinYesVotesThreshold().call()
            : await presaleContract.methods.minYesVotesThreshold().call();
        totalStakedLocked = store.getStore("totalStakedLocked");
      } else {
        minYesVotesThreshold = await starterInfoContract.methods
          .getMinYesVotesThreshold(fundingTokenAddress)
          .call();
        totalStakedLocked = await starterInfoContract.methods
          .getTotalStaked(fundingTokenAddress)
          .call();
      }

      const cakeListingPriceInWei = await presaleContract.methods
        .cakeListingPriceInWei()
        .call();

      const cakeLiquidityAddingTime = await presaleContract.methods
        .cakeLiquidityAddingTime()
        .call();

      const cakeLPTokensLockDurationInDays = await presaleContract.methods
        .cakeLPTokensLockDurationInDays()
        .call();
      const cakeLiquidityPercentageAllocation = await presaleContract.methods
        .cakeLiquidityPercentageAllocation()
        .call();

      const accountInvestment =
        account && account.address
          ? await presaleContract.methods.investments(account.address).call({
              from: account.address,
            })
          : "0";

      const isWhitelistMode = await presaleContract.methods
        .onlyWhitelistedAddressesAllowed()
        .call();
      const isWhitelistedAddress = isWhitelistMode
        ? account && account.address
          ? await presaleContract.methods
              .whitelistedAddresses(account.address)
              .call()
          : false
        : true;

      const claimed =
        account && account.address
          ? await presaleContract.methods.claimed(account.address).call({
              from: account.address,
            })
          : "0";

      const presaleCancelled = await presaleContract.methods
        .presaleCancelled()
        .call();
      const _bscsDevFeesExempted = presaleContract.methods.bscsDevFeesExempted
        ? await presaleContract.methods.bscsDevFeesExempted().call()
        : false;
      const bscsDevFeesExempted = _bscsDevFeesExempted ? "true" : "false";
      const _auditInformation = await presaleContract.methods
        .auditInformation()
        .call();

      const auditInformation =
        presaleVersion === "V1"
          ? {
              auditor: web3.utils.hexToUtf8(_auditInformation.auditor),
              isVerified: _auditInformation.isVerified,
              isWarning: _auditInformation.isWarning,
              verifiedHash: _auditInformation.verifiedHash,
              warningHash: _auditInformation.warningHash,
            }
          : {
              auditor: web3.utils.hexToUtf8(_auditInformation.auditor),
              isVerified: _auditInformation.isVerified,
              isWarning: _auditInformation.isWarning,
              linkAudit: _auditInformation.linkAudit,
            };

      const _accountInvestment = parseFloat(
        toFixed(
          bigInt(accountInvestment),
          parseInt(fundingTokenDecimals) || 18,
          parseInt(fundingTokenDecimals) == 18 ? 8 : 6
        )
      );
      const _tokenPrice = parseFloat(
        toFixed(
          bigInt(tokenPriceInWei),
          parseInt(fundingTokenDecimals) || 18,
          parseInt(fundingTokenDecimals) == 18 ? 8 : 6
        )
      );
      let tokenAmount =
        _tokenPrice > 0 && !parseInt(claimed)
          ? _accountInvestment / _tokenPrice
          : 0;
      const votedBalance =
        account && account.address
          ? await presaleContract.methods.voters(account.address).call()
          : "0";

      const unsoldTokensDumpAddress = await presaleContract.methods
        .unsoldTokensDumpAddress()
        .call();
      let minInvestorBSCSBalance = 0;
      if (themeType === "bsc" || themeType == "pulse") {
        minInvestorBSCSBalance =
          presaleVersion === "V3"
            ? await starterInfoContract.methods
                .getMinInvestorBSCSBalance()
                .call()
            : await presaleContract.methods.minInvestorBSCSBalance().call();
      } else if (themeType === "mtc") {
        minInvestorBSCSBalance = await starterInfoContract.methods
          .getMinInvestorBSCSBalance(fundingTokenAddress)
          .call();
      }

      const minVoterBscsBalance =
        presaleVersion === "V3"
          ? starterInfoContract.methods.getMinVoterBSCSBalance
            ? await starterInfoContract.methods.getMinVoterBSCSBalance().call()
            : minInvestorBSCSBalance
          : presaleContract.methods.minVoterBSCSBalance
          ? await presaleContract.methods.minVoterBSCSBalance().call()
          : minInvestorBSCSBalance;

      const bscsLiqLockAddress = lockedLiquidityAddress;
      const stakedAndLockedBalance = store.getStore("stakedAndLockedBalance");

      const presaleCreatorClaimTime = await presaleContract.methods
        .presaleCreatorClaimTime()
        .call();

      let voteAvailable = stakedAndLockedBalance;
      const stakingTokenName = store.getStore("StakingTokenName");
      const stakingTokenAddress = store.getStore("StakingToken");

      let additionalInfo = {};

      let maxInvestAmount = 0;

      try {
        maxInvestAmount =
          presaleVersion === "V3"
            ? account && account.address
              ? await presaleContract.methods
                  .getMaxInvestAmount(account.address)
                  .call()
              : "0"
            : maxInvestInWei;
      } catch (error) {
        console.log("Max Invest Amount", maxInvestInWei, error);
        maxInvestAmount = maxInvestInWei;
      }

      if (presaleVersion !== "V1") {
        const tokenDecimals = await presaleContract.methods
          .tokenDecimals()
          .call();
        const kycInformation = await presaleContract.methods
          .kycInformation()
          .call();
        const description = await presaleContract.methods.description().call();
        const whitepaper = await presaleContract.methods.whitepaper().call();
        const categoryId = await presaleContract.methods.categoryId().call();
        const tokenMagnitude = await presaleContract.methods
          .tokenMagnitude()
          .call();
        const presaleType = parseInt(
          await presaleContract.methods.presaleType().call()
        );
        const guaranteedHours = await presaleContract.methods
          .guaranteedHours()
          .call();

        let minInvestorGuaranteedBalance = 0;
        if (themeType === "bsc" || themeType == "pulse") {
          minInvestorGuaranteedBalance =
            presaleVersion === "V3"
              ? await starterInfoContract.methods
                  .getMinInvestorGuaranteedBalance()
                  .call()
              : await presaleContract.methods
                  .minInvestorGuaranteedBalance()
                  .call();
        } else if (themeType === "mtc") {
          minInvestorGuaranteedBalance = await starterInfoContract.methods
            .getMinInvestorGuaranteedBalance(fundingTokenAddress)
            .call();
        }

        let releasePerCycle =
          presaleVersion === "V3"
            ? await presaleContract.methods.releasePerCycle().call()
            : 0;
        if (
          themeType === "mtc" &&
          (presaleIndex == 16 ||
            presaleIndex == 17 ||
            presaleIndex == 18 ||
            presaleIndex == 23 ||
            presaleIndex == 27)
        ) {
          releasePerCycle = releasePerCycle / 100;
        }

        let releaseCycle =
          presaleVersion === "V3" && presaleContract.methods.releaseCycle
            ? await presaleContract.methods.releaseCycle().call()
            : 30 * 86400;

        if (
          (themeType === "bsc" || themeType == "pulse") &&
          presaleIndex == 149
        ) {
          releasePerCycle = releasePerCycle / 100;
          releaseCycle = 86400;
        }

        if (
          (themeType === "bsc" || themeType == "pulse") &&
          presaleIndex == 152
        ) {
          releasePerCycle = releasePerCycle / 100;
          releaseCycle = 2592000;
        }

        if (
          (themeType === "bsc" || themeType == "pulse") &&
          presaleIndex == 154
        ) {
          releaseCycle = 2592000;
        }

        if (
          (themeType === "bsc" || themeType == "pulse") &&
          presaleIndex >= 154
        ) {
          releasePerCycle = releasePerCycle / 100;
        }

        const claimAllowed =
          presaleVersion === "V3"
            ? await presaleContract.methods.claimAllowed().call()
            : false;

        let investmentLimit = 0;
        if (themeType === "bsc" || themeType == "pulse") {
          investmentLimit = starterInfoContract.methods.getInvestmentLimit
            ? await starterInfoContract.methods.getInvestmentLimit().call()
            : 20;
        } else if (themeType === "mtc") {
          investmentLimit = starterInfoContract.methods.getInvestmentLimit
            ? await starterInfoContract.methods
                .getInvestmentLimit(fundingTokenAddress)
                .call()
            : 20;
        } else if (themeType === "eth") {
          investmentLimit = starterInfoContract.methods.getInvestmentLimit
            ? await starterInfoContract.methods
                .getInvestmentLimit(fundingTokenAddress)
                .call()
            : 20;
        } else if (themeType === "ftm") {
          investmentLimit = starterInfoContract.methods.getInvestmentLimit
            ? await starterInfoContract.methods
                .getInvestmentLimit(fundingTokenAddress)
                .call()
            : 20;
        }

        if (bigInt(maxInvestAmount).greater(bigInt(investmentLimit))) {
          maxInvestAmount = investmentLimit;
        }
        if (presaleVersion === "V3") {
          const calculatedAmount =
            _tokenPrice > 0 ? _accountInvestment / _tokenPrice : 0;
          const totalClaimed = parseFloat(
            toFixed(bigInt(claimed), parseInt(tokenDecimals) || 18, 8)
          );
          tokenAmount = parseFloat(calculatedAmount) - parseFloat(totalClaimed);
        }
        additionalInfo = {
          tokenDecimals,
          kycInformation,
          description,
          whitepaper,
          categoryId,
          tokenMagnitude,
          presaleType,
          guaranteedHours,
          minInvestorGuaranteedBalance,
          releasePerCycle,
          releaseCycle,
          claimAllowed,
          investmentLimit,
        };
      }

      const isBanned = false;

      const updatedPresaleInfo = {
        ...presale,
        ...additionalInfo,
        bscsLiqLockAddress,
        bscsDevFeesExempted,
        presaleCreatorAddress,
        presaleCreatorClaimTime,
        presaleVersion,
        totalInvestorsCount,
        totalCollectedWei,
        totalTokens,
        totalStakedLocked,
        tokensLeft,
        tokenPriceInWei,
        stakingTokenName,
        stakingTokenAddress,
        hardCapInWei,
        softCapInWei,
        maxInvestInWei,
        minInvestInWei,
        openTime,
        closeTime,
        saleTitle,
        linkTelegram,
        linkTwitter,
        linkGithub,
        linkWebsite,
        linkLogo,
        accountInvestment,
        isWhitelistMode,
        isWhitelistedAddress,
        tokenAmount,
        claimed,
        url,
        presaleIndex,
        yesVotes,
        noVotes,
        minYesVotesThreshold,
        minInvestorBSCSBalance,
        cakeListingPriceInWei,
        cakeLiquidityAddingTime,
        cakeLPTokensLockDurationInDays,
        cakeLiquidityPercentageAllocation,
        tokenAddress,
        fundingTokenAddress,
        fundingTokenBalance,
        fundingTokenDecimals,
        lockedLiquidityAddress,
        presaleAddress,
        presaleCancelled,
        auditInformation,
        minVoterBscsBalance,
        votedBalance: votedBalance,
        voteAvailable: voteAvailable,
        unsoldTokensDumpAddress,
        maxInvestAmount,
        isBanned,
      };
      store.setStore({
        publicPresales: {
          ...presales,
          [presaleIndex]: updatedPresaleInfo,
        },
      });
      return emitter.emit(GET_PUBLIC_PRESALE_INFO_RETURNED);
    } catch (error) {
      console.error("GET_PUBLIC_PRESALE_INFO", error);
      return emitter.emit(ERROR, error);
    }
  };

  getFarmingBalances = async () => {
    const provider = await this.getRpcUrl();
    const web3 = new Web3(provider);
    const account = store.getStore("account");

    try {
      async.parallel(
        [
          (callbackInnerInner) => {
            _getStartInfo(store, dispatcher, emitter, web3, callbackInnerInner);
          },
          (callbackInnerInner) => {
            _getFarmingInfo(
              store,
              dispatcher,
              emitter,
              web3,
              account,
              callbackInnerInner
            );
          },
        ],
        (err, data) => {
          if (err) {
            console.log(err);
            return emitter.emit(ERROR, err);
          }
          const farmingPool = store.getStore("farming");
          store.setStore({
            farming: {
              ...farmingPool,
              totalSupply: data[0].totalSupply,
              totalBurned: data[0].totalBurned,
              halvingTimestamp: data[1].halvingTimestamp,
              rewardAllocation: data[1].rewardAllocation,
              accountInfo: data[1].accountInfo,
              totalLpLocked: data[1].totalLocked,
              lpTotalSupply: data[1].lpTotalSupply,
              lpBnbBalance: data[1].lpBnbBalance,
              rewardEarned: data[1].rewardEarned,
              withdrawLimit: data[1].withdrawLimit,
              withdrawCycle: data[1].withdrawCycle,
              claimFee: data[1].claimFee,
              farmingStartTimestamp: data[1].farmingStartTimestamp,
            },
          });
          return emitter.emit(GET_FARMING_INFO_RETURNED);
        }
      );
    } catch (e) {
      console.log("Get Farming Balance Error", e);
    }
  };

  getPoolsSummary = async () => {
    const themeType = store.getStore("themeType");
    const provider = await this.getRpcUrl();
    const web3 = new Web3(provider);

    let publicPresales = {};

    try {
      const url = poolsSummaryUrl[themeType];
      const poolsList = await rp(url);
      publicPresales = JSON.parse(poolsList);
    } catch (e) {
      publicPresales = {};
    }

    console.log(publicPresales);
    try {
      const presales = store.getStore("presales");
      async.parallel(
        Object.keys(presales).map((presaleIndex) => (callbackInnerInner) => {
          _getPoolSummary(
            store,
            dispatcher,
            emitter,
            web3,
            presaleIndex,
            callbackInnerInner
          );
        }),
        (err, data) => {
          if (err) {
            console.log(err);
            return emitter.emit(ERROR, err);
          }
          let updatedPresales = {};
          Object.keys(presales).map((presaleIndex, index) => {
            updatedPresales[presaleIndex] = {
              ...presales[presaleIndex],
              ...data[index],
            };
          });
          store.setStore({
            presales: {
              ...updatedPresales,
            },
          });
        }
      );
    } catch (err) {
      console.error("GET_POOL_SUMMARY_ERROR", err);
      return emitter.emit(ERROR, err);
    }

    try {
      async.parallel(
        [
          (callbackInnerInner) => {
            _getStakingPool(
              store,
              dispatcher,
              emitter,
              web3,
              callbackInnerInner
            );
          },
          (callbackInnerInner) => {
            _getFactoryPool(
              store,
              dispatcher,
              emitter,
              web3,
              callbackInnerInner
            );
          },
        ],
        (err, data) => {
          if (err) {
            console.log(err);
            return emitter.emit(ERROR, err);
          }
          store.setStore({
            stakingPool: {
              address: data[0].address,
            },
            factoryPool: {
              address: data[1].address,
              stakingPool: data[1].stakingPool,
              starterInfo: data[1].starterInfo,
              owner: data[1].owner,
              pendingOwner: data[1].pendingOwner,
            },
          });
        }
      );
    } catch (err) {
      console.error("GET_PUBLIC_POOL_SUMMARY_ERROR - 1", err);
      return emitter.emit(ERROR, err);
    }

    try {
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );

      const onGoingPresales = Object.keys(publicPresales).filter(
        (presaleIndex) => {
          const openTime = parseInt(
            publicPresales[presaleIndex]?.openTime || "0"
          );
          const closeTime = parseInt(
            publicPresales[presaleIndex]?.closeTime || "0"
          );
          const currentTimestamp = parseInt(Date.now() / 1000);
          return (
            openTime <= currentTimestamp &&
            closeTime > currentTimestamp &&
            !publicPresales[presaleIndex].presaleCancelled
          );
        }
      );

      console.log(onGoingPresales);
      async.parallel(
        onGoingPresales.map((presaleIndex) => (callbackInnerInner) => {
          _getPublicPoolSummary(
            store,
            dispatcher,
            emitter,
            web3,
            starterInfoContract,
            presaleIndex,
            callbackInnerInner
          );
        }),
        (err, data) => {
          if (err) {
            console.log(err);
            return emitter.emit(ERROR, err);
          }
          let updatedPresales = {
            ...publicPresales,
          };
          onGoingPresales.forEach((presaleIndex, index) => {
            updatedPresales[presaleIndex] = data[index];
          });

          store.setStore({
            publicPresales: {
              ...updatedPresales,
            },
          });
          return emitter.emit(GET_POOLS_SUMMARY_RETURNED);
        }
      );
    } catch (err) {
      console.error("GET_PUBLIC_POOL_SUMMARY_ERROR - 2", err);
      return emitter.emit(ERROR, err);
    }
  };

  write = async ({ content: { presaleIndex, method, args } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      if (presaleIndex == -1) {
        const contractAbi = store.getStore("StakedContractV3Abi");
        const contractAddress = store.getStore("StakedContractV3Address");

        let stakedContract = new web3.eth.Contract(
          contractAbi,
          contractAddress
        );

        _callWrite(
          store,
          dispatcher,
          emitter,
          web3,
          stakedContract,
          account,
          presaleIndex,
          method,
          args,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }

            return emitter.emit(WRITE_RETURNED, res);
          }
        );
      } else if (presaleIndex == -2) {
        const factory = store.getStore("factory");
        const contractAbi = factory.factoryV3Abi;
        const contractAddress = factory.factoryV3Address;

        let factoryContract = new web3.eth.Contract(
          contractAbi,
          contractAddress
        );

        _callWrite(
          store,
          dispatcher,
          emitter,
          web3,
          factoryContract,
          account,
          presaleIndex,
          method,
          args,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }

            return emitter.emit(WRITE_RETURNED, res);
          }
        );
      } else {
        let presaleAddress;
        presaleAddress = await starterInfoContract.methods
          .getPresaleAddress(presaleIndex)
          .call();
        const presaleContract = new web3.eth.Contract(
          _getPresaleAbiByBscsId(store, presaleIndex),
          presaleAddress
        );

        _callWrite(
          store,
          dispatcher,
          emitter,
          web3,
          presaleContract,
          account,
          presaleIndex,
          method,
          args,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }

            return emitter.emit(WRITE_RETURNED, res);
          }
        );
      }
    } catch (error) {
      console.error("WRITE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  swap = async ({ content: { amount, amountOut, direction } }) => {
    const themeType = store.getStore("themeType");
    const account = store.getStore("account");
    const web3 = new Web3(store.getStore("web3context").library.provider);
    const startTokenAddress = store.getStore("tokenAddress");
    const startTokenAbi = store.getStore("tokenAbi");
    const swapData = store.getStore("swapData");

    const swapRouterContract = new web3.eth.Contract(
      swapData.swapRouterAbi,
      swapData.swapRouterAddress
    );
    const path =
      direction === 0
        ? [swapData.swapTokenAddress, startTokenAddress]
        : [startTokenAddress, swapData.swapTokenAddress];

    const inputToken =
      direction === 0 ? swapData.swapTokenAddress : startTokenAddress;

    const outputToken =
      direction === 0 ? startTokenAddress : swapData.swapTokenAddress;

    if (
      web3.utils.toChecksumAddress(inputToken) !==
      web3.utils.toChecksumAddress(defaultFundingAddress[themeType])
    ) {
      const isApproved = await _checkApproval(
        store,
        dispatcher,
        emitter,
        config[themeType].ERC20Abi,
        inputToken,
        account,
        amount,
        swapData.swapRouterAddress
      );
      if (!isApproved) {
        return emitter.emit(ERROR, {
          message: "APPROVE failed",
        });
      }
    }
    _callSwap(
      store,
      dispatcher,
      emitter,
      web3,
      account,
      swapRouterContract,
      path,
      inputToken,
      outputToken,
      amount,
      amountOut,
      (err, res) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }
        return emitter.emit(SWAP_RETURNED, res);
      }
    );
  };

  customWrite = async ({ content: { address, abi, method, args } }) => {
    const account = store.getStore("account");
    const web3 = new Web3(store.getStore("web3context").library.provider);
    const presaleContract = new web3.eth.Contract(
      _getPresaleAbiByVersion(store, abi),
      address
    );
    _callWrite(
      store,
      dispatcher,
      emitter,
      web3,
      presaleContract,
      account,
      0,
      method,
      args,
      (err, res) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }

        return emitter.emit(WRITE_RETURNED, res);
      }
    );
  };

  collectFunds = async ({ content: { presaleIndex } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      let presaleAddress;
      presaleAddress = await starterInfoContract.methods
        .getPresaleAddress(presaleIndex)
        .call();
      const presaleContract = new web3.eth.Contract(
        _getPresaleAbiByBscsId(store, presaleIndex),
        presaleAddress
      );
      _callCollectFunds(
        store,
        dispatcher,
        emitter,
        web3,
        presaleContract,
        account,
        presaleIndex,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(COLLECT_FUND_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("COLLECT_FUNDS ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  cancelPresale = async ({ content: { presaleIndex } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      let presaleAddress;
      presaleAddress = await starterInfoContract.methods
        .getPresaleAddress(presaleIndex)
        .call();
      const presaleContract = new web3.eth.Contract(
        _getPresaleAbiByBscsId(store, presaleIndex),
        presaleAddress
      );
      _callCancelPresale(
        store,
        dispatcher,
        emitter,
        web3,
        presaleContract,
        account,
        presaleIndex,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(CANCEL_PRESALE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("CANCEL_PRESALE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  sendUnsoldTokens = async ({ content: { presaleIndex } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      let presaleAddress;
      presaleAddress = await starterInfoContract.methods
        .getPresaleAddress(presaleIndex)
        .call();
      const presaleContract = new web3.eth.Contract(
        _getPresaleAbiByBscsId(store, presaleIndex),
        presaleAddress
      );
      _callSendUnSoldTokens(
        store,
        dispatcher,
        emitter,
        web3,
        presaleContract,
        account,
        presaleIndex,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(SEND_UNSOLD_TOKENS_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("CANCEL_PRESALE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  allowClaim = async ({ content: { presaleIndex } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      let presaleAddress;
      presaleAddress = await starterInfoContract.methods
        .getPresaleAddress(presaleIndex)
        .call();
      const presaleContract = new web3.eth.Contract(
        _getPresaleAbiByBscsId(store, presaleIndex),
        presaleAddress
      );
      _callAllowClaim(
        store,
        dispatcher,
        emitter,
        web3,
        presaleContract,
        account,
        presaleIndex,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(ALLOW_CLAIM_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("CANCEL_PRESALE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  invest = async (
    { content: { presaleIndex, amount, decimals = 18 } },
    presaleType = "private"
  ) => {
    try {
      const themeType = store.getStore("themeType");
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const presales =
        presaleType === "private"
          ? store.getStore("presales")
          : store.getStore("publicPresales");
      const presale = presales[presaleIndex];
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      let presaleAddress;
      if (presaleType === "private") {
        presaleAddress = presale.presaleAddress;
      } else {
        presaleAddress = await starterInfoContract.methods
          .getPresaleAddress(presaleIndex)
          .call();
      }
      const presaleContract =
        presaleType === "private"
          ? new web3.eth.Contract(presale.presaleAbi, presale.presaleAddress)
          : new web3.eth.Contract(
              _getPresaleAbiByBscsId(store, presaleIndex),
              presaleAddress
            );

      const weiAmount = bigInt(parseInt(amount * 10 ** decimals)).toString();
      const fundingTokenAddress = presaleContract.methods.fundingTokenAddress
        ? await presaleContract.methods.fundingTokenAddress().call()
        : defaultFundingAddress[themeType];

      if (
        web3.utils.toChecksumAddress(fundingTokenAddress) !==
        web3.utils.toChecksumAddress(defaultFundingAddress[themeType])
      ) {
        const isApproved = await _checkApproval(
          store,
          dispatcher,
          emitter,
          config[themeType].ERC20Abi,
          fundingTokenAddress,
          account,
          weiAmount,
          presaleAddress
        );
        if (!isApproved) {
          return emitter.emit(ERROR, {
            message: "APPROVE failed",
          });
        }
      }

      _callInvest(
        store,
        dispatcher,
        emitter,
        web3,
        presaleContract,
        account,
        presaleIndex,
        presaleType,
        fundingTokenAddress,
        weiAmount,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(INVEST_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("INVEST ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  lockLiquidity = async ({ content: { presaleIndex } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const presales = store.getStore("publicPresales");
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      let presaleAddress = await starterInfoContract.methods
        .getPresaleAddress(presaleIndex)
        .call();
      const presaleContract = new web3.eth.Contract(
        _getPresaleAbiByBscsId(store, presaleIndex),
        presaleAddress
      );
      _callLockLiquidity(
        store,
        dispatcher,
        emitter,
        web3,
        presaleContract,
        account,
        presaleIndex,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(LOCK_LIQUIDITY_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("LOCK_LIQUIDITY ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  getRefund = async ({ content: { presaleIndex } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const presales = store.getStore("presales");
      let presaleContract = null;

      if (presales[presaleIndex] && presales[presaleIndex].tokenAddress) {
        presaleContract = new web3.eth.Contract(
          presales[presaleIndex].presaleAbi,
          presales[presaleIndex].presaleAddress
        );
      } else {
        const factory = store.getStore("factory");
        const starterInfoContract = new web3.eth.Contract(
          factory.starterInfoAbi,
          factory.starterInfoAddress
        );
        let presaleAddress = await starterInfoContract.methods
          .getPresaleAddress(presaleIndex)
          .call();

        presaleContract = new web3.eth.Contract(
          _getPresaleAbiByBscsId(store, presaleIndex),
          presaleAddress
        );
      }

      _callGetRefund(
        store,
        dispatcher,
        emitter,
        web3,
        presaleContract,
        account,
        presaleIndex,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(GET_REFUND_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("GET_REFUND ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  vote = async ({ content: { presaleIndex, voteType } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const presales = store.getStore("publicPresales");
      const factory = store.getStore("factory");
      const starterInfoContract = new web3.eth.Contract(
        factory.starterInfoAbi,
        factory.starterInfoAddress
      );
      let presaleAddress = await starterInfoContract.methods
        .getPresaleAddress(presaleIndex)
        .call();
      const presaleContract = new web3.eth.Contract(
        _getPresaleAbiByBscsId(store, presaleIndex),
        presaleAddress
      );
      _callVote(
        store,
        dispatcher,
        emitter,
        web3,
        presaleContract,
        account,
        presaleIndex,
        voteType,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(VOTE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("VOTE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  stake = async ({ content: { amount } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const contractAbi = store.getStore("StakedContractV3Abi");
      const contractAddress = store.getStore("StakedContractV3Address");

      const tokenAbi = store.getStore("StakingTokenAbi");
      const tokenAddress = store.getStore("StakingToken");

      let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);
      const isApproved = await _checkApproval(
        store,
        dispatcher,
        emitter,
        tokenAbi,
        tokenAddress,
        account,
        amount,
        contractAddress
      );
      if (!isApproved) {
        return emitter.emit(ERROR, {
          message: "APPROVE failed",
        });
      }

      _callStake(
        store,
        dispatcher,
        emitter,
        web3,
        stakedContract,
        amount,
        tokenAddress,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }
          return emitter.emit(STAKE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("STAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  stakeV1 = async ({ content: { amount } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const contractAbi = store.getStore("StakedContractV2Abi");
      const contractAddress = store.getStore("StakedContractV2Address");

      const tokenAbi = store.getStore("StakingTokenAbi");
      const tokenAddress = store.getStore("StakingToken");

      let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);
      const isApproved = await _checkApproval(
        store,
        dispatcher,
        emitter,
        tokenAbi,
        tokenAddress,
        account,
        amount,
        contractAddress
      );
      if (!isApproved) {
        return emitter.emit(ERROR, {
          message: "APPROVE failed",
        });
      }

      _callStake(
        store,
        dispatcher,
        emitter,
        web3,
        stakedContract,
        amount,
        tokenAddress,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(STAKE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("STAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  migrateTo = async ({ content: { amount } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const contractAbi = store.getStore("StakedContractV3Abi");
      const contractAddress = store.getStore("StakedContractV3Address");

      const tokenAbi = store.getStore("StakingTokenAbi");
      const tokenAddress = store.getStore("StakingToken");

      let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);
      const isApproved = await _checkApproval(
        store,
        dispatcher,
        emitter,
        tokenAbi,
        tokenAddress,
        account,
        amount,
        contractAddress
      );
      if (!isApproved) {
        return emitter.emit(ERROR, {
          message: "APPROVE failed",
        });
      }

      _callMigrate(
        store,
        dispatcher,
        emitter,
        web3,
        stakedContract,
        tokenAddress,
        amount,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(STAKE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("STAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  moveStake = async () => {
    try {
      const themeType = store.getStore("themeType");
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const contractAbi = store.getStore("StakedContractV2Abi");
      const contractAddress = store.getStore("StakedContractV2Address");
      const tokenAbi = store.getStore("StakingTokenAbi");
      const tokenAddress = store.getStore("StakingToken");

      let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);

      if (themeType === "bsc" || themeType == "pulse") {
        let stakedInfo = await stakedContract.methods
          .accountInfos(account.address)
          .call({
            from: account.address,
          });

        const stakedBalance = bigInt(stakedInfo.balance);

        _callUnstakeV1(
          store,
          dispatcher,
          emitter,
          web3,
          stakedContract,
          stakedInfo.balance,
          tokenAddress,
          account,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }
            this.migrateTo({
              content: {
                amount: stakedBalance.toString(),
              },
            });
            return emitter.emit(MOVE_STAKE_RETURNED, res);
          }
        );
      } else if (themeType === "mtc") {
        let stakedInfo = await stakedContract.methods
          .accountLpInfos(tokenAddress, account.address)
          .call();

        const stakedBalance = bigInt(stakedInfo.balance);

        _callUnstakeV1(
          store,
          dispatcher,
          emitter,
          web3,
          stakedContract,
          stakedInfo.balance,
          tokenAddress,
          account,
          (err, res) => {
            if (err) {
              return emitter.emit(ERROR, err);
            }
            this.migrateTo({
              content: {
                amount: stakedBalance.toString(),
              },
            });
            return emitter.emit(MOVE_STAKE_RETURNED, res);
          }
        );
      }
    } catch (error) {
      console.error("moveStake ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  unstakeV1 = async ({ content: { amount } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const contractAbi = store.getStore("StakedContractV2Abi");
      const contractAddress = store.getStore("StakedContractV2Address");
      const tokenAbi = store.getStore("StakingTokenAbi");
      const tokenAddress = store.getStore("StakingToken");

      let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);

      _callUnstakeV1(
        store,
        dispatcher,
        emitter,
        web3,
        stakedContract,
        amount,
        tokenAddress,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }
          return emitter.emit(UNSTAKE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("UNSTAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  unstake = async ({ content: { amount } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const contractAbi = store.getStore("StakedContractV3Abi");
      const contractAddress = store.getStore("StakedContractV3Address");
      const tokenAbi = store.getStore("StakingTokenAbi");
      const tokenAddress = store.getStore("StakingToken");

      let stakedContract = new web3.eth.Contract(contractAbi, contractAddress);

      _callUnstake(
        store,
        dispatcher,
        emitter,
        web3,
        stakedContract,
        amount,
        tokenAddress,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }
          return emitter.emit(UNSTAKE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("UNSTAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  apply = async ({
    content: { bscPresaleInfo, presalePancakeSwapInfo, presaleStringInfo },
  }) => {
    try {
      const themeType = store.getStore("themeType");
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const factory = store.getStore("factory");

      const factoryContract = new web3.eth.Contract(
        factory.factoryV3Abi,
        factory.factoryV3Address
      );

      const amount = bigInt(bscPresaleInfo.hardCapInWei).divide(
        bigInt(bscPresaleInfo.tokenPriceInWei)
      );
      const isApproved = await _checkApproval(
        store,
        dispatcher,
        emitter,
        config[themeType].ERC20Abi,
        bscPresaleInfo.tokenAddress,
        account,
        amount,
        factory.factoryV3Address
      );
      if (!isApproved) {
        return emitter.emit(ERROR, {
          message: "APPROVE failed",
        });
      }
      _callApply(
        store,
        dispatcher,
        emitter,
        web3,
        factoryContract,
        account,
        bscPresaleInfo,
        presalePancakeSwapInfo,
        presaleStringInfo,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(APPLY_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("APPLY ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  farmStake = async ({ content: { amount } }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const farming = store.getStore("farming");
      let farmingContract = new web3.eth.Contract(farming.abi, farming.address);
      const weiAmount = web3.utils.toWei(amount);
      _callFarmStake(
        store,
        dispatcher,
        emitter,
        web3,
        farmingContract,
        weiAmount,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(FARMING_STAKE_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("FARM_STAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  farmWithdraw = async ({ content }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const farming = store.getStore("farming");
      let farmingContract = new web3.eth.Contract(farming.abi, farming.address);
      _callFarmWithdraw(
        store,
        dispatcher,
        emitter,
        web3,
        farmingContract,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(FARMING_WITHDRAW_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("FARM_STAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  farmClaim = async ({ content }) => {
    try {
      const account = store.getStore("account");
      const web3 = new Web3(store.getStore("web3context").library.provider);
      const farming = store.getStore("farming");
      let farmingContract = new web3.eth.Contract(farming.abi, farming.address);
      _callFarmClaim(
        store,
        dispatcher,
        emitter,
        web3,
        farmingContract,
        account,
        (err, res) => {
          if (err) {
            return emitter.emit(ERROR, err);
          }

          return emitter.emit(FARMING_CLAIM_RETURNED, res);
        }
      );
    } catch (error) {
      console.error("FARM_STAKE ERROR", error);
      return emitter.emit(ERROR, error);
    }
  };

  claim = ({ content: { presaleIndex } }, presaleType = "private") => {
    const account = store.getStore("account");

    _callClaimReward(
      store,
      dispatcher,
      emitter,
      presaleIndex,
      account,
      presaleType,
      (err, res) => {
        if (err) {
          return emitter.emit(ERROR, err);
        }

        return emitter.emit(CLAIM_RETURNED, res);
      }
    );
  };

  claimHodler = (param) => {
    const account = store.getStore("account");

    _callClaimHodler(store, dispatcher, emitter, account, (err, res) => {
      if (err) {
        return emitter.emit(ERROR, err);
      }

      return emitter.emit(CLAIM_HODLER_RETURNED, res);
    });
  };
}

const store = new Store();

export default {
  store: store,
  dispatcher: dispatcher,
  emitter: emitter,
};
