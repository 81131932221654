import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {
  Typography,
  Button,
  Input,
  TextField,
  useTheme,
} from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import Web3 from "web3";
import { fundingTokens, tokens } from "../../../constants/constants";

const styles = (theme) => ({
  poolPresaleDetailsCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.apply?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.apply?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  poolSettingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  presaleTypeContainer: {
    width: "100%",
  },

  poolPresaleDetailInputContainer: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "auto",
    gridGap: "20px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "auto auto",
      gridGap: "30px",
    },
    marginTop: "20px",
  },

  poolInfoRadioBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "5px",
  },

  poolInfoInput: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  poolInfoDropdownBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border:
        "solid 1px " + theme?.colors?.apply?.cardTitleSecondary + " !important",
    },
  },

  poolInfoDropdownBoxHeader: {
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: theme?.colors?.apply?.cardTitleSecondary,
    textTransform: "capitalize",
    maxWidth: "200px",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "16px",
    },
  },

  poolInfoRadioBoxHeader: {
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: theme?.colors?.apply?.cardTitleSecondary,
    textTransform: "capitalize",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "16px",
    },
  },

  poolInfoInputHeader: {
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: theme?.colors?.apply?.cardTitleSecondary,
    textTransform: "capitalize",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "16px",
    },
  },

  text: {
    color: theme?.colors?.apply?.cardText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  textSecondary: {
    color: theme?.colors?.apply?.cardTitleSecondary,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
  },

  actionInput: {
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: theme?.colors?.apply?.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.apply?.cardTitleSecondary,
  },
  poolPrevNextWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  walletButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  errorText: {
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    color: theme?.colors?.apply?.warningBg,
    marginTop: "4px",
    marginRight: "4px",
    textAlign: "right",
  },
  textField: {
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: theme?.colors?.apply?.cardText,
    fontSize: "20px",
    outline: "0px",
    "& > div > input": {
      color: theme?.colors?.apply?.cardText,
    },
  },
  radioBoxColorPrimary: {
    color: theme?.colors?.apply?.cardTitleSecondary + " !important",
  },
  radioBoxRoot: {
    flex: 1,
  },
  outlinedDropdown: {
    borderRadius: "14px",
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    color: theme?.colors?.apply?.cardText,
    fontSize: "20px",
    outline: "0px",
    padding: "7.5px",
  },
  iconOutlined: {
    color: theme?.colors?.apply?.cardTitleSecondary,
  },
  dropdownRoot: {
    "& > .MuiOutlinedInput-root": {
      border: "transparent !important",
    },
  },
  dropdownSelected: {
    display: "flex",
    alignItems: "center",
    borderRadius: "14px",
    "&:focus": {
      borderRadius: "14px",
    },
  },
  itemLogo: {
    width: "30px",
    height: "30px",
    marginRight: "5px",
  },
});

const PresaleDetailsCard = ({ classes, value, onNext }) => {
  const theme = useTheme();
  const [detailInfo, setDetailInfo] = useState({
    tokenAddress: value.tokenAddress || "",
    tokenDecimals: value.tokenDecimals || 18,
    unsoldTokensDumpAddress: value.unsoldTokensDumpAddress || "",
    fundingTokenAddress: fundingTokens[theme.type][0].value,
    tokenPriceInWei: value.tokenPriceInWei || 0,
    hardCapInWei: value.hardCapInWei || 0,
    softCapInWei: value.softCapInWei || 0,
    maxInvestInWei: value.maxInvestInWei || 0,
    minInvestInWei: value.minInvestInWei || "0.001",
    openTime: value.openTime || Math.floor(Date.now() / 1000),
    closeTime: value.closeTime || Math.floor(Date.now() / 1000),
    presaleType: value.presaleType || 1,
    guaranteedHours: value.guaranteedHours || 1800,
    releasePerCycle: value.releasePerCycle || 100,
    releaseCycle: value.releaseCycle || 30 * 86400,
  });
  const [errors, setErrors] = useState({});

  const handleNext = () => {
    let isCompleted = true;
    let updatedErrors = {};
    if (
      !detailInfo.tokenAddress ||
      !Web3.utils.isAddress(detailInfo.tokenAddress)
    ) {
      updatedErrors = { ...updatedErrors, tokenAddress: "Invalid address" };
      isCompleted = false;
    }
    if (
      !detailInfo.unsoldTokensDumpAddress ||
      !Web3.utils.isAddress(detailInfo.unsoldTokensDumpAddress)
    ) {
      updatedErrors = {
        ...updatedErrors,
        unsoldTokensDumpAddress: "Invalid address",
      };
      isCompleted = false;
    }
    if (parseFloat(detailInfo.tokenPriceInWei) <= 0) {
      updatedErrors = { ...updatedErrors, tokenPriceInWei: "Invalid price" };
      isCompleted = false;
    }
    if (parseFloat(detailInfo.hardCapInWei) <= 0) {
      updatedErrors = { ...updatedErrors, hardCapInWei: "Invalid price" };
      isCompleted = false;
    }
    if (
      parseFloat(detailInfo.softCapInWei) <= 0 ||
      parseFloat(detailInfo.softCapInWei) >= parseFloat(detailInfo.hardCapInWei)
    ) {
      updatedErrors = { ...updatedErrors, softCapInWei: "Invalid price" };
      isCompleted = false;
    }
    if (parseFloat(detailInfo.maxInvestInWei) <= 0) {
      updatedErrors = { ...updatedErrors, maxInvestInWei: "Invalid price" };
      isCompleted = false;
    }
    if (
      parseFloat(detailInfo.minInvestInWei) <= 0 ||
      parseFloat(detailInfo.minInvestInWei) >=
        parseFloat(detailInfo.maxInvestInWei)
    ) {
      updatedErrors = { ...updatedErrors, minInvestInWei: "Invalid price" };
      isCompleted = false;
    }

    if (!detailInfo.openTime) {
      updatedErrors = { ...updatedErrors, openTime: "Invalid time" };
      isCompleted = false;
    }
    if (!detailInfo.closeTime || detailInfo.closeTime <= detailInfo.openTime) {
      updatedErrors = { ...updatedErrors, closeTime: "Invalid time" };
      isCompleted = false;
    }

    setErrors(updatedErrors);
    if (isCompleted) {
      onNext(detailInfo);
    }
  };

  const parseTimestampToString = (timestamp) => {
    return moment.unix(timestamp).format("YYYY-MM-DDTHH:mm");
  };

  const parseStringToTimestamp = (time) => {
    return moment(time, "yyyy-MM-DDThh:mm").unix();
  };

  const renderInfoInput = (title, field, readOnly) => {
    return (
      <div className={classes.poolInfoInput}>
        <Typography variant="h4" className={classes.poolInfoInputHeader}>
          {title}
        </Typography>
        <Input
          classes={{
            root: classes.actionInput,
            underline: classes.actionUnderline,
          }}
          disableUnderline={true}
          value={detailInfo[field]}
          onChange={(ev) => {
            setDetailInfo({ ...detailInfo, [field]: ev.target.value });
          }}
          readOnly={readOnly}
        />
        <Typography variant="h6" className={classes.errorText}>
          {errors[field]}
        </Typography>
      </div>
    );
  };

  const renderDropdown = (title, field, selections) => {
    return (
      <div className={classes.poolInfoDropdownBox}>
        <Typography variant="h4" className={classes.poolInfoDropdownBoxHeader}>
          {title}
        </Typography>
        <div className={classes.poolInfoDropdownBox}>
          <Select
            variant="outlined"
            id={`${field}-label`}
            value={detailInfo[field]}
            onChange={(ev) => {
              setDetailInfo({
                ...detailInfo,
                [field]: ev.target.value,
              });
            }}
            disableUnderline={true}
            classes={{
              outlined: classes.outlinedDropdown,
              iconOutlined: classes.iconOutlined,
              select: classes.dropdownSelected,
            }}
            input={<Input classes={{ root: classes.dropdownRoot }} />}
          >
            {selections.map((item) => {
              return (
                <MenuItem key={item.value} value={item.value}>
                  {item.logo && (
                    <img
                      src={item.logo}
                      alt="logo"
                      className={classes.itemLogo}
                    />
                  )}
                  {item.label}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </div>
    );
  };

  const renderRadioBox = (title, field, selections) => {
    return (
      <div className={classes.poolInfoRadioBox}>
        <Typography variant="h4" className={classes.poolInfoRadioBoxHeader}>
          {title}
        </Typography>
        <div className={classes.poolInfoRadioBox}>
          <RadioGroup
            row
            aria-label="presale-type"
            name="presaleType"
            value={detailInfo[field] || 0}
            onChange={(ev) => {
              setDetailInfo({
                ...detailInfo,
                [field]: parseInt(ev.target.value),
              });
            }}
          >
            {selections.map((item, index) => (
              <FormControlLabel
                value={index}
                control={
                  <Radio
                    classes={{
                      root: classes.radioBoxColorPrimary,
                      checked: classes.radioBoxColorPrimary,
                    }}
                    color="primary"
                  />
                }
                label={item}
                classes={{
                  root: classes.radioBoxRoot,
                  label: classes.radioBoxColorPrimary,
                }}
              />
            ))}
          </RadioGroup>
        </div>
      </div>
    );
  };

  const renderInfoTimePicker = (title, field) => {
    return (
      <div className={classes.poolInfoInput}>
        <Typography variant="h4" className={classes.poolInfoInputHeader}>
          {title}
        </Typography>
        <TextField
          id="datetime-local"
          type="datetime-local"
          value={parseTimestampToString(detailInfo[field])}
          onChange={(ev) => {
            const timestamp = parseStringToTimestamp(ev.target.value);
            setDetailInfo({
              ...detailInfo,
              [field]: timestamp,
            });
          }}
          className={classes.textField}
        />
        <Typography variant="h6" className={classes.errorText}>
          {errors[field]}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div className={classes.poolPresaleDetailsCard}>
        <Typography variant="h1" className={classes.poolTitle}>
          Presale Details
        </Typography>
        <div className={classes.poolSettingContainer}>
          <div className={classes.presaleTypeContainer}>
            {renderRadioBox("Presale Type", "presaleType", [
              "Token Sale",
              "Public IDO",
              "Certified START",
            ])}
          </div>
          <div className={classes.poolPresaleDetailInputContainer}>
            {renderInfoInput(
              theme.type === "bsc" ? "BEP20 Token Contract" : "ERC20 Token",
              "tokenAddress"
            )}
            {renderDropdown(
              "Funding Token",
              "fundingTokenAddress",
              fundingTokens[theme.type]
            )}
            {renderInfoInput(
              `SoftCap (${tokens[detailInfo.fundingTokenAddress].label})`,
              "softCapInWei"
            )}
            {renderInfoInput(
              `HardCap (${tokens[detailInfo.fundingTokenAddress].label})`,
              "hardCapInWei"
            )}
            {renderInfoInput("Token Price", "tokenPriceInWei")}
            {detailInfo.presaleType === 0 &&
              renderInfoInput("Token Decimals", "tokenDecimals")}
            {detailInfo.presaleType === 0 &&
              renderInfoInput(
                `Min ${
                  tokens[detailInfo.fundingTokenAddress].label
                } Per Wallet`,
                "minInvestInWei"
              )}
            {detailInfo.presaleType === 0 &&
              renderInfoInput(
                `Max ${
                  tokens[detailInfo.fundingTokenAddress].label
                } Per Wallet`,
                "maxInvestInWei"
              )}
            {detailInfo.presaleType !== 0 &&
              renderInfoInput(
                `${
                  tokens[detailInfo.fundingTokenAddress].label
                } Allocation Factor`,
                "maxInvestInWei"
              )}

            {renderInfoTimePicker(
              "Sale Start Time (in your timezone)",
              "openTime"
            )}
            {renderInfoTimePicker(
              "Sale End Time (in your timezone)",
              "closeTime"
            )}
            {detailInfo.presaleType !== 0 &&
              renderInfoInput("Token Decimals", "tokenDecimals")}
            {renderInfoInput(
              "Unsold Tokens Address",
              "unsoldTokensDumpAddress"
            )}
            {renderInfoInput("HeadStart(in seconds)", "guaranteedHours")}
            {renderInfoInput("Release Per Month(%)", "releasePerCycle")}
            {renderInfoInput("Release Cycle(in seconds)", "releaseCycle")}
          </div>
        </div>
      </div>
      <div className={classes.poolPrevNextWrapper}>
        <Button
          classes={{
            root: classes.walletButton,
            label: classes.buttonLabel,
          }}
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default withNamespaces()(
  withRouter(withStyles(styles)(PresaleDetailsCard))
);
