import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";

import { ReactComponent as FilterIcon } from "../../../assets/filter.svg";

const useStyles = makeStyles((theme) => ({
  filterButton: {
    color: theme?.colors?.wallet?.buttonBackground,
  },
  filterLabel: {
    width: "24px",
    height: "24px",
  },
  filterIcon: {
    width: "24px",
    height: "24px",
    color: theme?.colors?.wallet?.buttonBackground,
  },
  paperRoot: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  menuList: {
    backgroundColor: theme?.colors?.pools?.menuBackground,
    color: theme?.colors?.pools?.text,
    border: "1px solid " + theme?.colors?.pools?.menuBorder,
    borderRadius: "14px",
    padding: "10px 20px",
  },
  menuItemRoot: {
    color: theme?.colors?.pools?.text,
    borderBottom: "solid 1px " + theme?.colors?.pools?.menuBorder,
    padding: "10px",
    width: "130px",
    "&:hover": {
      color: theme?.colors?.pools?.textSelected,
    },
  },
  menuItemSelected: {
    color: theme?.colors?.pools?.textSelected,
  },
}));

export default function MenuListComposition({ menuItems, onChange }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [currentSelection, setCurrentSelection] = React.useState(0);

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    onChange(menuItems[currentSelection]);
  }, [currentSelection]);

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="left-start"
        style={{ zIndex: "6" }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper classes={{ root: classes.paperRoot }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  classes={{ root: classes.menuList }}
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {menuItems.map((item, index) => {
                    return (
                      <MenuItem
                        classes={{
                          root: classes.menuItemRoot,
                          selected: classes.menuItemSelected,
                        }}
                        selected={index == currentSelection}
                        onClick={(ev) => {
                          setCurrentSelection(index);
                          handleClose(ev);
                        }}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-label="filter"
        aria-haspopup="true"
        onClick={handleToggle}
        classes={{ root: classes.filterButton, label: classes.filterLabel }}
      >
        <FilterIcon className={classes.filterIcon} />
      </IconButton>
    </>
  );
}
