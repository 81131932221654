import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment-timezone";
import bigInt from "big-integer";

import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme } from "@material-ui/core";
import { withNamespaces } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import CertifiedBadge from "../../../assets/certified-mark.svg";
import QuestionBadge from "../../../assets/question-badge.png";

import { toFixed, tokens, Certified } from "../../../constants/constants";

const styles = (theme) => ({
  poolInfoCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolMainInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: "25px",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "45px",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  poolTitleAddressContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  poolImage: {
    width: "90px",
    height: "90px",
    marginRight: "15px",
    [theme.breakpoints.up("sm")]: {
      marginRight: "35px",
    },
  },
  poolTitleAddress: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "24px",
    marginBottom: "5px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "30px",
      lineHeight: "36px",
      marginBottom: "10px",
    },
    position: "relative",
  },
  poolDescription: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },

  poolTokenLabel: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  poolTokenAddress: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "10px",
    fontWeight: "normal",
    lineHeight: "12px",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
      fontWeight: "normal",
      lineHeight: "18px",
    },
  },
  poolStatusContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("ms")]: {
      alignItems: "flex-end",
      justifyContent: "flex-end",
      minWidth: "250px",
    },
  },
  poolStatusBox: {
    minWidth: "140px",
    width: "fit-content",
    paddingLeft: "8px",
    paddingRight: "8px",
    height: "32px",
    borderRadius: "24px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.colors.poolCard?.poolTypeText,
    whiteSpace: "nowrap",
  },
  poolListingInfo: {
    marginTop: "25px",
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  poolStatusInfo: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "30px",
    },
  },
  poolSaleInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginBottom: "20px",
    },
  },
  poolSaleText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  poolSaleHeader: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  poolSaleValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "25px",
    fontWeight: "normal",
    lineHeight: "30px",
  },
  poolParticipantsInfo: {
    display: "flex",
    alignItems: "flex-end",
  },
  poolParticipantText: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "25px",
    fontWeight: "bold",
    lineHeight: "30px",
  },
  poolPercentageBox: {
    display: "flex",
    height: "12px",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "12px",
    marginBottom: "4px",
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      height: "16px",
      marginBottom: "8px",
    },
  },
  poolFilledBox: {
    height: "12px",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.addressValueText + " !important",
    borderRadius: "12px",
    position: "absolute",
    left: "0px",
    top: "0px",
    [theme.breakpoints.up("sm")]: {
      height: "16px",
    },
  },
  poolPercentageInfo: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  poolPercentageText: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  poolSubInfoSecondaryContainer: {
    display: "grid",
    width: "100%",
    gridGap: "15px",
    gridTemplateColumns: "auto auto",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "auto auto",
      gridGap: "20px",
    },
    [theme.breakpoints.up("ms")]: {
      gridTemplateColumns: "33% 33% 33%",
      gridGap: "25px",
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "auto auto auto auto",
      gridGap: "30px",
    },
  },
  poolSubInfoContainer: {
    display: "grid",
    width: "100%",
    gridGap: "15px",
    gridTemplateColumns: "auto auto",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "auto auto",
      gridGap: "20px",
    },
    [theme.breakpoints.up("ms")]: {
      gridTemplateColumns: "33% 33% 33%",
      gridGap: "25px",
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "auto auto auto auto auto",
      gridGap: "30px",
    },
  },

  poolSubInfoV3Container: {
    display: "grid",
    width: "100%",
    gridGap: "15px",
    gridTemplateColumns: "auto auto",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "auto auto",
      gridGap: "20px",
    },
    [theme.breakpoints.up("ms")]: {
      gridTemplateColumns: "33% 33% 33%",
      gridGap: "25px",
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "auto auto auto auto",
      gridGap: "30px",
    },
  },
  poolSubInfoCard: {
    display: "flex",
    flexDirection: "column",
  },
  poolSubInfoHeading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "4px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8px",
    },
  },
  poolSubInfoValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
  },
  badge: {
    position: "absolute",
    right: "-40px",
    width: "30px",
    height: "30px",
  },
});

const getPoolType = (
  isCancelled,
  isStarted,
  isEnded,
  totalCollected,
  hardCap,
  softCap
) => {
  if (isCancelled) return "CANCELLED";
  if (totalCollected >= hardCap) return "FILLED";
  if (isEnded) {
    if (totalCollected < softCap) {
      return "EXPIRED";
    }
    return "CLOSED";
  }
  if (!isStarted) {
    return "NOTOPENED";
  }
  return "ONGOING";
};

const getStatusBarColor = (poolType) => {
  if (poolType === "FILLED") return "GREEN";
  if (poolType === "CLOSED") return "ORANGE";
  if (poolType === "NOTOPENED") return "YELLOW";
  if (poolType === "ONGOING") return "YELLOW";
  if (poolType === "EXPIRED") return "RED";
  return "RED";
};

const getRemainingTime = (remainingTime) => {
  const remainingDay = Math.floor(remainingTime / 3600 / 24);
  const remainingHours = Math.floor(remainingTime / 3600);
  const remainingMinutes = Math.floor(remainingTime / 60);
  const remainingSeconds = Math.floor(remainingTime);

  if (remainingDay > 0) {
    if (remainingDay === 1) {
      return `${remainingDay} day`;
    }
    return `${remainingDay} days`;
  }
  const _remainingHours =
    remainingHours < 10 ? `0${remainingHours}` : `${remainingHours}`;
  const _remainingMins =
    remainingMinutes % 60 < 10
      ? `0${remainingMinutes % 60}`
      : `${remainingMinutes % 60}`;
  const _remainingSecs =
    remainingSeconds % 60 < 10
      ? `0${remainingSeconds % 60}`
      : `${remainingSeconds % 60}`;
  return `${_remainingHours}:${_remainingMins}:${_remainingSecs}`;
};

const MainInfoCard = ({ classes, poolInfo }) => {
  const [logoImage, setLogoImage] = useState(
    poolInfo.linkLogo || "pools/default.png"
  );
  const [timeRemaining, setTimeRemaining] = useState("");
  const theme = useTheme();
  const listingTime = moment
    .unix(poolInfo.cakeLiquidityAddingTime)
    .tz("Etc/GMT")
    .format("MMMM DD, YYYY HH:mm A z");

  const fundingToken = tokens[poolInfo.fundingTokenAddress];

  const poolSubInfo = (title, info, type = "string", decimals = 0) => {
    let poolInfoItem = poolInfo[info] || 0;
    if (type === "start") {
      poolInfoItem =
        toFixed(
          bigInt(poolInfoItem),
          poolInfo?.fundingTokenDecimals,
          decimals
        ) +
        " " +
        poolInfo.stakingTokenName;
    }
    if (type === "votes") {
      poolInfoItem =
        toFixed(bigInt(poolInfoItem), 18, decimals) +
        " " +
        poolInfo.stakingTokenName;
    }
    if (type === "wei") {
      poolInfoItem =
        toFixed(
          bigInt(poolInfoItem),
          poolInfo?.fundingTokenDecimals,
          poolInfo?.fundingTokenDecimals >= 18 || decimals < 6 ? decimals : 5
        ) +
        " " +
        fundingToken?.label;
    }
    if (type === "headstart") {
      poolInfoItem = parseInt(poolInfoItem) / 60 + " mins";
    }
    if (type === "days") {
      if (parseInt(poolInfoItem) > 1) {
        poolInfoItem = poolInfoItem + " days";
      } else {
        poolInfoItem = poolInfoItem + " day";
      }
    }
    if (type === "percent") {
      poolInfoItem = poolInfoItem + "%";
    }
    if (type === "time") {
      poolInfoItem = moment
        .unix(poolInfoItem)
        .tz("Etc/GMT")
        .format("MMMM DD, YYYY HH:mm A z");
    }
    if (type === "cycle") {
      poolInfoItem = parseInt(poolInfoItem);
      if (poolInfoItem >= 3600 * 24) {
        if (poolInfoItem / (3600 * 24) > 1) {
          poolInfoItem = parseFloat(poolInfoItem / (3600 * 24)) + " days";
        } else {
          poolInfoItem = parseFloat(poolInfoItem / (3600 * 24)) + " day";
        }
      } else if (poolInfoItem >= 3600) {
        poolInfoItem = parseFloat(poolInfoItem / 3600) + " hours";
      } else if (poolInfoItem >= 60) {
        poolInfoItem = parseFloat(poolInfoItem / 60) + " minutes";
      } else {
        poolInfoItem = poolInfoItem + " seconds";
      }
    }
    if (type === "presaleType") {
      const pTypes = ["Token Sale", "Public Presale", "Certified START"];
      poolInfoItem = pTypes[poolInfoItem];
    }
    if (type === "category") {
      const categories = ["DeFi", "Farming", "Games"];
      poolInfoItem = categories[poolInfoItem];
    }
    return (
      <div className={classes.poolSubInfoCard}>
        <Typography variant="h5" className={classes.poolSubInfoHeading}>
          {title}
        </Typography>
        <Typography variant="h5" className={classes.poolSubInfoValue}>
          {poolInfoItem}
        </Typography>
      </div>
    );
  };

  const isStarted = moment.unix(poolInfo.openTime).isBefore(moment.now());
  const isEnded = moment.unix(poolInfo.closeTime).isBefore(moment.now());

  const totalCollected = parseFloat(
    toFixed(
      bigInt(poolInfo?.totalCollectedWei || "0"),
      poolInfo?.fundingTokenDecimals,
      3
    )
  );
  const hardCapWei = parseFloat(
    toFixed(
      bigInt(poolInfo?.hardCapInWei || "0"),
      poolInfo?.fundingTokenDecimals,
      3
    )
  );
  const softCapWei = parseFloat(
    toFixed(
      bigInt(poolInfo?.softCapInWei || "0"),
      poolInfo?.fundingTokenDecimals,
      3
    )
  );

  const percentage = parseFloat((totalCollected / hardCapWei) * 100);
  const tokenPriceInWeiText = toFixed(
    bigInt(poolInfo?.tokenPriceInWei || "0"),
    parseInt(poolInfo?.fundingTokenDecimals || 18),
    poolInfo?.fundingTokenDecimals >= 18 ? 12 : 5
  );
  const tokenPriceInWei = parseFloat(
    toFixed(
      bigInt(poolInfo?.tokenPriceInWei || "0"),
      parseInt(poolInfo?.fundingTokenDecimals || 18),
      poolInfo?.fundingTokenDecimals >= 18 ? 12 : 5
    )
  );

  const poolType = getPoolType(
    poolInfo?.presaleCancelled,
    isStarted,
    isEnded,
    totalCollected,
    hardCapWei,
    softCapWei
  );

  const fillBarColorId = getStatusBarColor(poolType);
  useEffect(() => {
    setLogoImage(poolInfo.linkLogo);
    const interval = setInterval(() => {
      const isStarted = moment.unix(poolInfo.openTime).isBefore(moment.now());
      const isEnded = moment.unix(poolInfo.closeTime).isBefore(moment.now());
      const currentTimestamp = moment.now() / 1000;
      const remainingTime = isEnded
        ? 0
        : isStarted
        ? poolInfo.closeTime - currentTimestamp
        : poolInfo.openTime - currentTimestamp;
      setTimeRemaining(getRemainingTime(remainingTime));
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [poolInfo]);

  return (
    <div className={classes.poolInfoCard}>
      <div className={classes.poolMainInfo}>
        <div className={classes.poolTitleAddressContainer}>
          <img
            className={classes.poolImage}
            src={logoImage}
            alt="pool"
            onError={(er) => {
              setLogoImage("pools/default.png");
            }}
          />
          <div className={classes.poolTitleAddress}>
            <Typography variant="h1" className={classes.poolTitle}>
              {poolInfo.saleTitle || "..."}
              {poolInfo.presaleType == "2" ||
              Certified[theme?.type][poolInfo.presaleIndex] ? (
                <Tooltip title="Certified START">
                  <img
                    src={theme?.certifiedBadge || CertifiedBadge}
                    className={classes.badge}
                    alt="badge"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Non-Certified START">
                  <img
                    src={QuestionBadge}
                    className={classes.badge}
                    alt="badge"
                  />
                </Tooltip>
              )}
            </Typography>
            {poolInfo.presaleVersion === "V1" ? (
              <Typography variant="h5" className={classes.poolTokenLabel}>
                Token Address
              </Typography>
            ) : (
              <Typography variant="h1" className={classes.poolDescription}>
                {poolInfo.description || "..."}
              </Typography>
            )}
            <a
              className={classes.link}
              href={theme?.blockExplorer + "address/" + poolInfo.tokenAddress}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="h5" className={classes.poolTokenAddress}>
                {poolInfo.tokenAddress}
              </Typography>
            </a>
          </div>
        </div>
        <div className={classes.poolStatusContainer}>
          <div
            className={classes.poolStatusBox}
            style={{
              backgroundColor: theme?.colors.poolCard?.poolType[poolType],
            }}
          >
            {poolType === "CANCELLED" && "cancelled"}
            {poolType === "EXPIRED" && "expired "}
            {poolType === "FILLED" && "filled"}
            {poolType === "ONGOING" && "ending " + timeRemaining}
            {poolType === "NOTOPENED" && "opens in " + timeRemaining}
          </div>
          {(poolInfo?.presaleType != 0 ||
            poolInfo?.presaleIndex == 171 ||
            poolInfo.presaleIndex == 172) && (
            <Typography variant="h5" className={classes.poolListingInfo}>
              Listing: {listingTime}
            </Typography>
          )}
        </div>
      </div>
      <div className={classes.poolStatusInfo}>
        <div className={classes.poolSaleInfo}>
          <div className={classes.poolSaleText}>
            <Typography variant="h5" className={classes.poolSaleHeader}>
              {tokenPriceInWeiText} {fundingToken?.label} per Token
            </Typography>
            <Typography variant="h5" className={classes.poolSaleValue}>
              {totalCollected.toFixed(3)} {fundingToken?.label} Raised
            </Typography>
          </div>
          <div className={classes.poolParticipantsInfo}>
            <Typography variant="h5" className={classes.poolParticipantText}>
              {poolInfo.totalInvestorsCount || 0} Participants
            </Typography>
          </div>
        </div>
        <div className={classes.poolPercentageBox}>
          <div
            className={classes.poolFilledBox}
            style={{
              width: percentage <= 100 ? `${percentage}%` : "100%",
              backgroundColor:
                theme?.colors.poolCard?.statusBarFilled[fillBarColorId],
            }}
          />
        </div>
        <div className={classes.poolPercentageInfo}>
          <Typography variant="h5" className={classes.poolPercentageText}>
            {hardCapWei
              ? ((totalCollected / hardCapWei) * 100).toFixed(3)
              : "0"}
            % (Min{" "}
            {hardCapWei ? ((softCapWei / hardCapWei) * 100).toFixed(3) : "0"}
            %)
          </Typography>
          <Typography variant="h5" className={classes.poolPercentageText}>
            {toFixed(
              bigInt(poolInfo?.totalCollectedWei || "0"),
              poolInfo?.fundingTokenDecimals,
              3
            )}{" "}
            /&nbsp;
            {toFixed(
              bigInt(poolInfo?.hardCapInWei) || "0",
              poolInfo?.fundingTokenDecimals,
              3
            )}{" "}
            {fundingToken?.label}
          </Typography>
        </div>
      </div>

      {poolInfo.presaleVersion !== "V3" && (
        <div className={classes.poolSubInfoContainer}>
          {poolSubInfo("Softcap", "softCapInWei", "wei", 3)}
          {poolSubInfo("Min Per Wallet", "minInvestInWei", "wei", 4)}
          {poolSubInfo("Presale Rate", "tokenPriceInWei", "wei", 8)}
          {poolSubInfo(
            "Liquidity Allocation",
            "cakeLiquidityPercentageAllocation",
            "percent"
          )}
          {poolSubInfo("Open Time", "openTime", "time")}

          {poolSubInfo("Hardcap", "hardCapInWei", "wei", 3)}
          {poolSubInfo("BNB Allocation Factor", "maxInvestInWei", "wei", 4)}
          {theme?.type === "bsc" &&
            poolSubInfo(
              "PancakeSwap Listing Rate",
              "cakeListingPriceInWei",
              "wei",
              6
            )}
          {theme?.type === "mtc" &&
            poolSubInfo(
              "QuickSwap Listing Rate",
              "cakeListingPriceInWei",
              "wei",
              6
            )}

          {theme?.type === "eth" &&
            poolSubInfo(
              "Uniswap Listing Rate",
              "cakeListingPriceInWei",
              "wei",
              6
            )}
          {theme?.type === "ftm" &&
            poolSubInfo(
              "SpiritSwap Listing Rate",
              "cakeListingPriceInWei",
              "wei",
              6
            )}
          {poolSubInfo(
            "Liquidity Lock Duration",
            "cakeLPTokensLockDurationInDays",
            "days"
          )}
          {poolSubInfo("Close Time", "closeTime", "time")}

          {poolInfo.presaleVersion !== "V1" && (
            <>
              {poolSubInfo("PresaleType", "presaleType", "presaleType")}
              {poolInfo.presaleType === 1 &&
                poolSubInfo("Headstart", "guaranteedHours", "headstart")}
              {poolSubInfo("Category", "categoryId", "category")}
            </>
          )}
          {poolSubInfo("Yes Votes", "yesVotes", "votes")}
          {poolSubInfo("No Votes", "noVotes", "votes")}
        </div>
      )}
      {poolInfo?.presaleVersion === "V3" && (
        <div
          className={
            poolInfo?.presaleType !== 0
              ? classes.poolSubInfoV3Container
              : classes.poolSubInfoSecondaryContainer
          }
        >
          {poolSubInfo("Softcap", "softCapInWei", "wei")}
          {/* {poolSubInfo("Min Per Wallet", "minInvestInWei", "wei", 4)} */}
          {poolSubInfo("Presale Rate", "tokenPriceInWei", "wei", 12)}
          {poolInfo?.presaleType !== 0 &&
            poolSubInfo(
              "Liquidity Allocation",
              "cakeLiquidityPercentageAllocation",
              "percent"
            )}
          {poolSubInfo("Open Time", "openTime", "time")}

          {poolInfo?.presaleType === 0 &&
            poolSubInfo("Min Per Wallet", "minInvestInWei", "wei", 4)}

          {poolSubInfo("Hardcap", "hardCapInWei", "wei")}
          {/* {poolSubInfo("BNB Allocation Factor", "maxInvestInWei", "wei", 4)} */}
          {poolInfo?.presaleType !== 0 &&
            theme?.type === "bsc" &&
            poolSubInfo(
              "PancakeSwap Listing Rate",
              "cakeListingPriceInWei",
              "wei",
              12
            )}

          {poolInfo?.presaleType !== 0 &&
            theme?.type === "mtc" &&
            poolSubInfo(
              "QuickSwap Listing Rate",
              "cakeListingPriceInWei",
              "wei",
              6
            )}
          {poolInfo?.presaleType !== 0 &&
            theme?.type === "eth" &&
            poolSubInfo(
              "Uniswap Listing Rate",
              "cakeListingPriceInWei",
              "wei",
              6
            )}
          {poolInfo?.presaleType !== 0 &&
            theme?.type === "ftm" &&
            poolSubInfo(
              "SpiritSwap Listing Rate",
              "cakeListingPriceInWei",
              "wei",
              6
            )}
          {poolInfo?.presaleType !== 0 &&
            poolSubInfo(
              "Liquidity Lock Duration",
              "cakeLPTokensLockDurationInDays",
              "days"
            )}
          {poolInfo?.presaleType === 0 &&
            poolSubInfo("Presale Type", "presaleType", "presaleType")}
          {poolSubInfo("Close Time", "closeTime", "time")}
          {poolInfo?.presaleType === 0 &&
            poolSubInfo("Max Per Wallet", "maxInvestInWei", "wei", 4)}

          {poolInfo?.presaleType === 1 &&
            poolSubInfo("Presale Type", "presaleType", "presaleType")}

          {poolInfo?.presaleType !== 0 &&
            poolSubInfo("Maximum Investment", "maxInvestAmount", "wei", 4)}
          {poolInfo?.presaleType !== 0 &&
            poolSubInfo("Headstart", "guaranteedHours", "headstart")}
          {/* {poolSubInfo("Category", "categoryId", "category")} */}
          {poolInfo?.presaleType === 1 &&
            poolSubInfo("Yes Votes", "yesVotes", "votes")}
          {poolInfo?.presaleType === 1 &&
            poolSubInfo("No Votes", "noVotes", "votes")}

          {poolSubInfo("Release Per Cycle", "releasePerCycle", "percent")}
          {poolSubInfo("Release Cycle", "releaseCycle", "cycle")}
        </div>
      )}
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(MainInfoCard)));
