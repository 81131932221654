import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import CheckMarkIcon from "../../../assets/check.svg";
import WarningIcon from "../../../assets/warning.svg";

const styles = (theme) => ({
  poolKycCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  auditInformation: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
  },
  auditNameContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    color: theme?.colors?.presale?.cardText,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
  },
  auditVerifiedMark: {
    display: "flex",
    backgroundColor: theme?.colors?.presale?.verifiedBg,
    borderRadius: "24px",
    padding: "8px 25px",
    height: "32px",
    color: theme?.colors?.presale?.cardTitleSecondary,
    textAlign: "left",
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0px",
  },
  auditFailedMark: {
    display: "flex",
    backgroundColor: theme?.colors?.presale?.warningBg,
    borderRadius: "24px",
    padding: "8px 25px",
    height: "32px",
    color: theme?.colors?.presale?.cardTitleSecondary,
    textAlign: "left",
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0px",
  },
  auditName: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "0px",
  },
  text: {
    color: theme?.colors?.presale?.cardText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  hashText: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  textSecondary: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
  },
  kycGif: {
    cursor: "pointer",
    width: "fit-content",
    height: "200px",
    [theme.breakpoints.up("sm")]: {
      height: "300px",
    },
  },
});

const KycCard = ({ classes, poolInfo }) => {
  const kycInformation = poolInfo.kycInformation;

  return (
    <div className={classes.poolKycCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        GameWin has successfully been KYC'd by{" "}
        <a
          className={classes.poolTitle}
          href={"https://assuredefi.io"}
          target="_blank"
          rel="noreferrer noopener"
        >
          Assure (AssureDefi.io).
        </a>
      </Typography>

      {kycInformation && (
        <div className={classes.auditInformation}>
          <img
            src={
              "https://www.assuredefi.io/wp-content/uploads/2021/07/GameWin-NFT.gif"
            }
            alt="kyc"
            className={classes.kycGif}
            onClick={() => {
              window.open(kycInformation, "_blank");
            }}
          />
        </div>
      )}
      {!kycInformation && (
        <Typography variant="h5" className={classes.textSecondary}>
          No KYC.
        </Typography>
      )}
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(KycCard)));
