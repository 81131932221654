import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  useTheme,
  Button,
  Input,
  TextField,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { withNamespaces } from "react-i18next";

import Web3 from "web3";
import { ReactComponent as LogoSvg } from "../../../assets/pancakeswap-cake-logo.svg";

const styles = (theme) => ({
  poolProductionInfoCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.apply?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.apply?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",

    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  poolPresaleDetailInputContainer: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridGap: "20px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "auto auto",
      gridGap: "30px",
    },
  },

  poolInfoInput: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  poolInfoInputHeader: {
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: theme?.colors?.apply?.cardTitleSecondary,
    textTransform: "capitalize",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "16px",
    },
  },

  poolInfoDropdownBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",

    ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "transparent !important",
    },
  },

  poolInfoDropdownBoxHeader: {
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: theme?.colors?.apply?.cardTitleSecondary,
    textTransform: "capitalize",
    maxWidth: "200px",
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "16px",
    },
  },

  text: {
    color: theme?.colors?.apply?.cardText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  textSecondary: {
    color: theme?.colors?.apply?.cardTitleSecondary,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
  },

  actionInput: {
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: theme?.colors?.apply?.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.apply?.cardTitleSecondary,
  },
  poolPrevNextWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  walletButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "8px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  applyButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginRight: "8px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  errorText: {
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    color: theme?.colors?.apply?.warningBg,
    marginTop: "4px",
    marginRight: "4px",
    textAlign: "right",
  },
  textField: {
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: theme?.colors?.apply?.cardText,
    fontSize: "20px",
    outline: "0px",
    "& > div > input": {
      color: theme?.colors?.apply?.cardText,
    },
  },
  outlinedDropdown: {
    borderRadius: "14px",
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    color: theme?.colors?.apply?.cardText,
    fontSize: "20px",
    outline: "0px",
    padding: "7.5px",
  },
  iconOutlined: {
    color: theme?.colors?.apply?.cardTitleSecondary,
  },
  dropdownRoot: {
    "& > .MuiOutlinedInput-root": {
      border: "transparent !important",
    },
  },
  dropdownSelected: {
    borderRadius: "14px",
  },
});

const ProductionInfoCard = ({ classes, value, onNext, onBack }) => {
  const [productionInfo, setProductionInfo] = useState({
    saleTitle: value.saleTitle || "",
    linkTelegram: value.linkTelegram || "",
    linkGithub: value.linkGithub || "",
    linkTwitter: value.linkTwitter || "",
    linkWebsite: value.linkWebsite || "",
    linkLogo: value.linkLogo || "",
    kycInformation: value.kycInformation || "",
    description: value.description || "",
    whitepaper: value.whitepaper || "",
    categoryId: value.categoryId || 0,
  });
  const [errors, setErrors] = useState({});

  const handleBack = () => {
    onBack();
  };

  const handleNext = () => {
    let isCompleted = true;
    let updatedErrors = {};
    if (!productionInfo.saleTitle || productionInfo.saleTitle.length > 32) {
      updatedErrors = {
        ...updatedErrors,
        saleTitle: "Invalid value. (must less than 32 characters)",
      };
      isCompleted = false;
    }
    if (
      !productionInfo.linkTelegram ||
      productionInfo.linkTelegram.length > 32
    ) {
      updatedErrors = {
        ...updatedErrors,
        linkTelegram: "Invalid value. (must less than 32 characters)",
      };
      isCompleted = false;
    }
    if (!productionInfo.linkGithub || productionInfo.linkGithub.length > 32) {
      updatedErrors = {
        ...updatedErrors,
        linkGithub: "Invalid value. (must less than 32 characters)",
      };
      isCompleted = false;
    }
    if (!productionInfo.linkTwitter || productionInfo.linkTwitter.length > 32) {
      updatedErrors = {
        ...updatedErrors,
        linkTwitter: "Invalid value. (must less than 32 characters)",
      };
      isCompleted = false;
    }
    if (!productionInfo.linkWebsite || productionInfo.linkWebsite.length > 32) {
      updatedErrors = {
        ...updatedErrors,
        linkWebsite: "Invalid value. (must less than 32 characters)",
      };
      isCompleted = false;
    }
    if (!productionInfo.linkLogo) {
      updatedErrors = {
        ...updatedErrors,
        linkLogo: "Invalid value.",
      };
      isCompleted = false;
    }
    setErrors(updatedErrors);
    if (isCompleted) {
      onNext(productionInfo);
    }
  };

  const renderInfoInput = (title, field, placeholder) => {
    return (
      <div className={classes.poolInfoInput}>
        <Typography variant="h4" className={classes.poolInfoInputHeader}>
          {title}
        </Typography>
        <Input
          classes={{
            root: classes.actionInput,
            underline: classes.actionUnderline,
          }}
          disableUnderline={true}
          value={productionInfo[field]}
          onChange={(ev) => {
            setProductionInfo({
              ...productionInfo,
              [field]: ev.target.value,
            });
          }}
          placeholder={placeholder}
          autoFocus={true}
        />
        <Typography variant="h6" className={classes.errorText}>
          {errors[field]}
        </Typography>
      </div>
    );
  };

  const renderDropdown = (title, field, selections) => {
    return (
      <div className={classes.poolInfoDropdownBox}>
        <Typography variant="h4" className={classes.poolInfoDropdownBoxHeader}>
          {title}
        </Typography>
        <div className={classes.poolInfoDropdownBox}>
          <Select
            variant="outlined"
            id={`${field}-label`}
            value={productionInfo[field]}
            onChange={(ev) => {
              setProductionInfo({
                ...productionInfo,
                [field]: parseInt(ev.target.value),
              });
            }}
            disableUnderline={true}
            classes={{
              outlined: classes.outlinedDropdown,
              iconOutlined: classes.iconOutlined,
              select: classes.dropdownSelected,
            }}
            input={<Input classes={{ root: classes.dropdownRoot }} />}
          >
            {selections.map((item, index) => {
              return <MenuItem value={index}>{item}</MenuItem>;
            })}
          </Select>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.poolProductionInfoCard}>
        <Typography variant="h1" className={classes.poolTitle}>
          Product Information
        </Typography>
        <div className={classes.poolPresaleDetailInputContainer}>
          {renderInfoInput("Sale Title", "saleTitle")}
          {renderInfoInput("Short Description", "description")}
          {renderInfoInput("Website", "linkWebsite")}
          {renderInfoInput("Telegram(Username only)", "linkTelegram")}
          {renderInfoInput("Github(Username only)", "linkGithub")}
          {renderInfoInput("Twitter(Username only)", "linkTwitter")}
          {renderInfoInput(
            "Logo",
            "linkLogo",
            "fully-qualified URL + 32 characters or less"
          )}
          {renderInfoInput("KYC Information", "kycInformation")}
          {renderInfoInput("Whitepaper", "whitepaper")}
          {renderDropdown("Category", "categoryId", [
            "DeFi",
            "Farming",
            "Games",
          ])}
        </div>
      </div>
      <div className={classes.poolPrevNextWrapper}>
        <Button
          classes={{
            root: classes.applyButton,
            label: classes.buttonLabel,
          }}
          onClick={() => {
            handleBack();
          }}
        >
          Back
        </Button>
        <Button
          classes={{
            root: classes.walletButton,
            label: classes.buttonLabel,
          }}
          onClick={() => {
            handleNext();
          }}
        >
          Finish
        </Button>
      </div>
    </>
  );
};

export default withNamespaces()(
  withRouter(withStyles(styles)(ProductionInfoCard))
);
