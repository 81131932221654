import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import bigInt from "big-integer";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Typography,
  Button,
  Fade,
  Snackbar,
  useTheme,
  CircularProgress,
} from "@material-ui/core";

import { withNamespaces } from "react-i18next";

import PoolMainInfoCard from "./components/mainInfoCard";
import InvestmentCard from "./components/investCard";
import LinkCard from "./components/linkCard";
import KycCard from "./components/kycCard";
import DisclaimerCard from "./components/disclaimerCard";

import BuyModal from "./components/modal";

import Store from "../../stores/store";

import {
  CLAIM,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  GET_PRESALE_INFO,
  GET_PRESALE_INFO_RETURNED,
  INVEST,
  INVEST_RETURNED,
  toFixed,
} from "../../constants/constants";

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "fit-content",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "1140px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 4,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: "white",
    position: "absolute",
    top: "100px",
    left: "50%",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PresalePage = ({ classes, location }) => {
  const history = useHistory();
  const [whitelisted, setWhitelisted] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [poolInfo, setPoolInfo] = useState(
    store.getStore("presales")["starchiPrivateSale"]
  );
  const [account, setAccount] = useState(null);
  const [openBuyModal, showBuyModal] = useState(false);

  useEffect(() => {
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    emitter.on(GET_PRESALE_INFO_RETURNED, getPresaleInfoReturned);
    dispatcher.dispatch({
      type: GET_PRESALE_INFO,
      content: { presaleIndex: "starchiPrivateSale" },
    });
    const account = store.getStore("account");
    if (account) {
      setAccount(account);
    }
    return () => {
      emitter.removeListener(GET_PRESALE_INFO_RETURNED, getPresaleInfoReturned);
    };
  }, []);

  const connectionConnected = () => {
    setAccount(store.getStore("account"));
    dispatcher.dispatch({
      type: GET_PRESALE_INFO,
      content: { presaleIndex: "starchiPrivateSale" },
    });
  };

  const connectionDisconnected = () => {
    setAccount(null);
  };

  const getPresaleInfoReturned = () => {
    setPoolInfo(store.getStore("presales")["starchiPrivateSale"]);
    if (account) {
      window.setTimeout(() => {
        dispatcher.dispatch({
          type: GET_PRESALE_INFO,
          content: { presaleIndex: "starchiPrivateSale" },
        });
      }, 5000);
    }
  };

  const onClaim = () => {
    if (poolInfo.claimAllowed) {
      dispatcher.dispatch({
        type: CLAIM,
        content: {
          presaleIndex: "starchiPrivateSale",
        },
      });
    } else {
      setErrorMessage("Claim is not allowed yet.");
    }
  };

  const onGetRefund = () => {};

  const onInvest = () => {
    const isWhitelisted = poolInfo.isWhitelistedAddress;
    if (!isWhitelisted) {
      setErrorMessage("You are not whitelisted...");
      return;
    }
    const isExpired = moment.unix(poolInfo.closeTime).isBefore(moment.now());
    if (!isExpired) {
      showBuyModal(true);
    } else {
      setErrorMessage("Sale Expired...");
    }
  };

  const onLockLiq = () => {};

  const onBuy = (inputAmount) => {
    dispatcher.dispatch({
      type: INVEST,
      content: {
        presaleIndex: "starchiPrivateSale",
        amount: inputAmount,
        decimals: 6,
      },
    });
  };

  console.log(poolInfo);

  return (
    <div className={classes.root}>
      {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={3000}
          onClick={() => {
            setErrorMessage(false);
          }}
          onClose={() => {
            setErrorMessage(false);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      )}
      {whitelisted &&
        poolInfo.isWhitelistedAddress &&
        poolInfo.cakeLiquidityAddingTime && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={true}
            onClick={() => {
              setWhitelisted(false);
            }}
            onClose={() => {
              setWhitelisted(false);
            }}
          >
            <Alert severity={"info"}>
              {poolInfo.isWhitelistedAddress &&
                "Congrats, you are on the whitelist for this presale. Good luck!"}
            </Alert>
          </Snackbar>
        )}

      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          {(!poolInfo.kycInformation || !poolInfo.openTime) && (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loadingIcon} size={60} />
            </div>
          )}
          <PoolMainInfoCard poolInfo={poolInfo} />
          <InvestmentCard
            yourInfo={poolInfo}
            onClaim={onClaim}
            onRefund={onGetRefund}
            onBuy={onInvest}
            onLockLiq={onLockLiq}
            account={account}
          />
          {poolInfo?.kycInformation && <KycCard poolInfo={poolInfo} />}
          <LinkCard poolInfo={poolInfo} />
          <DisclaimerCard />
          <BuyModal
            closeModal={() => {
              showBuyModal(false);
            }}
            modalOpen={openBuyModal}
            tokenPrice={parseFloat(
              toFixed(bigInt(poolInfo.tokenPriceInWei), 6, 4)
            )}
            minInvestAmount={parseFloat(
              toFixed(bigInt(poolInfo.minInvestInWei), 6, 4)
            )}
            maxInvestAmount={parseFloat(
              toFixed(bigInt(poolInfo.maxInvestInWei), 6, 4)
            )}
            onBuy={onBuy}
          />
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(PresalePage)));
