import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import bigInt from "big-integer";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Typography,
  Fade,
  Snackbar,
  CircularProgress,
  useTheme,
} from "@material-ui/core";

import { withNamespaces } from "react-i18next";

import PoolMainInfoCard from "./components/mainInfoCard";
import InvestmentCard from "./components/investCard";
import LinkCard from "./components/linkCard";
import DisclaimerCard from "./components/disclaimerCard";
import AuditCard from "./components/auditCard";
import KYCCard from "./components/kycCard";
import ManageCard from "./components/manageCard";

import BuyModal from "./components/modal";
import VoteModal from "./components/voteModal";
import RegionBanModal from "./components/regionBanModal";

import { THEME_CHANGE_RETURNED, tokens } from "../../constants";

import Store from "../../stores/store";

import {
  PUBLIC_CLAIM,
  CONNECTION_CONNECTED,
  CONNECTION_DISCONNECTED,
  GET_PUBLIC_PRESALE_INFO,
  GET_PUBLIC_PRESALE_INFO_RETURNED,
  PUBLIC_INVEST,
  GET_BALANCES_RETURNED,
  LOCK_LIQUIDITY,
  VOTE,
  COLLECT_FUND,
  CANCEL_PRESALE,
  SEND_UNSOLD_TOKENS,
  ALLOW_CLAIM,
  GET_REFUND,
  toFixed,
  _getBanned,
} from "../../constants/constants";
import InpulseLinkCard from "./components/inpulseLinkCard";

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "1140px",
    padding: "30px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme?.colors?.presale?.loadingBackground,
    zIndex: 4,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: theme?.colors?.presale?.loadingIcon,
    position: "absolute",
    top: "100px",
    left: "50%",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PresalePage = ({ classes, location }) => {
  const history = useHistory();
  const { bscsId } = useParams();
  const theme = useTheme();
  const [isBannedLocation, setBannedLocation] = useState(false);
  const [whitelisted, setWhitelisted] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [poolInfo, setPoolInfo] = useState(
    (store.getStore("publicPresales") &&
      store.getStore("publicPresales")[bscsId]) ||
      {}
  );
  const [account, setAccount] = useState(null);
  const [openBuyModal, showBuyModal] = useState(false);
  const [openVoteModal, showVoteModal] = useState(false);
  const [openBannedModal, showBannedModal] = useState(false);

  const fetchBanned = async () => {
    const _isBanned = await _getBanned();
    setBannedLocation(_isBanned);
  };

  useEffect(() => {
    emitter.on(CONNECTION_CONNECTED, connectionConnected);
    emitter.on(CONNECTION_DISCONNECTED, connectionDisconnected);
    emitter.on(GET_PUBLIC_PRESALE_INFO_RETURNED, getPresaleInfoReturned);
    emitter.on(THEME_CHANGE_RETURNED, themeChanged);
    const account = store.getStore("account");
    if (account) {
      setAccount(account);
    }
    dispatcher.dispatch({
      type: GET_PUBLIC_PRESALE_INFO,
      content: { presaleIndex: bscsId },
    });
    fetchBanned();
    return () => {
      emitter.removeListener(
        GET_PUBLIC_PRESALE_INFO_RETURNED,
        getPresaleInfoReturned
      );
    };
  }, []);

  const themeChanged = () => {
    history.push("/");
  };

  const connectionConnected = () => {
    setAccount(store.getStore("account"));
    dispatcher.dispatch({
      type: GET_PUBLIC_PRESALE_INFO,
      content: { presaleIndex: bscsId },
    });
  };

  const connectionDisconnected = () => {
    setAccount(null);
  };

  const getPresaleInfoReturned = () => {
    setPoolInfo(store.getStore("publicPresales")[bscsId]);
    window.setTimeout(() => {
      dispatcher.dispatch({
        type: GET_PUBLIC_PRESALE_INFO,
        content: { presaleIndex: bscsId },
      });
    }, 5000);
  };

  const onClaim = () => {
    dispatcher.dispatch({
      type: PUBLIC_CLAIM,
      content: {
        presaleIndex: bscsId,
      },
    });
  };

  const onVoteClicked = () => {
    showVoteModal(true);
  };

  const onVote = (type) => {
    dispatcher.dispatch({
      type: VOTE,
      content: {
        presaleIndex: bscsId,
        voteType: type,
      },
    });
  };

  const onCollect = () => {
    dispatcher.dispatch({
      type: COLLECT_FUND,
      content: {
        presaleIndex: bscsId,
      },
    });
  };

  const onCancelPresale = () => {
    dispatcher.dispatch({
      type: CANCEL_PRESALE,
      content: {
        presaleIndex: bscsId,
      },
    });
  };

  const onSendUnsoldTokens = () => {
    dispatcher.dispatch({
      type: SEND_UNSOLD_TOKENS,
      content: {
        presaleIndex: bscsId,
      },
    });
  };

  const onAllowClaim = () => {
    dispatcher.dispatch({
      type: ALLOW_CLAIM,
      content: {
        presaleIndex: bscsId,
      },
    });
  };

  const onGetRefund = () => {
    dispatcher.dispatch({
      type: GET_REFUND,
      content: {
        presaleIndex: bscsId,
      },
    });
  };

  const onInvest = () => {
    const isWhitelisted = poolInfo.isWhitelistedAddress;
    if (!isWhitelisted) {
      setErrorMessage("You are not whitelisted...");
      return;
    }
    if (poolInfo.isBanned && isBannedLocation) {
      showBannedModal(poolInfo.isBanned);
    } else {
      const isExpired = moment.unix(poolInfo.closeTime).isBefore(moment.now());
      if (!isExpired) {
        showBuyModal(true);
      } else {
        setErrorMessage("Sale Expired...");
      }
    }
  };

  const onLockLiq = () => {
    const isExpired = moment
      .unix(poolInfo.cakeLiquidityAddingTime)
      .isBefore(moment.now());
    const totalCollected = parseFloat(
      toFixed(
        bigInt(poolInfo?.totalCollectedWei || "0"),
        poolInfo?.fundingTokenDecimals || 18,
        3
      )
    );
    const hardCapWei = parseFloat(
      toFixed(
        bigInt(poolInfo?.hardCapInWei || "0"),
        poolInfo?.fundingTokenDecimals || 18,
        3
      )
    );
    if (totalCollected >= hardCapWei || isExpired) {
      dispatcher.dispatch({
        type: LOCK_LIQUIDITY,
        content: {
          presaleIndex: bscsId,
        },
      });
    } else {
      if (totalCollected < hardCapWei) {
        setErrorMessage("Cannot Lock Yet - Not HardCap Reached yet.");
      }
      if (!isExpired) {
        setErrorMessage("Cannot Lock Yet - Not locking time yet.");
      }
    }
  };

  const onBuy = (inputAmount) => {
    dispatcher.dispatch({
      type: PUBLIC_INVEST,
      content: {
        presaleIndex: bscsId,
        amount: inputAmount,
        decimals: parseInt(poolInfo?.fundingTokenDecimals),
      },
    });
  };

  console.log(poolInfo);
  return (
    <div className={classes.root}>
      {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={3000}
          onClick={() => {
            setErrorMessage(false);
          }}
          onClose={() => {
            setErrorMessage(false);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      )}
      {whitelisted &&
        poolInfo?.isWhitelistMode &&
        poolInfo?.isWhitelistedAddress &&
        poolInfo?.cakeLiquidityAddingTime && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={true}
            onClick={() => {
              setWhitelisted(false);
            }}
            onClose={() => {
              setWhitelisted(false);
            }}
          >
            <Alert severity={"info"}>
              {poolInfo.isWhitelistMode &&
                poolInfo.isWhitelistedAddress &&
                "Congrats, you are on the whitelist for this presale. Good luck!"}
            </Alert>
          </Snackbar>
        )}
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          {!poolInfo.saleTitle ||
          !poolInfo.openTime ||
          !poolInfo.presaleAddress ||
          !poolInfo.totalStakedLocked ? (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loadingIcon} size={60} />
            </div>
          ) : (
            <>
              <PoolMainInfoCard poolInfo={poolInfo} />
              <InvestmentCard
                poolInfo={poolInfo}
                onClaim={onClaim}
                onVote={onVoteClicked}
                onRefund={onGetRefund}
                onBuy={onInvest}
                account={account}
              />
              {account &&
                poolInfo.presaleCreatorAddress === account.address && (
                  <ManageCard
                    poolInfo={poolInfo}
                    onCollect={onCollect}
                    onCancelPresale={onCancelPresale}
                    onSendUnsoldTokens={onSendUnsoldTokens}
                    onAllowClaim={onAllowClaim}
                    onLockLiq={onLockLiq}
                  />
                )}
              {!(poolInfo.presaleIndex == 170 && theme.type == "bsc") &&
                !(poolInfo.presaleIndex == 171 && theme.type == "bsc") &&
                !(poolInfo.presaleIndex == 172 && theme.type == "bsc") && (
                  <LinkCard poolInfo={poolInfo} />
                )}
              {((poolInfo.presaleIndex == 170 && theme.type == "bsc") ||
                (poolInfo.presaleIndex == 171 && theme.type == "bsc") ||
                (poolInfo.presaleIndex == 172 && theme.type == "bsc")) && (
                <InpulseLinkCard poolInfo={poolInfo} />
              )}
              <AuditCard poolInfo={poolInfo} />
              {poolInfo.presaleVersion !== "V1" && (
                <KYCCard poolInfo={poolInfo} />
              )}
              <DisclaimerCard poolInfo={poolInfo} />
              <BuyModal
                closeModal={() => {
                  showBuyModal(false);
                }}
                modalOpen={openBuyModal}
                tokenPrice={parseFloat(
                  toFixed(
                    bigInt(poolInfo.tokenPriceInWei),
                    poolInfo?.fundingTokenDecimals || 18,
                    poolInfo?.fundingTokenDecimals == 18 ? 12 : 5
                  )
                )}
                minInvestAmount={parseFloat(
                  toFixed(
                    bigInt(poolInfo.minInvestInWei),
                    poolInfo?.fundingTokenDecimals || 18,
                    5
                  )
                )}
                maxInvestAmount={parseFloat(
                  toFixed(
                    bigInt(poolInfo.maxInvestInWei),
                    poolInfo?.fundingTokenDecimals || 18,
                    6
                  )
                )}
                presale={poolInfo}
                stakedLockedAmount={poolInfo.voteAvailable}
                totalLockedValues={poolInfo.totalStakedLocked}
                fundingToken={tokens[poolInfo.fundingTokenAddress]}
                fundingTokenBalance={poolInfo.fundingTokenBalance}
                onBuy={onBuy}
              />
              <VoteModal
                closeModal={() => {
                  showVoteModal(false);
                }}
                modalOpen={openVoteModal}
                salesTitle={poolInfo?.saleTitle || ""}
                onVote={onVote}
              />
              <RegionBanModal
                modalOpen={openBannedModal}
                closeModal={() => {
                  showBannedModal(false);
                }}
              />
            </>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(PresalePage)));
