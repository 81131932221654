import BscBackgroundImage from "../assets/bg/bsc.png";
import MaticBackgroundImage from "../assets/bg/mtc.png";
import EthBackgroundImage from "../assets/bg/eth.png";
import FtmBackgroundImage from "../assets/bg/ftm.png";

import bscApplyDesktopImg from "../assets/apply.png";
import bscApplyMobileImg from "../assets/apply-mobile.png";
import ethApplyDesktopImg from "../assets/apply-eth.png";
import ethApplyMobileImg from "../assets/apply-eth-mobile.png";
import mtcApplyDesktopImg from "../assets/apply-mtc.png";
import mtcApplyMobileImg from "../assets/apply-mtc-mobile.png";
import solApplyDesktopImg from "../assets/apply-sol.png";
import solApplyMobileImg from "../assets/apply-sol-mobile.png";
import ftmApplyDesktopImg from "../assets/apply-ftm.png";
import ftmApplyMobileImg from "../assets/apply-ftm-mobile.png";
import avaxApplyDesktopImg from "../assets/apply-avax.png";
import avaxApplyMobileImg from "../assets/apply-avax-mobile.png";

import bscSaleDesktopImg from "../assets/sale.png";
import bscSaleMobileImg from "../assets/sale-mobile.png";

import ethSaleDesktopImg from "../assets/sale-eth.png";
import ethSaleMobileImg from "../assets/sale-eth-mobile.png";

import mtcSaleDesktopImg from "../assets/sale-mtc.png";
import mtcSaleMobileImg from "../assets/sale-mtc-mobile.png";

import solSaleDesktopImg from "../assets/sale-sol.png";
import solSaleMobileImg from "../assets/sale-sol-mobile.png";

import ftmSaleDesktopImg from "../assets/sale-ftm.png";
import ftmSaleMobileImg from "../assets/sale-ftm-mobile.png";

import avaxSaleDesktopImg from "../assets/sale-avax.png";
import avaxSaleMobileImg from "../assets/sale-avax-mobile.png";

import bscIdoImg from "../assets/ido.svg";
import ethIdoImg from "../assets/ido-eth.svg";
import mtcIdoImg from "../assets/ido-mtc.svg";
import solIdoImg from "../assets/ido-sol.svg";
import ftmIdoImg from "../assets/ido-ftm.svg";
import avaxIdoImg from "../assets/ido-avax.svg";

import bscInvestmentImg from "../assets/investment.svg";
import ethInvestmentImg from "../assets/investment-eth.svg";
import mtcInvestmentImg from "../assets/investment-mtc.svg";
import solInvestmentImg from "../assets/investment-sol.svg";
import ftmInvestmentImg from "../assets/investment-ftm.svg";
import avaxInvestmentImg from "../assets/investment-avax.svg";

import bscFeesImg from "../assets/fees.svg";
import ethFeesImg from "../assets/fees-eth.svg";
import mtcFeesImg from "../assets/fees-mtc.svg";
import solFeesImg from "../assets/fees-sol.svg";
import ftmFeesImg from "../assets/fees-ftm.svg";
import avaxFeesImg from "../assets/fees-avax.svg";

import bscTimeImg from "../assets/time.svg";
import ethTimeImg from "../assets/time-eth.svg";
import mtcTimeImg from "../assets/time-mtc.svg";
import solTimeImg from "../assets/time-sol.svg";
import ftmTimeImg from "../assets/time-ftm.svg";
import avaxTimeImg from "../assets/time-avax.svg";

import bscLockImg from "../assets/lock.svg";
import ethLockImg from "../assets/lock-eth.svg";
import mtcLockImg from "../assets/lock-mtc.svg";
import solLockImg from "../assets/lock-sol.svg";
import ftmLockImg from "../assets/lock-ftm.svg";
import avaxLockImg from "../assets/lock-avax.svg";

import bscLogoImg from "../assets/logos/starter-binance.png";
import ethLogoImg from "../assets/logos/starter-ethereum.svg";
import mtcLogoImg from "../assets/logos/starter-polygon.png";
import solLogoImg from "../assets/logos/starter-solana.svg";
import ftmLogoImg from "../assets/logos/starter-fantom.svg";
import avaxLogoImg from "../assets/logos/starter-avalanche.svg";
import pulseLogoImg from '../assets/logos/starter-pulse.svg';

import EthereumIcon from "../assets/eth-blue.png";
import PolygonIcon from "../assets/polygon.svg";
import BscIcon from "../assets/bsc.svg";
import SolIcon from "../assets/sol.svg";
import FtmIcon from "../assets/ftm.svg";
import AvaxIcon from "../assets/avax.svg";
import PulseIcon from "../assets/PulseChain.png";

import QuickIcon from "../assets/quick-icon.png";
import QuickStartLpIcon from "../assets/quick-start-lp.svg";

import SpiritIcon from "../assets/spirit-icon.png";
import PngIcon from "../assets/PNG-logo.png";
import SpiritStartLpIcon from "../assets/spirit-start-lp.png";
import AvaxStartLpIcon from "../assets/avax-start-lp.png";

import BscCertifiedBadge from "../assets/certified.png";
import MtcCertifiedBadge from "../assets/certified.png";
import PulseCertifiedBadge from "../assets/certified.png";

import DefaultPoolImage from "../assets/logos/default.png";

import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

export const bscColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#1E232C",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",
  darkBlackTransparent: "rgba(0, 0, 0, 0.8)",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#D86F21",
  lightYellowSecondary: "#FBC915",
  lightYellowPrimaryHover: "#9b521a",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",
  lightYellowFourth: "#4c3b03",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#707070",
  lightGrayFifth: "#2c2f36",
  lightGraySixth: "#909090",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#153d6f70",
  lightBlueSixth: "#2172e5",
  lightBlueSeventh: "#6da8ff",
  lightBlueEighth: "#b189f3",

  lightWhitePrimary: "#FFFCFB",
  lightSkySeventh: "#dfedfd",
  lightSkyEighth: "#f1f7fe",
};

export const ethColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#1E232C",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#F0B900",
  lightYellowPrimaryHover: "#ae8501",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#E0E0E0",
  lightGrayTransparent: "rgba(181, 181, 181, 0.8)",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",

  lightSkyPrimary: "#DEEFFC",
  lightSkySecondary: "#64A3FF",
  lightSkySecondaryHover: "#dff4fa",
  lightSkyThird: "#8cacfe",
  lightSkyFifth: "#A5FCF6",
  lightSkySeventh: "#dfedfd",
  lightSkyEighth: "#f1f7fe",
  darkBluePrimary: "#628cf5",
  darkBlueSecondary: "#81A8F8",
  darkBlueThird: "#173b86",
  lightBlueEighth: "#b189f3",

  lightWhitePrimary: "#FFFCFB",

  lightPinkPrimary: "#CEB0FA",
  lightPinkSecondary: "#F5BBB2",
};

export const mtcColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#1E232C",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#F0B900",
  lightYellowPrimaryHover: "#ae8501",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#707070",
  lightGraySixth: "#909090",
  lightGrayTransparent: "rgba(110, 110, 110, 0.2)",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#cde6fe",
  lightBlueSixth: "#2891f9",
  lightBlueSeventh: "#0a1647",
  lightBlueEighth: "#b189f3",

  lightSkyPrimary: "#DEEFFC",
  lightSkySecondary: "#64A3FF",
  lightSkySecondaryHover: "#dff4fa",
  lightSkyThird: "#5281F7",
  lightSkyFourth: "#8148e570",
  lightSkySeventh: "#dfedfd",
  darkBluePrimary: "#8247e5",
  darkBlueSecondary: "#81A8F8",

  lightWhitePrimary: "#FFFCFB",
  lightSkyFifth: "#A5FCF6",
  lightSkySixth: "#5f00ff",
  lightSkyEighth: "#f1f7fe",

  lightPinkPrimary: "#CEB0FA",
  lightPinkSecondary: "#F5BBB2",
};

export const ftmColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#1E232C",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#F0B900",
  lightYellowPrimaryHover: "#ae8501",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGreyPrimary: "#737373",
  lightGreySecondary: "#efefef",
  lightGreyThird: "#5f5f5f",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#707070",
  lightGraySixth: "#909090",
  lightGrayTransparent: "rgba(181, 181, 181, 0.8)",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#1969ff",
  lightBlueSecondary: "#1969ff9f",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#cde6fe",
  lightBlueSixth: "#2891f9",
  lightBlueSeventh: "#0a1647",
  lightBlueEighth: "#b189f3",

  lightSkyPrimary: "#DEEFFC",
  lightSkySecondary: "#64A3FF",
  lightSkySecondaryHover: "#dff4fa",
  lightSkyThird: "#5281F7",
  lightSkyFourth: "#8148e570",
  lightSkySeventh: "#dfedfd",
  darkBluePrimary: "#8247e5",
  darkBlueSecondary: "#818181",

  lightWhitePrimary: "#FFFCFB",
  lightSkyFifth: "#A5FCF6",
  lightSkySixth: "#5f00ff",
  lightSkyEighth: "#f1f7fe",

  lightPinkPrimary: "#CEB0FA",
  lightPinkSecondary: "#F5BBB2",
};

export const avaxColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#1E232C",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#F0B900",
  lightYellowPrimaryHover: "#ae8501",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGreyPrimary: "#737373",
  lightGreySecondary: "#efefef",
  lightGreyThird: "#5f5f5f",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#707070",
  lightGraySixth: "#909090",
  lightGrayTransparent: "rgba(181, 181, 181, 0.8)",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#e84142",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#1969ff",
  lightBlueSecondary: "#1969ff9f",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#cde6fe",
  lightBlueSixth: "#2891f9",
  lightBlueSeventh: "#0a1647",
  lightBlueEighth: "#b189f3",

  lightSkyPrimary: "#DEEFFC",
  lightSkySecondary: "#64A3FF",
  lightSkySecondaryHover: "#dff4fa",
  lightSkyThird: "#5281F7",
  lightSkyFourth: "#8148e570",
  lightSkySeventh: "#dfedfd",
  darkBluePrimary: "#8247e5",
  darkBlueSecondary: "#818181",

  lightWhitePrimary: "#FFFCFB",
  lightSkyFifth: "#A5FCF6",
  lightSkySixth: "#5f00ff",
  lightSkyEighth: "#f1f7fe",

  lightPinkPrimary: "#CEB0FA",
  lightPinkSecondary: "#F5BBB2",
};

export const solColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#1E232C",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",
  darkBlackTransparent: "rgba(0, 0, 0, 0.8)",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#00FFBD",
  lightYellowPrimaryHover: "#00946e",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",
  lightYellowFourth: "#4c3b03",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#1E232C",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#707070",
  lightGrayFifth: "#2c2f36",
  lightGraySixth: "#909090",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#153d6f70",
  lightBlueSixth: "#2172e5",
  lightBlueSeventh: "#6da8ff",
  lightBlueEighth: "#b189f3",

  lightWhitePrimary: "#FFFCFB",
  lightSkySeventh: "#dfedfd",
  lightSkyEighth: "#f1f7fe",
};

export const pulseColors = {
  white: "#fff",
  black: "#000",
  blue: "#2F80ED",
  red: "#e02424",
  green: "#1abc9c",
  orange: "#ffa500",
  pink: "#DC6BE5",
  tomato: "#e56b73",
  purple: "#935dff",

  darkGreen: "#27652f",
  darkBlack: "#350859",
  darkRedPrimary: "#ff0000",
  darkRedSecondary: "#471C23",
  darkBlackTransparent: "rgba(0, 0, 0, 0.8)",

  lightPumpkin: "#FD9B40",
  lightPumpkinSecondary: "#F58A1F",

  lightYellowPrimary: "#8000ff",
  lightYellowPrimaryBackground: "#8000ff",
  lightYellowSecondary: "#FBC915",
  lightYellowPrimaryHover: "#0080ff",
  lightYellowThird: "rgba(238, 203, 112, 0.3)",
  lightYellowFourth: "#4c3b03",

  lightBlackPrimary: "#13161B",
  lightBlackSecondary: "#6a6a6a",

  brownPrimary: "#D1884F",
  blackPrimary: "#2c1143",

  lightGrayPrimary: "#EDEDED",
  lightGrayPrimaryTrans: "#ededed7a",
  lightGraySecondary: "#A1A1A4",
  lightGrayThird: "#2A2F38",
  lightGrayFourth: "#fc284c",
  lightGrayFifth: "#2c2f36",
  lightGraySixth: "#909090",

  lightGreenPrimary: "#00C76B",
  lightGreenSecondary: "#184539",
  lightGreenThird: "#1FF582",

  lightRedPrimary: "#E80000",
  lightRedSecondary: "#de5656",

  lightBluePrimary: "#00C2F0",
  lightBlueSecondary: "#3a709e",
  lightBlueThird: "#1FABF5",
  lightBlueFourth: "#112b36",
  lightBlueFifth: "#153d6f70",
  lightBlueSixth: "#2172e5",
  lightBlueSeventh: "#6da8ff",
  lightBlueEighth: "#b189f3",

  lightWhitePrimary: "#FFFCFB",
  lightSkySeventh: "#dfedfd",
  lightSkyEighth: "#f1f7fe",
};

const breakpoints = createBreakpoints({
  keys: ["xs", "sm", "ms", "md", "lg", "xl"],
  values: {
    xs: 520,
    sm: 600,
    ms: 768,
    md: 900,
    lg: 1200,
    xl: 1800,
  },
});

const bscTheme = {
  type: "bsc",
  chain: "Binance Smart Chain",
  chainName: "Smart Chain",
  chainSymbol: "BSC",
  chainLogo: BscIcon,
  chainId: 56,
  blockExplorer: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed.binance.org/",
  guide:
    "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-binance-smart-chain-bsc",
  lp: "https://v1exchange.pancakeswap.finance/#/add/BNB/0x31d0a7ada4d4c131eb612db48861211f63e57610",
  symbol: "BNB",
  swapSymbol: "BNB",
  stakingToken: "START",
  stakingTokenImage: bscLogoImg,
  enabled: true,
  colors: {
    header: {
      background: bscColors.darkBlack,
      text: bscColors.white,
      link: bscColors.white,
      linkHover: bscColors.lightPumpkin,
      linkHoverBg: bscColors.lightGrayFourth,
      menuButton: bscColors.white,
      boldBrownText: bscColors.lightYellowPrimary,
      balanceText: bscColors.lightYellowPrimary,
      balanceUsdText: bscColors.lightGraySecondary,
      balanceValue: bscColors.lightYellowPrimary,
      heading: bscColors.white,
      borderColor: bscColors.lightYellowPrimary,
      textSecondary: bscColors.lightGrayPrimary,
      closeButton: bscColors.white,
      tag: bscColors.lightYellowThird,
      darkBluePrimary: bscColors.white,
      linkSelected: bscColors.white,
      border: bscColors.darkBlack,
      comingSoon: bscColors.lightGreenPrimary,
      comingSoonText: bscColors.white,
      details: bscColors.lightYellowPrimaryHover,
      headerBackground:
        "linear-gradient(0deg, " +
        bscColors.black +
        " 0%, " +
        bscColors.darkBlack +
        " 100%)",
      balanceBackground: bscColors.darkBlack,
      stakeBackground: bscColors.darkBlack,
      tierBackground: bscColors.darkBlack,
      tierCircle: bscColors.darkBlack,
      tierCircleFilled: bscColors.lightGrayPrimary,
      tooltip: bscColors.lightGrayPrimary,
    },
    footer: {
      background: bscColors.blackPrimary,
      text: bscColors.lightGrayPrimary,
      textSecondary: bscColors.lightGraySecondary,
      icon: bscColors.lightYellowSecondary,
      border: bscColors.darkBlack,
    },
    wallet: {
      buttonBackground: bscColors.lightYellowPrimary,
      buttonBackgroundSecondary: bscColors.lightYellowPrimaryHover,
      buttonBackgroundHover: bscColors.lightYellowPrimaryHover,
      buttonText: bscColors.white,
      buttonTextSecondary: bscColors.white,
    },
    home: {
      heading: bscColors.white,
      headingShadow: bscColors.lightYellowPrimary,
      headingSecondary: bscColors.lightYellowPrimary,
      text: bscColors.lightGrayPrimary,
      textSecondary: bscColors.lightGraySecondary,
      background: bscColors.blackPrimary,
      buttonText: bscColors.white,
      aluminiBg: bscColors.blackPrimary,
    },
    voting: {
      heading: bscColors.white,
      tableHeaderText: bscColors.lightGraySecondary,
      text: bscColors.lightGrayPrimary,
      secondaryText: bscColors.lightYellowPrimary,
      searchText: bscColors.lightGrayPrimary,
      border: bscColors.lightGrayPrimaryTrans,
      searchMagnifier: bscColors.lightGraySecondary,
      tableHeader: bscColors.lightGrayThird,
      tableBorder: bscColors.lightGrayFourth,
      tableCell: bscColors.lightBlackPrimary,
      tableRowHover: bscColors.lightBlueFourth,
    },
    pools: {
      border: bscColors.lightGrayPrimaryTrans,
      searchMagnifier: bscColors.lightGraySecondary,
      searchText: bscColors.lightGrayPrimary,
      text: bscColors.lightGrayPrimary,
      menuBackground: bscColors.lightBlackPrimary,
      menuBorder: bscColors.blackPrimary,
      textSelected: bscColors.lightYellowPrimary,
      paginationText: bscColors.lightYellowPrimary,
      paginationItemSelectedBkg: bscColors.lightYellowPrimary,
      paginationItemSelectedText: bscColors.lightBlackPrimary,
      paginationItemBorder: bscColors.lightYellowPrimary,
    },
    poolCard: {
      background: bscColors.blackPrimary,
      border: bscColors.lightGrayFourth,
      title: bscColors.lightGrayPrimary,
      textSecondary: bscColors.lightYellowPrimary,
      statusBarBackground: bscColors.lightGrayPrimary,
      statusBarLabel: bscColors.lightGraySecondary,
      statusBarFilled: {
        GREEN: bscColors.lightGreenPrimary,
        RED: bscColors.lightRedPrimary,
        YELLOW: bscColors.lightYellowPrimary,
        ORANGE: bscColors.lightGreenPrimary,
      },
      poolType: {
        NOTOPENED: bscColors.lightBluePrimary,
        ONGOING: bscColors.lightYellowPrimary,
        FILLED: bscColors.lightGreenPrimary,
        CANCELLED: bscColors.lightRedSecondary,
        EXPIRED: bscColors.lightRedSecondary,
      },
      statusBarLabelSecondary: bscColors.white,
      poolTypeText: bscColors.lightGrayPrimary,
      linkIcon: bscColors.lightYellowPrimary,
      linkIconHover: bscColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: bscColors.white,
      headingSecondary: bscColors.lightYellowPrimary,
      text: bscColors.lightGrayPrimary,
      textSecondary: bscColors.lightGraySecondary,
      background: bscColors.blackPrimary,
      buttonText: bscColors.white,
      bgBreakpoint: [bscColors.lightGreenBg1, bscColors.lightGreenBg2],
      disclaimerBackground: bscColors.darkBlack,
    },
    presale: {
      cardBackground: bscColors.darkBlack,
      cardTitle: bscColors.white,
      cardTitleSecondary: bscColors.lightGrayPrimary,
      cardText: bscColors.white,
      addressValueText: bscColors.lightYellowPrimary,
      buttonBg: bscColors.lightYellowPrimary,
      buttonBgHover: bscColors.lightYellowPrimaryHover,
      buttonBgDisabled: bscColors.lightGraySecondary,

      buttonText: bscColors.lightBlackPrimary,
      verifiedBg: bscColors.lightGreenSecondary,
      warningBg: bscColors.darkRedSecondary,
      commentText: bscColors.lightGraySecondary,

      icon: bscColors.lightYellowPrimary,
      loadingBackground: bscColors.darkBlackTransparent,
      loadingIcon: bscColors.white,
    },
    apply: {
      cardBackground: bscColors.darkBlack,
      cardTitle: bscColors.white,
      cardTitleSecondary: bscColors.lightGrayPrimary,
      cardText: bscColors.white,
      addressValueText: bscColors.lightYellowPrimary,
      buttonBg: bscColors.lightYellowPrimary,
      buttonBgHover: bscColors.lightYellowPrimaryHover,
      buttonBgDisabled: bscColors.lightGraySecondary,

      buttonText: bscColors.lightBlackPrimary,
      verifiedBg: bscColors.lightGreenSecondary,
      warningBg: bscColors.darkRedPrimary,
      commentText: bscColors.lightGraySecondary,

      icon: bscColors.lightYellowPrimary,
    },
    admin: {
      cardBackground: bscColors.darkBlack,
      cardTitle: bscColors.white,
      cardTitleSecondary: bscColors.lightGrayPrimary,
      cardText: bscColors.white,
      addressValueText: bscColors.lightYellowPrimary,
      buttonBg: bscColors.lightYellowPrimary,
      buttonBgHover: bscColors.lightYellowPrimaryHover,
      buttonBgDisabled: bscColors.lightGraySecondary,

      buttonText: bscColors.lightBlackPrimary,
      verifiedBg: bscColors.lightGreenSecondary,
      warningBg: bscColors.darkRedPrimary,
      commentText: bscColors.lightGraySecondary,

      icon: bscColors.lightYellowPrimary,
    },
    stake: {
      background: bscColors.lightBlackPrimary,
    },
    partner: {
      logo: bscColors.lightBlackPrimary,
    },
    swap: {
      background: bscColors.lightBlackPrimary,
      border: bscColors.lightGrayFifth,
      inputBox: bscColors.lightWhitePrimary,
      maxBg: bscColors.lightBlueFifth,
      maxHover: bscColors.lightBlueSixth,
      maxText: bscColors.lightBlueSeventh,
      header: bscColors.lightWhitePrimary,
      exchangeButton: bscColors.lightBlueSixth,
    },
    dollarHighlight: bscColors.lightBlueSecondary,
    divider: bscColors.lightGraySecondary,
    backgroundColor: bscColors.darkBlack,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${BscBackgroundImage})`,
  applyDesktopImg: bscApplyDesktopImg,
  applyMobileImg: bscApplyMobileImg,
  saleDesktopImg: bscSaleDesktopImg,
  saleMobileImg: bscSaleMobileImg,
  idoImg: bscIdoImg,
  investmentImg: bscInvestmentImg,
  feesImg: bscFeesImg,
  timeImg: bscTimeImg,
  lockImg: bscLockImg,
  logoImage: bscLogoImg,
  swapSymbolImage: BscIcon,
  certifiedBadge: BscCertifiedBadge,
  defaultPoolImage: DefaultPoolImage,
  exchangeUrl: "PancakeSwap",
};

const ethTheme = {
  type: "eth",
  chain: "Ethereum Network",
  chainName: "Ethereum",
  chainSymbol: "Ethereum",
  chainLogo: EthereumIcon,
  chainId: 1,
  blockExplorer: "https://etherscan.com/",
  rpcUrl: "https://mainnet.infura.io/v3/fcff88c9730449d5b3404188deaa2e12",
  guide:
    "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-ethereum-eth",
  lp: "https://app.uniswap.org/#/add/v2/0x1d7ca62f6af49ec66f6680b8606e634e55ef22c1/ETH",
  symbol: "ETH",
  swapSymbol: "ETH",
  stakingToken: "START",
  stakingTokenImage: ethLogoImg,
  enabled: true,
  colors: {
    header: {
      background: ethColors.lightSkyPrimary,
      text: ethColors.darkBluePrimary,
      link: ethColors.darkBluePrimary,
      linkHover: ethColors.darkBlueSecondary,
      linkHoverBg: ethColors.lightGrayFourth,
      menuButton: ethColors.darkBluePrimary,
      boldBrownText: ethColors.darkBluePrimary,
      balanceText: ethColors.darkBluePrimary,
      balanceUsdText: ethColors.darkBlueSecondary,
      balanceValue: ethColors.darkBluePrimary,
      heading: ethColors.darkBluePrimary,
      textSecondary: ethColors.lightSkyThird,
      closeButton: ethColors.darkBluePrimary,
      tag: ethColors.darkBlueSecondary,
      linkSelected: ethColors.darkBlueSecondary,
      dropdownIcon: ethColors.darkBluePrimary,
      border: ethColors.darkBluePrimary,
      comingSoon: ethColors.lightGreenPrimary,
      comingSoonText: ethColors.white,
      borderColor: ethColors.darkBluePrimary,
      details: ethColors.lightBlueEighth,
      headerBackground:
        "linear-gradient(0deg, " +
        ethColors.lightSkySeventh +
        " 0%, " +
        ethColors.white +
        " 100%)",
      balanceBackground: ethColors.white,
      stakeBackground: ethColors.lightSkyEighth,
      tierBackground: ethColors.lightGrayPrimary,
      tierCircle: ethColors.white,
      tierCircleFilled: ethColors.darkBluePrimary,
      tooltip: ethColors.lightSkyEighth,
    },
    footer: {
      background: ethColors.lightSkyPrimary,
      text: ethColors.darkBluePrimary,
      textSecondary: ethColors.lightSkySecondary,
      icon: ethColors.darkBluePrimary,
      border: ethColors.darkBluePrimary,
    },
    wallet: {
      buttonBackground: ethColors.darkBluePrimary,
      buttonBackgroundSecondary: ethColors.darkBlueSecondary,
      buttonBackgroundHover: ethColors.darkBlueSecondary,
      buttonText: ethColors.lightSkyPrimary,
      buttonTextSecondary: ethColors.darkBluePrimary,
    },
    home: {
      heading: ethColors.lightSkyThird,
      headingShadow: ethColors.darkBluePrimary,
      headingSecondary: ethColors.darkBluePrimary,
      text: ethColors.white,
      textSecondary: ethColors.lightSkySecondary,
      background: ethColors.lightSkyPrimary,
      buttonText: ethColors.lightSkyThird,
      aluminiBg: ethColors.lightSkyPrimary,
    },
    voting: {
      heading: ethColors.white,
      tableHeaderText: ethColors.lightWhitePrimary,
      text: ethColors.lightSkyThird,
      secondaryText: ethColors.darkBluePrimary,
      searchText: ethColors.lightSkyThird,
      border: ethColors.lightSkySecondary,
      searchMagnifier: ethColors.lightSkySecondary,
      tableHeader: ethColors.lightSkySecondary,
      tableBorder: ethColors.lightSkySecondary,
      tableCell: ethColors.lightWhitePrimary,
      tableRowHover: ethColors.lightSkySecondaryHover,
    },
    pools: {
      border: ethColors.lightSkySecondary,
      searchMagnifier: ethColors.lightSkySecondary,
      searchText: ethColors.lightSkyThird,
      text: ethColors.lightSkyThird,
      menuBackground: ethColors.lightSkyPrimary,
      menuBorder: ethColors.lightSkyPrimary,
      textSelected: ethColors.darkBluePrimary,
      paginationText: ethColors.darkBluePrimary,
      paginationItemSelectedBkg: ethColors.darkBluePrimary,
      paginationItemSelectedText: ethColors.lightSkyPrimary,
      paginationItemBorder: ethColors.darkBluePrimary,
    },
    poolCard: {
      background: ethColors.lightSkyPrimary,
      border: ethColors.darkBluePrimary,
      title: ethColors.lightSkyThird,
      textSecondary: ethColors.darkBluePrimary,
      statusBarBackground: ethColors.lightSkyThird,
      statusBarLabel: ethColors.lightSkySecondary,
      statusBarFilled: {
        GREEN: ethColors.lightSkyFifth,
        RED: ethColors.lightRedPrimary,
        YELLOW: ethColors.darkBluePrimary,
        ORANGE: ethColors.lightGreenPrimary,
      },
      poolType: {
        NOTOPENED: ethColors.lightPinkPrimary,
        ONGOING: ethColors.lightPinkSecondary,
        FILLED: ethColors.lightSkyFifth,
        CANCELLED: ethColors.lightRedSecondary,
        EXPIRED: ethColors.lightRedSecondary,
      },
      statusBarLabelSecondary: ethColors.lightSkyThird,
      poolTypeText: ethColors.darkBluePrimary,
      linkIcon: ethColors.darkBluePrimary,
      linkIconHover: ethColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: ethColors.lightSkyThird,
      headingSecondary: ethColors.darkBluePrimary,
      text: ethColors.lightSkyThird,
      textSecondary: ethColors.lightSkySecondary,
      background: ethColors.lightSkyPrimary,
      buttonText: ethColors.lightSkyThird,
      bgBreakpoint: [ethColors.lightGreenBg1, ethColors.lightGreenBg2],
      disclaimerBackground: ethColors.lightSkyPrimary,
    },
    presale: {
      cardBackground: ethColors.lightSkyPrimary,
      cardTitle: ethColors.lightSkyThird,
      cardTitleSecondary: ethColors.darkBlueSecondary,
      cardText: ethColors.darkBluePrimary,
      addressValueText: ethColors.darkBluePrimary,
      buttonBg: ethColors.darkBluePrimary,
      buttonBgHover: ethColors.lightYellowPrimaryHover,
      buttonBgDisabled: ethColors.lightSkySecondary,

      buttonText: ethColors.lightSkyPrimary,
      verifiedBg: ethColors.lightGreenSecondary,
      warningBg: ethColors.darkRedSecondary,
      commentText: ethColors.lightSkySecondary,

      icon: ethColors.darkBluePrimary,
      loadingBackground: ethColors.lightGrayTransparent,
      loadingIcon: ethColors.darkBluePrimary,
    },
    apply: {
      cardBackground: ethColors.lightSkyPrimary,
      cardTitle: ethColors.darkBluePrimary,
      cardTitleSecondary: ethColors.darkBluePrimary,
      cardText: ethColors.darkBluePrimary,
      addressValueText: ethColors.darkBluePrimary,
      buttonBg: ethColors.darkBluePrimary,
      buttonBgHover: ethColors.lightYellowPrimaryHover,
      buttonBgDisabled: ethColors.lightSkySecondary,

      buttonText: ethColors.lightSkyPrimary,
      verifiedBg: ethColors.lightGreenSecondary,
      warningBg: ethColors.darkRedPrimary,
      commentText: ethColors.lightSkySecondary,

      icon: ethColors.darkBluePrimary,
    },
    admin: {
      cardBackground: ethColors.lightSkyPrimary,
      cardTitle: ethColors.lightSkyThird,
      cardTitleSecondary: ethColors.lightSkyThird,
      cardText: ethColors.lightSkyThird,
      addressValueText: ethColors.darkBluePrimary,
      buttonBg: ethColors.darkBluePrimary,
      buttonBgHover: ethColors.lightYellowPrimaryHover,
      buttonBgDisabled: ethColors.lightSkySecondary,

      buttonText: ethColors.lightSkyPrimary,
      verifiedBg: ethColors.lightGreenSecondary,
      warningBg: ethColors.darkRedPrimary,
      commentText: ethColors.lightSkySecondary,

      icon: ethColors.darkBluePrimary,
    },
    stake: {
      background: ethColors.lightSkyPrimary,
    },
    partner: {
      logo: ethColors.darkBlueThird,
    },
    swap: {
      background: ethColors.lightSkyPrimary,
      border: ethColors.lightSkyThird,
      inputBox: ethColors.darkBluePrimary,
      maxBg: ethColors.lightBlueFifth,
      maxHover: ethColors.lightBlueSixth,
      maxText: ethColors.lightBlueSeventh,
      header: ethColors.darkBluePrimary,
      exchangeButton: ethColors.lightBlueSixth,
    },
    dollarHighlight: ethColors.lightBlueSecondary,
    divider: ethColors.lightSkySecondary,
    backgroundColor: ethColors.lightWhitePrimary,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${EthBackgroundImage})`,
  applyDesktopImg: ethApplyDesktopImg,
  applyMobileImg: ethApplyMobileImg,
  saleDesktopImg: ethSaleDesktopImg,
  saleMobileImg: ethSaleMobileImg,
  idoImg: ethIdoImg,
  investmentImg: ethInvestmentImg,
  feesImg: ethFeesImg,
  timeImg: ethTimeImg,
  lockImg: ethLockImg,
  logoImage: ethLogoImg,
  certifiedBadge: MtcCertifiedBadge,
  defaultPoolImage: DefaultPoolImage,
  swapSymbolImage: EthereumIcon,
  exchangeUrl: "Uniswap",
  bridge: "https://starter.xyz/eth-bridge",
};

const mtcTheme = {
  type: "mtc",
  chain: "Polygon Network",
  chainName: "Polygon",
  chainSymbol: "Polygon",
  chainLogo: PolygonIcon,
  chainId: 137,
  blockExplorer: "https://polygonscan.com/",
  rpcUrl: "https://rpc-mainnet.matic.network",
  guide:
    "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-polygon-matic",
  lp: "https://quickswap.exchange/#/add/0x6ccf12b480a99c54b23647c995f4525d544a7e72/0x831753dd7087cac61ab5644b308642cc1c33dc13",
  symbol: "MATIC",
  swapSymbol: "QUICK",
  stakingToken: "QUICK+START LP",
  stakingTokenImage: QuickStartLpIcon,
  enabled: true,
  colors: {
    header: {
      background: mtcColors.lightSkyPrimary,
      text: mtcColors.darkBluePrimary,
      link: mtcColors.darkBluePrimary,
      linkHover: mtcColors.darkBlueSecondary,
      linkHoverBg: mtcColors.lightGrayFourth,
      menuButton: mtcColors.darkBluePrimary,
      boldBrownText: mtcColors.darkBluePrimary,
      balanceText: mtcColors.darkBluePrimary,
      balanceUsdText: mtcColors.lightGraySixth,
      balanceValue: mtcColors.darkBluePrimary,
      heading: mtcColors.darkBluePrimary,
      textSecondary: mtcColors.lightSkyThird,
      closeButton: mtcColors.darkBluePrimary,
      tag: mtcColors.darkBlueSecondary,
      linkSelected: mtcColors.darkBlueSecondary,
      dropdownIcon: mtcColors.darkBluePrimary,
      border: mtcColors.darkBluePrimary,
      comingSoon: mtcColors.lightGreenPrimary,
      comingSoonText: mtcColors.white,
      borderColor: mtcColors.darkBluePrimary,
      details: mtcColors.lightBlueEighth,
      headerBackground:
        "linear-gradient(0deg, " +
        mtcColors.lightSkySeventh +
        " 0%, " +
        mtcColors.white +
        " 100%)",
      balanceBackground: mtcColors.white,
      stakeBackground: mtcColors.lightSkyEighth,
      tierBackground: mtcColors.lightGrayPrimary,
      tierCircle: mtcColors.lightGrayPrimary,
      tierCircleFilled: mtcColors.darkBlueSecondary,
      tooltip: mtcColors.darkBlack,
    },
    footer: {
      background: mtcColors.lightSkyPrimary,
      text: mtcColors.darkBluePrimary,
      textSecondary: mtcColors.lightSkySecondary,
      icon: mtcColors.darkBluePrimary,
      border: mtcColors.darkBluePrimary,
    },
    wallet: {
      buttonBackground: mtcColors.darkBluePrimary,
      buttonBackgroundSecondary: mtcColors.darkBlueSecondary,
      buttonBackgroundHover: mtcColors.darkBlueSecondary,
      buttonText: mtcColors.lightSkyPrimary,
      buttonTextSecondary: mtcColors.darkBluePrimary,
    },
    home: {
      heading: mtcColors.lightSkyThird,
      headingShadow: mtcColors.darkBluePrimary,
      headingSecondary: mtcColors.darkBluePrimary,
      text: mtcColors.white,
      textSecondary: mtcColors.lightSkySecondary,
      background: mtcColors.lightSkyPrimary,
      buttonText: mtcColors.lightSkyThird,
      aluminiBg: mtcColors.lightSkyPrimary,
    },
    voting: {
      heading: mtcColors.white,
      tableHeaderText: mtcColors.lightWhitePrimary,
      text: mtcColors.lightSkyThird,
      secondaryText: mtcColors.darkBluePrimary,
      searchText: mtcColors.lightSkyThird,
      border: mtcColors.lightSkySecondary,
      searchMagnifier: mtcColors.lightSkySecondary,
      tableHeader: mtcColors.lightSkySecondary,
      tableBorder: mtcColors.lightSkySecondary,
      tableCell: mtcColors.lightWhitePrimary,
      tableRowHover: mtcColors.lightSkySecondaryHover,
    },
    pools: {
      border: mtcColors.lightSkySecondary,
      searchMagnifier: mtcColors.lightSkySecondary,
      searchText: mtcColors.lightSkyThird,
      text: mtcColors.lightSkyThird,
      menuBackground: mtcColors.lightSkyPrimary,
      menuBorder: mtcColors.lightSkyPrimary,
      textSelected: mtcColors.darkBluePrimary,
      paginationText: mtcColors.darkBluePrimary,
      paginationItemSelectedBkg: mtcColors.darkBluePrimary,
      paginationItemSelectedText: mtcColors.lightSkyPrimary,
      paginationItemBorder: mtcColors.darkBluePrimary,
    },
    poolCard: {
      background: mtcColors.lightSkyPrimary,
      border: mtcColors.darkBluePrimary,
      title: mtcColors.lightSkyThird,
      textSecondary: mtcColors.darkBluePrimary,
      statusBarBackground: mtcColors.lightSkyThird,
      statusBarLabel: mtcColors.lightSkySecondary,
      statusBarFilled: {
        GREEN: mtcColors.darkBluePrimary,
        RED: mtcColors.lightRedPrimary,
        YELLOW: mtcColors.darkBluePrimary,
        ORANGE: mtcColors.lightSkySixth,
      },
      poolType: {
        NOTOPENED: mtcColors.darkBluePrimary,
        ONGOING: mtcColors.darkBluePrimary,
        FILLED: mtcColors.darkBluePrimary,
        CANCELLED: mtcColors.lightRedSecondary,
        EXPIRED: mtcColors.lightRedSecondary,
      },
      statusBarLabelSecondary: mtcColors.lightSkyThird,
      poolTypeText: mtcColors.white,
      linkIcon: mtcColors.darkBluePrimary,
      linkIconHover: mtcColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: mtcColors.lightSkyThird,
      headingSecondary: mtcColors.darkBluePrimary,
      text: mtcColors.lightSkyThird,
      textSecondary: mtcColors.lightSkySecondary,
      background: mtcColors.lightSkyPrimary,
      buttonText: mtcColors.lightSkyThird,
      bgBreakpoint: [mtcColors.lightGreenBg1, mtcColors.lightGreenBg2],
      disclaimerBackground: mtcColors.lightSkyPrimary,
    },
    presale: {
      cardBackground: mtcColors.lightSkyPrimary,
      cardTitle: mtcColors.lightSkyThird,
      cardTitleSecondary: mtcColors.darkBlueSecondary,
      cardText: mtcColors.darkBluePrimary,
      addressValueText: mtcColors.darkBluePrimary,
      buttonBg: mtcColors.darkBluePrimary,
      buttonBgHover: mtcColors.lightYellowPrimaryHover,
      buttonBgDisabled: mtcColors.lightSkySecondary,

      buttonText: mtcColors.lightSkyPrimary,
      verifiedBg: mtcColors.lightGreenSecondary,
      warningBg: mtcColors.darkRedSecondary,
      commentText: mtcColors.lightSkySecondary,

      icon: mtcColors.darkBluePrimary,
      loadingBackground: mtcColors.lightGrayTransparent,
      loadingIcon: mtcColors.darkBluePrimary,
    },
    apply: {
      cardBackground: mtcColors.lightSkyPrimary,
      cardTitle: mtcColors.darkBluePrimary,
      cardTitleSecondary: mtcColors.darkBluePrimary,
      cardText: mtcColors.darkBluePrimary,
      addressValueText: mtcColors.darkBluePrimary,
      buttonBg: mtcColors.darkBluePrimary,
      buttonBgHover: mtcColors.lightYellowPrimaryHover,
      buttonBgDisabled: mtcColors.lightSkySecondary,

      buttonText: mtcColors.lightSkyPrimary,
      verifiedBg: mtcColors.lightGreenSecondary,
      warningBg: mtcColors.darkRedPrimary,
      commentText: mtcColors.lightSkySecondary,

      icon: mtcColors.darkBluePrimary,
    },
    admin: {
      cardBackground: mtcColors.lightSkyPrimary,
      cardTitle: mtcColors.lightSkyThird,
      cardTitleSecondary: mtcColors.lightSkyThird,
      cardText: mtcColors.lightSkyThird,
      addressValueText: mtcColors.darkBluePrimary,
      buttonBg: mtcColors.darkBluePrimary,
      buttonBgHover: mtcColors.lightYellowPrimaryHover,
      buttonBgDisabled: mtcColors.lightSkySecondary,

      buttonText: mtcColors.lightSkyPrimary,
      verifiedBg: mtcColors.lightGreenSecondary,
      warningBg: mtcColors.darkRedPrimary,
      commentText: mtcColors.lightSkySecondary,

      icon: mtcColors.darkBluePrimary,
    },
    stake: {
      background: mtcColors.lightSkyPrimary,
    },
    partner: {
      logo: mtcColors.lightSkyFourth,
    },
    swap: {
      background: mtcColors.lightSkyPrimary,
      border: mtcColors.lightSkyThird,
      inputBox: mtcColors.darkBluePrimary,
      maxBg: mtcColors.lightBlueFifth,
      maxHover: mtcColors.lightBlueSixth,
      maxText: mtcColors.lightBlueSeventh,
      header: mtcColors.darkBluePrimary,
      exchangeButton: mtcColors.lightBlueSixth,
    },
    dollarHighlight: mtcColors.lightBlueSecondary,
    divider: mtcColors.lightSkySecondary,
    backgroundColor: mtcColors.lightWhitePrimary,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${MaticBackgroundImage})`,
  applyDesktopImg: mtcApplyDesktopImg,
  applyMobileImg: mtcApplyMobileImg,
  saleDesktopImg: mtcSaleDesktopImg,
  saleMobileImg: mtcSaleMobileImg,
  idoImg: mtcIdoImg,
  investmentImg: mtcInvestmentImg,
  feesImg: mtcFeesImg,
  timeImg: mtcTimeImg,
  lockImg: mtcLockImg,
  logoImage: mtcLogoImg,
  swapSymbolImage: QuickIcon,
  certifiedBadge: MtcCertifiedBadge,
  defaultPoolImage: DefaultPoolImage,
  exchangeUrl: "QuickSwap",
  bridge: "https://starter.xyz/matic-bridge",
};

const ftmTheme = {
  type: "ftm",
  chain: "Fantom Network",
  chainName: "Fantom",
  chainSymbol: "Ftm",
  chainLogo: FtmIcon,
  chainId: 250,
  blockExplorer: "https://ftmscan.com/",
  rpcUrl:
    "https://apis.ankr.com/bbd46eb85b7e4564b4c2424a26a2555e/6fcf448e08d85dc2a0726dea7d38bc8d/fantom/full/main",
  guide:
    "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-fantom-ftm",
  lp: "https://spookyswap.finance/add/FTM/0x8ca2ecbCE34c322fceA6Db912d9DbfD2DdA5920D",
  symbol: "FTM",
  swapSymbol: "SPIRIT",
  stakingToken: "SPIRIT+START LP",
  stakingTokenImage: SpiritStartLpIcon,
  enabled: true,
  colors: {
    header: {
      background: ftmColors.white,
      text: ftmColors.lightGreyPrimary,
      link: ftmColors.lightGreyPrimary,
      linkHover: ftmColors.darkBlueSecondary,
      linkHoverBg: ftmColors.lightGrayPrimary,
      menuButton: ftmColors.lightGreyPrimary,
      boldBrownText: ftmColors.lightGreyPrimary,
      balanceText: ftmColors.lightGreyPrimary,
      balanceUsdText: ftmColors.lightGraySixth,
      balanceValue: ftmColors.lightGreyPrimary,
      heading: ftmColors.lightGreyPrimary,
      textSecondary: ftmColors.lightGreySecondary,
      closeButton: ftmColors.lightGreyPrimary,
      tag: ftmColors.darkBlueSecondary,
      linkSelected: ftmColors.darkBlueSecondary,
      dropdownIcon: ftmColors.lightGreyPrimary,
      border: ftmColors.lightGreyPrimary,
      comingSoon: ftmColors.lightGreenPrimary,
      comingSoonText: ftmColors.white,
      borderColor: ftmColors.lightGreyPrimary,
      details: ftmColors.lightBlueEighth,
      headerBackground:
        "linear-gradient(0deg, " +
        ftmColors.lightSkySeventh +
        " 0%, " +
        ftmColors.white +
        " 100%)",
      balanceBackground: ftmColors.white,
      stakeBackground: ftmColors.lightSkyEighth,
      tierBackground: ftmColors.lightGrayPrimary,
      tierCircle: ftmColors.lightGrayPrimary,
      tierCircleFilled: ftmColors.darkBlueSecondary,
      tooltip: ftmColors.darkBlack,
    },
    footer: {
      background: ftmColors.lightGreySecondary,
      text: ftmColors.lightGreyPrimary,
      textSecondary: ftmColors.lightSkySecondary,
      icon: ftmColors.lightGreyPrimary,
      border: ftmColors.lightGreyPrimary,
    },
    wallet: {
      buttonBackground: ftmColors.lightBluePrimary,
      buttonBackgroundSecondary: ftmColors.lightBlueSecondary,
      buttonBackgroundHover: ftmColors.lightBlueSecondary,
      buttonText: ftmColors.white,
      buttonTextSecondary: ftmColors.lightBluePrimary,
    },
    home: {
      heading: ftmColors.white,
      headingShadow: ftmColors.lightGreyPrimary,
      headingSecondary: ftmColors.lightGreyPrimary,
      text: ftmColors.white,
      textSecondary: ftmColors.lightSkySecondary,
      background: ftmColors.lightGreySecondary,
      buttonText: ftmColors.lightGreySecondary,
      aluminiBg: ftmColors.lightGreySecondary,
    },
    voting: {
      heading: ftmColors.white,
      tableHeaderText: ftmColors.lightWhitePrimary,
      text: ftmColors.lightGreySecondary,
      secondaryText: ftmColors.lightGreyPrimary,
      searchText: ftmColors.lightGreySecondary,
      border: ftmColors.lightSkySecondary,
      searchMagnifier: ftmColors.lightSkySecondary,
      tableHeader: ftmColors.lightSkySecondary,
      tableBorder: ftmColors.lightSkySecondary,
      tableCell: ftmColors.lightWhitePrimary,
      tableRowHover: ftmColors.lightSkySecondaryHover,
    },
    pools: {
      border: ftmColors.lightSkySecondary,
      searchMagnifier: ftmColors.lightSkySecondary,
      searchText: ftmColors.lightGreySecondary,
      text: ftmColors.lightGreySecondary,
      menuBackground: ftmColors.lightGreySecondary,
      menuBorder: ftmColors.lightGreySecondary,
      textSelected: ftmColors.lightGreyPrimary,
      paginationText: ftmColors.lightGreyPrimary,
      paginationItemSelectedBkg: ftmColors.lightGreyPrimary,
      paginationItemSelectedText: ftmColors.lightGreySecondary,
      paginationItemBorder: ftmColors.lightGreyPrimary,
    },
    poolCard: {
      background: ftmColors.lightGreySecondary,
      border: ftmColors.lightGreyPrimary,
      title: ftmColors.lightGreyPrimary,
      textSecondary: ftmColors.lightGreyPrimary,
      statusBarBackground: ftmColors.lightGreyThird,
      statusBarLabel: ftmColors.lightSkySecondary,
      statusBarFilled: {
        GREEN: ftmColors.lightGreyPrimary,
        RED: ftmColors.lightRedPrimary,
        YELLOW: ftmColors.lightGreyPrimary,
        ORANGE: ftmColors.lightSkySixth,
      },
      poolType: {
        NOTOPENED: ftmColors.lightGreyPrimary,
        ONGOING: ftmColors.lightGreyPrimary,
        FILLED: ftmColors.lightGreyPrimary,
        CANCELLED: ftmColors.lightRedSecondary,
        EXPIRED: ftmColors.lightRedSecondary,
      },
      statusBarLabelSecondary: ftmColors.lightGreySecondary,
      poolTypeText: ftmColors.white,
      linkIcon: ftmColors.lightGreyPrimary,
      linkIconHover: ftmColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: ftmColors.lightGreySecondary,
      headingSecondary: ftmColors.lightGreyPrimary,
      text: ftmColors.lightGreySecondary,
      textSecondary: ftmColors.lightSkySecondary,
      background: ftmColors.lightGreySecondary,
      buttonText: ftmColors.lightGreySecondary,
      bgBreakpoint: [ftmColors.lightGreenBg1, ftmColors.lightGreenBg2],
      disclaimerBackground: ftmColors.lightGreySecondary,
    },
    presale: {
      cardBackground: ftmColors.lightGreySecondary,
      cardTitle: ftmColors.lightGreySecondary,
      cardTitleSecondary: ftmColors.darkBlueSecondary,
      cardText: ftmColors.lightGreyPrimary,
      addressValueText: ftmColors.lightGreyPrimary,
      buttonBg: ftmColors.lightGreyPrimary,
      buttonBgHover: ftmColors.lightYellowPrimaryHover,
      buttonBgDisabled: ftmColors.lightSkySecondary,

      buttonText: ftmColors.lightGreySecondary,
      verifiedBg: ftmColors.lightGreenSecondary,
      warningBg: ftmColors.darkRedSecondary,
      commentText: ftmColors.lightSkySecondary,

      icon: ftmColors.lightGreyPrimary,
      loadingBackground: ftmColors.lightGrayTransparent,
      loadingIcon: ftmColors.lightGreyPrimary,
    },
    apply: {
      cardBackground: ftmColors.lightGreySecondary,
      cardTitle: ftmColors.lightGreyPrimary,
      cardTitleSecondary: ftmColors.lightGreyPrimary,
      cardText: ftmColors.lightGreyPrimary,
      addressValueText: ftmColors.lightGreyPrimary,
      buttonBg: ftmColors.lightGreyPrimary,
      buttonBgHover: ftmColors.lightYellowPrimaryHover,
      buttonBgDisabled: ftmColors.lightSkySecondary,

      buttonText: ftmColors.lightGreySecondary,
      verifiedBg: ftmColors.lightGreenSecondary,
      warningBg: ftmColors.darkRedPrimary,
      commentText: ftmColors.lightSkySecondary,

      icon: ftmColors.lightGreyPrimary,
    },
    admin: {
      cardBackground: ftmColors.lightGreySecondary,
      cardTitle: ftmColors.lightGreySecondary,
      cardTitleSecondary: ftmColors.lightGreySecondary,
      cardText: ftmColors.lightGreySecondary,
      addressValueText: ftmColors.lightGreyPrimary,
      buttonBg: ftmColors.lightGreyPrimary,
      buttonBgHover: ftmColors.lightYellowPrimaryHover,
      buttonBgDisabled: ftmColors.lightSkySecondary,

      buttonText: ftmColors.lightGreySecondary,
      verifiedBg: ftmColors.lightGreenSecondary,
      warningBg: ftmColors.darkRedPrimary,
      commentText: ftmColors.lightSkySecondary,

      icon: ftmColors.lightGreyPrimary,
    },
    stake: {
      background: ftmColors.lightSkyPrimary,
    },
    partner: {
      logo: ftmColors.lightSkyFourth,
    },
    swap: {
      background: ftmColors.lightGreySecondary,
      border: ftmColors.lightGreyThird,
      inputBox: ftmColors.lightGreyPrimary,
      maxBg: ftmColors.lightBlueFifth,
      maxHover: ftmColors.lightBlueSixth,
      maxText: ftmColors.lightBlueSeventh,
      header: ftmColors.lightGreyPrimary,
      exchangeButton: ftmColors.lightBlueSixth,
    },
    dollarHighlight: ftmColors.lightBlueSecondary,
    divider: ftmColors.lightSkySecondary,
    backgroundColor: ftmColors.lightWhitePrimary,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${FtmBackgroundImage})`,
  applyDesktopImg: ftmApplyDesktopImg,
  applyMobileImg: ftmApplyMobileImg,
  saleDesktopImg: ftmSaleDesktopImg,
  saleMobileImg: ftmSaleMobileImg,
  idoImg: ftmIdoImg,
  investmentImg: ftmInvestmentImg,
  feesImg: ftmFeesImg,
  timeImg: ftmTimeImg,
  lockImg: ftmLockImg,
  logoImage: ftmLogoImg,
  swapSymbolImage: SpiritIcon,
  certifiedBadge: MtcCertifiedBadge,
  defaultPoolImage: DefaultPoolImage,
  exchangeUrl: "SpiritSwap",
  bridge: "https://starter.xyz/ftm-bridge",
};

const solTheme = {
  type: "sol",
  chain: "Solana Network",
  chainName: "Solana",
  chainSymbol: "SOL",
  chainLogo: SolIcon,
  chainId: 56,
  blockExplorer: "http://explorer.solana.com/",
  rpcUrl: "entrypoint.mainnet-beta.solana.com:8001",
  symbol: "SOL",
  swapSymbol: "SOL",
  stakingToken: "START",
  stakingTokenImage: solLogoImg,
  enabled: false,
  colors: {
    header: {
      background: solColors.darkBlack,
      text: solColors.white,
      link: solColors.white,
      linkHover: solColors.lightPumpkin,
      menuButton: solColors.white,
      boldBrownText: solColors.lightYellowPrimary,
      balanceText: solColors.lightYellowPrimary,
      balanceUsdText: solColors.lightGraySecondary,
      balanceValue: solColors.lightYellowPrimary,
      heading: solColors.lightYellowPrimary,
      borderColor: solColors.lightYellowPrimary,
      textSecondary: solColors.lightGrayPrimary,
      closeButton: solColors.white,
      tag: solColors.lightYellowThird,
      darkBluePrimary: solColors.white,
      linkSelected: solColors.white,
      border: solColors.darkBlack,
      comingSoon: solColors.lightGreenPrimary,
      comingSoonText: solColors.white,
      details: solColors.lightYellowPrimaryHover,
      headerBackground:
        "linear-gradient(0deg, " +
        solColors.black +
        " 0%, " +
        solColors.darkBlack +
        " 100%)",
      balanceBackground: solColors.darkBlack,
      stakeBackground: solColors.darkBlack,
      tierBackground: solColors.darkBlack,
      tierCircle: solColors.darkBlack,
      tierCircleFilled: solColors.lightGrayPrimary,
      tooltip: solColors.lightGrayPrimary,
    },
    footer: {
      background: solColors.blackPrimary,
      text: solColors.lightGrayPrimary,
      textSecondary: solColors.lightGraySecondary,
      icon: solColors.lightYellowPrimary,
      border: solColors.darkBlack,
    },
    wallet: {
      buttonBackground: solColors.lightYellowPrimary,
      buttonBackgroundSecondary: solColors.lightYellowPrimaryHover,
      buttonBackgroundHover: solColors.lightYellowPrimaryHover,
      buttonText: solColors.lightBlackPrimary,
      buttonTextSecondary: solColors.lightYellowPrimary,
    },
    home: {
      heading: solColors.white,
      headingShadow: solColors.darkBluePrimary,
      headingSecondary: solColors.lightYellowPrimary,
      text: solColors.lightGrayPrimary,
      textSecondary: solColors.lightGraySecondary,
      background: solColors.blackPrimary,
      buttonText: solColors.white,
    },
    voting: {
      heading: solColors.white,
      tableHeaderText: solColors.lightGraySecondary,
      text: solColors.lightGrayPrimary,
      secondaryText: solColors.lightYellowPrimary,
      searchText: solColors.lightGrayPrimary,
      border: solColors.lightGrayPrimaryTrans,
      searchMagnifier: solColors.lightGraySecondary,
      tableHeader: solColors.lightGrayThird,
      tableBorder: solColors.lightGrayFourth,
      tableCell: solColors.lightBlackPrimary,
      tableRowHover: solColors.lightBlueFourth,
    },
    pools: {
      border: solColors.lightGrayPrimaryTrans,
      searchMagnifier: solColors.lightGraySecondary,
      searchText: solColors.lightGrayPrimary,
      text: solColors.lightGrayPrimary,
      menuBackground: solColors.lightBlackPrimary,
      menuBorder: solColors.blackPrimary,
      textSelected: solColors.lightYellowPrimary,
      paginationText: solColors.lightYellowPrimary,
      paginationItemSelectedBkg: solColors.lightYellowPrimary,
      paginationItemSelectedText: solColors.lightBlackPrimary,
      paginationItemBorder: solColors.lightYellowPrimary,
    },
    poolCard: {
      background: solColors.blackPrimary,
      border: solColors.blackPrimary,
      title: solColors.lightGrayPrimary,
      textSecondary: solColors.lightYellowPrimary,
      statusBarBackground: solColors.lightGrayPrimary,
      statusBarLabel: solColors.lightGraySecondary,
      statusBarFilled: {
        GREEN: solColors.lightGreenPrimary,
        RED: solColors.lightRedPrimary,
        YELLOW: solColors.lightYellowPrimary,
        ORANGE: solColors.lightGreenPrimary,
      },
      poolType: {
        NOTOPENED: solColors.lightBluePrimary,
        ONGOING: solColors.lightYellowPrimary,
        FILLED: solColors.lightGreenPrimary,
        CANCELLED: solColors.lightRedSecondary,
        EXPIRED: solColors.lightRedSecondary,
      },
      statusBarLabelSecondary: solColors.white,
      poolTypeText: solColors.lightGrayPrimary,
      linkIcon: solColors.lightYellowPrimary,
      linkIconHover: solColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: solColors.white,
      headingSecondary: solColors.lightYellowPrimary,
      text: solColors.lightGrayPrimary,
      textSecondary: solColors.lightGraySecondary,
      background: solColors.blackPrimary,
      buttonText: solColors.white,
      bgBreakpoint: [solColors.lightGreenBg1, solColors.lightGreenBg2],
      disclaimerBackground: solColors.darkBlack,
    },
    presale: {
      cardBackground: solColors.darkBlack,
      cardTitle: solColors.white,
      cardTitleSecondary: solColors.lightGrayPrimary,
      cardText: solColors.white,
      addressValueText: solColors.lightYellowPrimary,
      buttonBg: solColors.lightYellowPrimary,
      buttonBgHover: solColors.lightYellowPrimaryHover,
      buttonBgDisabled: solColors.lightGraySecondary,

      buttonText: solColors.lightBlackPrimary,
      verifiedBg: solColors.lightGreenSecondary,
      warningBg: solColors.darkRedSecondary,
      commentText: solColors.lightGraySecondary,

      icon: solColors.lightYellowPrimary,
      loadingBackground: solColors.darkBlackTransparent,
      loadingIcon: solColors.white,
    },
    apply: {
      cardBackground: solColors.darkBlack,
      cardTitle: solColors.white,
      cardTitleSecondary: solColors.lightGrayPrimary,
      cardText: solColors.white,
      addressValueText: solColors.lightYellowPrimary,
      buttonBg: solColors.lightYellowPrimary,
      buttonBgHover: solColors.lightYellowPrimaryHover,
      buttonBgDisabled: solColors.lightGraySecondary,

      buttonText: solColors.lightBlackPrimary,
      verifiedBg: solColors.lightGreenSecondary,
      warningBg: solColors.darkRedPrimary,
      commentText: solColors.lightGraySecondary,

      icon: solColors.lightYellowPrimary,
    },
    admin: {
      cardBackground: solColors.darkBlack,
      cardTitle: solColors.white,
      cardTitleSecondary: solColors.lightGrayPrimary,
      cardText: solColors.white,
      addressValueText: solColors.lightYellowPrimary,
      buttonBg: solColors.lightYellowPrimary,
      buttonBgHover: solColors.lightYellowPrimaryHover,
      buttonBgDisabled: solColors.lightGraySecondary,

      buttonText: solColors.lightBlackPrimary,
      verifiedBg: solColors.lightGreenSecondary,
      warningBg: solColors.darkRedPrimary,
      commentText: solColors.lightGraySecondary,

      icon: solColors.lightYellowPrimary,
    },
    stake: {
      background: solColors.lightBlackPrimary,
    },
    partner: {
      logo: solColors.lightBlackPrimary,
    },
    swap: {
      background: solColors.lightBlackPrimary,
      border: solColors.lightGrayFifth,
      inputBox: solColors.lightWhitePrimary,
      maxBg: solColors.lightBlueFifth,
      maxHover: solColors.lightBlueSixth,
      maxText: solColors.lightBlueSeventh,
      header: solColors.lightWhitePrimary,
      exchangeButton: solColors.lightBlueSixth,
    },
    dollarHighlight: solColors.lightBlueSecondary,
    divider: solColors.lightGraySecondary,
    backgroundColor: solColors.darkBlack,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${BscBackgroundImage})`,
  applyDesktopImg: solApplyDesktopImg,
  applyMobileImg: solApplyMobileImg,
  saleDesktopImg: solSaleDesktopImg,
  saleMobileImg: solSaleMobileImg,
  idoImg: solIdoImg,
  investmentImg: solInvestmentImg,
  feesImg: solFeesImg,
  timeImg: solTimeImg,
  lockImg: solLockImg,
  logoImage: solLogoImg,
  swapSymbolImage: SolIcon,
  certifiedBadge: BscCertifiedBadge,
  defaultPoolImage: DefaultPoolImage,
  exchangeUrl: "solswap.exchange",
};

const avaxTheme = {
  type: "avax",
  chain: "Avalanche Network",
  chainName: "Avalanche",
  chainSymbol: "Avax",
  chainLogo: AvaxIcon,
  chainId: 43114,
  blockExplorer: "https://snowtrace.io/",
  rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
  guide:
    "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-avalanche-avax",
  lp: "https://app.pangolin.exchange/#/add/AVAX/0xf44fb887334fa17d2c5c0f970b5d320ab53ed557",
  symbol: "AVAX",
  swapSymbol: "AVAX",
  stakingToken: "AVAX+START LP",
  stakingTokenImage: AvaxStartLpIcon,
  enabled: true,
  colors: {
    header: {
      background: avaxColors.white,
      text: avaxColors.lightRedPrimary,
      link: avaxColors.lightRedPrimary,
      linkHover: avaxColors.darkBlueSecondary,
      linkHoverBg: avaxColors.lightGrayPrimary,
      menuButton: avaxColors.lightRedPrimary,
      boldBrownText: avaxColors.lightRedPrimary,
      balanceText: avaxColors.lightRedPrimary,
      balanceUsdText: avaxColors.lightGraySixth,
      balanceValue: avaxColors.lightRedPrimary,
      heading: avaxColors.lightRedPrimary,
      textSecondary: avaxColors.lightGreySecondary,
      closeButton: avaxColors.lightRedPrimary,
      tag: avaxColors.darkBlueSecondary,
      linkSelected: avaxColors.darkBlueSecondary,
      dropdownIcon: avaxColors.lightRedPrimary,
      border: avaxColors.lightRedPrimary,
      comingSoon: avaxColors.lightGreenPrimary,
      comingSoonText: avaxColors.white,
      borderColor: avaxColors.lightRedPrimary,
      details: avaxColors.lightBlueEighth,
      headerBackground:
        "linear-gradient(0deg, " +
        avaxColors.lightSkySeventh +
        " 0%, " +
        avaxColors.white +
        " 100%)",
      balanceBackground: avaxColors.white,
      stakeBackground: avaxColors.lightSkyEighth,
      tierBackground: avaxColors.lightGrayPrimary,
      tierCircle: avaxColors.lightGrayPrimary,
      tierCircleFilled: avaxColors.darkBlueSecondary,
      tooltip: avaxColors.darkBlack,
    },
    footer: {
      background: avaxColors.lightGreySecondary,
      text: avaxColors.lightRedPrimary,
      textSecondary: avaxColors.lightSkySecondary,
      icon: avaxColors.lightRedPrimary,
      border: avaxColors.lightRedPrimary,
    },
    wallet: {
      buttonBackground: avaxColors.lightRedPrimary,
      buttonBackgroundSecondary: avaxColors.lightRedSecondary,
      buttonBackgroundHover: avaxColors.lightRedSecondary,
      buttonText: avaxColors.white,
      buttonTextSecondary: avaxColors.lightRedPrimary,
    },
    home: {
      heading: avaxColors.white,
      headingShadow: avaxColors.lightRedPrimary,
      headingSecondary: avaxColors.lightRedPrimary,
      text: avaxColors.white,
      textSecondary: avaxColors.lightSkySecondary,
      background: avaxColors.lightGreySecondary,
      buttonText: avaxColors.lightGreySecondary,
      aluminiBg: avaxColors.lightGreySecondary,
    },
    voting: {
      heading: avaxColors.white,
      tableHeaderText: avaxColors.lightWhitePrimary,
      text: avaxColors.lightGreySecondary,
      secondaryText: avaxColors.lightRedPrimary,
      searchText: avaxColors.lightGreySecondary,
      border: avaxColors.lightSkySecondary,
      searchMagnifier: avaxColors.lightSkySecondary,
      tableHeader: avaxColors.lightSkySecondary,
      tableBorder: avaxColors.lightSkySecondary,
      tableCell: avaxColors.lightWhitePrimary,
      tableRowHover: avaxColors.lightSkySecondaryHover,
    },
    pools: {
      border: avaxColors.lightSkySecondary,
      searchMagnifier: avaxColors.lightSkySecondary,
      searchText: avaxColors.lightGreySecondary,
      text: avaxColors.lightGreySecondary,
      menuBackground: avaxColors.lightGreySecondary,
      menuBorder: avaxColors.lightGreySecondary,
      textSelected: avaxColors.lightRedPrimary,
      paginationText: avaxColors.lightRedPrimary,
      paginationItemSelectedBkg: avaxColors.lightRedPrimary,
      paginationItemSelectedText: avaxColors.lightGreySecondary,
      paginationItemBorder: avaxColors.lightRedPrimary,
    },
    poolCard: {
      background: avaxColors.lightGreySecondary,
      border: avaxColors.lightRedPrimary,
      title: avaxColors.lightRedPrimary,
      textSecondary: avaxColors.lightRedPrimary,
      statusBarBackground: avaxColors.lightGreyThird,
      statusBarLabel: avaxColors.lightSkySecondary,
      statusBarFilled: {
        GREEN: avaxColors.lightRedPrimary,
        RED: avaxColors.lightRedPrimary,
        YELLOW: avaxColors.lightRedPrimary,
        ORANGE: avaxColors.lightSkySixth,
      },
      poolType: {
        NOTOPENED: avaxColors.lightRedPrimary,
        ONGOING: avaxColors.lightRedPrimary,
        FILLED: avaxColors.lightRedPrimary,
        CANCELLED: avaxColors.lightRedSecondary,
        EXPIRED: avaxColors.lightRedSecondary,
      },
      statusBarLabelSecondary: avaxColors.lightGreySecondary,
      poolTypeText: avaxColors.white,
      linkIcon: avaxColors.lightRedPrimary,
      linkIconHover: avaxColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: avaxColors.lightGreySecondary,
      headingSecondary: avaxColors.lightRedPrimary,
      text: avaxColors.lightGreySecondary,
      textSecondary: avaxColors.lightSkySecondary,
      background: avaxColors.lightGreySecondary,
      buttonText: avaxColors.lightGreySecondary,
      bgBreakpoint: [avaxColors.lightGreenBg1, avaxColors.lightGreenBg2],
      disclaimerBackground: avaxColors.lightGreySecondary,
    },
    presale: {
      cardBackground: avaxColors.lightGreySecondary,
      cardTitle: avaxColors.lightRedPrimary,
      cardTitleSecondary: avaxColors.darkBlueSecondary,
      cardText: avaxColors.lightRedPrimary,
      addressValueText: avaxColors.lightRedPrimary,
      buttonBg: avaxColors.lightRedPrimary,
      buttonBgHover: avaxColors.lightYellowPrimaryHover,
      buttonBgDisabled: avaxColors.lightSkySecondary,

      buttonText: avaxColors.lightGreySecondary,
      verifiedBg: avaxColors.lightGreenSecondary,
      warningBg: avaxColors.darkRedSecondary,
      commentText: avaxColors.lightSkySecondary,

      icon: avaxColors.lightRedPrimary,
      loadingBackground: avaxColors.lightGrayTransparent,
      loadingIcon: avaxColors.lightRedPrimary,
    },
    apply: {
      cardBackground: avaxColors.lightGreySecondary,
      cardTitle: avaxColors.lightRedPrimary,
      cardTitleSecondary: avaxColors.lightRedPrimary,
      cardText: avaxColors.lightRedPrimary,
      addressValueText: avaxColors.lightRedPrimary,
      buttonBg: avaxColors.lightRedPrimary,
      buttonBgHover: avaxColors.lightYellowPrimaryHover,
      buttonBgDisabled: avaxColors.lightSkySecondary,

      buttonText: avaxColors.lightGreySecondary,
      verifiedBg: avaxColors.lightGreenSecondary,
      warningBg: avaxColors.darkRedPrimary,
      commentText: avaxColors.lightSkySecondary,

      icon: avaxColors.lightRedPrimary,
    },
    admin: {
      cardBackground: avaxColors.lightGreySecondary,
      cardTitle: avaxColors.lightGreySecondary,
      cardTitleSecondary: avaxColors.lightGreySecondary,
      cardText: avaxColors.lightGreySecondary,
      addressValueText: avaxColors.lightRedPrimary,
      buttonBg: avaxColors.lightRedPrimary,
      buttonBgHover: avaxColors.lightYellowPrimaryHover,
      buttonBgDisabled: avaxColors.lightSkySecondary,

      buttonText: avaxColors.lightGreySecondary,
      verifiedBg: avaxColors.lightGreenSecondary,
      warningBg: avaxColors.darkRedPrimary,
      commentText: avaxColors.lightSkySecondary,

      icon: avaxColors.lightRedPrimary,
    },
    stake: {
      background: avaxColors.lightSkyPrimary,
    },
    partner: {
      logo: avaxColors.lightSkyFourth,
    },
    swap: {
      background: avaxColors.lightGreySecondary,
      border: avaxColors.lightGreyThird,
      inputBox: avaxColors.lightRedPrimary,
      maxBg: avaxColors.lightBlueFifth,
      maxHover: avaxColors.lightBlueSixth,
      maxText: avaxColors.lightBlueSeventh,
      header: avaxColors.lightRedPrimary,
      exchangeButton: avaxColors.lightBlueSixth,
    },
    dollarHighlight: avaxColors.lightBlueSecondary,
    divider: avaxColors.lightSkySecondary,
    backgroundColor: avaxColors.lightWhitePrimary,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${MaticBackgroundImage})`,
  applyDesktopImg: avaxApplyDesktopImg,
  applyMobileImg: avaxApplyMobileImg,
  saleDesktopImg: avaxSaleDesktopImg,
  saleMobileImg: avaxSaleMobileImg,
  idoImg: avaxIdoImg,
  investmentImg: avaxInvestmentImg,
  feesImg: avaxFeesImg,
  timeImg: avaxTimeImg,
  lockImg: avaxLockImg,
  logoImage: avaxLogoImg,
  swapSymbolImage: AvaxIcon,
  certifiedBadge: MtcCertifiedBadge,
  defaultPoolImage: DefaultPoolImage,
  exchangeUrl: "pangolin.exchange",
  bridge: "https://starter.xyz/avax-bridge",
};

const pulseTheme = {
  type: "pulse",
  chain: "PulseChain",
  chainName: "PulseChain",
  chainSymbol: "PLS",
  chainLogo: PulseIcon,
  chainId: 56,
  blockExplorer: "https://bscscan.com/",
  rpcUrl: "https://bsc-dataseed.binance.org/",
  guide:
    "https://docs.starter.xyz/faq/how-to-guides/how-to-take-part-in-a-sale-on-binance-smart-chain-bsc",
  lp: "https://v1exchange.pancakeswap.finance/#/add/BNB/0x31d0a7ada4d4c131eb612db48861211f63e57610",
  symbol: "PLS",
  swapSymbol: "PLS",
  stakingToken: "START",
  stakingTokenImage: pulseLogoImg,
  enabled: true,
  colors: {
    header: {
      background: 'linear-gradient(180deg,#2c1143,#350859 120.56%)',
      text: pulseColors.white,
      link: pulseColors.white,
      linkHover: pulseColors.lightPumpkin,
      linkHoverBg: pulseColors.lightYellowPrimaryHover,
      menuButton: pulseColors.white,
      boldBrownText: pulseColors.lightYellowPrimary,
      balanceText: pulseColors.lightYellowPrimary,
      balanceUsdText: pulseColors.lightGraySecondary,
      balanceValue: pulseColors.lightYellowPrimary,
      heading: pulseColors.white,
      borderColor: pulseColors.lightYellowPrimary,
      textSecondary: pulseColors.lightGrayPrimary,
      closeButton: pulseColors.white,
      tag: pulseColors.lightYellowThird,
      darkBluePrimary: pulseColors.white,
      linkSelected: pulseColors.white,
      border: pulseColors.darkBlack,
      comingSoon: pulseColors.lightGreenPrimary,
      comingSoonText: pulseColors.white,
      details: pulseColors.lightYellowPrimaryHover,
      headerBackground:
        "linear-gradient(0deg, " +
        pulseColors.black +
        " 0%, " +
        pulseColors.darkBlack +
        " 100%)",
      balanceBackground: pulseColors.darkBlack,
      stakeBackground: pulseColors.darkBlack,
      tierBackground: pulseColors.darkBlack,
      tierCircle: pulseColors.darkBlack,
      tierCircleFilled: pulseColors.lightGrayPrimary,
      tooltip: pulseColors.lightGrayPrimary,
    },
    footer: {
      background: pulseColors.blackPrimary,
      text: pulseColors.lightGrayPrimary,
      textSecondary: pulseColors.lightGraySecondary,
      icon: pulseColors.lightYellowSecondary,
      border: pulseColors.darkBlack,
    },
    wallet: {
      buttonBackground: pulseColors.lightYellowPrimaryBackground,
      buttonBackgroundSecondary: pulseColors.lightYellowPrimaryHover,
      buttonBackgroundHover: pulseColors.lightYellowPrimaryHover,
      buttonText: pulseColors.white,
      buttonTextSecondary: pulseColors.white,
    },
    home: {
      heading: pulseColors.white,
      headingShadow: pulseColors.lightYellowPrimary,
      headingSecondary: pulseColors.lightYellowPrimary,
      text: pulseColors.lightGrayPrimary,
      textSecondary: pulseColors.lightGraySecondary,
      background: pulseColors.blackPrimary,
      buttonText: pulseColors.white,
      aluminiBg: pulseColors.blackPrimary,
    },
    voting: {
      heading: pulseColors.white,
      tableHeaderText: pulseColors.lightGraySecondary,
      text: pulseColors.lightGrayPrimary,
      secondaryText: pulseColors.lightYellowPrimary,
      searchText: pulseColors.lightGrayPrimary,
      border: pulseColors.lightGrayPrimaryTrans,
      searchMagnifier: pulseColors.lightGraySecondary,
      tableHeader: pulseColors.lightGrayThird,
      tableBorder: pulseColors.lightGrayFourth,
      tableCell: pulseColors.lightBlackPrimary,
      tableRowHover: pulseColors.lightBlueFourth,
    },
    pools: {
      border: pulseColors.lightGrayPrimaryTrans,
      searchMagnifier: pulseColors.lightGraySecondary,
      searchText: pulseColors.lightGrayPrimary,
      text: pulseColors.lightGrayPrimary,
      menuBackground: pulseColors.lightBlackPrimary,
      menuBorder: pulseColors.blackPrimary,
      textSelected: pulseColors.lightYellowPrimary,
      paginationText: pulseColors.lightYellowPrimary,
      paginationItemSelectedBkg: pulseColors.lightYellowPrimary,
      paginationItemSelectedText: pulseColors.lightBlackPrimary,
      paginationItemBorder: pulseColors.lightYellowPrimary,
    },
    poolCard: {
      background: pulseColors.blackPrimary,
      boxShadow: "inset 0 4px 34px rgb(243 38 73 / 40%)",
      border: pulseColors.lightGrayFourth,
      title: pulseColors.lightGrayPrimary,
      textSecondary: pulseColors.lightYellowPrimary,
      statusBarBackground: pulseColors.lightGrayPrimary,
      statusBarLabel: pulseColors.lightGraySecondary,
      statusBarFilled: {
        GREEN: pulseColors.lightGreenPrimary,
        RED: pulseColors.lightRedPrimary,
        YELLOW: pulseColors.lightYellowPrimary,
        ORANGE: pulseColors.lightGreenPrimary,
      },
      poolType: {
        NOTOPENED: pulseColors.lightBluePrimary,
        ONGOING: pulseColors.lightYellowPrimary,
        FILLED: pulseColors.lightGreenPrimary,
        CANCELLED: pulseColors.lightRedSecondary,
        EXPIRED: pulseColors.lightRedSecondary,
      },
      statusBarLabelSecondary: pulseColors.white,
      poolTypeText: pulseColors.lightGrayPrimary,
      linkIcon: pulseColors.lightYellowPrimary,
      linkIconHover: pulseColors.lightYellowPrimaryHover,
    },
    litepaper: {
      heading: pulseColors.white,
      headingSecondary: pulseColors.lightYellowPrimary,
      text: pulseColors.lightGrayPrimary,
      textSecondary: pulseColors.lightGraySecondary,
      background: pulseColors.blackPrimary,
      buttonText: pulseColors.white,
      bgBreakpoint: [pulseColors.lightGreenBg1, pulseColors.lightGreenBg2],
      disclaimerBackground: pulseColors.darkBlack,
    },
    presale: {
      cardBackground: pulseColors.darkBlack,
      cardTitle: pulseColors.white,
      cardTitleSecondary: pulseColors.lightGrayPrimary,
      cardText: pulseColors.white,
      addressValueText: pulseColors.lightYellowPrimary,
      buttonBg: pulseColors.lightYellowPrimary,
      buttonBgHover: pulseColors.lightYellowPrimaryHover,
      buttonBgDisabled: pulseColors.lightGraySecondary,

      buttonText: pulseColors.lightBlackPrimary,
      verifiedBg: pulseColors.lightGreenSecondary,
      warningBg: pulseColors.darkRedSecondary,
      commentText: pulseColors.lightGraySecondary,

      icon: pulseColors.lightYellowPrimary,
      loadingBackground: pulseColors.darkBlackTransparent,
      loadingIcon: pulseColors.white,
    },
    apply: {
      cardBackground: pulseColors.darkBlack,
      cardTitle: pulseColors.white,
      cardTitleSecondary: pulseColors.lightGrayPrimary,
      cardText: pulseColors.white,
      addressValueText: pulseColors.lightYellowPrimary,
      buttonBg: pulseColors.lightYellowPrimary,
      buttonBgHover: pulseColors.lightYellowPrimaryHover,
      buttonBgDisabled: pulseColors.lightGraySecondary,

      buttonText: pulseColors.lightBlackPrimary,
      verifiedBg: pulseColors.lightGreenSecondary,
      warningBg: pulseColors.darkRedPrimary,
      commentText: pulseColors.lightGraySecondary,

      icon: pulseColors.lightYellowPrimary,
    },
    admin: {
      cardBackground: pulseColors.darkBlack,
      cardTitle: pulseColors.white,
      cardTitleSecondary: pulseColors.lightGrayPrimary,
      cardText: pulseColors.white,
      addressValueText: pulseColors.lightYellowPrimary,
      buttonBg: pulseColors.lightYellowPrimary,
      buttonBgHover: pulseColors.lightYellowPrimaryHover,
      buttonBgDisabled: pulseColors.lightGraySecondary,

      buttonText: pulseColors.lightBlackPrimary,
      verifiedBg: pulseColors.lightGreenSecondary,
      warningBg: pulseColors.darkRedPrimary,
      commentText: pulseColors.lightGraySecondary,

      icon: pulseColors.lightYellowPrimary,
    },
    stake: {
      background: pulseColors.lightBlackPrimary,
    },
    partner: {
      logo: pulseColors.lightBlackPrimary,
    },
    swap: {
      background: pulseColors.lightBlackPrimary,
      border: pulseColors.lightGrayFifth,
      inputBox: pulseColors.lightWhitePrimary,
      maxBg: pulseColors.lightBlueFifth,
      maxHover: pulseColors.lightBlueSixth,
      maxText: pulseColors.lightBlueSeventh,
      header: pulseColors.lightWhitePrimary,
      exchangeButton: pulseColors.lightBlueSixth,
    },
    dollarHighlight: pulseColors.lightBlueSecondary,
    divider: pulseColors.lightGraySecondary,
    backgroundColor: pulseColors.darkBlack,
  },
  breakpoints: breakpoints,
  backgroundImage: `url(${BscBackgroundImage})`,
  applyDesktopImg: bscApplyDesktopImg,
  applyMobileImg: bscApplyMobileImg,
  saleDesktopImg: bscSaleDesktopImg,
  saleMobileImg: bscSaleMobileImg,
  idoImg: bscIdoImg,
  investmentImg: bscInvestmentImg,
  feesImg: bscFeesImg,
  timeImg: bscTimeImg,
  lockImg: bscLockImg,
  logoImage: pulseLogoImg,
  swapSymbolImage: pulseLogoImg,
  certifiedBadge: PulseCertifiedBadge,
  defaultPoolImage: DefaultPoolImage,
  exchangeUrl: "PancakeSwap",
};

export { bscTheme, ethTheme, mtcTheme, ftmTheme, solTheme, avaxTheme, pulseTheme };
