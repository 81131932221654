import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import { ReactComponent as CheckMarkIcon } from "../../../assets/check.svg";

const styles = (theme) => ({
  poolDisclaimerCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },

  text: {
    color: theme?.colors?.presale?.cardText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  subText: {
    color: theme?.colors?.presale?.cardText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "5px",
    },
  },
  title: {
    color: theme?.colors?.presale?.cardText,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      fontWeight: "bold",
      lineHeight: "24px",
    },
  },
});

const DisclaimerCard = ({ classes }) => {
  return (
    <div className={classes.poolDisclaimerCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        Disclaimer
      </Typography>
      <Typography variant="h5" className={classes.text}>
        The Starter team does not endorse, support or otherwise perform any due
        diligence on the projects listed on its platform. As always, please DO
        YOUR OWN RESEARCH before investing any of your hard-earned BNB into
        these projects and never invest more than you are willing to lose.
      </Typography>
      <Typography variant="h5" className={classes.title}>
        PLEASE NOTE THAT WE CANNOT ACCEPT INVESTMENT FROM THE FOLLOWING
        COUNTRIES:
      </Typography>
      <ul>
        <li className={classes.subText}>
          <Typography variant="h5" className={classes.subText}>
            Afghanistan, Albania, Bahamas, Barbados, Botswana, Cambodia, Canada,
            Cuba, Ghana, Iran (Islamic Republic of), Iraq, Jamaica, Korea (the
            Democratic People’s Republic of), Libya, Mauritius, Myanmar,
            Nicaragua, Panama, Pakistan, South Sudan, Sudan (North), Syrian Arab
            Republic, The Crimea, Trinidad and Tobago, Uganda, United States of
            America, Vanuatu, Yemen, Zimbabwe;
          </Typography>
        </li>
        <li className={classes.subText}>
          <Typography variant="h5" className={classes.subText}>
            Jurisdictions in which participation or ownership of tokens is
            prohibited by any applicable Law;
          </Typography>
        </li>
        <li className={classes.subText}>
          <Typography variant="h5" className={classes.subText}>
            Jurisdictions which are subject to United States, United Nations, or
            other applicable sanctions or embargoes.
          </Typography>
        </li>
      </ul>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(DisclaimerCard)));
