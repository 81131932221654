import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Fade,
  Input,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  IconButton,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MuiAlert from "@material-ui/lab/Alert";
import bigInt from "big-integer";

import { FadeInWhenVisible } from "../animation";

import { withNamespaces } from "react-i18next";
import {
  GET_POOLS_SUMMARY_RETURNED,
  VOTE,
  toFixed,
  tokens,
} from "../../constants/constants";

import { ReactComponent as ThumbupIcon } from "../../assets/thumb-up-green.svg";
import { ReactComponent as ThumbdownIcon } from "../../assets/thumb-down-red.svg";

import VotingModal from "./components/voteModal";
import LogoImage from "./components/logoImage";

import Store from "../../stores/store";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "1140px",
  },
  header: {
    fontSize: "25px",
    lineHeight: "30px",
    fontFamily: "SFPro",
    fontWeight: "bold",
    textAlign: "center",
    color: theme?.colors?.voting?.heading,
    marginBottom: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "30px",
      lineHeight: "36px",
      marginBottom: "20px",
    },
  },

  searchBox: {
    width: "100%",
    height: "40px",
    borderRadius: "14px",
    border: "solid 1px " + theme?.colors?.voting?.border,
    marginBottom: "40px",
  },

  searchInput: {
    fontSize: "15px",
    lineHeight: "18px",
    letterSpacing: "0px",
    color: theme?.colors?.voting?.searchText,
    borderRadius: "14px",
  },

  searchMagnifier: {
    width: "17px",
    height: "17px",
    margin: "16px",
    color: theme?.colors?.voting?.searchMagnifier,
  },

  mainBody: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  poolsContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "75px",
    },
  },
  poolsWrapper: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridColumnGap: "30px",
    gridRowGap: "40px",

    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "auto auto",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },

    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "33% 33% 33%",
      gridColumnGap: "30px",
      gridRowGap: "40px",
    },
  },
  poolItemWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    fontSize: "16px",
    fontWeight: "lighter",
    textAlign: "left",
    color: theme?.colors?.voting?.text,
    lineHeight: "24px",
    marginBottom: "15px",
    marginTop: "0px",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
  },

  priceText: {
    color: theme?.colors?.voting?.secondaryText,
  },

  emptyContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  poolsTableContainer: {
    border: "solid 1px " + theme?.colors?.voting?.tableBorder,
    borderRadius: "14px",
  },
  poolsTable: {
    minWidth: "768px",
  },
  logoTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  logoImage: {
    width: "32px",
    height: "32px",
    marginRight: "20px",
  },

  voteActionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },

  voteActionItem: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme?.colors?.voting?.tableHeader,
    color: theme?.colors?.voting?.tableHeaderText,
    fontSize: "17px",
    fontWeight: "bold",
    border: "none",
    height: "80px",
    padding: "0px 30px",
  },
  body: {
    color: theme?.colors?.voting?.text,
    fontSize: "20px",
    border: "none",
    height: "80px",
    padding: "0px 30px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    backgroundColor: theme?.colors?.voting?.tableCell,
    "&:hover": {
      backgroundColor: theme?.colors?.voting?.tableRowHover,
    },
    "&:nth-of-type(odd)": {
      borderTop: "solid 1px " + theme?.colors?.voting?.tableBorder,
      borderBottom: "solid 1px " + theme?.colors?.voting?.tableBorder,
    },
  },
}))(TableRow);

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

const Voting = ({ classes, location }) => {
  const history = useHistory();
  const [votingPools, setVotingPools] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [filteredPools, setFilteredPools] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isVoteModalShown, showVoteModal] = useState(false);
  const [voteModalTitle, setVoteModalTitle] = useState("");
  const [votePool, setVotePool] = useState({});
  const [currentTimestamp, setCurrentTimestamp] = useState(moment.now() / 1000);

  const getVotingPresales = () => {
    const publicPresales = store.getStore("publicPresales");
    const filteredPublicPresales = Object.values(publicPresales).filter(
      (presale) => !presale?.presaleCancelled
    );
    const presales = store.getStore("presales");
    const filteredPresales = Object.values(presales).filter(
      (item) => item.public
    );
    const featuredPresales = [
      ...filteredPresales,
      ...filteredPublicPresales,
    ].sort((presale1, presale2) => presale1?.openTime - presale2?.openTime);

    const currenTimestamp = Date.now() / 1000;
    const votingPresales = featuredPresales.filter(
      (presale) =>
        presale.openTime > currenTimestamp && presale.presaleType === "1"
    );
    return votingPresales;
  };

  const getRemainingTime = (remainingTime) => {
    const remainingDay = Math.floor(remainingTime / 3600 / 24);
    const remainingHours = Math.floor(remainingTime / 3600);
    const remainingMinutes = Math.floor(remainingTime / 60);
    const remainingSeconds = Math.floor(remainingTime);

    if (remainingDay > 0) {
      return `${remainingDay} days`;
    }
    const _remainingHours =
      remainingHours < 10 ? `0${remainingHours}` : `${remainingHours}`;
    const _remainingMins =
      remainingMinutes % 60 < 10
        ? `0${remainingMinutes % 60}`
        : `${remainingMinutes % 60}`;
    const _remainingSecs =
      remainingSeconds % 60 < 10
        ? `0${remainingSeconds % 60}`
        : `${remainingSeconds % 60}`;
    return `${_remainingHours}:${_remainingMins}:${_remainingSecs}`;
  };

  useEffect(() => {
    const votingPresales = getVotingPresales();
    setVotingPools(votingPresales);
    if (votingPresales.length > 0) {
      setLoaded(true);
    }
    emitter.on(GET_POOLS_SUMMARY_RETURNED, getPoolsSummaryReturned);
    let interval = setInterval(() => {
      setCurrentTimestamp(moment.now() / 1000);
    }, 1000);
    return () => {
      clearInterval(interval);
      emitter.removeListener(
        GET_POOLS_SUMMARY_RETURNED,
        getPoolsSummaryReturned
      );
    };
  }, []);

  const getPoolsSummaryReturned = () => {
    const votingPresales = getVotingPresales();
    setVotingPools(votingPresales);
    setLoaded(true);
  };

  useEffect(() => {
    if (!searchString || searchString === "") {
      setFilteredPools(votingPools);
    }
    const _searchString = searchString?.toLowerCase();
    const _filtered = votingPools.filter((pool) => {
      return (
        pool?.saleTitle
          ?.toLowerCase()
          .replace(/ /g, "")
          .includes(_searchString) ||
        pool?.tokenAddress
          ?.toLowerCase()
          .replace(/ /g, "")
          .includes(_searchString)
      );
    });
    setFilteredPools(_filtered);
  }, [votingPools, searchString]);

  const onVote = (pool, yesOrNo) => {
    if (yesOrNo) {
      setVotePool(pool);
      setVoteModalTitle(pool.saleTitle);
      showVoteModal(true);
    } else {
      dispatcher.dispatch({
        type: VOTE,
        content: {
          presaleIndex: pool.presaleIndex,
          voteType: yesOrNo,
        },
      });
    }
  };

  const onVoteYes = () => {
    dispatcher.dispatch({
      type: VOTE,
      content: {
        presaleIndex: votePool.presaleIndex,
        voteType: true,
      },
    });
  };

  return (
    <div className={classes.root}>
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          <Typography className={classes.header} variant="h1">
            Presale Voting
          </Typography>
          <Input
            classes={{ root: classes.searchBox, input: classes.searchInput }}
            disableUnderline={true}
            placeholder="Search by Name, Token contract address, Token description"
            value={searchString}
            startAdornment={
              <SearchIcon classes={{ root: classes.searchMagnifier }} />
            }
            onChange={(ev) => {
              setSearchString(ev.target.value);
            }}
          />
          <div className={classes.mainBody}>
            <div className={classes.poolsContainer}>
              {loaded && votingPools.length > 0 && (
                <FadeInWhenVisible duration={0.83} delay={0.1}>
                  <TableContainer
                    className={classes.poolsTableContainer}
                    component={Paper}
                  >
                    <Table className={classes.poolsTable}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell align="left">Price</StyledTableCell>
                          <StyledTableCell align="left">
                            Softcap
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Hardcap
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            Opens in
                          </StyledTableCell>
                          <StyledTableCell align="left">Voting</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredPools.map((pool) => {
                          console.log(pool);
                          const hardCap = parseFloat(
                            toFixed(
                              bigInt(pool.hardCapInWei),
                              pool?.fundingTokenDecimals || 18,
                              2
                            )
                          );
                          const softCap = parseFloat(
                            toFixed(
                              bigInt(pool.softCapInWei),
                              pool?.fundingTokenDecimals || 18,
                              2
                            )
                          );
                          const tokenPrice = parseFloat(
                            toFixed(
                              bigInt(pool.tokenPriceInWei),
                              pool?.fundingTokenDecimals || 18,
                              6
                            )
                          );
                          const yesVotes = parseFloat(
                            toFixed(bigInt(pool.yesVotes), 18, 4)
                          );
                          const yesPercentage = parseFloat(
                            (yesVotes / 1000000) * 100
                          ).toFixed(2);
                          const noVotes = parseFloat(
                            toFixed(bigInt(pool.noVotes), 18, 4)
                          );
                          const noPercentage = parseFloat(
                            (noVotes / 1000000) * 100
                          ).toFixed(2);
                          const remainingTime = getRemainingTime(
                            pool.openTime - currentTimestamp
                          );
                          const fundingToken = tokens[pool.fundingTokenAddress];

                          return (
                            <StyledTableRow
                              key={pool.saleTitle}
                              onClick={() => {
                                history.push(pool.url);
                              }}
                            >
                              <StyledTableCell component="th" scope="row">
                                <div className={classes.logoTitleContainer}>
                                  <LogoImage
                                    src={pool.linkLogo}
                                    alt="logo"
                                    className={classes.logoImage}
                                    defaultImage={"pools/default.png"}
                                  />
                                  {pool.saleTitle}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <Typography className={classes.priceText}>
                                  {tokenPrice} {fundingToken?.label || "BNB"}
                                </Typography>
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {softCap} {fundingToken?.label || "BNB"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {hardCap} {fundingToken?.label || "BNB"}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {remainingTime}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                <div className={classes.voteActionContainer}>
                                  <div className={classes.voteActionItem}>
                                    <Typography className={classes.percentText}>
                                      {yesPercentage}%
                                    </Typography>
                                    <IconButton
                                      onClick={(ev) => {
                                        ev.stopPropagation();
                                        onVote(pool, true);
                                      }}
                                    >
                                      <ThumbupIcon />
                                    </IconButton>
                                  </div>
                                  <div className={classes.voteActionItem}>
                                    <Typography className={classes.percentText}>
                                      {noPercentage}%
                                    </Typography>
                                    <IconButton
                                      onClick={(ev) => {
                                        ev.stopPropagation();
                                        onVote(pool, false);
                                      }}
                                    >
                                      <ThumbdownIcon />
                                    </IconButton>
                                  </div>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                        {filteredPools.length === 0 && (
                          <StyledTableRow>
                            <StyledTableCell colSpan={5} align="center">
                              No matches
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </FadeInWhenVisible>
              )}
              {!loaded && (
                <div className={classes.emptyContainer}>
                  <div className={classes.warningContainer}>
                    <Typography variant="h4" className={classes.warningText}>
                      Loading...
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Fade>
      <VotingModal
        closeModal={() => {
          showVoteModal(false);
        }}
        modalOpen={isVoteModalShown}
        salesTitle={voteModalTitle || ""}
        onVote={onVoteYes}
      />
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(Voting)));
