const abi = [
  {
    inputs: [
      { internalType: "address", name: "_bscsInfoAddress", type: "address" },
      { internalType: "address", name: "_bscsToken", type: "address" },
      { internalType: "address", name: "_bscsStakingPool", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "title",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "bscsId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "creator",
        type: "address",
      },
    ],
    name: "PresaleCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Received",
    type: "event",
  },
  {
    inputs: [],
    name: "BSCS",
    outputs: [
      { internalType: "contract StarterInfo", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bscsStakingPool",
    outputs: [
      {
        internalType: "contract StarterStaking",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "bscsToken",
    outputs: [
      { internalType: "contract STARToken", name: "", type: "address" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "claimHodlerFund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "address", name: "tokenAddress", type: "address" },
          {
            internalType: "address",
            name: "unsoldTokensDumpAddress",
            type: "address",
          },
          {
            internalType: "address[]",
            name: "whitelistedAddresses",
            type: "address[]",
          },
          {
            internalType: "uint256",
            name: "tokenPriceInWei",
            type: "uint256",
          },
          { internalType: "uint256", name: "hardCapInWei", type: "uint256" },
          { internalType: "uint256", name: "softCapInWei", type: "uint256" },
          {
            internalType: "uint256",
            name: "maxInvestInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "minInvestInWei",
            type: "uint256",
          },
          { internalType: "uint256", name: "openTime", type: "uint256" },
          { internalType: "uint256", name: "closeTime", type: "uint256" },
        ],
        internalType: "struct StarterFactory.PresaleInfo",
        name: "_info",
        type: "tuple",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "listingPriceInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidityAddingTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lpTokensLockDurationInDays",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "liquidityPercentageAllocation",
            type: "uint256",
          },
        ],
        internalType: "struct StarterFactory.PresalePancakeSwapInfo",
        name: "_cakeInfo",
        type: "tuple",
      },
      {
        components: [
          { internalType: "bytes32", name: "saleTitle", type: "bytes32" },
          { internalType: "bytes32", name: "linkTelegram", type: "bytes32" },
          { internalType: "bytes32", name: "linkGithub", type: "bytes32" },
          { internalType: "bytes32", name: "linkTwitter", type: "bytes32" },
          { internalType: "bytes32", name: "linkWebsite", type: "bytes32" },
          { internalType: "bytes32", name: "linkLogo", type: "bytes32" },
        ],
        internalType: "struct StarterFactory.PresaleStringInfo",
        name: "_stringInfo",
        type: "tuple",
      },
    ],
    name: "createPresale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "lastClaimedTimestamp",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  { stateMutability: "payable", type: "receive" },
];

export default abi;
