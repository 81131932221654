import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, Button } from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import { ReactComponent as TwitterIcon } from "../../../assets/twitter.svg";
import { ReactComponent as TelegramIcon } from "../../../assets/telegram.svg";
import { ReactComponent as GithubIcon } from "../../../assets/github.svg";
import { ReactComponent as ATagIcon } from "../../../assets/atag.svg";
import { ReactComponent as WhitepaperIcon } from "../../../assets/whitepaper.svg";

import { tokens } from "../../../constants";
const styles = (theme) => ({
  poolLinkCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.presale?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  linksContainer: {
    width: "100%",
    display: "grid",
    gridGap: "15px",
    gridTemplateColumns: "auto",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "auto auto",
      gridGap: "20px",
    },
  },
  linkAddressPartItem: {
    display: "flex",
    flexDirection: "column",
  },
  infoHeading: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "4px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8px",
    },
  },
  infoValue: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "15px",
    [theme.breakpoints.up("ms")]: {
      fontSize: "15px",
      lineHeight: "20px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
      lineHeight: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "20px",
      lineHeight: "24px",
    },
  },
  connectContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  actionButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.presale?.buttonBg,
    border: "solid 2px" + theme?.colors?.presale?.buttonBg,
    color: theme?.colors?.presale?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    minWidth: "150px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.presale?.buttonBgHover,
    },
    fontFamily: "SFPro",
    letterSpacing: "0px",
    marginTop: "15px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "25px",
    },
  },
  buttonLabel: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
  },
  icon: {
    color: theme?.colors?.presale?.icon,
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
  },
  iconLink: {
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
    marginRight: "20px",
  },
});

const LinkCard = ({ classes, poolInfo }) => {
  const linkAddressPart = (heading, value, link) => {
    return (
      <div className={classes.linkAddressPartItem}>
        <Typography variant="h5" className={classes.infoHeading}>
          {heading}
        </Typography>
        <a
          href={link}
          className={classes.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography variant="h2" className={classes.infoValue}>
            {value}
          </Typography>
        </a>
      </div>
    );
  };
  const theme = useTheme();
  return (
    <div className={classes.poolLinkCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        Important Links
      </Typography>
      <div className={classes.linksContainer}>
        {linkAddressPart(
          "Token Contract Address",
          poolInfo.tokenAddress,
          theme.blockExplorer + "address/" + poolInfo.tokenAddress
        )}

        {theme.type === "bsc" &&
          linkAddressPart(
            "PancakeSwap Address",
            poolInfo.tokenAddress || "",
            poolInfo.tokenAddress
              ? "https://exchange.pancakeswap.finance/#/swap?inputCurrency=" +
                  poolInfo.tokenAddress
              : "https://exchange.pancakeswap.finance/#/swap"
          )}
        {theme.type === "mtc" &&
          linkAddressPart(
            "QuickSwap Address",
            poolInfo.tokenAddress || "",
            poolInfo.tokenAddress
              ? "https://quickswap.exchange/#/swap?inputCurrency=" +
                  poolInfo.tokenAddress
              : "https://quickswap.exchange/#/swap"
          )}
        {linkAddressPart(
          "Locked Liquidity Address",
          poolInfo.lockedLiquidityAddress || "-",
          poolInfo.lockedLiquidityAddress
            ? theme.blockExplorer + "address/" + poolInfo.lockedLiquidityAddress
            : theme.blockExplorer + "address/"
        )}
        {theme.type === "bsc" &&
          linkAddressPart(
            "PooCoin Address",
            poolInfo.tokenAddress || "-",
            poolInfo.tokenAddress
              ? "https://poocoin.app/tokens/" + poolInfo.tokenAddress
              : "https://poocoin.app/tokens/"
          )}
        {theme.type === "mtc" &&
          linkAddressPart(
            "QuickSwap Chart",
            poolInfo.tokenAddress || "-",
            poolInfo.tokenAddress
              ? "https://quickchart.app/token/" +
                  poolInfo.tokenAddress +
                  "?pairedWith=" +
                  tokens[poolInfo?.fundingTokenAddress].label
              : "https://poocoin.app/tokens/"
          )}

        {poolInfo.presaleIndex !== "0" &&
          linkAddressPart(
            "Presale Contract Address",
            poolInfo.presaleAddress,
            theme.blockExplorer + "address/" + poolInfo.presaleAddress
          )}

        {poolInfo.presaleIndex === "0" &&
          linkAddressPart(
            "Presale Contract Address",
            poolInfo.tokenAddress,
            theme.blockExplorer + "address/" + poolInfo.tokenAddress
          )}

        <div classes={classes.linkAddressPartItem}>
          <Typography variant="h5" className={classes.infoHeading}>
            Connect
          </Typography>
          <div className={classes.connectContainer}>
            <a
              href={"https://t.me/" + poolInfo.linkTelegram?.replace("@", "")}
              className={classes.iconLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            <a
              href={
                "https://twitter.com/" + poolInfo.linkTwitter?.replace("@", "")
              }
              className={classes.iconLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            <a
              href={poolInfo.linkWebsite}
              className={classes.iconLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ATagIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            <a
              href={"https://github.com/" + poolInfo.linkGithub}
              className={classes.iconLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GithubIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            {poolInfo.presaleVersion !== "V1" && (
              <a
                href={poolInfo.whitepaper}
                className={classes.iconLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhitepaperIcon
                  className={classes.icon}
                  style={{
                    width: "24px",
                    height: "24px",
                    color: theme?.colors?.presale?.icon,
                  }}
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(LinkCard)));
