import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, CircularProgress } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import { withNamespaces } from "react-i18next";

import moment from "moment";

import { ReactComponent as ThumbUpSvg } from "../../assets/thumb-up.svg";
import { ReactComponent as TelegramSvg } from "../../assets/telegram.svg";
import { ReactComponent as ATagSvg } from "../../assets/atag.svg";

import { tokens } from "../../constants";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxWidth: "360px",
    minWidth: "320px",
    maxHeight: "360px",
    width: "100%",
    height: "100%",
    borderRadius: "30px",
    backgroundColor: theme?.colors.poolCard?.background,
    border: "solid 2px " + theme?.colors.poolCard?.border,
    padding: "40px",
    fontFamily: "SFPro",
  },
  launchIcon: {
    position: "absolute",
    top: "30px",
    right: "30px",
    color: theme?.colors.poolCard?.title,
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
  },
  topPoolType: {
    position: "absolute",
    zIndex: "2",
    top: "-16px",
    left: "110px",
    minWidth: "140px",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "32px",
    borderRadius: "24px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.colors.poolCard?.poolTypeText,
    whiteSpace: "nowrap",
  },
  poolHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "25px",
  },
  poolImage: {
    width: "60px",
    height: "60px",
    marginRight: "20px",
  },
  poolTitle: {
    fontSize: "20px",
    color: theme?.colors.poolCard?.title,
    cursor: "pointer",
    wordBreak: "break-all",
    "&:hover": {
      color: theme?.colors.poolCard?.textSecondary,
    },
  },
  per1BNB: {
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors.poolCard?.title,
    marginBottom: "8px",
  },
  saleValue: {
    fontSize: "25px",
    fontWeight: "normal",
    lineHeight: "30px",
    letterSpacing: "0px",
    textAlign: "left",
    minHeight: "30px",
    color: theme?.colors.poolCard?.textSecondary,
    marginBottom: "15px",
  },
  saleStatusContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "8px",
  },
  salesStatusBar: {
    marginRight: "10px",
    width: "100%",
    height: "16px",
    borderRadius: "12px",
    backgroundColor: theme?.colors.poolCard?.statusBarBackground,
    marginBottom: "16px",
    position: "relative",
  },
  salesStatusSubBar: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
  },
  salesStatusBarYesFilled: {
    zIndex: "2",
    height: "16px",
    borderRadius: "12px",
    position: "absolute",
    left: "0px",
    top: "0px",
  },
  salesStatusBarNoFilled: {
    zIndex: "2",
    height: "16px",
    borderRadius: "12px",
    position: "absolute",
    right: "0px",
    top: "0px",
  },
  saleStatusBarYesVotes: {
    fontSize: "10px",
    color: theme?.colors.poolCard?.statusBarLabelSecondary,
    whiteSpace: "nowrap",
    zIndex: "2",
    position: "absolute",
    left: "0px",
    top: "18px",
  },
  saleStatusBarNoVotes: {
    fontSize: "10px",
    fontWeight: "bold",
    color: theme?.colors.poolCard?.statusBarLabelSecondary,
    whiteSpace: "nowrap",
    zIndex: "2",
    position: "absolute",
    right: "0px",
    top: "18px",
  },
  salesStatusText: {
    fontSize: "15px",
    color: theme?.colors.poolCard?.statusBarLabel,
    whiteSpace: "nowrap",
    marginRight: "8px",
  },
  salesRequiredText: {
    fontSize: "15px",
    color: theme?.colors.poolCard?.statusBarLabel,
    marginBottom: "25px",
  },
  maximumConnectContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginRight: "20px",
  },
  maximumWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  connectWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  maximumConnectLabel: {
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors.poolCard?.title,
    marginBottom: "8px",
  },
  maximumValue: {
    fontSize: "20px",
    lineHeight: "24px",
    color: theme?.colors.poolCard?.textSecondary,
  },
  connectIconsWrapper: {
    display: "flex",
    "& > *": {
      width: "24px",
      height: "24px",
      color: theme?.colors.poolCard?.linkIcon,
      marginRight: "20px",
      "&:hover": {
        color: theme?.colors.poolCard?.linkIconHover,
      },
      cursor: "pointer",
    },
  },
  poolPercentageText: {
    color: theme?.colors?.poolCard?.statusBarLabel,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme?.colors?.presale?.loadingBackground,
    zIndex: 4,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: theme?.colors?.presale?.loadingIcon,
    position: "absolute",
    top: "100px",
    left: "50%",
  },
});

const getPoolType = (isStarted, isEnded, totalCollected, hardCap, softCap) => {
  if (totalCollected >= hardCap) return "FILLED";
  if (isEnded) {
    if (totalCollected < softCap) {
      return "EXPIRED";
    }
    return "CLOSED";
  }
  if (!isStarted) {
    return "NOTOPENED";
  }
  return "ONGOING";
};

const getStatusBarColor = (poolType) => {
  if (poolType === "FILLED") return "GREEN";
  if (poolType === "CLOSED") return "ORANGE";
  if (poolType === "NOTOPENED") return "YELLOW";
  if (poolType === "ONGOING") return "YELLOW";
  if (poolType === "EXPIRED") return "RED";
  return "RED";
};

const getRemainingTime = (remainingTime) => {
  const remainingDay = Math.floor(remainingTime / 3600 / 24);
  const remainingHours = Math.floor(remainingTime / 3600);
  const remainingMinutes = Math.floor(remainingTime / 60);
  const remainingSeconds = Math.floor(remainingTime);

  if (remainingDay > 0) {
    if (remainingDay === 1) {
      return `${remainingDay} day`;
    }
    return `${remainingDay} days`;
  }

  if (remainingHours > 0) {
    return `${remainingHours}hrs ${remainingMinutes % 60} mins`;
  }

  if (remainingMinutes > 0) {
    return `${remainingMinutes} mins`;
  }

  if (remainingSeconds > 0) {
    return `${remainingSeconds} secs`;
  }

  return "";
};

const CancelledPoolCard = ({
  classes,
  openTime,
  closeTime,
  image,
  title,
  token,
  tokenPrice,
  salesValue,
  yesPercentage,
  noPercentage,
  yesVotes,
  noVotes,
  minYesVotesThreshold,
  presaleType,
  maximum,
  minimum,
  maximumInvest,
  telegram,
  externalLink,
  url,
  fundingTokenAddress,
  version,
}) => {
  const history = useHistory();
  const fundingToken = tokens[fundingTokenAddress] || { label: "BNB" };
  const isStarted = moment.unix(openTime).isBefore(moment.now());
  const isEnded = moment.unix(closeTime).isBefore(moment.now());
  const poolType = getPoolType(
    isStarted,
    isEnded,
    salesValue,
    maximum,
    minimum
  );

  const statusBarColorId = getStatusBarColor(poolType);

  const currentTimestamp = moment.now() / 1000;

  const remainingTime = isEnded
    ? 0
    : isStarted
    ? closeTime - currentTimestamp
    : openTime - currentTimestamp;

  const theme = useTheme();

  const [logoImage, setLogoImage] = useState(image || "pools/default.png");

  useEffect(() => {
    setLogoImage(image);
  }, [image]);

  return (
    <div className={classes.root}>
      {url && (
        <LaunchIcon
          className={classes.launchIcon}
          onClick={() => {
            history.push(url);
          }}
        />
      )}
      {!title && !openTime && (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loadingIcon} size={60} />
        </div>
      )}

      <div className={classes.poolHeader}>
        <img
          className={classes.poolImage}
          src={logoImage}
          alt="pool"
          onError={(er) => {
            setLogoImage("pools/default.png");
          }}
        />
        <Typography
          variant="h3"
          className={classes.poolTitle}
          onClick={() => {
            history.push(url);
          }}
        >
          {title}
        </Typography>
      </div>
      <Typography variant="h5" className={classes.per1BNB}>
        {tokenPrice} {fundingToken?.label} per Token
      </Typography>
      <Typography variant="h4" className={classes.saleValue}>
        {salesValue.toLocaleString()} {fundingToken?.label}
      </Typography>
      <div className={classes.saleStatusContainer}>
        <div className={classes.salesStatusBar}>
          <div
            className={classes.salesStatusBarYesFilled}
            style={{
              width: `${yesPercentage}%`,
              backgroundColor: theme?.colors.poolCard?.statusBarFilled.GREEN,
            }}
          />
          <div
            className={classes.salesStatusBarNoFilled}
            style={{
              width: `${noPercentage}%`,
              backgroundColor: theme?.colors.poolCard?.statusBarFilled.RED,
            }}
          />
          <Typography className={classes.saleStatusBarYesVotes}>
            Yes: {parseFloat(yesVotes).toFixed(0)}
          </Typography>
          <Typography className={classes.saleStatusBarNoVotes}>
            No: {parseFloat(noVotes).toFixed(0)}
          </Typography>
        </div>
        <Typography className={classes.salesStatusText}>
          {yesPercentage.toFixed(0)}%
        </Typography>
        <ThumbUpSvg />
      </div>
      {presaleType !== "0" && version === "V1" && (
        <Typography className={classes.salesRequiredText}>
          At least {(minYesVotesThreshold / 10000).toFixed(0)}% 'Yes' votes
          required.
        </Typography>
      )}
      {presaleType !== "0" && version === "V2" && (
        <Typography className={classes.salesRequiredText}>
          Yes - No Votes > {(minYesVotesThreshold / 10000).toFixed(0)}% of max
          supply
        </Typography>
      )}
      {presaleType !== "0" && version === "V3" && (
        <Typography className={classes.salesRequiredText}>
          Yes - No Votes > {(minYesVotesThreshold / 10000).toFixed(0)}% of max
          supply
        </Typography>
      )}
      <div className={classes.maximumConnectContainer}>
        <div className={classes.maximumWrapper}>
          <Typography className={classes.maximumConnectLabel}>
            Maximum
          </Typography>
          <Typography className={classes.maximumValue}>
            {maximumInvest.toFixed(2)} {fundingToken?.label}
          </Typography>
        </div>
        <div className={classes.connectWrapper}>
          <Typography className={classes.maximumConnectLabel}>
            Connect
          </Typography>
          <div className={classes.connectIconsWrapper}>
            {telegram && (
              <TelegramSvg
                onClick={() => {
                  window.open(telegram);
                }}
              />
            )}
            {externalLink && (
              <ATagSvg
                onClick={() => {
                  window.open(externalLink);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(
  withRouter(withStyles(styles)(CancelledPoolCard))
);
