import React, { useState, useEffect } from "react";
import { withRouter, useHistory, useParams } from "react-router-dom";
import bigInt from "big-integer";
import moment from "moment";

import { withStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Typography,
  Button,
  Fade,
  Snackbar,
  useTheme,
  CircularProgress,
} from "@material-ui/core";

import { withNamespaces } from "react-i18next";

import PoolMainInfoCard from "./components/mainInfoCard";
import InvestmentCard from "./components/investmentCard";
import QualifyCard from "./components/qualifyCard";
import IDOCard from "./components/idoCard";
import PlatformFeeCard from "./components/platformFeeCard";
import MinimumLockingTimeCard from "./components/minimumLockingTimeCard";
import PriceCard from "./components/priceCard";

import Store from "../../stores/store";

import {
  GET_START_STATS,
  GET_START_STATS_RETURNED,
} from "../../constants/constants";

const emitter = Store.emitter;
const dispatcher = Store.dispatcher;
const store = Store.store;

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    fontFamily: "SFPro",
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    maxWidth: "1140px",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    height: "50px",
    padding: "10px 20px",
    backgroundColor: theme?.colors?.poolCard?.background,
    borderRadius: "12px",
  },
  warningText: {
    color: theme?.colors?.poolCard?.title,
    textAlign: "center",
    fontSize: "20px",
    lineHeight: "25px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    zIndex: 4,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: "white",
    position: "absolute",
    top: "100px",
    left: "50%",
  },
  miniCardContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  blankCard: {
    width: "360px",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const PresalePage = ({ classes, location }) => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState("");
  const [startStats, setStartStats] = useState(
    store.getStore("startStats") || {}
  );

  useEffect(() => {
    emitter.on(GET_START_STATS_RETURNED, getStartStatsReturned);
    dispatcher.dispatch({
      type: GET_START_STATS,
      content: {},
    });
    return () => {
      emitter.removeListener(GET_START_STATS_RETURNED, getStartStatsReturned);
    };
  }, []);

  const getStartStatsReturned = () => {
    setStartStats(store.getStore("startStats") || {});
    window.setTimeout(() => {
      dispatcher.dispatch({
        type: GET_START_STATS,
        content: {},
      });
    }, 5000);
  };

  return (
    <div className={classes.root}>
      {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          autoHideDuration={3000}
          onClick={() => {
            setErrorMessage(false);
          }}
          onClose={() => {
            setErrorMessage(false);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
      )}
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          {!startStats.startPrice && (
            <div className={classes.loadingContainer}>
              <CircularProgress className={classes.loadingIcon} size={60} />
            </div>
          )}
          <PoolMainInfoCard poolInfo={startStats} />
          <div className={classes.miniCardContainer}>
            <PriceCard poolInfo={startStats} />
            <PlatformFeeCard />
            <MinimumLockingTimeCard />
          </div>
          <div className={classes.miniCardContainer}>
            <QualifyCard poolInfo={startStats} />
            <IDOCard poolInfo={startStats} />
            <InvestmentCard poolInfo={startStats} />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(PresalePage)));
