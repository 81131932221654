import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Button,
  Typography,
  Input,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { colors } from "../../../theme/theme";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: "#13161B",
    minWidth: "100%",
    minHeight: "100%",
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "45px",
    "&:first-child": {
      paddingTop: "45px",
    },
    borderRadius: "30px",
  },
  modalRoot: {
    borderRadius: "30px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  screenMask: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  walletButton: {
    borderRadius: "0.375rem",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  cancelButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    marginLeft: "15px",
    marginRight: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  modalHeader: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "30px",
    fontWeight: "normal",
    lineHeight: "36px",
    marginBottom: "30px",
  },
  priceHeader: {
    color: theme?.colors?.presale?.cardTitleSecondary,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "8px",
  },
  priceText: {
    color: theme?.colors?.presale?.addressValueText,
    fontSize: "30px",
    fontWeight: "normal",
    lineHeight: "36px",
    marginBottom: "30px",
  },
  minMaxText: {
    color: theme?.colors?.presale?.commentText,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "24px",
    marginBottom: "16px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "395px",
    position: "relative",
    marginBottom: "30px",
  },
  actionInput: {
    border: "solid 1px " + theme?.colors?.presale?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    paddingRight: "70px",
    color: theme?.colors?.presale.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.presale.cardTitleSecondary,
  },
  maxButton: {
    position: "absolute",
    right: "0px",
    borderRadius: "0px 14px 14px 0px",
    height: "50px",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    width: "70px",
    padding: "16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
  },
});

const BuyModal = ({
  classes,
  closeModal,
  modalOpen,
  tokenPrice,
  minInvestAmount,
  maxInvestAmount,
  onBuy,
}) => {
  const [inputValue, setInputValue] = useState(0);

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={false}
      classes={{
        paperWidthSm: classes.modalRoot,
        scrollPaper: classes.screenMask,
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <div className={classes.modalContainer}>
          <Typography variant="h3" className={classes.modalHeader}>
            Buy Token
          </Typography>
          <Typography variant="h5" className={classes.priceHeader}>
            Price
          </Typography>
          <Typography variant="h3" className={classes.priceText}>
            1 KXA = {tokenPrice} BNB
          </Typography>
          <Typography variant="h4" className={classes.minMaxText}>
            Min {minInvestAmount} BNB and Max {maxInvestAmount} BNB
          </Typography>
          <div className={classes.inputContainer}>
            <Input
              classes={{
                root: classes.actionInput,
                underline: classes.actionUnderline,
              }}
              disableUnderline={true}
              value={inputValue}
              onChange={(ev) => {
                setInputValue(ev.target.value);
              }}
              autoFocus={true}
            />
            <Button
              classes={{ root: classes.maxButton, label: classes.buttonLabel }}
              onClick={() => {
                setInputValue(maxInvestAmount.toString());
              }}
            >
              MAX
            </Button>
          </div>
          <div className={classes.actionContainer}>
            <Button
              classes={{
                root: classes.cancelButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              classes={{
                root: classes.walletButton,
                label: classes.buttonLabel,
              }}
              onClick={(ev) => {
                if (
                  minInvestAmount <= inputValue &&
                  maxInvestAmount >= inputValue
                ) {
                  if (onBuy) {
                    onBuy(inputValue);
                  }
                  closeModal();
                } else {
                  setInputValue(minInvestAmount);
                }
              }}
            >
              Buy
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(BuyModal));
