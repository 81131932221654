import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Fade,
  Snackbar,
  useTheme,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { withNamespaces } from "react-i18next";

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: "30px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      padding: "70px",
    },
    position: "relative",
  },
  mainContainer: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    maxWidth: "1140px",
  },
  logo: {
    width: "250px",
    height: "250px",
    objectFit: "contain",
    marginBottom: "1rem",
  },
  header: {
    fontSize: "30px",
    lineHeight: "35px",
    fontWeight: "bold",
    textAlign: "left",
    color: theme?.colors?.litepaper?.heading,
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
      lineHeight: "65px",
      marginBottom: "60px",
    },
  },
  headerSecondary: {
    fontSize: "30px",
    lineHeight: "35px",
    fontWeight: "bold",
    textAlign: "left",
    color: theme?.colors?.litepaper?.headingSecondary,
    marginBottom: "30px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "50px",
      lineHeight: "65px",
      marginBottom: "60px",
    },
  },
  textBody: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginBottom: "50px",
    },
  },

  textBodyColumn: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "50px",
    },
  },

  textBodyHeader: {
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "20px",
    color: theme?.colors?.litepaper?.heading,
    marginBottom: "15px",
    [theme.breakpoints.up("md")]: {
      fontSize: "25px",
      lineHeight: "25px",
      marginBottom: "25px",
    },
  },

  textBodyHeaderSecondary: {
    fontWeight: "normal",
    fontSize: "20px",
    lineHeight: "25px",
    color: theme?.colors?.litepaper?.headingSecondary,
    [theme.breakpoints.up("md")]: {
      fontSize: "25px",
      lineHeight: "30px",
    },
  },

  textBodyLeft: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      marginRight: "20px",
    },
  },

  textBodyRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      marginLeft: "20px",
    },
  },
  imageContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    marginBottom: "30px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "70px",
    },
  },

  diagramImage: {
    width: "100%",
    display: "none",
    height: "100%",
    objectFit: "contain",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },

  diagramImageMobile: {
    width: "100%",
    display: "block",
    height: "100%",
    objectFit: "contain",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  disclaimerContainer: {
    width: "100%",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    backgroundColor: theme?.colors?.litepaper?.disclaimerBackground,
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  disclaimerHeader: {
    fontWeight: "bold",
    fontSize: "15px",
    lineHeight: "20px",
    color: theme?.colors?.litepaper?.heading,
    marginBottom: "15px",
    [theme.breakpoints.up("md")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "20px",
    },
  },
  disclaimerText: {
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors?.litepaper?.heading,
    marginBottom: "5px",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "30px",
    },
  },

  tokenomicsCard: {
    display: "flex",
    backgroundColor: theme?.colors?.litepaper?.background,
    maxWidth: "360px",
    width: "100%",
    height: "fit-content",
    borderRadius: "30px",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 40px 10px 40px",
    marginBottom: "30px",
  },
  tokenomicsHeading: {
    fontSize: "25px",
    fontWeight: "bold",
    color: theme?.colors?.litepaper?.heading,
    letterSpacing: "0px",
    marginBottom: "20px",
    whiteSpace: "nowrap",
  },
  tokenomicsInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
  },
  tokenomicsInfoRow: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      marginBottom: "15px",
      justifyContent: "flex-start",
    },
  },
  tokenomicsInfoItem: {
    minWidth: "160px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginBottom: "0px",
    },
  },
  tokenomicsInfoItemHeading: {
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.litepaper?.heading,
    fontWeight: "normal",
    fontSize: "15px",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8px",
    },
  },
  tokenomicsInfoItemBody: {
    textAlign: "left",
    letterSpacing: "0px",
    color: theme?.colors?.litepaper?.headingSecondary,
    fontWeight: "normal",
    fontSize: "25px",
  },
  text: {
    fontSize: "15px",
    fontWeight: "normal",
    textAlign: "left",
    color: theme?.colors?.litepaper?.text,
    lineHeight: "22px",
    marginBottom: "15px",
    marginTop: "0px",
    [theme.breakpoints.up("md")]: {
      fontSize: "18px",
      lineHeight: "22px",
      marginBottom: "25px",
    },
  },
  farmingButton: {
    marginTop: "1.25rem",
    backgroundColor: theme?.colors.home?.buttonBackground,
    color: theme?.colors.home?.buttonText,
    fontWeight: "bold",
    height: "42px",
    textTransform: "capitalize",
    borderRadius: ".375rem",
    padding: "0.5rem 1rem",
    "&:hover": {
      backgroundColor: theme?.colors.home?.buttonBackground,
      color: theme?.colors.home?.buttonText,
      opacity: 0.7,
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LitepaperPage = ({ classes, location }) => {
  const history = useHistory();
  const [snackOpen, setSnackOpen] = useState(true);
  const theme = useTheme();

  const nav = (screen) => {
    history.push(screen);
  };

  return (
    <div className={classes.root}>
      <Fade in={true} timeout={{ enter: 1000 }}>
        <div className={classes.mainContainer}>
          <Typography className={classes.header} variant="h1">
            A truly decentralized{" "}
            <span className={classes.headerSecondary}>launchpad</span> <br />
            for&nbsp;
            <span className={classes.headerSecondary}>
              {theme?.chain || "Binance Smart Chain"}
            </span>
            &nbsp;({theme?.chainSymbol || "BSC"})
          </Typography>
          <div className={classes.textBody}>
            <div className={classes.textBodyLeft}>
              <p className={classes.text}>
                Innovation for bootstrapped projects has been grinding to a halt
                on the Ethereum blockchain. Exorbitant gas fees has nearly
                caused new project launches to stall and existing projects to
                lose user engagement - staking, claiming, and normal trades are
                costing ETH users hundreds in transaction fees.{" "}
              </p>
              <p className={classes.text}>
                And this trend will likely continue.
              </p>
              <p className={classes.text}>
                But innovation cannot be stopped. Over the past several months,
                developers have sought lower-cost options to deploy their
                experiments. The {theme?.chain || "Binance Smart Chain"} (
                {theme?.chainSymbol || "BSC"}) has become the go-to platform for
                new product launches based on Solidity, and for existing
                projects looking to stay alive.
              </p>
              <p className={classes.text}>
                {theme?.chainSymbol || "BSC"} launchpads today are too
                bureaucratic - they require KYC and a manual selection process
                that is a reminder of the old barriers that have traditionally
                kept new innovation only accessible to the rich.
              </p>
              <p className={classes.text}>
                We believe communities are an important contributor to
                addressing many of the worlds problems. We believe, that given
                the correct tools, communities can come together and take
                matters into their own hands.{" "}
              </p>
              <p className={classes.text}>
                That is why we have developed Starter - it is a
                community-governed launchpad for raising capital for{" "}
                {theme?.chainSymbol || "BSC"}&nbsp;projects, that isn't filled
                with government red tape and KYC rules. Instead, it is the
                Starter community that will determine which projects to list. It
                is the Starter community that uses their collective due
                diligence and DYOR skills to vote Yes or No on projects coming
                through Starter looking for funds.
              </p>
              <p className={classes.text}>
                Let us filter through the messy landscape where rugs overshadow
                the great potential of our growing ecosystem on{" "}
                {theme?.chainSymbol || "BSC"}.
              </p>
              <p className={classes.text}>
                Join the first community-oriented network for raising capital
                that will quickly become the go-to place to find the next high
                quality gems who are looking for an affordable place to call
                home for their smart contracts.
              </p>
            </div>
            <div className={classes.textBodyRight}>
              <div className={classes.tokenomicsCard}>
                <Typography className={classes.tokenomicsHeading}>
                  START Tokenomics
                </Typography>
                <div className={classes.tokenomicsInfo}>
                  <div className={classes.tokenomicsInfoRow}>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Total Supply
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>1M</span>
                    </div>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Presale Supply
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        450K
                      </span>
                    </div>
                  </div>
                  <div className={classes.tokenomicsInfoRow}>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Liquidity
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        225K
                      </span>
                    </div>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Incubator Grant
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        50K
                      </span>
                    </div>
                  </div>
                  <div className={classes.tokenomicsInfoRow}>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Development
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        75K
                      </span>
                    </div>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Partnerships
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        125K
                      </span>
                    </div>
                  </div>
                  <div className={classes.tokenomicsInfoRow}>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Marketing
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        25K
                      </span>
                    </div>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Team
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        50K
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.tokenomicsCard}>
                <Typography className={classes.tokenomicsHeading}>
                  START Token Offering
                </Typography>
                <div className={classes.tokenomicsInfo}>
                  <div className={classes.tokenomicsInfoRow}>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Presale Supply
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        450K
                      </span>
                    </div>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Purchase Option
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        BNB
                      </span>
                    </div>
                  </div>
                  <div className={classes.tokenomicsInfoRow}>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Presale Price
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        $1.50
                      </span>
                    </div>
                    <div className={classes.tokenomicsInfoItem}>
                      <span className={classes.tokenomicsInfoItemHeading}>
                        Listing Price
                      </span>
                      <span className={classes.tokenomicsInfoItemBody}>
                        $1.75
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.textBodyColumn}>
            <div className={classes.textBodyHeader}>
              How It Works -&nbsp;
              <span className={classes.textBodyHeaderSecondary}>Applying</span>
            </div>
            <p className={classes.text}>
              Applying for a sale on Starter is very simple, and more
              importantly, very affordable! Here is how:
            </p>
          </div>
          <div className={classes.imageContainer}>
            <img
              className={classes.diagramImage}
              src={theme.applyDesktopImg}
              alt="applying"
            />
            <img
              className={classes.diagramImageMobile}
              src={theme.applyMobileImg}
              alt="applying"
            />
          </div>
          <div className={classes.textBodyColumn}>
            <div className={classes.textBodyHeader}>
              How It Works -&nbsp;
              <span className={classes.textBodyHeaderSecondary}>Sale</span>
            </div>
            <p className={classes.text}>
              Participating in a presale is a simple as 1.2.3! Just follow the
              steps below.
            </p>
          </div>
          <div className={classes.imageContainer}>
            <img
              className={classes.diagramImage}
              src={theme.saleDesktopImg}
              alt="sale"
            />
            <img
              className={classes.diagramImageMobile}
              src={theme.saleMobileImg}
              alt="sale"
            />
          </div>
          <div className={classes.disclaimerContainer}>
            <div className={classes.disclaimerHeader}>Disclaimer</div>
            <div className={classes.disclaimerText}>
              The Starter team does not endorse, support or otherwise perform
              any due diligence on the projects listed on its platform. As
              always, please DO YOUR OWN RESEARCH before investing any of your
              hard-earned {theme?.symbol || "BNB"} into these projects and never
              invest more than you are willing to lose.
            </div>
          </div>
          <div className={classes.textBodyColumn}>
            <div className={classes.textBodyHeader}>
              Starter&nbsp;
              <span className={classes.textBodyHeaderSecondary}>Holder</span>
              &nbsp;Benefits
            </div>
            <p className={classes.text}>
              Wondering what are the benefits of being a START HODLER? At
              launch, here are some of the amazing benefits available to all!
            </p>
            <p className={classes.text}>
              <b>* IDO PARTICIPATION</b>: Hodlers staking at least 50 START
              (QUICK+START LP on Polygon) tokens can participate in future IDOs
              on Starter
              <br />
              <b>* VOTING POWER</b>: Hodlers staking at least 100 START
              (QUICK+START LP on Polygon) tokens can vote to approve or deny
              projects on Starter
              <br /> <b>* EARN {theme?.symbol || "BNB"}</b>: Hodlers staking at
              least 1,250 START (QUICK+START LP on Polygon) tokens
              <br /> <b>* HODLER DISCOUNT</b>: Hodlers staking at least 1,250 or
              more START tokens (QUICK+START LP on Polygon)
              <br />
              <b>* FUTURE BENEFITS</b>: as we acquire the financial resources to
              establish Starter as a viable fundraising platform for the long
              term, more benefits will be unveiled specifically for START token
              holders
            </p>
            <p className={classes.text}>
              Be an engaged member of the Starter community, let your voice be
              heard AND get paid in {theme?.symbol || "BNB"}!
            </p>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(LitepaperPage)));
