import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme } from "@material-ui/core";
import { ReactComponent as TwitterIcon } from "../../assets/twitter.svg";
import { ReactComponent as TelegramIcon } from "../../assets/telegram.svg";
import { ReactComponent as DiscordIcon } from "../../assets/discord.svg";
import { ReactComponent as MediumIcon } from "../../assets/medium.svg";
import { ReactComponent as EtherscanIcon } from "../../assets/etherscan-logo.svg";
import { ReactComponent as GitbookIcon } from "../../assets/gitbook.svg";

import { withNamespaces } from "react-i18next";

const styles = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    minHeight: "120px",
    height: "fit-content",
    backgroundColor: theme?.colors?.footer?.background,
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 40px",
    borderTop: "solid 2px " + theme?.colors?.footer?.border,
  },
  footer: {
    display: "flex",
    width: "100%",
    maxWidth: "1140px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  footerLogo: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      alignItems: "flex-start",
      marginBottom: "0px",
    },
  },
  footerLogoBottom: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  footerLogoWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  footerLogoText: {
    fontSize: "20px",
    fontWeight: "bold",
    color: theme?.colors?.footer?.text,
  },
  footerLogoBottomText: {
    fontSize: "15px",
    letterSpacing: 0,
    color: theme?.colors?.footer?.textSecondary,
    whiteSpace: "nowrap",
  },
  footerLinks: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
  },
  footerLinksText: {
    fontSize: "20px",
    lineHeight: "25px",
    color: theme?.colors?.footer?.text,
    fontWeight: "bold",
    letterSpacing: "0px",
    marginBottom: "15px",
  },
  footerLinksContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footerRight: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  footerText: {
    cursor: "pointer",
  },
  languageContainer: {
    paddingLeft: "12px",
    display: "none",
  },
  link: {
    padding: "0 5px 0 5px",
    textDecoration: "none",
    "&:hover": {
      opacity: 0.8,
    },
    [theme.breakpoints.up("sm")]: {
      padding: "0 20px 0 20px",
    },
  },
  icon: {
    color: theme?.colors?.footer?.icon,
  },
  logoText: {
    color: theme?.colors?.header?.text,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  logoBoldText: {
    color: theme?.colors?.header?.boldBrownText,
    letterSpacing: ".025em",
    fontSize: "1.5rem",
    fontWeight: "bold",
  },
  linkText: {
    color: theme?.colors?.header?.boldBrownText,
    letterSpacing: ".025em",
    fontSize: "1rem",
    fontWeight: "bold",
  },
});

const Footer = ({ classes }) => {
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <div className={classes.footer}>
        <div className={classes.footerLogo}>
          <div className={classes.footerLogoWrapper}>
            <img
              src={theme.logoImage}
              alt="BSCStarter"
              style={{ width: "46px", height: "46px", marginRight: "8px" }}
            />
            <span className={classes.logoText}>Starter</span>
          </div>
          <div className={classes.footerLogoBottom}>
            <span className={classes.footerLogoBottomText}>
              © 2022 Starter Ltd. All rights reserved.
            </span>
          </div>
        </div>
        <div className={classes.footerLinks}>
          <span className={classes.footerLinksText}>Connect with us</span>
          <div className={classes.footerLinksContainer}>
            <a
              href="https://t.me/StarterXyz"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TelegramIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            <a
              href="https://twitter.com/StarterXyz"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            <a
              href="https://starterxyz.medium.com"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <MediumIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
            {theme.type === "bsc" && (
              <a
                href="https://bscscan.com/token/0x31d0a7ada4d4c131eb612db48861211f63e57610"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EtherscanIcon
                  className={classes.icon}
                  style={{ width: "24px", height: "24px" }}
                />
              </a>
            )}
            {theme.type === "mtc" && (
              <a
                href="https://polygonscan.com/token/0x6ccf12b480a99c54b23647c995f4525d544a7e72"
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <EtherscanIcon
                  className={classes.icon}
                  style={{ width: "24px", height: "24px" }}
                />
              </a>
            )}
            <a
              href="https://docs.bscstarter.finance/"
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitbookIcon
                className={classes.icon}
                style={{ width: "24px", height: "24px" }}
              />
            </a>
          </div>
        </div>
        <div className={classes.footerRight}>
          <a href="/#/advisors" className={classes.link}>
            <Typography className={classes.linkText}>Advisors</Typography>
          </a>
          <a href="/#/litepaper" className={classes.link}>
            <Typography className={classes.linkText}>Litepaper</Typography>
          </a>
          <a
            href="http://bit.ly/StarterCertifiedApp"
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography className={classes.linkText}>
              Apply for Certified
            </Typography>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(Footer)));
