import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

import {
  Typography,
  useTheme,
  Button,
  Input,
  TextField,
} from "@material-ui/core";
import { withNamespaces } from "react-i18next";

import { strings as v1BscStrings } from "../abis/bsc/v1";
import { strings as v2BscStrings } from "../abis/bsc/v2";
import { strings as v3BscStrings } from "../abis/bsc/v3";

import { strings as v1MtcStrings } from "../abis/mtc/v1";
import { strings as v2MtcStrings } from "../abis/mtc/v2";
import { strings as v3MtcStrings } from "../abis/mtc/v3";

import Web3 from "web3";

const v1Strings = {
  bsc: v1BscStrings,
  mtc: v1MtcStrings,
  eth: v1MtcStrings,
  ftm: v1MtcStrings,
  avax: v1MtcStrings,
};
const v2Strings = {
  bsc: v2BscStrings,
  mtc: v2MtcStrings,
  eth: v2MtcStrings,
  ftm: v2MtcStrings,
  avax: v2MtcStrings,
};
const v3Strings = {
  bsc: v3BscStrings,
  mtc: v3MtcStrings,
  eth: v3MtcStrings,
  ftm: v3MtcStrings,
  avax: v3MtcStrings,
};

const styles = (theme) => ({
  poolStringCard: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: theme?.colors?.apply?.cardBackground,
    borderRadius: "30px",
    padding: "20px",
    marginBottom: "25px",
    [theme.breakpoints.up("sm")]: {
      borderRadius: "30px",
      padding: "40px",
      marginBottom: "50px",
    },
  },
  poolTitle: {
    color: theme?.colors?.apply?.cardTitle,
    fontSize: "15px",
    fontWeight: "bold",
    lineHeight: "20px",
    marginBottom: "15px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "25px",
      lineHeight: "30px",
      marginBottom: "30px",
    },
  },
  poolPresaleDetailInputContainer: {
    display: "grid",
    gridTemplateColumns: "auto",
    gridGap: "5px 20px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "auto auto",
      gridGap: "10px 20px",
    },
    marginTop: "20px",
  },

  poolInfoInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  poolInfoInputHeader: {
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0px",
    color: theme?.colors?.apply?.cardTitleSecondary,
    textTransform: "capitalize",
  },

  poolInfoInputValue: {
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0px",
    color: "#F0B900",
  },

  text: {
    color: theme?.colors?.apply?.cardText,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "15px",
    },
  },
  textSecondary: {
    color: theme?.colors?.apply?.cardTitleSecondary,
    fontSize: "15px",
    lineHeight: "15px",
    letterSpacing: "0px",
  },

  actionInput: {
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: theme?.colors?.apply?.cardTitleSecondary,
    outline: "0px",
  },
  actionUnderline: {
    color: theme?.colors?.apply?.cardTitleSecondary,
  },
  poolPrevNextWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  actionButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  errorText: {
    fontSize: "12px",
    lineHeight: "16px",
    width: "100%",
    color: theme?.colors?.apply?.warningBg,
    marginTop: "4px",
    marginRight: "4px",
    textAlign: "right",
  },
  textField: {
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    borderRadius: "14px",
    padding: "8px",
    height: "50px",
    width: "100%",
    color: theme?.colors?.apply?.cardText,
    fontSize: "20px",
    outline: "0px",
    "& > div > input": {
      color: theme?.colors?.apply?.cardText,
    },
  },
  poolPresaleActionContainer: {
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
  },
  poolActionPart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    marginBottom: "50px",
  },
  poolActionInputContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginBottom: "20px",
  },
  poolInfoArgInput: {
    border: "solid 1px " + theme?.colors?.apply?.cardTitleSecondary,
    borderRadius: "8px",
    padding: "0px 8px",
    color: theme?.colors?.apply?.cardText,
    fontSize: "16px",
    width: "100%",
    marginBottom: "10px",
    marginTop: "10px",
  },
});

const getValueFromArg = (type = "string", value = "") => {
  if (type === "string") {
    return value;
  }
  if (type === "bool") {
    return value === "true";
  }
  if (type === "uint256") {
    if (parseInt(value) >= 100000000000000) {
      return value;
    }
    return parseInt(value);
  }
  if (type === "uint8") {
    return parseInt(value);
  }
  if (type === "address") {
    return value;
  }
  if (type === "address[]") {
    return value.slice(1, value.length - 1).split(",");
  }
  return value;
};

const getMethodFromArg = (method, args) => {
  return method + "(" + args.map((item) => item.type).join(",") + ")";
};

const ActionPart = ({ classes, method, args, onWrite }) => {
  const [inputArgs, setInputArgs] = useState(
    (args?.length > 0 && args?.map((item) => "")) || []
  );
  return (
    <div className={classes.poolActionPart}>
      <Typography variant="h4" className={classes.poolInfoInputHeader}>
        {method}
      </Typography>
      <div className={classes.poolActionInputContainer}>
        {args.length > 0 &&
          args.map((item, index) => (
            <Input
              key={item?.name}
              classes={{ root: classes.poolInfoArgInput }}
              placeholder={item?.name + "(" + item?.type + ")"}
              onChange={(ev) => {
                const _temp = [...inputArgs];
                _temp[index] = getValueFromArg(item?.type, ev.target.value);
                setInputArgs(_temp);
              }}
            />
          ))}
      </div>
      <Button
        classes={{ root: classes.actionButton, label: classes.buttonLabel }}
        onClick={() => {
          onWrite(getMethodFromArg(method, args), inputArgs);
        }}
      >
        Write
      </Button>
    </div>
  );
};

const StringCard = ({ classes, presale, onWrite }) => {
  const theme = useTheme();
  const v1String = v1Strings[theme?.type || "mtc"];
  const v2String = v2Strings[theme?.type || "mtc"];
  const v3String = v3Strings[theme?.type || "mtc"];

  const renderInfo = (title, field) => {
    return (
      <div className={classes.poolInfoInput}>
        <Typography variant="h4" className={classes.poolInfoInputHeader}>
          {title}
        </Typography>
        <Typography variant="h4" className={classes.poolInfoInputValue}>
          {presale[field]}
        </Typography>
      </div>
    );
  };

  return (
    <div className={classes.poolStringCard}>
      <Typography variant="h1" className={classes.poolTitle}>
        String Details
      </Typography>
      <div className={classes.poolPresaleDetailInputContainer}>
        {renderInfo("Sale Title", "saleTitle")}
        {renderInfo("Link Telegram", "linkTelegram")}
        {renderInfo("Link Github", "linkGithub")}
        {renderInfo("Link Website", "linkWebsite")}
        {renderInfo("Link Twitter", "linkTwitter")}
        {renderInfo("Link Logo", "linkLogo")}
        {presale.presaleVersion === "V2" && (
          <>
            {renderInfo("KYC Information", "kycInformation")}
            {renderInfo("Description", "description")}
            {renderInfo("Whitepaper", "whitepaper")}
            {renderInfo("Category", "categoryId")}
          </>
        )}
      </div>
      <div className={classes.poolPresaleActionContainer}>
        {presale.presaleVersion === "V1" &&
          v1String.map((method) => (
            <ActionPart
              classes={classes}
              method={method?.name || ""}
              args={method?.inputs || []}
              onWrite={onWrite}
            />
          ))}
        {presale.presaleVersion === "V2" &&
          v2String.map((method) => (
            <ActionPart
              classes={classes}
              method={method?.name || ""}
              args={method?.inputs || []}
              onWrite={onWrite}
            />
          ))}
        {presale.presaleVersion === "V3" &&
          v3String.map((method) => (
            <ActionPart
              classes={classes}
              method={method?.name || ""}
              args={method?.inputs || []}
              onWrite={onWrite}
            />
          ))}
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(StringCard)));
