import React, { useState, useEffect, useCallback } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Button,
  Typography,
  useTheme,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as ExternalLinkIcon } from "../../../assets/external-link.svg";
import GlobalStore from "../../../stores";

import { THEME_CHANGE } from "../../../constants";

import {
  bscTheme,
  ethTheme,
  mtcTheme,
  ftmTheme,
  solTheme,
  avaxTheme,
  pulseTheme,
} from "../../../theme";

const emitter = GlobalStore.emitter;
const store = GlobalStore.store;
const dispatcher = GlobalStore.dispatcher;

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: theme?.colors?.stake?.background,
    minWidth: "100%",
    minHeight: "100%",
    overflow: "auto",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "45px",
    "&:first-child": {
      paddingTop: "45px",
    },
    [theme.breakpoints.up("md")]: {
      borderRadius: "30px",
    },
  },
  modalRoot: {
    maxWidth: "640px",
    [theme.breakpoints.up("md")]: {
      borderRadius: "30px",
    },
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  closeButton: {
    position: "absolute",
    right: "32px",
  },
  screenMask: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  closeIcon: {
    color: theme?.colors?.header?.closeButton,
    width: "32px",
    height: "32px",
  },
  themeContainer: {
    display: "grid",
    gridTemplateColumns: 'auto',
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      gridGap: '20px',
      gridTemplateColumns: 'auto auto auto'
    },
  },
  logoIcon: {
    height: "80px",
    marginBottom: "16px",
  },
  themeName: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "20px",
    color: theme?.colors?.header?.text,
  },
  themeButton: {
    margin: "10px",
  },
  themeWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  themeDisabledButton: {
    opacity: 0.7,
  },
  comingSoon: {
    background: theme?.colors?.header?.comingSoon,
    color: theme?.colors?.header?.comingSoonText,
    borderRadius: "10px",
    padding: "8px",
    fontSize: "8px",
    fontWeight: "bold",
    position: "absolute",
    top: "35px",
  },
  bridgeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: "30px",
  },
  bridgeText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.colors?.header?.text,
    fontSize: "15px",
    lineHeight: "18px",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

const themes = [bscTheme, mtcTheme, ethTheme, ftmTheme, avaxTheme, pulseTheme];

const ThemeModal = ({ classes, closeModal, modalOpen }) => {
  const fullScreen = window.innerWidth < 900;
  const currentTheme = useTheme();
  const [themeType, setThemeType] = useState(
    store?.getStore("themeType") || "mtc"
  );

  useEffect(() => {
    if (store?.getStore("themeType") !== themeType) {
      dispatcher.dispatch({
        type: THEME_CHANGE,
        content: {
          themeType,
        },
      });
    }
  }, [themeType]);

  const handleSelect = (themeType) => {
    setThemeType(themeType);
    closeModal(true);
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      classes={{
        paperWidthSm: classes.modalRoot,
        scrollPaper: classes.screenMask,
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.themeContainer}>
          {themes.map((item) => (
            <Button
              classes={{
                root: classes.themeButton,
                label: classes.themeWrapper,
                disabled: classes.themeDisabledButton,
              }}
              onClick={() => {
                handleSelect(item.type);
              }}
              disabled={!item.enabled}
            >
              <img
                src={item.chainLogo}
                alt="bsc"
                className={classes.logoIcon}
              />
              <Typography className={classes.themeName}>
                {item.chainName}
              </Typography>
              {!item.enabled && (
                <Typography className={classes.comingSoon}>
                  Coming Soon
                </Typography>
              )}
            </Button>
          ))}
        </div>
        {currentTheme?.bridge && (
          <div className={classes.bridgeContainer}>
            <a
              href={currentTheme?.bridge}
              className={classes.bridgeText}
              target="_blank"
              rel="noopener noreferrer"
            >
              Bridge To {currentTheme?.chainName}
              <ExternalLinkIcon
                style={{ width: "20px", height: "20px", marginLeft: "5px" }}
              />
            </a>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(ThemeModal));
