import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { withNamespaces } from "react-i18next";
import Store from "../../../stores";

const styles = (theme) => ({
  root: {
    display: "flex",
    position: "relative",
    height: "40px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme?.colors?.header?.linkHoverBg,
    },
  },

  activeTag: {
    position: "absolute",
    top: "-22px",
    right: "5px",
    backgroundColor: theme?.colors.header?.tag,
    padding: "2px 6px",
    borderRadius: "3px",
  },

  activeTagMobile: {
    backgroundColor: theme?.colors.header?.tag,
    padding: "2px 6px",
    borderRadius: "3px",
    marginLeft: "5px",
  },

  disabledTag: {
    position: "absolute",
    top: "-22px",
    backgroundColor: theme?.colors.header?.tag,
    padding: "2px 6px",
    borderRadius: "3px",
    opacity: 0.5,
  },

  disabledTagMobile: {
    backgroundColor: theme?.colors.header?.tag,
    padding: "2px 6px",
    borderRadius: "3px",
    marginLeft: "5px",
    opacity: 0.5,
  },

  tagText: {
    fontStyle: "normal",
    fontWeight: "lighter",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.06em",
    color: "#3205fe",
  },

  activeSpan: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    position: "relative",
    color: theme?.colors.header?.link,
  },

  disabledSpan: {
    opacity: 0.5,
    cursor: "not-allowed",
    color: theme?.colors.header?.link,
  },

  linkText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    fontFamily: "'SFPro'",
    lineHeight: "18px",
    letterSpacing: "0.06em",
    whiteSpace: "nowrap",
  },

  linkBackgroundImg: {
    width: "100%",
    height: "40px",
    objectFit: "contain",
  },

  // linkTextSelectedMark: {
  //   position: "absolute",
  //   bottom: "-8px",
  //   width: "100%",
  //   height: "calc(100% + 12px)",
  //   borderBottom: "solid 3px " + theme?.colors.header?.linkSelected,
  // },

  // linkTextMarkHover: {
  //   position: "absolute",
  //   bottom: "-8px",
  //   width: "100%",
  //   height: "calc(100% + 12px)",
  // },
});

const store = Store.store;

class Link extends Component {
  render() {
    const {
      classes,
      text,
      background,
      to,
      disabled,
      tag,
      redirectedTo,
      screenType,
      selected,
      closeModal,
      onClick,
    } = this.props;

    if (screenType === "MOBILE") {
      return (
        <div
          className={classes.root}
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              if (redirectedTo) {
                store.setStore({ redirect: redirectedTo });
              }
              this.nav(to);
            }
            if (closeModal) {
              closeModal();
            }
          }}
        >
          <div className={disabled ? classes.disabledSpan : classes.activeSpan}>
            <Typography variant="h5" className={classes.linkText}>
              {background && <img src={background} alt="background" />}
              {text}
            </Typography>
          </div>
          {tag && (
            <div
              className={
                disabled ? classes.disabledTagMobile : classes.activeTagMobile
              }
            >
              <Typography variant="h6" className={classes.tagText}>
                {tag}
              </Typography>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div
          className={classes.root}
          onClick={() => {
            if (onClick) {
              onClick();
            } else {
              if (redirectedTo) {
                store.setStore({ redirect: redirectedTo });
              }
              if (!disabled) {
                this.nav(to);
              }
            }
            if (closeModal) {
              closeModal();
            }
          }}
        >
          {tag && (
            <div className={disabled ? classes.disabledTag : classes.activeTag}>
              <Typography variant="h6" className={classes.tagText}>
                {tag}
              </Typography>
            </div>
          )}
          <div className={disabled ? classes.disabledSpan : classes.activeSpan}>
            <Typography variant="h5" className={classes.linkText}>
              {background && (
                <img
                  src={background}
                  alt="background"
                  className={classes.linkBackgroundImg}
                />
              )}
              {text}
              {selected && <div className={classes.linkTextSelectedMark} />}
              {!disabled && <div className={classes.linkTextMarkHover} />}
            </Typography>
          </div>
        </div>
      );
    }
  }

  nav = (screen) => {
    const { externalLink, to } = this.props;
    if (externalLink) {
      window.open(screen);
    } else if (to) {
      this.props.history.push(screen);
    }
  };
}

export default withNamespaces()(withRouter(withStyles(styles)(Link)));
