import React from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Button,
  useTheme,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import HeaderLink from "../link/link";
import { FORMATE_ADDRESS } from "../../../constants";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: theme?.colors?.stake?.background,
    minWidth: "100%",
    minHeight: "100%",
    overflow: "hidden",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px",
  },
  closeButton: {
    position: "absolute",
    right: "16px",
  },
  linkContainer: {
    marginTop: "20%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "& > div": {
      marginTop: "10px",
    },
  },
  walletButton: {
    borderRadius: "0.375rem",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "200px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  applyButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "200px",
    padding: "8px 16px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  balanceInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors?.header?.balanceText,
  },
  bnbBalance: {
    fontSize: "15px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    color: theme?.colors?.header?.balanceValue,
  },
  closeIcon: {
    color: theme?.colors?.header?.closeButton,
    width: "32px",
    height: "32px",
  },
});

const RedirectModal = ({
  classes,
  closeModal,
  modalOpen,
  account,
  balance,
  onConnect,
  setStakeModalOpen,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const navUrl = history?.location?.pathname?.toLowerCase() || "";
  const fullScreen = window.innerWidth < 900;

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
    >
      <DialogContent classes={{ root: classes.root }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.linkContainer}>
          <HeaderLink
            screenType="MOBILE"
            text="Home"
            to="/home"
            selected={navUrl.includes("home")}
            closeModal={closeModal}
          />
          <HeaderLink
            screenType="MOBILE"
            text="Pools"
            to="/pools"
            selected={navUrl.includes("pools")}
            closeModal={closeModal}
          />
          <HeaderLink
            screenType="MOBILE"
            text="Voting"
            to="/voting"
            selected={navUrl.includes("voting")}
            closeModal={closeModal}
          />
          {theme?.type === "bsc" && (
            <HeaderLink
              screenType="MOBILE"
              text="Stats"
              to="/stats"
              selected={navUrl.includes("stats")}
              closeModal={closeModal}
            />
          )}
          <HeaderLink
            screenType="MOBILE"
            text="Advisors"
            to="/advisors"
            selected={navUrl.includes("advisors")}
            closeModal={closeModal}
          />
          <HeaderLink
            screenType="MOBILE"
            text="Litepaper"
            to="/litepaper"
            selected={navUrl.includes("faq")}
            closeModal={closeModal}
          />
          <HeaderLink
            screenType="MOBILE"
            text="Staking"
            onClick={(ev) => {
              setStakeModalOpen(true);
            }}
            closeModal={closeModal}
          />
          <HeaderLink
            screenType="MOBILE"
            text="Liquidity Mining"
            to="/mining"
            selected={navUrl.includes("mining")}
          />
          <HeaderLink
            screenType="MOBILE"
            text="Store"
            to={"https://shop.starter.xyz/"}
            externalLink={true}
          />
          <HeaderLink
            screenType="MOBILE"
            text="How-to Guide"
            to={theme.guide}
            externalLink={true}
          />
          <div className={classes.actionContainer}>
            <Button
              classes={{
                root: classes.walletButton,
                label: classes.buttonLabel,
              }}
              onClick={(ev) => {
                if (onConnect) {
                  onConnect();
                }
              }}
            >
              {account && account.address
                ? FORMATE_ADDRESS(account.address)
                : "Connect Wallet"}
            </Button>
          </div>
          <div className={classes.actionContainer}>
            <Button
              classes={{
                root: classes.applyButton,
                label: classes.buttonLabel,
              }}
              onClick={() => {
                history.push("/apply");
              }}
            >
              Create Pool
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(RedirectModal));
