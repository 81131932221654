import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Typography, useTheme, CircularProgress } from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import Tooltip from "@material-ui/core/Tooltip";
import { withNamespaces } from "react-i18next";

import moment from "moment";

import { ReactComponent as ThumbUpSvg } from "../../assets/thumb-up.svg";
import { ReactComponent as TelegramSvg } from "../../assets/telegram.svg";
import { ReactComponent as ATagSvg } from "../../assets/atag.svg";
import CertifiedBadge from "../../assets/certified-mark.svg";

import { tokens } from "../../constants";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    maxWidth: "360px",
    minWidth: "320px",
    maxHeight: "360px",
    width: "100%",
    height: "100%",
    borderRadius: "30px",
    backgroundColor: theme?.colors.poolCard?.background,
    border: "solid 2px " + theme?.colors.poolCard?.border,
    boxShadow: theme?.colors?.poolCard?.boxShadow,
    padding: "40px",
    fontFamily: "SFPro",
  },
  launchIcon: {
    position: "absolute",
    top: "20px",
    right: "20px",
    color: theme?.colors.poolCard?.title,
    cursor: "pointer",
    "&:hover": {
      opacity: "0.8",
    },
  },
  topPoolType: {
    minWidth: "80px",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "24px",
    borderRadius: "16px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.colors.poolCard?.poolTypeText,
    whiteSpace: "nowrap",
  },
  poolHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "25px",
  },
  poolImage: {
    width: "60px",
    height: "60px",
    marginRight: "20px",
  },
  logoContainer: {
    display: "flex",
    position: "relative",
  },
  badge: {
    position: "absolute",
    right: "-40px",
    width: "30px",
    height: "30px",
  },
  poolTitle: {
    marginTop: "30px",
    fontSize: "20px",
    lineHeight: "30px",
    color: theme?.colors.poolCard?.title,
    cursor: "pointer",
    wordBreak: "break-all",
    "&:hover": {
      color: theme?.colors.poolCard?.textSecondary,
    },
    position: "relative",
  },
  per1BNB: {
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors.poolCard?.title,
    marginBottom: "8px",
  },
  saleValue: {
    fontSize: "25px",
    fontWeight: "normal",
    lineHeight: "30px",
    letterSpacing: "0px",
    textAlign: "left",
    color: theme?.colors.poolCard?.textSecondary,
    marginBottom: "15px",
  },
  saleStatusContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "25px",
  },
  salesStatusBar: {
    marginRight: "10px",
    width: "100%",
    height: "16px",
    borderRadius: "12px",
    backgroundColor: theme?.colors.poolCard?.statusBarBackground,
    marginBottom: "8px",
  },
  salesStatusSubBar: {
    display: "flex",
    width: "100%",
    height: "50px",
    alignItems: "center",
    justifyContent: "space-between",
  },
  salesStatusBarFilled: {
    height: "16px",
    borderRadius: "12px",
  },
  salesStatusText: {
    fontSize: "15px",
    color: theme?.colors.poolCard?.statusBarLabel,
    whiteSpace: "nowrap",
    marginRight: "8px",
  },
  salesRequiredText: {
    fontSize: "15px",
    color: theme?.colors.poolCard?.statusBarLabel,
    marginBottom: "25px",
  },
  maximumConnectContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginRight: "20px",
  },
  maximumWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  connectWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  maximumConnectLabel: {
    fontSize: "15px",
    lineHeight: "18px",
    color: theme?.colors.poolCard?.title,
    marginBottom: "8px",
  },
  maximumValue: {
    fontSize: "20px",
    lineHeight: "24px",
    color: theme?.colors.poolCard?.textSecondary,
  },
  connectIconsWrapper: {
    display: "flex",
    "& > *": {
      width: "24px",
      height: "24px",
      color: theme?.colors.poolCard?.linkIcon,
      marginRight: "20px",
      "&:hover": {
        color: theme?.colors.poolCard?.linkIconHover,
      },
      cursor: "pointer",
    },
  },
  poolPercentageText: {
    color: theme?.colors?.poolCard?.statusBarLabel,
    fontSize: "15px",
    fontWeight: "normal",
    lineHeight: "18px",
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme?.colors?.presale?.loadingBackground,
    zIndex: 4,
    position: "absolute",
    top: "0px",
    left: "0px",
  },
  loadingIcon: {
    zIndex: 5,
    color: theme?.colors?.presale?.loadingIcon,
    position: "absolute",
    top: "100px",
    left: "50%",
  },
  topPoolImage: {
    position: "absolute",
    top: "-36px",
    left: "calc(50% - 45px)",
    minWidth: "90px",
    paddingLeft: "5px",
    paddingRight: "5px",
    height: "90px",
    borderRadius: "50%",
    backgroundColor: theme?.colors?.poolCard?.background,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.colors.poolCard?.poolTypeText,
    whiteSpace: "nowrap",
  },
  poolDefaultImage: {
    zIndex: 4,
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },
});

const getPoolType = (
  isStarted,
  isEnded,
  totalCollected,
  hardCap,
  softCap,
  cancelled,
  votePassed
) => {
  if (cancelled) return "CANCELLED";
  if (totalCollected >= hardCap) return "FILLED";
  if (isEnded) {
    if (totalCollected < softCap) {
      return "EXPIRED";
    }
    return "CLOSED";
  }
  if (!isStarted) {
    return "NOTOPENED";
  }
  return "ONGOING";
};

const getStatusBarColor = (poolType) => {
  if (poolType === "FILLED") return "GREEN";
  if (poolType === "CLOSED") return "ORANGE";
  if (poolType === "NOTOPENED") return "YELLOW";
  if (poolType === "ONGOING") return "YELLOW";
  if (poolType === "EXPIRED") return "RED";
  return "RED";
};

const getRemainingTime = (remainingTime) => {
  const remainingDay = Math.floor(remainingTime / 3600 / 24);
  const remainingHours = Math.floor(remainingTime / 3600);
  const remainingMinutes = Math.floor(remainingTime / 60);
  const remainingSeconds = Math.floor(remainingTime);

  if (remainingDay > 0) {
    if (remainingDay === 1) {
      return `${remainingDay} day`;
    }
    return `${remainingDay} days`;
  }

  if (remainingHours > 0) {
    return `${remainingHours}hrs ${remainingMinutes % 60} mins`;
  }

  if (remainingMinutes > 0) {
    return `${remainingMinutes} mins`;
  }

  if (remainingSeconds > 0) {
    return `${remainingSeconds} secs`;
  }

  return "";
};

const PoolCard = ({
  classes,
  openTime,
  closeTime,
  image,
  title,
  token,
  tokenPrice,
  salesValue,
  percentage,
  maximum,
  minimum,
  maximumInvest,
  liquidityAllocation,
  presaleType,
  telegram,
  externalLink,
  url,
  version,
  cancelled = false,
  bnbPrice = 0,
  isVotePassed = true,
  fundingTokenAddress,
}) => {
  const history = useHistory();
  const fundingToken = tokens[fundingTokenAddress] || { label: "BNB" };
  const isStarted = moment.unix(openTime).isBefore(moment.now());
  const isEnded = moment.unix(closeTime).isBefore(moment.now());
  const poolType = getPoolType(
    isStarted,
    isEnded,
    salesValue,
    maximum,
    minimum,
    cancelled,
    isVotePassed
  );

  const statusBarColorId = getStatusBarColor(poolType);

  const currentTimestamp = moment.now() / 1000;

  const remainingTime = isEnded
    ? 0
    : isStarted
    ? closeTime - currentTimestamp
    : openTime - currentTimestamp;

  const theme = useTheme();

  const [logoImage, setLogoImage] = useState(image || "pools/default.png");

  useEffect(() => {
    setLogoImage(image);
  }, [image]);

  return (
    <div className={classes.root}>
      {url && (
        <LaunchIcon
          className={classes.launchIcon}
          onClick={() => {
            history.push(url);
          }}
        />
      )}
      {!title ||
        (!openTime && (
          <div className={classes.loadingContainer}>
            <CircularProgress className={classes.loadingIcon} size={60} />
          </div>
        ))}
      <div className={classes.topPoolImage}>
        <img
          className={classes.poolDefaultImage}
          src={logoImage}
          alt="pool"
          onError={(er) => {
            setLogoImage("pools/default.svg");
          }}
        />
      </div>
      <div className={classes.poolHeader}>
        <Typography
          variant="h3"
          className={classes.poolTitle}
          onClick={() => {
            history.push(url);
          }}
        >
          {title}
          <Tooltip title="Certified START">
            <img
              src={theme?.certifiedBadge || CertifiedBadge}
              className={classes.badge}
              alt="badge"
            />
          </Tooltip>
        </Typography>
      </div>
      <Typography variant="h5" className={classes.per1BNB}>
        {tokenPrice} {fundingToken?.label} per Token
      </Typography>
      <Typography variant="h4" className={classes.saleValue}>
        {salesValue > 0 &&
          `${salesValue.toLocaleString()} ${fundingToken?.label}`}
      </Typography>
      <div className={classes.saleStatusContainer}>
        <div className={classes.salesStatusBar}>
          <div
            className={classes.salesStatusBarFilled}
            style={{
              width: percentage <= 100 ? `${percentage}%` : "100%",
              backgroundColor:
                theme?.colors.poolCard?.statusBarFilled[statusBarColorId],
            }}
          />
        </div>
        <div className={classes.salesStatusSubBar}>
          <Typography variant="h5" className={classes.poolPercentageText}>
            {maximum ? ((salesValue / maximum) * 100).toFixed(2) : "0"}% (Min{" "}
            {maximum ? ((minimum / maximum) * 100).toFixed(2) : "0"}
            %)
          </Typography>
          <Typography variant="h5" className={classes.poolPercentageText}>
            {salesValue.toFixed(2)} /&nbsp;
            {maximum.toFixed(2)} {fundingToken?.label}
          </Typography>
        </div>
      </div>
      <div className={classes.maximumConnectContainer}>
        <div
          className={classes.topPoolType}
          style={{
            backgroundColor: theme?.colors.poolCard?.poolType[poolType],
          }}
        >
          {poolType === "NOTOPENED" && "opens in "}
          {poolType === "ONGOING" && "ending in "}
          {poolType === "FILLED" && "filled"}
          {poolType === "EXPIRED" && "expired"}
          {poolType === "CANCELLED" && "cancelled"}
          {poolType === "NOT_PASSED" && "not passed"}
          {poolType !== "FILLED" &&
            poolType !== "CANCELLED" &&
            poolType !== "NOT_PASSED" &&
            remainingTime > 0 &&
            getRemainingTime(remainingTime)}
        </div>
        {/* {presaleType === "0" ? (
          <div className={classes.maximumWrapper}>
            <Typography className={classes.maximumConnectLabel}>
              HardCap
            </Typography>
            <Typography className={classes.maximumValue}>
              {maximum}&nbsp;
              {fundingToken.label}
            </Typography>
          </div>
        ) : (
          <div className={classes.maximumWrapper}>
            <Typography className={classes.maximumConnectLabel}>
              Liquidity Allocation
            </Typography>
            <Typography className={classes.maximumValue}>
              {liquidityAllocation} %
            </Typography>
          </div>
        )} */}
        <div className={classes.connectWrapper}>
          <div className={classes.connectIconsWrapper}>
            {telegram && (
              <TelegramSvg
                onClick={() => {
                  window.open(telegram);
                }}
              />
            )}
            {externalLink && (
              <ATagSvg
                onClick={() => {
                  window.open(externalLink);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withNamespaces()(withRouter(withStyles(styles)(PoolCard)));
