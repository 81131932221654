import React, { useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  DialogContent,
  Dialog,
  Slide,
  IconButton,
  Button,
  Typography,
  Input,
} from "@material-ui/core";
import { ReactComponent as CheckSVG } from "../../../assets/vote-check.svg";
import { ReactComponent as WarningSVG } from "../../../assets/warning.svg";

import CloseIcon from "@material-ui/icons/Close";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const styles = (theme) => ({
  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: theme?.colors?.stake?.background,
    minWidth: "100%",
    minHeight: "100%",
    overflow: "auto",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "45px",
    "&:first-child": {
      paddingTop: "45px",
    },
    borderRadius: "30px",
  },
  modalRoot: {
    maxWidth: "720px",
    borderRadius: "30px",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  closeButton: {
    position: "absolute",
    right: "32px",
  },
  screenMask: {
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  walletButton: {
    borderRadius: "0.375rem",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonText,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    margin: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  cancelButton: {
    borderRadius: "14px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,.05)",
    backgroundColor: "transparent",
    border: "solid 2px" + theme?.colors?.wallet?.buttonBackground,
    color: theme?.colors?.wallet?.buttonTextSecondary,
    textTransform: "capitalize",
    height: "40px",
    width: "150px",
    padding: "8px 16px",
    margin: "15px",
    "&:hover": {
      backgroundColor: theme?.colors?.wallet?.buttonBackgroundHover,
      color: theme?.colors?.wallet?.buttonText,
    },
  },
  buttonLabel: {
    fontWeight: "bold",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
  modalHeader: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "30px",
    fontWeight: "normal",
    lineHeight: "36px",
    marginBottom: "30px",
  },
  votingWarning: {
    color: theme?.colors?.presale?.cardTitle,
    fontSize: "20px",
    fontWeight: "normal",
    lineHeight: "18px",
    marginBottom: "30px",
    textAlign: "center",
  },
  voteDescription: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "30px",
  },
  voteTipText: {
    color: theme?.colors?.presale?.commentText,
    fontSize: "17px",
    fontWeight: "normal",
    lineHeight: "25px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  closeIcon: {
    color: theme?.colors?.header?.closeButton,
    width: "32px",
    height: "32px",
  },
});

const VoteModal = ({ classes, closeModal, modalOpen, salesTitle, onVote }) => {
  const fullScreen = window.innerWidth < 900;

  return (
    <Dialog
      open={modalOpen}
      onClose={closeModal}
      fullWidth={true}
      maxWidth={"sm"}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      classes={{
        paperWidthSm: classes.modalRoot,
        scrollPaper: classes.screenMask,
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeModal}
        >
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
        <div className={classes.modalContainer}>
          <Typography variant="h3" className={classes.modalHeader}>
            Confirmation
          </Typography>
          <Typography variant="h5" className={classes.votingWarning}>
            You are about to vote to <b>{salesTitle}</b>. This action cannot be
            undone.
            <br />
            Please confirm that you are aware of spotting scams.
          </Typography>
          <div className={classes.voteDescription}>
            <Typography variant="h3" className={classes.voteTipText}>
              1. This project has purpose and potential <CheckSVG />
            </Typography>
            <Typography variant="h3" className={classes.voteTipText}>
              2. Github has active development activity <CheckSVG />
            </Typography>
            <Typography variant="h3" className={classes.voteTipText}>
              3. Smart Contract audit results are positive <CheckSVG />
            </Typography>
            <Typography variant="h3" className={classes.voteTipText}>
              4. Devs are not anon or I am comfortable with them <CheckSVG />
            </Typography>
            <Typography variant="h3" className={classes.voteTipText}>
              5. Tokenomics are generally sound <CheckSVG />
            </Typography>
            <Typography variant="h3" className={classes.voteTipText}>
              6. This does not look like a rug or scam <CheckSVG />
            </Typography>
          </div>
          <div className={classes.actionContainer}>
            <Button
              classes={{
                root: classes.walletButton,
                label: classes.buttonLabel,
              }}
              onClick={(ev) => {
                if (onVote) {
                  onVote(true);
                }
                closeModal();
              }}
            >
              <CheckSVG
                style={{ width: "15px", height: "15px", marginRight: "8px" }}
              />{" "}
              YES
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default withRouter(withStyles(styles)(VoteModal));
